import React from 'react';
import './index.css';
export default function RadioComponent(props) {
  const { onChange, label, checked } = props;

  return (
    <>
      <label className="radioContainer">
        {label}
        <input
          type="radio"
          checked={checked}
          onChange={(e) => {
            let checked = e.target.checked;
            onChange(checked);
          }}
          name="radio"
        />
        <span className="radioCheckmark"></span>
      </label>
      {/* <div style={{ display: 'flex' }}>
        <div>
          <input
            type="radio"
            id={id}
            name="age"
            checked={checked}
            onChange={(e) => {
              let checked = e.target.checked;
              onChange(checked);
            }}
          />
        </div>
        <div>
          <label htmlFor={htmlFor} style={{ color: '#fff' }}>
            {label}
          </label>
        </div>
      </div> */}
    </>
  );
}
