import React from 'react';
// import bg from '../../assets/img/dashBoardBg.png';
export default function CountBox(props) {
  const { bg, count, title, onClick, image } = props;
  return (
    <div
      onClick={onClick}
      style={{
        minHeight: 150,
        width: '100%',
        position: 'relative',
        borderTopRightRadius: 110,
        background: bg,
        borderTopLeftRadius: 25,
        borderBottomLeftRadius: 25,
        borderBottomRightRadius: 20
      }}>
      <div
        style={{
          position: 'absolute',
          bottom: 20,
          left: 20
        }}>
        <div className="dashBoard-total-title">{count} </div>
        <div className="dashBoard-total-subTitle text-muted">{title}</div>
      </div>
      <div
        style={{
          background: 'white',
          height: 70,
          width: 70,
          borderRadius: 50,
          position: 'absolute',
          top: 0,
          right: 15,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <img src={image} alt="" width={'30'} />
      </div>
    </div>
  );
}
