import React, { useState } from 'react';
import { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Cars from '../../assets/img/latestcars.png';
import { useDispatch } from 'react-redux';
import { updateFavList } from 'store/slice/landingPage';
import SliderButton from 'components/sliderButton';
import { useResponsive } from 'themes/useResponsive';
import LatestCardImageBox from 'pages/landingPage/subComponent/latestCars/latestCardImageBox';

export default function Swiper(props) {
  // const { allLatestCarList } = useSelector((state) => state.landingPage);
  const { cameFrom, sliedData, excludeLinks } = props;
  const dispatch = useDispatch();

  const [arrowIndex, setArrowIndex] = useState(0);
  let sliderRef = useRef(null);
  const next = (event) => {
    console.log('event', event);
    sliderRef.slickNext();
  };
  const { screenType } = useResponsive();
  const beforeChange = (prev, next) => {
    setArrowIndex({ index: next, prev: prev });
  };

  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    arrows: false,
    dots: false,
    swipeToslide: true,
    beforeChange: beforeChange,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: screenType === 'MOBILE' ? 1 : screenType === 'TABLET' ? 2 : 4,
    slidesToScroll: screenType === 'MOBILE' ? 1 : screenType === 'TABLET' ? 2 : 4
  };

  const updateFav = (request) => {
    dispatch(updateFavList(request, updateFav));
  };

  return (
    <div className="slider-container">
      <Slider
        ref={(slider) => {
          sliderRef = slider;
        }}
        {...settings}>
        {sliedData?.map((item, index) => {
          return (
            <LatestCardImageBox
              key={index}
              image={Cars}
              data={item?.productData}
              excludeLinks={excludeLinks}
              screenType={screenType}
              cameFrom={cameFrom}
              updateFav={updateFav}
            />
          );
        })}
      </Slider>
      {/* {!cameFrom && ( */}
      <SliderButton
        previous={previous}
        next={next}
        updateFav={updateFav}
        // data={data}
        index={arrowIndex?.index ? arrowIndex?.index : 0}
        prev={arrowIndex?.index + 1 + arrowIndex?.prev + 1}
      />
      {/* )} */}
    </div>
  );
}
