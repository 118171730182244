import { createSlice } from '@reduxjs/toolkit';
import api from '../../../services';
import toast from 'react-hot-toast';
import { RENTEDWHEELSAPI } from '../../../services/apiConstant';

const slice = createSlice({
  name: 'uploadDocument',
  initialState: {
    addImageByUser: null,
    allDocumentType: null,
    uploadData: [],
    removeDocument: [],
    getDocumentsList: []
  },
  reducers: {
    uploadDocumentSuccess: (state, action) => {
      state.addImageByUser = action.payload;
    },
    getAllDocumentTypeSuccess: (state, action) => {
      state.allDocumentType = action.payload;
    },
    addUserDocumentSuccess: (state, action) => {
      state.getDocumentsList = [...state.getDocumentsList, ...action.payload];
    },
    deleteDocumentSuccess: (state, action) => {
      state.getDocumentsList = state.getDocumentsList.filter(
        (x) => x.id !== action.payload.documentId
      );
    },
    getUserDocumentSuccess: (state, action) => {
      state.getDocumentsList = action.payload;
    }
  }
});

const {
  uploadDocumentSuccess,
  getAllDocumentTypeSuccess,
  addUserDocumentSuccess,
  deleteDocumentSuccess,
  getUserDocumentSuccess
} = slice.actions;

export const uploadFile = (request, setShowModal) => async (dispatch) => {
  api
    .post(RENTEDWHEELSAPI.uploadDocument.uploadFile, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(uploadDocumentSuccess(result.data));
        setShowModal(false);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const getAllDocumentType = (request) => async (dispatch) => {
  api
    .get(RENTEDWHEELSAPI.uploadDocument.getAllDocumentType, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(getAllDocumentTypeSuccess(result.data));
        console.log('result', result?.data);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const addUserDocument =
  (request, setShowModal, setFormData, setImagesPath, setuploadImages) => async (dispatch) => {
    api
      .post(RENTEDWHEELSAPI.uploadDocument.addUserDocument, request)
      .then((response) => {
        let result = response.data;
        if (result.status == 1) {
          console.log(result, 'result');
          setShowModal(false);
          dispatch(addUserDocumentSuccess(result.data));
        } else {
          toast.error(result.message);
        }
        setFormData((formData) => ({
          ...formData,
          documentType: ''
        }));
        setImagesPath([]);
        setuploadImages([]);
      })
      .catch(() => {});
  };
export const deleteDocument = (request) => async (dispatch) => {
  api
    .post(RENTEDWHEELSAPI.uploadDocument.deleteDocument, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(deleteDocumentSuccess(request));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const getUserDocument = (request, setIsLoading) => async (dispatch) => {
  api
    .get(RENTEDWHEELSAPI.uploadDocument.getUserDocument, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(getUserDocumentSuccess(result.data));
      } else {
        // toast.error(result.message);
      }
      setIsLoading(false);
    })
    .catch(() => {});
};

export default slice.reducer;
