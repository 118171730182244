import { useResponsive } from '../../themes/useResponsive';

const DeleteMyCar = ({ handleConfirm, closeDeleteDialogRef }) => {
  const { screenType } = useResponsive();

  //   const handleConfirmDelete = () => {
  //     const request = {
  //       carId: 'carId'
  //     };
  //     dispatch(deleteCar(request));
  //   };
  return (
    <>
      <div
        className="modal fade bd-example-modal-lg"
        id="exampleModalDeleteCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className={`modal-content py-2 ${screenType != 'desktop' ? '' : 'px-4'} `}>
            <div className="modal-header">
              <h1
                className="modal-title"
                id="exampleModalLongTitle"
                style={{ marginLeft: '-0.5rem' }}>
                Delete Car
              </h1>
            </div>
            <div className="modal-body">
              <h5 style={{ fontFamily: 'Circular Std Book' }}>
                Are you sure you want to delete this car?
              </h5>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}>
              <button
                ref={closeDeleteDialogRef}
                type="button"
                className="smallBtn"
                data-dismiss="modal"
                style={{ width: 100 }}>
                Cancel
              </button>
              <button
                type="button"
                className="smallBtn"
                style={{ width: 100 }}
                onClick={handleConfirm}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteMyCar;
