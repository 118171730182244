import React, { useContext } from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import '../style.css';
import { useResponsive } from 'themes/useResponsive';
import MyCarContext from 'components/context/myCarContext';
import ColoredButton from 'components/button/colorFullButtons';
import profile from '../../../../assets/img/noProfile.jpg';

export default function CustomerReviews() {
  const { screenType } = useResponsive();
  const { carDetails } = useContext(MyCarContext);
  const [reviewsHeight, setReviewsHeight] = useState(false);

  return carDetails?.ratingdata.length > 0 ? (
    <>
      <h4 className="mt-5  HeadingAll">Customer Reviews</h4>
      <div
        style={{
          height: !reviewsHeight ? (screenType === 'MOBILE' ? 150 : 300) : 'auto',
          overflow: 'hidden'
        }}>
        {carDetails?.ratingdata?.map((item, index) => {
          return (
            <>
              <div className="pt-3" key={index}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <div style={{ height: 40, width: 40, borderRadius: 25 }}>
                      <img
                        src={profile}
                        // item?.userProfile ? item?.userProfile :
                        style={{ objectFit: 'cover' }}
                        alt=""
                        width={'100%'}
                      />
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div className="d-block">
                      <div className="circularBlack" style={{ color: '#132649' }}>
                        {item?.userName}
                      </div>
                      <div className="circularBook" style={{ color: '#6c757dad', fontSize: 14 }}>
                        {item?.time}
                      </div>
                    </div>
                  </div>
                  <span
                    className="badge text-dark d-flex mt-2 px-3 "
                    style={{
                      background: '#FFF8E7',
                      height: 30,
                      borderRadius: 15,
                      paddingTop: '0.3rem'
                    }}>
                    <Icon icon="fluent:star-12-filled" height={18} color="#F1AE01" />
                    &nbsp;&nbsp;
                    <div
                      style={{
                        fontSize: 15,
                        fontFamily: 'Circular Std Medium',
                        paddingTop: '0.2rem'
                      }}>
                      {item?.rating}
                    </div>
                  </span>
                </div>
                <div className="circularBook pt-2" style={{ color: '#6c757dad', fontSize: 14 }}>
                  {item?.review}
                </div>
              </div>
              <hr />
            </>
          );
        })}
      </div>
      {carDetails?.ratingdata?.length > 1 ? (
        <div className="d-flex justify-content-center mt-2">
          <ColoredButton
            onClick={() => setReviewsHeight(!reviewsHeight)}
            style={{ background: '#132649' }}
            title={
              <div className="circularBook px-3" style={{ fontSize: 17 }}>
                {' '}
                {reviewsHeight ? 'Show less' : 'Show all'}{' '}
                <span style={{ color: '#F1AE01' }}>Reviews</span>
              </div>
            }
          />
        </div>
      ) : (
        ''
      )}
    </>
  ) : (
    ''
  );
}
