import React, { useContext, useEffect, useState } from 'react';
import { useResponsive } from '../../../../../themes/useResponsive';
import Input from '../../../../../components/input';
import MyCarContext from '../../../../../components/context/myCarContext';
import Button from '../../../../../components/button';
// import SelectInput from '../../../../../components/select';
import { useDispatch, useSelector } from 'react-redux';
import { destinationsList } from '../../../../../store/slice/landingPage';
import Autocomplete from 'react-google-autocomplete';
import SelectInput from 'components/select';

export default function BasicDetail() {
  const { myCarData, setMyCarData, mileageInfoRef, fuelData, setFuelData } =
    useContext(MyCarContext);
  const { destinationCity } = useSelector((state) => state.landingPage);
  const [searchData, setSearchData] = useState('');
  const { screenType } = useResponsive();
  const dispatch = useDispatch();
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');

  const handleNext = () => {
    if (!fuelData.withFule && !fuelData.withoutFule) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        fuleType: 'Please select fuel type'
      }));
      return;
    }
    if (fuelData.withFule && !myCarData?.amountPerHour) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        amountPerHourErr: 'Please enter per hour amount'
      }));
      return;
    }
    if (fuelData.withFule && !myCarData?.amountPerDay) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        amountPerDayErr: 'Please enter per day amount'
      }));
      return;
    }
    if (fuelData.withFule && !myCarData?.amountPerWeek) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        amountPerWeekErr: 'Please enter per week amount'
      }));
      return;
    }
    if (fuelData.withoutFule && !myCarData?.amountPerHours) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        amountPerHourErrs: 'Please enter per hour amount'
      }));
      return;
    }
    if (fuelData.withoutFule && !myCarData?.amountPerDays) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        amountPerDayErrs: 'Please enter per day amount'
      }));
      return;
    }
    if (fuelData.withoutFule && !myCarData?.amountPerWeeks) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        amountPerWeekErrs: 'Please enter per week amount'
      }));
      return;
    }
    if (!myCarData?.address) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        addressError: 'Please select location'
      }));
      return;
    }
    if (!myCarData?.cityName) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        cityNameErr: 'Please select city'
      }));
      return;
    }

    mileageInfoRef.current && mileageInfoRef.current.click();
  };

  useEffect(() => {
    fetchCityList();
  }, []);
  const fetchCityList = () => {
    let request = {
      language: 'en'
    };
    dispatch(destinationsList(request));
  };
  useEffect(() => {
    setMyCarData((myCarData) => ({
      ...myCarData,
      longitude: lng
    }));
  }, [lng]);

  useEffect(() => {
    setMyCarData((myCarData) => ({
      ...myCarData,
      latitude: lat
    }));
  }, [lat]);

  const getPlaceDetail = (place) => {
    setLat(place.geometry.location.lat);
    setLng(place.geometry.location.lng);
    setSearchData(place.formatted_address);
    setMyCarData((myCarData) => ({
      ...myCarData,
      address: place.formatted_address
    }));
  };

  return (
    <>
      <div className="row">
        <div className="col8 col-lg-8 col-sm-12">
          <span className="myCar-label">Fuel</span>
          <div className="d-flex gap-4">
            <div className="circularMedium pt-2">With Fuel Car</div>
            <input
              type="checkbox"
              style={{ width: '1.1rem', marginTop: '-6px', objectFit: 'cover' }}
              checked={fuelData.withFule}
              // disabled={!fuelData.withoutFuel ? true : false}
              onChange={(e) => {
                setFuelData((fuelData) => ({
                  ...fuelData,
                  withFule: e.target.checked
                }));

                setMyCarData((myCarData) => ({
                  ...myCarData,
                  fuleType: ''
                }));
              }}
            />

            <div className="circularMedium pt-2">Without Fuel Car</div>
            <input
              type="checkbox"
              style={{ width: '1.1rem', marginTop: '-6px' }}
              checked={fuelData.withoutFule}
              onChange={(e) => {
                setFuelData((fuelData) => ({
                  ...fuelData,
                  withoutFule: e.target.checked
                }));

                setMyCarData((myCarData) => ({
                  ...myCarData,
                  fuleType: ''
                }));
              }}
              value={fuelData?.withoutFule}
            />
          </div>
          <div
            style={{
              color: 'red',
              fontSize: 13,
              marginTop: '-0.5rem',
              paddingLeft: 5
            }}
            className="text-error">
            {myCarData?.fuleType}
          </div>
          <div className="d-flex gap-3"></div>
        </div>
      </div>
      {fuelData?.withFule ? (
        <>
          <div className="py-4 mb-5">
            <div className="row mt-4">
              <div className={screenType === 'desktop' ? 'col-4' : 'col-12'}>
                <div className="myCar-label">Per Hours</div>
                <div className="form-group pt-3 w-100">
                  <Input
                    type="number"
                    onChange={(val) => {
                      if (val) {
                        setMyCarData((myCarData) => ({
                          ...myCarData,
                          amountPerHour: Number(val),
                          amountPerHourErr: ''
                        }));
                      } else {
                        setMyCarData((myCarData) => ({
                          ...myCarData,
                          amountPerHour: ''
                        }));
                      }
                    }}
                    value={myCarData?.amountPerHour}
                    formError={myCarData?.amountPerHourErr}
                    placeholder={'Enter amount per hours'}
                    endName={
                      <div className="circularBold" style={{ color: '#6CD2C2', fontSize: 20 }}>
                        N$
                      </div>
                    }
                  />
                </div>
              </div>
              <div className={screenType === 'desktop' ? 'col-4' : 'col-12'}>
                <div className="myCar-label">Per Day</div>
                <div className="form-group pt-3 w-100">
                  <Input
                    type="number"
                    onChange={(val) => {
                      if (val) {
                        setMyCarData((myCarData) => ({
                          ...myCarData,
                          amountPerDay: Number(val),
                          amountPerDayErr: ''
                        }));
                      } else {
                        setMyCarData((myCarData) => ({
                          ...myCarData,
                          amountPerDay: ''
                        }));
                      }
                    }}
                    value={myCarData?.amountPerDay}
                    formError={myCarData?.amountPerDayErr}
                    placeholder={'Enter amount per day'}
                    endName={
                      <div className="circularBold" style={{ color: '#6CD2C2', fontSize: 20 }}>
                        N$
                      </div>
                    }
                  />
                </div>
              </div>{' '}
              <div className={screenType === 'desktop' ? 'col-4 ' : 'col-12'}>
                <div className="myCar-label">Per Week</div>
                <div className="form-group pt-3 w-100">
                  <Input
                    type="number"
                    onChange={(val) => {
                      if (val) {
                        setMyCarData((myCarData) => ({
                          ...myCarData,
                          amountPerWeek: Number(val),
                          amountPerWeekErr: ''
                        }));
                      } else {
                        setMyCarData((myCarData) => ({
                          ...myCarData,
                          amountPerWeek: ''
                        }));
                      }
                    }}
                    value={myCarData?.amountPerWeek}
                    formError={myCarData?.amountPerWeekErr}
                    placeholder={'Enter amount per  week'}
                    endName={
                      <div className="circularBold" style={{ color: '#6CD2C2', fontSize: 20 }}>
                        N$
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}

      {fuelData?.withoutFule ? (
        <>
          <div className="py-4 mb-5">
            <div className="row mt-4">
              <div className={screenType === 'desktop' ? 'col-4' : 'col-12'}>
                <div className="myCar-label">Per Hours</div>
                <div className="form-group pt-3 w-100">
                  <Input
                    type="number"
                    onChange={(val) => {
                      if (val) {
                        setMyCarData((myCarData) => ({
                          ...myCarData,
                          amountPerHours: Number(val),
                          amountPerHourErrs: ''
                        }));
                      } else {
                        setMyCarData((myCarData) => ({
                          ...myCarData,
                          amountPerHours: ''
                        }));
                      }
                    }}
                    value={myCarData?.amountPerHours}
                    formError={myCarData?.amountPerHourErrs}
                    placeholder={'Enter amount per hours'}
                    endName={
                      <div className="circularBold" style={{ color: '#6CD2C2', fontSize: 20 }}>
                        N$
                      </div>
                    }
                  />
                </div>
              </div>
              <div className={screenType === 'desktop' ? 'col-4' : 'col-12'}>
                <div className="myCar-label">Per Day</div>
                <div className="form-group pt-3 w-100">
                  <Input
                    type="number"
                    onChange={(val) => {
                      if (val) {
                        setMyCarData((myCarData) => ({
                          ...myCarData,
                          amountPerDays: Number(val),
                          amountPerDayErrs: ''
                        }));
                      } else {
                        setMyCarData((myCarData) => ({
                          ...myCarData,
                          amountPerDays: ''
                        }));
                      }
                    }}
                    value={myCarData?.amountPerDays}
                    formError={myCarData?.amountPerDayErrs}
                    placeholder={'Enter amount per day'}
                    endName={
                      <div className="circularBold" style={{ color: '#6CD2C2', fontSize: 20 }}>
                        N$
                      </div>
                    }
                  />
                </div>
              </div>{' '}
              <div className={screenType === 'desktop' ? 'col-4 ' : 'col-12'}>
                <div className="myCar-label">Per Week</div>
                <div className="form-group pt-3 w-100">
                  <Input
                    type="number"
                    onChange={(val) => {
                      if (val) {
                        setMyCarData((myCarData) => ({
                          ...myCarData,
                          amountPerWeeks: Number(val),
                          amountPerWeekErrs: ''
                        }));
                      } else {
                        setMyCarData((myCarData) => ({
                          ...myCarData,
                          amountPerWeeks: ''
                        }));
                      }
                    }}
                    value={myCarData?.amountPerWeeks}
                    formError={myCarData?.amountPerWeekErrs}
                    placeholder={'Enter amount per  week'}
                    endName={
                      <div className="circularBold" style={{ color: '#6CD2C2', fontSize: 20 }}>
                        N$
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
      <div className="row mt-5">
        <div className="col col-lg-4 col-sm-12">
          <span className="myCar-label">Location</span>

          <div>
            <div className="form-group">
              <Autocomplete
                Placeholder="Please select location"
                value={searchData ? searchData : myCarData?.address}
                apiKey={process.env.REACT_APP_MAP_KEY}
                options={{
                  types: ['address'],
                  componentRestrictions: { country: 'na' }
                }}
                onPlaceSelected={getPlaceDetail}
                onChange={(val) => {
                  setSearchData(val.target.value);
                  setMyCarData((myCarData) => ({
                    ...myCarData,
                    address: '',
                    longitude: '',
                    latitude: '',
                    addressError: ''
                  }));
                }}
                style={{ width: '-webkit-fill-available' }}
                className="rounded-2"
              />
            </div>
          </div>
          <div
            style={{
              color: 'red',
              fontSize: 13,
              marginTop: '-0.5rem',
              paddingLeft: 5
            }}
            className="text-error">
            {myCarData?.addressError}
          </div>
        </div>
        <div className="col col-lg-4 col-sm-12">
          <span className="myCar-label">City</span>

          <div>
            <div className="form-group">
              <SelectInput
                onChange={(val) => {
                  setMyCarData((myCarData) => ({
                    ...myCarData,
                    cityName: val.target.value,
                    cityNameErr: ''
                  }));
                }}
                value={myCarData?.cityName}
                formError={myCarData?.cityNameErr}
                options={destinationCity?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item?.cityName}
                    </option>
                  );
                })}
                Placeholder={'Please select city'}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="position-absolute"
        style={{
          bottom: screenType !== 'MOBILE' ? 0 : -10,
          right: screenType !== 'MOBILE' ? 15 : 0,
          left: screenType !== 'MOBILE' ? '' : 0
        }}>
        <Button onClick={handleNext} title="Next" />
      </div>
    </>
  );
}
