/** @format */

import io from 'socket.io-client';
import secureLocalStorage from 'react-secure-storage';

let userData = secureLocalStorage.getItem('authenticated');
const loginData = JSON.parse(userData);
export const socket = io(process.env.REACT_APP_SOCKET_URL);

socket.on('connect', () => {
  console.log('Socket connected successfully.');
  socket.emit('join', { userId: loginData?.id });
});

socket.on('disconnect', () => {
  console.log('Socket Disconnected');
});

socket.on('connect_error', () => {
  console.log('Socket Connection Error');
  setTimeout(() => {
    socket.connect();
  }, 1000);
});
