import React, { useEffect, useState, useRef } from 'react';
import { useResponsive } from '../../../../themes/useResponsive';
import { Container } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import DatePicker from 'react-datepicker';
// import 'react-time-picker/dist/TimePicker.css';
// import 'react-clock/dist/Clock.css';
import '../../../../assets/css/styles.css';
// import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { setSearchData } from 'store/slice/landingPage';
import Autocomplete from 'react-google-autocomplete';
import moment from 'moment';
import calendarIcon from '../../../../assets/img/Calendar.png';
import LocationIcon from '../../../../assets/img/Location.png';
import TimerIcon from '../../../../assets/img/Timer.png';
import toast from 'react-hot-toast';
import TimePicker from '../../../../components/timePicker';
import secureLocalStorage from 'react-secure-storage';
export default function ImageWithSearch() {
  const [formData, setFormData] = useState({});
  // const { searchData } = useSelector((state) => state.landingPage);
  // const [startDateTime, setStartDateTime] = useState();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [searchData, setSearchData] = useState();
  const datePickerRef = useRef(null);
  const datePickerRefOne = useRef(null);
  // const timepicker = useRef(null);

  const { screenType } = useResponsive();

  useEffect(() => {
    const data = secureLocalStorage.getItem('searchData');
    setSearchData(JSON.parse(data));
  }, []);

  useEffect(() => {
    if (searchData) {
      setFormData((formData) => ({
        ...formData,
        ...searchData,
        companyId: '',
        cityId: ''
      }));
      console.log('searchData', searchData);
      // setStartDateTime((startDateTime) => ({
      //   ...startDateTime,
      //   date: searchData?.fromBookingDate
      //     ? moment(searchData?.fromBookingDate).format('DD MMMM, YYYY')
      //     : null,
      //   time: searchData?.fromBookingDate ? moment(searchData?.fromBookingDate).format('hh:mm') : ''
      // }));
    }
  }, [searchData]);

  // useEffect(() => {
  //   if (startDateTime) {
  //     setFormData((formData) => ({
  //       ...formData,
  //       fromBookingDate: startDateTime.time
  //         ? `${startDateTime.date} ${startDateTime.time}`
  //         : startDateTime.date
  //     }));
  //   }
  // }, [startDateTime]);

  const handleSearch = () => {
    // if (
    //   !formData?.address &&
    //   !formData?.fromBookingDate &&
    //   !formData?.toBookingDate &&
    //   !formData?.time
    // ) {
    //   return toast('Please select filter');
    // }
    if (!formData?.address) {
      return toast('Please select location');
    }
    if (formData?.fromBookingDate > formData?.toBookingDate) {
      return toast('Please select valid date');
    }
    secureLocalStorage.setItem('searchData', JSON.stringify(formData));
    navigate('/smart-search');
    // dispatch(setSearchData(formData, navigate));
  };

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      longitude: lng
    }));
  }, [lng]);

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      latitude: lat
    }));
  }, [lat]);

  const getPlaceDetail = (place) => {
    setLat(place.geometry.location.lat);
    setLng(place.geometry.location.lng);
    setFormData((formData) => ({
      ...formData,
      address: place.formatted_address
    }));
  };
  const openCalendar = () => {
    datePickerRef.current.setFocus(true);
  };
  const openCalendarOne = () => {
    datePickerRefOne.current.setFocus(true);
  };
  // const timer = () => {
  //   timepicker.current.focus();
  // };

  console.log('formData?.address -- ', formData?.address);

  return (
    <>
      <Container fluid style={{ position: 'relative' }}>
        <div className="row">
          <div
            className="col-sm"
            style={{
              background: '#FFF8E8',
              display: 'flex',
              justifyContent: 'end',
              height: screenType === 'MOBILE' ? 650 : 610
            }}>
            <div
              style={{
                height: '100%',
                marginRight: '1rem',
                paddingLeft: screenType === 'MOBILE' || screenType === 'TABLET' ? '1rem' : '',
                width: screenType === 'MOBILE' || screenType === 'TABLET' ? '100%' : '80%',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: screenType === 'MOBILE' || screenType === 'TABLET' ? '6rem' : '',
                alignItems: screenType === 'MOBILE' || screenType === 'TABLET' ? 'start' : 'center'
              }}>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'right',
                  alignItems: 'right',
                  width: '100%'
                }}>
                <div className="text-dark  masterHead-heading">{'Choose Pickup '}</div>
                <div className="text-dark  masterHead-heading">{'Date & Location. '}</div>
                <div className="subHeading" style={{ width: screenType === 'desktop' ? 450 : '' }}>
                  Explore Namibia Your Way with Community-Driven Car Sharing at Your Fingertips.
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm HomeBg"></div>
        </div>
        <div
          className="container psx-5"
          style={{
            position: 'absolute',
            top: screenType === 'TABLET' ? '68%' : '',
            bottom: screenType === 'MOBILE' || screenType === 'TABLET' ? 0 : '2rem',
            display: screenType === 'MOBILE' || screenType === 'TABLET' ? '' : 'flex',

            left: 0,
            right: 0,
            gap: 10
          }}>
          <div
            className="container"
            style={{
              background: 'white',
              height: 'auto',
              borderRadius: 10
            }}>
            <div className="row gx-5">
              <div className="col-lg-3 col-md-6 text-muted d-flex justify-content-between ">
                <div className="d-flex align-item-center">
                  <img src={LocationIcon} height={25} style={{ marginTop: '1rem' }} />
                  &nbsp;
                  <Autocomplete
                    className="bg-transparent"
                    options={{
                      types: ['address'],
                      componentRestrictions: { country: 'na' }
                    }}
                    value={formData?.address ? formData?.address : ''}
                    onChange={(e) => {
                      setFormData((formData) => ({
                        ...formData,
                        address: e.target.value
                      }));
                    }}
                    apiKey={process.env.REACT_APP_MAP_KEY}
                    onPlaceSelected={getPlaceDetail}
                  />
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 d-flex justify-content-between "
                style={{ color: '#858E9F' }}>
                <div onClick={openCalendar} style={{ cursor: 'pointer' }} className="d-flex w-100">
                  <img src={calendarIcon} height={25} style={{ marginTop: '1rem' }} />
                  &nbsp; &nbsp;
                  <DatePicker
                    ref={datePickerRef}
                    className="form-control bg-transparent"
                    selected={formData?.fromBookingDate ? formData?.fromBookingDate : null}
                    onChange={(date) => {
                      setFormData((formData) => ({
                        ...formData,
                        fromBookingDate: date ? moment(date).format('DD MMMM, YYYY') : undefined
                      }));
                    }}
                    minDate={new Date()}
                    placeholderText="Pickup date"
                  />
                  <Icon
                    onClick={openCalendar}
                    icon="fe:arrow-down"
                    height={20}
                    style={{ marginTop: '1.2rem' }}
                  />
                </div>
              </div>

              <div
                className="col-lg-3 col-md-6  d-flex justify-content-between "
                style={{ color: '#858E9F' }}>
                <div
                  onClick={openCalendarOne}
                  style={{ cursor: 'pointer' }}
                  className="d-flex w-100">
                  <img src={calendarIcon} height={25} style={{ marginTop: '1rem' }} />
                  &nbsp; &nbsp;
                  <DatePicker
                    ref={datePickerRefOne}
                    className="form-control bg-transparent"
                    selected={formData?.toBookingDate ? formData?.toBookingDate : null}
                    onChange={(date) =>
                      setFormData((formData) => ({
                        ...formData,
                        toBookingDate: date ? moment(date).format('DD MMMM, YYYY') : undefined
                      }))
                    }
                    minDate={new Date()}
                    placeholderText="Return date"
                  />
                  <Icon
                    onClick={openCalendarOne}
                    icon="fe:arrow-down"
                    height={20}
                    style={{ marginTop: '1.2rem' }}
                  />
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6  d-flex justify-content-between "
                style={{ color: '#858E9F' }}>
                <div style={{ cursor: 'pointer' }} className="d-flex w-100">
                  <img src={TimerIcon} height={25} style={{ marginTop: '1rem' }} />
                  &nbsp; &nbsp;
                  {/* <input
                    ref={timepicker}
                    className="bg-transparent"
                    // type="time"
                    type={formData?.time ? 'time' : 'text'}
                    onFocus={(e) => (e.target.type = 'time')}
                    onBlur={(e) => {
                      if (!formData?.time) {
                        e.target.type = 'text';
                      }
                    }}
                    placeholder="Select time"
                    value={formData?.time}
                    onChange={(e) => {
                      setFormData((formData) => ({
                        ...formData,
                        time: e.target.value
                      }));
                    }}
                  /> */}
                  <TimePicker
                    placeholder={'Select time'}
                    value={formData?.time}
                    onChange={(time, timeString) => {
                      setFormData((formData) => ({
                        ...formData,
                        time: timeString
                      }));
                    }}
                  />
                </div>
                <Icon
                  // onClick={timer}
                  icon="fe:arrow-down"
                  height={20}
                  style={{ marginTop: '1.3rem' }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              background: '#F1AE01',
              borderRadius: 15,
              width: screenType === 'MOBILE' || screenType === 'TABLET' ? '100%' : 80,
              marginTop: screenType === 'MOBILE' || screenType === 'TABLET' ? '1rem' : '',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: screenType === 'MOBILE' || screenType === 'TABLET' ? 40 : 'auto',
              cursor: 'pointer'
            }}
            onClick={handleSearch}>
            {screenType === 'MOBILE' || screenType === 'TABLET' ? (
              'Search'
            ) : (
              <Icon icon="mynaui:search" height={35} />
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
