import { useContext, useEffect } from 'react';
import { Icon } from '@iconify/react';
import noImage from '../../../../assets/img/No_image_available.png';
import profileImage from '../../../../assets/img/noProfile.jpg';
import { useState } from 'react';
import '../style.css';
import { useResponsive } from 'themes/useResponsive';
import MyCarContext from 'components/context/myCarContext';
import { addtofavorite } from 'store/slice/carUser/favorite';
import { useDispatch } from 'react-redux';
import { favoriteCarDetails } from 'store/slice/carOwner/review';
import { useNavigate } from 'react-router-dom';
import { createPersonalChatRoomId } from 'store/slice/carOwner/message';
import Carousel from 'react-bootstrap/Carousel';
import { loginData } from 'components/constant';
import LoginDialog from 'components/dialog/loginNowDialog';
import messageLogo from 'assets/img/owner-icons/message.png';
import ShareDialog from 'components/dialog/shareDialog';
import { useParams } from 'react-router-dom';

export default function Details() {
  const { screenType } = useResponsive();
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const { id } = useParams();
  const [showShareDialog, setShowShareDialog] = useState(false);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { carDetails } = useContext(MyCarContext);
  // const text =
  //   " is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum";
  // const [isReadMore, setIsReadMore] = useState(true);
  // const toggleReadMore = () => {
  //   setIsReadMore(!isReadMore);
  // };

  const updateFav = (request) => {
    dispatch(favoriteCarDetails(request));
  };

  const handleFavorite = () => {
    if (loginData) {
      let request = {
        carId: carDetails?.id,
        language: 'en',
        status: !carDetails?.isFavorite
      };
      dispatch(addtofavorite(request, updateFav));
    } else {
      setShowLoginDialog(true);
    }
  };

  const handleCreateChat = () => {
    if (loginData) {
      const data = {
        recieverUserId: carDetails?.userData?.userId,
        language: 'en'
      };
      dispatch(createPersonalChatRoomId(data, navigate));
    } else {
      setShowLoginDialog(true);
    }
  };

  return (
    <>
      <ShareDialog
        showDialog={showShareDialog}
        setShowDialog={setShowShareDialog}
        url={`${process.env.REACT_APP_URL}/${id}`}
      />
      <LoginDialog
        show={showLoginDialog}
        setShow={setShowLoginDialog}
        heading={'Please login now to use this feature.'}
      />

      <div className="row ">
        <div className="col-lg-4 col-md-12 col-sm-12">
          <div className="position-relative">
            <Carousel controls={false} slide={false} activeIndex={index} onSelect={handleSelect}>
              {carDetails?.carImagesUrl?.length != 0 ? (
                carDetails?.carImagesUrl?.map((item, i) => {
                  return (
                    <Carousel.Item key={i}>
                      <img
                        className="d-block"
                        src={item}
                        width={'100%'}
                        height={'250px'}
                        style={{ borderRadius: 15, objectFit: 'cover' }}
                        alt="First slide"
                      />
                    </Carousel.Item>
                  );
                })
              ) : (
                <div className="carousel-item active">
                  <img
                    className="d-block"
                    src={noImage}
                    width={'100%'}
                    height={'250px'}
                    style={{ borderRadius: 15, objectFit: 'cover' }}
                    alt="First slide"
                  />
                </div>
              )}
            </Carousel>

            <div className="position-absolute" style={{ top: 3, left: 8 }}>
              {carDetails?.averageRating && (
                <span
                  className="badge text-dark d-flex mt-2 px-3 "
                  style={{
                    background: 'WHITE',
                    height: 30,
                    borderRadius: 15,
                    paddingTop: '0.3rem'
                  }}>
                  <Icon icon="fluent:star-12-filled" height={18} color="#F1AE01" />
                  &nbsp;&nbsp;
                  <div
                    style={{
                      fontSize: 15,
                      fontFamily: 'Circular Std Medium',
                      paddingTop: '0.2rem'
                    }}>
                    {carDetails?.averageRating ? carDetails?.averageRating : 0}
                  </div>
                </span>
              )}
            </div>
          </div>
        </div>
        <div
          className="col-lg-6 col-md-12 col-sm-12"
          style={{ marginTop: screenType === 'desktop' ? '' : '1rem' }}>
          <div className="d-grid align-items-center h-100 w-100">
            <div>
              <h3 style={{ fontFamily: 'Circular Std Black' }}>{carDetails?.modelName} </h3>
              {screenType === 'MOBILE' ? (
                <div style={{ fontFamily: 'Circular Std Book', color: '#132649', fontSize: 23 }}>
                  <small className="d-inline">
                    {carDetails?.transmission.charAt(0).toUpperCase() +
                      carDetails?.transmission.slice(1).toLowerCase()}
                  </small>
                  &nbsp;&nbsp;
                  <small className="d-inline ">.</small>&nbsp;&nbsp;
                  <small className="d-inline">
                    {carDetails?.carFuelType ? carDetails?.carFuelType : ''}
                  </small>
                  &nbsp;&nbsp;
                  <small className="d-inline ">.</small>&nbsp;&nbsp;
                  <small className="d-inline">
                    {carDetails?.seatNumber ? carDetails?.seatNumber : 0} Seats
                  </small>
                </div>
              ) : (
                <div style={{ fontFamily: 'Circular Std Book', color: '#132649', fontSize: 23 }}>
                  <small className="d-inline">
                    {carDetails?.transmission.charAt(0).toUpperCase() +
                      carDetails?.transmission.slice(1).toLowerCase()}
                  </small>
                  &nbsp;&nbsp;
                  <small className="d-inline ">.</small>&nbsp;&nbsp;
                  <small className="d-inline ">
                    {' '}
                    {carDetails?.carFuelType ? carDetails?.carFuelType : ''}
                  </small>
                  &nbsp;&nbsp;
                  <small className="d-inline">.</small>&nbsp;&nbsp;
                  <small className="d-inline">
                    {carDetails?.seatNumber ? carDetails?.seatNumber : 0} Seats
                  </small>
                </div>
              )}
              <div
                style={{
                  backgroundImage: 'linear-gradient(to right, #ffe0b08a, #87cead69)'
                }}
                className="d-flex justify-content-between p-3 rounded-4 mt-3">
                <div className="d-flex gap-3">
                  <img
                    src={
                      carDetails?.userData?.profileImage
                        ? carDetails?.userData?.profileImage
                        : profileImage
                    }
                    style={{
                      height: '50px',
                      width: '50px',
                      borderRadius: 30,
                      objectFit: 'cover'
                    }}
                  />
                  <div className="pt-1">
                    <h5
                      style={{
                        fontFamily: 'Circular std Bold'
                      }}>
                      {carDetails?.userData?.firstName} {carDetails?.userData?.lastName}
                    </h5>
                    <div
                      style={{
                        fontFamily: 'Circular std Book',
                        color: 'grey',
                        marginTop: '-0.5rem'
                      }}>
                      +{carDetails?.userData?.countryCode} {carDetails?.userData?.phoneNumber}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center circularBook text-muted">Car Owner</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-12 col-sm-12 d-grid align-items-center justify-content-end">
          <div
            style={{
              display: screenType === 'desktop' ? 'contents' : 'flex',
              justifyContent: screenType === 'desktop' ? '' : 'space-evenly',
              marginTop: screenType === 'desktop' ? '' : '1rem'
            }}>
            {loginData ? (
              <div
                className="bg-black d-flex align-items-center justify-content-center rounded-3"
                style={{
                  width: 50,
                  height: 50
                }}>
                <Icon
                  icon={
                    carDetails?.isFavorite ? 'teenyicons:heart-solid' : 'teenyicons:heart-outline'
                  }
                  color="#F1AE01"
                  height={25}
                  onClick={handleFavorite}
                />
              </div>
            ) : (
              ''
            )}
            <div
              className="border d-flex align-items-center justify-content-center rounded-3"
              onClick={() => setShowShareDialog(true)}
              style={{
                width: 50,
                height: 50
              }}>
              <Icon icon="ion:arrow-redo-outline" color="#111318" height={29} />
            </div>
            {loginData ? (
              <div
                className="border d-flex align-items-center justify-content-center rounded-3"
                style={{
                  width: 50,
                  height: 50
                }}>
                <img src={messageLogo} alt="" width={29} onClick={handleCreateChat} />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {/* <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
          <p style={{ fontFamily: 'Circular Std Book', color: '#132649' }}>
            {isReadMore ? text.slice(0, 220) : text}
            <br />
            <div
              onClick={toggleReadMore}
              style={{
                cursor: 'pointer',
                fontFamily: 'Circular std Medium',
                color: '#132649'
              }}>
              {isReadMore ? ' Read More' : ' Read Less'} &nbsp;
              <Icon
                icon={!isReadMore ? 'solar:alt-arrow-up-linear' : 'ep:arrow-down-bold'}
                style={{ color: '#6CD2C2' }}
              />
            </div>
          </p>
        </div> */}
      </div>
    </>
  );
}
