import React, { useEffect } from 'react';
import logo from '../../../assets/img/authLogo.png';
import gif from '../../../assets/img/Verified.gif';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { verifiedUserDetail } from '../../../store/slice/auth';
export default function UserEmailVerfication() {
  const { id } = useParams();
  const { emailVerifiedData } = useSelector((state) => state.auth);
  useEffect(() => {
    if (emailVerifiedData) {
      setInterval(function () {
        window.location.reload();
      }, 2000);
    }
  }, [emailVerifiedData]);
  useEffect(() => {
    if (id) {
      dispatch(verifiedUserDetail(id));
    }
  }, [id]);
  const dispatch = useDispatch();
  return (
    <div
      style={{
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}>
        <img src={logo} height={'20%'} width={'40%'} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-5rem' }}>
        <img src={gif} />
      </div>
    </div>
  );
}
