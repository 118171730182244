// import axios from 'axios';
// import { useEffect } from 'react';
// import secureLocalStorage from 'react-secure-storage';
// import { useNavigate } from 'react-router';

// const token = secureLocalStorage.getItem(process.env.REACT_APP_TOKEN_STORAGE_KEY);
// // axios instance
// const instance = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   withCredentials: false,
//   headers: {
//     'Access-Control-Allow-Origin': '*',
//     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
//     'Content-Type': 'application/json',
//     'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
//     Authorization: token ? `Bearer ${token}` : `Bearer ${process.env.REACT_APP_KEY}`
//   }
// });

// const AxiosInterceptor = ({ children }) => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const reqInterceptor = (request) => {
//       const token = secureLocalStorage.getItem(process.env.REACT_APP_TOKEN_STORAGE_KEY);
//       if (token) {
//         request.headers.Authorization = `Bearer ${token}`;
//       }
//       return request;
//     };
//     const resInterceptor = (response) => {
//       return response;
//     };
//     const errInterceptor = (error) => {
//       console.log('errInterceptor == ', error);
//       if (error.response.status === 401) {
//         secureLocalStorage.clear();
//         navigate('/');
//       }
//       return Promise.reject(error);
//     };

//     const requestInterceptor = instance.interceptors.request.use(reqInterceptor, errInterceptor);
//     const interceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);
//     return () => {
//       instance.interceptors.response.eject(interceptor);
//       instance.interceptors.request.eject(requestInterceptor);
//     };
//   }, []);

//   return children;
// };

// export default instance;
// export { AxiosInterceptor };
/* @format / */
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

// store
// import store from "../store";
//store.subscribe(listener);

var bearerToken = '';
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: (status) => {
    if (status === 401) {
      secureLocalStorage.clear();
      window.location.href = '/';
      return false;
    } else {
      return true;
    }
  },
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Content-Type': 'application/json'
  }
});

// Add a request interceptor
// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
  const token = secureLocalStorage.getItem(process.env.REACT_APP_TOKEN_STORAGE_KEY);

  if (token) {
    bearerToken = `Bearer ${token}`;
  }
  request.headers.Authorization = bearerToken;
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  console.log('error', error.response.status === 401);

  if (error.message === 'Network Error') {
    return;
  } else if (error.response.status === 401) {
    secureLocalStorage.clear();
    window.location.href = '/auth';
    return;
  } else if (error.response.data.status === 404) {
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
api.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

api.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default api;
