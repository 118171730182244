import React, { useContext } from 'react';
// import { Icon } from '@iconify/react';
import '../style.css';
import { useResponsive } from 'themes/useResponsive';
import MyCarContext from 'components/context/myCarContext';
import ColoredButton from 'components/button/colorFullButtons';
// import { useNavigate } from 'react-router-dom';
import RadioComponent from 'components/radioComponent';
import toast from 'react-hot-toast';
import { loginData } from 'components/constant';

export default function Continue() {
  const { screenType } = useResponsive();
  // const navigate = useNavigate();
  const { carDetails, formData, setFormData, setBookingTab } = useContext(MyCarContext);

  const handleContine = () => {
    if (!loginData) {
      return toast.error('Please log in to book the car');
    }
    if (loginData?.userType == 2) {
      return toast.error('Please login with your user account for book car');
    }
    if (!formData?.bookingType) {
      return toast.error('Please select booking type');
    }
    if (!formData?.fuelType) {
      return toast.error('Please select fuel type');
    }
    setBookingTab(true);
  };
  // withFule
  return (
    <>
      <div className="row  d-flex align-items-center" style={{ background: '#132649' }}>
        {carDetails?.withoutFule && (
          <>
            <div
              className={`col-lg-4 col-sm-12 d-flex ${screenType != 'desktop' ? 'justify-content-start mt-3' : 'justify-content-center'} `}>
              <div className="d-flex">
                <RadioComponent
                  name="fuleType"
                  onChange={(checked) => {
                    setFormData((formData) => ({
                      ...formData,
                      fuelType: checked ? 'withoutfuel' : ''
                    }));
                  }}
                  label={
                    <div
                      className="circularMedium"
                      style={{ color: '#F1AE01', fontSize: 20, marginLeft: '2.5rem' }}>
                      Without Fuel
                      <span
                        style={{
                          color: '#FFFFFF',
                          fontFamily: 'Circular Std Book',
                          fontSize: 17,
                          fontWeight: 'bold'
                        }}>
                        /N${' '}
                        {formData?.bookingType === 'perHour'
                          ? carDetails?.withoutFule?.amountPerHour
                          : formData?.bookingType === 'perDay'
                            ? carDetails?.withoutFule?.amountPerDay
                            : formData?.bookingType === 'perWeek'
                              ? carDetails?.withoutFule?.amountPerWeek
                              : 0}
                      </span>{' '}
                      <span
                        style={{
                          color: '#FFFFFF',
                          fontFamily: 'Circular Std Book',
                          fontSize: 12,
                          marginLeft: '-0.3rem'
                        }}>
                        /
                        {formData?.bookingType === 'perHour'
                          ? 'hour'
                          : formData?.bookingType === 'perDay'
                            ? 'day'
                            : formData?.bookingType === 'perWeek'
                              ? 'week'
                              : 'hour'}
                      </span>
                    </div>
                  }
                />
              </div>
            </div>
          </>
        )}

        {carDetails?.withFule && (
          <>
            <div
              className={`col-lg-4 col-sm-12 d-flex ${screenType != 'desktop' ? 'justify-content-start mt-3' : 'justify-content-center'} `}>
              <div className="d-flex">
                <RadioComponent
                  name="fuleType"
                  onChange={(checked) => {
                    setFormData((formData) => ({
                      ...formData,
                      fuelType: checked ? 'withfuel' : ''
                    }));
                  }}
                  label={
                    <div
                      className="circularMedium"
                      style={{ color: '#F1AE01', fontSize: 20, marginLeft: '2.5rem' }}>
                      With Fuel
                      <span
                        style={{
                          color: '#FFFFFF',
                          fontFamily: 'Circular Std Book',
                          fontSize: 17,
                          fontWeight: 'bold'
                        }}>
                        /N${' '}
                        {formData?.bookingType === 'perHour'
                          ? carDetails?.withFule?.amountPerHour
                          : formData?.bookingType === 'perDay'
                            ? carDetails?.withFule?.amountPerDay
                            : formData?.bookingType === 'perWeek'
                              ? carDetails?.withFule?.amountPerWeek
                              : 0}
                      </span>{' '}
                      <span
                        style={{
                          color: '#FFFFFF',
                          fontFamily: 'Circular Std Book',
                          fontSize: 12,
                          marginLeft: '-0.3rem'
                        }}>
                        /
                        {formData?.bookingType === 'perHour'
                          ? 'hour'
                          : formData?.bookingType === 'perDay'
                            ? 'day'
                            : formData?.bookingType === 'perWeek'
                              ? 'week'
                              : 'hour'}
                      </span>
                    </div>
                  }
                />
              </div>
            </div>
          </>
        )}

        <div
          className={`${carDetails?.withFule && carDetails?.withoutFule ? 'col-lg-4' : 'col-lg-8 '} col-sm-12  ${screenType != 'desktop' ? ' mt-3' : 'mt-3 d-flex justify-content-end'} `}
          onClick={handleContine}>
          <ColoredButton
            title="Continue"
            style={{
              background: '#F1AE01',
              paddingLeft: '2rem',
              paddingRight: '2rem'
            }}
          />
        </div>
      </div>
    </>
  );
}
