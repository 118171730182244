import React from 'react';
import Input from '../../../components/input';
import Button from '../../../components/button';
import 'react-phone-number-input/style.css';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import userimage from '../../../assets/img/Order ride.gif';
import ownerimage from '../../../assets/img/By my car.gif';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';
import { registerUsers } from '../../../store/slice/auth';
import { Link } from 'react-router-dom';
import { deviceToken } from 'utils/appConstant';
export default function Register() {
  const [registerData, setRegisterData] = useState({});
  const [registerDataError, setRegisterDataError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleRegister = () => {
    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    if (!registerData?.firstName) {
      setRegisterDataError((registerDataError) => ({
        ...registerDataError,
        firstNameErr: 'Please enter your first name'
      }));
      return;
    }
    if (!registerData?.lastName) {
      setRegisterDataError((registerDataError) => ({
        ...registerDataError,
        lastNameErr: 'Please enter your last name'
      }));
      return;
    }
    if (!registerData?.phoneNumber) {
      setRegisterDataError((registerDataError) => ({
        ...registerDataError,
        phoneNumberErr: 'Please enter your phone number'
      }));
      return;
    }
    if (!registerData.email) {
      setRegisterDataError((registerDataError) => ({
        ...registerDataError,
        emailErr: 'Please enter your email address'
      }));
      return;
    } else if (!registerData?.email.includes('@')) {
      setRegisterDataError((registerDataError) => ({
        ...registerDataError,
        emailErr: 'Please enter a valid email address'
      }));
      return;
    } else if (!registerData?.email.includes('.')) {
      setRegisterDataError((registerDataError) => ({
        ...registerDataError,
        emailErr: 'Please enter a valid email address'
      }));
      return;
    } else if (!validEmail.test(registerData?.email)) {
      setRegisterDataError((registerDataError) => ({
        ...registerDataError,
        emailErr: 'Please enter a valid email address'
      }));
      return;
    }

    if (!registerData?.password) {
      setRegisterDataError((registerDataError) => ({
        ...registerDataError,
        passwordErr: 'Please enter your password'
      }));
      return;
    }
    if (registerData.password.length <= 7) {
      setRegisterDataError((registerDataError) => ({
        ...registerDataError,
        passwordErr: 'Password must be  8 characters '
      }));
      return;
    }
    setIsLoading(true);

    let request = {
      firstName: registerData.firstName,
      lastName: registerData.lastName,
      countryCode: registerData.countryCode,
      phoneNumber: registerData.phoneNumber,
      email: registerData.email,
      password: registerData.password,
      userType: registerData.userType === 'user' ? 3 : 2,
      deviceType: 'web',
      deviceToken: deviceToken
    };
    dispatch(registerUsers(request, setRegisterData, setIsLoading));
  };
  return (
    <>
      <div style={{ paddingTop: '4rem' }} className="px-2">
        <div className=" pb-3">
          <h3 className="authHeading"> Sign up to</h3>
          <h3 className="authHeading">RentedWheels</h3>
        </div>
        {registerData?.isUserType ? (
          <>
            <form action="#" method="post" className="pt-3">
              <div className="row">
                <div className="col">
                  <div className="form-group first ">
                    <Input
                      onChange={(val) => {
                        setRegisterData((prevState) => ({ ...prevState, firstName: val }));
                        setRegisterDataError((registerDataError) => ({
                          ...registerDataError,
                          firstNameErr: ''
                        }));
                      }}
                      value={registerData?.firstName}
                      placeholder={'First Name'}
                      icon={'fluent:person-28-regular'}
                      formError={registerDataError?.firstNameErr}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group first ">
                    <Input
                      onChange={(val) => {
                        setRegisterData((prevState) => ({ ...prevState, lastName: val }));
                        setRegisterDataError((registerDataError) => ({
                          ...registerDataError,
                          lastNameErr: ''
                        }));
                      }}
                      value={registerData?.lastName}
                      placeholder={'Last Name'}
                      icon={'fluent:person-28-regular'}
                      formError={registerDataError?.lastNameErr}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group first ">
                <div className="d-flex">
                  <div
                    style={{ background: '#f6f6f8 ', paddingLeft: '0.7rem', paddingTop: '0.7rem' }}>
                    <Icon icon="ph:phone-call-light" height={30} color="#757c83" />
                  </div>
                  <PhoneInput
                    placeholder={'(00) 0000-0000'}
                    country={'na'}
                    value={registerData?.phone}
                    inputStyle={{
                      width: '100%',
                      border: 'none',
                      height: '51px ',
                      fontSize: '19px'
                    }}
                    onChange={(value, inputdata) => {
                      setRegisterDataError((registerDataError) => ({
                        ...registerDataError,
                        phoneNumberErr: ''
                      }));
                      setRegisterData((registerData) => ({
                        ...registerData,
                        phone: value,
                        phoneNumber: value.slice(inputdata?.dialCode?.length),
                        countryCode: inputdata?.dialCode
                      }));
                    }}
                    inputClass="telInput"
                  />
                </div>
                <div
                  style={{
                    color: 'red',
                    fontSize: 13,
                    marginTop: '0.5rem',
                    paddingLeft: 5
                  }}
                  className="text-error">
                  {registerDataError?.phoneNumberErr && registerDataError?.phoneNumberErr}
                </div>
              </div>
              <div className="form-group first ">
                <Input
                  onChange={(val) => {
                    setRegisterData((registerData) => ({ ...registerData, email: val }));
                    setRegisterDataError((registerDataError) => ({
                      ...registerDataError,
                      emailErr: ''
                    }));
                  }}
                  value={registerData?.email}
                  placeholder={'Email Address'}
                  icon={'mdi-light:email'}
                  formError={registerDataError?.emailErr}
                />
              </div>
              <div className="form-group last mt-3 mb-3">
                <Input
                  type={!registerData?.showPass ? 'password' : 'text'}
                  endIcon={registerData?.showPass ? 'formkit:eye' : 'humbleicons:eye-off'}
                  passwordClick={() =>
                    setRegisterData((registerData) => ({
                      ...registerData,
                      showPass: !registerData?.showPass
                    }))
                  }
                  placeholder={'Password'}
                  icon={'circum:lock'}
                  onChange={(val) => {
                    setRegisterData((prevState) => ({ ...prevState, password: val }));
                    setRegisterDataError((registerDataError) => ({
                      ...registerDataError,
                      passwordErr: ''
                    }));
                  }}
                  value={registerData?.password}
                  formError={registerDataError?.passwordErr}
                />
              </div>
            </form>
            <div className="pt-3">
              <Button
                onClick={handleRegister}
                title={isLoading ? 'Loading' : 'Register Now'}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </div>
          </>
        ) : (
          <>
            <form action="#" method="post" className="pt-3">
              <h4 className="register-title">Select Your Role</h4>
              <div className="row mt-3">
                <div className="col-lg-6 col-sm-4  ">
                  <div
                    style={{
                      border:
                        registerData?.userType === 'user'
                          ? '2px solid #F1AE01'
                          : '2px solid #d2d2d2'
                    }}
                    onClick={() =>
                      setRegisterData((prevState) => ({ ...prevState, userType: 'user' }))
                    }>
                    <img
                      className="img-fluid"
                      src={userimage}
                      alt="..."
                      height={'100%'}
                      width={'100%'}
                    />
                  </div>

                  <div className="text-center mt-3 register-title">User</div>
                </div>
                <div className="col-lg-6 col-sm-4 ">
                  <div
                    style={{
                      border:
                        registerData?.userType === 'carOwner'
                          ? '2px solid #F1AE01'
                          : '2px solid #d2d2d2'
                    }}
                    onClick={() =>
                      setRegisterData((prevState) => ({ ...prevState, userType: 'carOwner' }))
                    }>
                    <img
                      className="img-fluid"
                      src={ownerimage}
                      alt="..."
                      height={'100%'}
                      width={'100%'}
                    />
                  </div>
                  <div className="text-center register-title mt-3">Car Owner</div>
                </div>
              </div>
            </form>
            <div className="pt-3">
              <Button
                title={'Next'}
                disabled={registerData?.userType ? false : true}
                onClick={() => setRegisterData((prevState) => ({ ...prevState, isUserType: true }))}
              />
            </div>
          </>
        )}
        <div className="d-flex mt-5 mb-5 align-items-center">
          <div className="text-left text-muted login-footer-text">
            Already a member ? &nbsp;
            <Link className="highlight" to="/auth/login">
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
