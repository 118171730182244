import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import auth from './slice/auth';
import review from './slice/carOwner/review';
import carOwnerMessage from './slice/carOwner/message';
import landingPage from './slice/landingPage';
import favorite from './slice/carUser/favorite';
import notification from './slice/carUser/notification';
import bookingsStatus from './slice/carOwner/bookingStatus';
import help from './slice/carUser/help';
import uploadDocument from './slice/carUser/uploadDocument';
import booking from './slice/carUser/carBooking';

const rootReducer = combineReducers({
  auth,
  review,
  carOwnerMessage,
  landingPage,
  favorite,
  notification,
  help,
  bookingsStatus,
  uploadDocument,
  booking
});
const store = configureStore({
  reducer: rootReducer
});
export default store;
