import './index.css';
import CarRequestCard from './carRequestCard';
import { Icon } from '@iconify/react';
import { useContext, useEffect, useRef, useState } from 'react';
import MyCarContext from 'components/context/myCarContext';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useResponsive } from 'themes/useResponsive';
import ColoredButton from 'components/button/colorFullButtons';
import Spinner from 'react-bootstrap/Spinner';

const PendingRequests = () => {
  const {
    allBookingsList,
    totalCount,
    setShowAleartDialog,
    showAleartDialog,
    state,
    setState,
    setFilterData,
    bookingTotalCount,
    limit,
    setLimit,
    getAllBookingStatus,
    isListLoading,
    isStatusLoading,
    isLoading,
    setIsLoading
  } = useContext(MyCarContext);
  const { screenType } = useResponsive();
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleIconClick = () => {
    setShowCalendar(!showCalendar);
  };

  return (
    <>
      <div className="row align-items-center mb-4 ">
        <div className="col-sm-8 fs-2 mt-1 carOwner-tab-title">
          Pending Bookings /{' '}
          <span style={{ color: '#FF5252' }}>{totalCount?.pendingBookingCount}</span>{' '}
        </div>
        <div className="col-sm-4 d-flex mt-3 justify-content-lg-end ">
          <form className="form-search form-inline rounded-5">
            <div className="row gx-0">
              <div className="col-10">
                <div className="dropdown m-0">
                  <button
                    style={{ minWidth: '12rem' }}
                    className="sort-List dropdown-toggle dropdown-toggle-split"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <label style={{ fontSize: '16px' }}> Sort by</label>
                    <Icon icon={'formkit:down'} width={'20px'} />
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a
                      className="dropdown-item"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setFilterData((filterData) => ({
                          ...filterData,
                          sortByDate: -1
                        }));
                      }}>
                      New-Old
                    </a>
                    <a
                      className="dropdown-item"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setFilterData((filterData) => ({
                          ...filterData,
                          sortByDate: 1
                        }));
                      }}>
                      Old-New
                    </a>
                    {/* <a className="dropdown-item" href="#">
                  Something else here
                </a> */}
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div
                  className="checklist bg-white rounded-4 ml-2 d-flex justify-content-center align-items-center"
                  style={{ height: 40, width: 40 }}>
                  <Icon
                    icon="octicon:calendar-16"
                    style={{ color: 'black', cursor: 'pointer' }}
                    onClick={handleIconClick}
                  />
                  {showCalendar && (
                    <div className="position-relative">
                      <div
                        ref={calendarRef}
                        className="position-absolute  "
                        style={{
                          zIndex: 1,
                          right: screenType == 'MOBILE' ? '-5rem' : '-1.2rem',
                          top: '2rem'
                        }}>
                        {/* <Calendar onChange={handleCalendarChange} value={date} /> */}
                        <DateRange
                          editableDateInputs={true}
                          onChange={(item) => setState([item.selection])}
                          moveRangeOnFirstSelection={false}
                          ranges={state}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="row mt-3">
        {allBookingsList?.length >= 1 ? (
          <>
            {allBookingsList?.map((item, index) => {
              return (
                <CarRequestCard
                  data={item}
                  key={index}
                  setShowAleartDialog={setShowAleartDialog}
                  showAleartDialog={showAleartDialog}
                />
              );
            })}

            <div className="d-flex justify-content-center mt-3">
              {isLoading && !isListLoading && !isStatusLoading ? (
                <Spinner animation="border" style={{ color: '#6CD2C2' }} />
              ) : (
                bookingTotalCount > limit && (
                  <ColoredButton
                    onClick={() => {
                      let perPage = limit + 10;
                      setLimit(perPage);
                      setIsLoading(true);
                      getAllBookingStatus(perPage);
                    }}
                    title="View More"
                    style={{ background: '#6CD2C2', width: '130px' }}
                  />
                )
              )}
            </div>
          </>
        ) : (
          <div className="noData" style={{ height: '50vh' }}>
            No Data Found
          </div>
        )}
      </div>
    </>
  );
};
export default PendingRequests;
