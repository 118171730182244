import React, { useContext, useState } from 'react';
import { Icon } from '@iconify/react';
import { useResponsive } from 'themes/useResponsive';
import MyCarContext from 'components/context/myCarContext';
import moment from 'moment';
import Carousel from 'react-bootstrap/Carousel';
import carImage from 'assets/img/No_image_available.png';

export default function TotalFairDetail() {
  const { screenType } = useResponsive();
  const { carDetails, calculatedAmount, formData } = useContext(MyCarContext);
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <div className="row mt-4">
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="position-relative">
            <Carousel controls={false} slide={false} activeIndex={index} onSelect={handleSelect}>
              {carDetails?.carImagesUrl?.length != 0 ? (
                carDetails?.carImagesUrl?.map((item, i) => {
                  return (
                    <Carousel.Item key={i}>
                      <img
                        className="d-block"
                        src={item}
                        width={'100%'}
                        height={'250px'}
                        style={{ borderRadius: 15, objectFit: 'cover' }}
                        alt="First slide"
                      />
                    </Carousel.Item>
                  );
                })
              ) : (
                <div className="carousel-item active">
                  <img
                    className="d-block"
                    src={carImage}
                    width={'100%'}
                    height={'250px'}
                    style={{ borderRadius: 15, objectFit: 'cover' }}
                    alt="First slide"
                  />
                </div>
              )}
            </Carousel>
            {carDetails?.averageRating && (
              <div className="position-absolute" style={{ top: 0, left: 5 }}>
                <span
                  className="badge text-dark d-flex mt-2 px-3 "
                  style={{
                    background: 'WHITE',
                    height: 30,
                    borderRadius: 15,
                    paddingTop: '0.3rem'
                  }}>
                  <Icon icon="fluent:star-12-filled" height={18} color="#F1AE01" />
                  &nbsp;&nbsp;
                  <div
                    style={{
                      fontSize: 15,
                      fontFamily: 'Circular Std Medium',
                      paddingTop: '0.2rem'
                    }}>
                    {carDetails?.averageRating ? carDetails?.averageRating : 0}
                  </div>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12">
          <div>
            <div className="mt-2">
              <h3 style={{ fontFamily: 'Circular Std Black', color: '#132649' }}>
                {carDetails?.modelName}
              </h3>
              {screenType === 'MOBILE' ? (
                <div
                  style={{
                    fontFamily: 'Circular Std Book',

                    fontSize: 20,
                    marginTop: '-8px'
                  }}>
                  {/* <small className="d-inline d-md-inline">Tue, 12 Aug 2021 - For Day</small> */}
                  {formData?.startDate ? (
                    <small className="d-inline d-md-inline">
                      {moment(formData?.startDate).format('ddd DD MMM YYYY')} - For{' '}
                      {formData?.bookingType == 'perHour'
                        ? 'Hour'
                        : formData?.bookingType == 'perDay'
                          ? 'Day'
                          : formData?.bookingType == 'perWeek'
                            ? 'Week'
                            : ''}
                    </small>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                <div
                  style={{
                    fontFamily: 'Circular Std Book',

                    fontSize: 20,
                    marginTop: '-8px'
                  }}>
                  {formData?.startDate ? (
                    <small className="d-block d-md-inline">
                      {moment(formData?.startDate).format('ddd DD MMM YYYY')} - For{' '}
                      {formData?.bookingType == 'perHour'
                        ? 'Hour'
                        : formData?.bookingType == 'perDay'
                          ? 'Day'
                          : formData?.bookingType == 'perWeek'
                            ? 'Week'
                            : ''}
                    </small>
                  ) : (
                    ''
                  )}
                </div>
              )}
              <hr />
              <div className="row pl-2">
                <div className="col-6">
                  <p className="Left-list">Trip Fare</p>
                  {/* <p className="Left-list">Fuel Charges</p> */}
                  <p className="Left-list">
                    Refundable Deposit
                    <br />
                    <small className="small d-inline d-md-inline">
                      Collect deposit from owner after completed booking
                    </small>
                  </p>
                  <p className="Left-list">Platform Fee</p>
                </div>
                <div className="col-6">
                  <p className="Right-list">
                    N${calculatedAmount?.finalAmount ? calculatedAmount?.finalAmount : 0}
                  </p>
                  {/* <p className="Right-list">
                    <span className="plusSign">+ </span>N$200
                  </p> */}
                  <p className="Right-list">
                    <span className="plusSign">+ </span>N$
                    {calculatedAmount?.refundableAmount ? calculatedAmount?.refundableAmount : 2000}
                  </p>
                  &nbsp; &nbsp; &nbsp;
                  <p className="Right-list">
                    <span className="plusSign">+ </span>N$
                    {calculatedAmount?.platformFees ? calculatedAmount?.platformFees : 0}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <hr />
    </>
  );
}
