import { createSlice } from '@reduxjs/toolkit';
import { RENTEDWHEELSAPI } from '../../services/apiConstant';
import api from '../../services';
import toast from 'react-hot-toast';

// import axios from 'axios';

const slice = createSlice({
  name: 'landingPage',
  initialState: {
    allCompanyList: [],
    allLatestCarList: [],
    getAllCarData: [],
    blogsData: [],
    blogsTotalCount: 0,
    blogDetail: [],
    searchData: null,
    destinationCity: [],
    topRatedCarsList: [],
    hostsList: [],
    getpolicyList: [],
    carTotalCount: 0
  },
  reducers: {
    getAllCompanyListSuccess: (state, action) => {
      state.allCompanyList = action.payload;
    },
    getAllLatestCarListSuccess: (state, action) => {
      state.allLatestCarList = action.payload;
    },
    getAllCarByUserSuccess: (state, action) => {
      state.getAllCarData = action.payload.data;
      state.carTotalCount = action.payload.totalCount;
    },
    getAllBlogsListSuccess: (state, action) => {
      state.blogsData = action.payload.data;
      state.blogsTotalCount = action.payload.totalCount;
    },
    blogDescriptionDataSuccess: (state, action) => {
      state.blogDetail = action.payload.data;
    },
    setSearchDataaSuccess: (state, action) => {
      state.searchData = action.payload;
    },
    destinationsListSuccess: (state, action) => {
      state.destinationCity = action.payload;
    },
    experiencedCarsListSuccess: (state, action) => {
      state.topRatedCarsList = action.payload;
      // console.log('topRatedCarsList', action.payload);
    },
    hostsListSuccess: (state, action) => {
      state.hostsList = action.payload;
    },
    updateFavListSuccess: (state, action) => {
      const index = state.allLatestCarList?.findIndex((x) => x.id === action.payload.carId);
      state.allLatestCarList[index].isFavorite = action.payload.status;
    },
    updateFavAllCarListSuccess: (state, action) => {
      const index = state.getAllCarData.findIndex((x) => x.id === action.payload.carId);
      state.getAllCarData[index].isFavorite = action.payload.status;
    },
    getTheListOfPolicySuccess: (state, action) => {
      state.getpolicyList = action.payload;
    }
  }
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

const {
  getAllCompanyListSuccess,
  getAllLatestCarListSuccess,
  getAllCarByUserSuccess,
  getAllBlogsListSuccess,
  blogDescriptionDataSuccess,
  destinationsListSuccess,
  experiencedCarsListSuccess,
  setSearchDataaSuccess,
  updateFavListSuccess,
  hostsListSuccess,
  updateFavAllCarListSuccess,
  getTheListOfPolicySuccess
} = slice.actions;

export const getAllCompany = (setIsloading, request) => async (dispatch) => {
  api
    .get(`${RENTEDWHEELSAPI.landingPage.getCompany}`, request)
    .then((response) => {
      let result = response.data;

      if (result.status == 1) {
        dispatch(getAllCompanyListSuccess(result.data));
        setIsloading(false);
      } else {
        toast.error(result.message);
        setIsloading(false);
      }
    })
    .catch(() => {});
};

export const getAllLatestCar = (request) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.landingPage.getLatestCar}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(getAllLatestCarListSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const getAllCarByUser = (request, setIsloading) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.landingPage.getAllCarByUser}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(getAllCarByUserSuccess({ data: result.data, totalCount: result.totalCount }));
        setIsloading(false);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const getAllBlogsList = (request, setIsloading) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.landingPage.getAllBlogsList}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(getAllBlogsListSuccess(result));
        setIsloading(false);
      } else {
        toast.error(result.message);
        setIsloading(false);
      }
    })
    .catch(() => {});
};

// axios
//   .post(
//     `${process.env.REACT_APP_API_URL}${RENTEDWHEELSAPI.landingPage.getAllBlogsList}`,
//     request,
//     {
//       headers: {
//         accept: 'application/json',
//         Authorization: `Bearer ${token} `,
//         'Content-Type': 'application/json'
//       }
//     }
//   )
//   .then((response) => {
//     let result = response.data;
//     if (result.status == 1) {
//       dispatch(getAllBlogsListSuccess(result.data));
//       setIsloading(false);
//     } else {
//       toast.error(result.message);
//       setIsloading(false);
//     }
//   })
//   .catch(() => {});

export const blogDescription = (request, setIsloading) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.landingPage.blogDescription}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(blogDescriptionDataSuccess(result));
        setIsloading(false);
      } else {
        toast.error(result.message);
        setIsloading(false);
      }
    })
    .catch(() => {});
};

export const setSearchData = (searchData, navigate) => async (dispatch) => {
  dispatch(setSearchDataaSuccess(searchData));
  navigate('/smart-search');
};

export const destinationsList = (request) => async (dispatch) => {
  api
    .get(`${RENTEDWHEELSAPI.landingPage.destinationsList}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(destinationsListSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const experiencedCarsList = (request) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.landingPage.experiencedCarsList}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(experiencedCarsListSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const hostsListing = (request) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.landingPage.hostsListing}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(hostsListSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const updateFavList = (request) => async (dispatch) => {
  dispatch(updateFavListSuccess(request));
};

export const updateFavAllCarList = (request) => async (dispatch) => {
  dispatch(updateFavAllCarListSuccess(request));
};
export const getTheListOfPolicy = (request) => async (dispatch) => {
  api
    .get(
      `${RENTEDWHEELSAPI.landingPage.getTheListOfPolicy}/?policyType=${request?.policyType}&language=${request?.language}`,
      request
    )
    .then((response) => {
      console.log('response', response);
      let result = response.data;
      if (result.status === 1) {
        dispatch(getTheListOfPolicySuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
