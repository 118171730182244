import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
// import { AxiosInterceptor } from './services';
import { Toaster } from 'react-hot-toast';
import Router from './routes';
import '../src/themes/style.css';
import PushNotifications from 'components/pushNotifications';
import { useEffect } from 'react';
import { loginData } from 'components/constant';
import { socket } from 'helpers/socketio-client';
function App() {
  // const CloseButton = ({ closeToast }) => (
  //   <Button size="small" onClick={closeToast} style={{ color: "white" }}>
  //     Ok
  //   </Button>
  // );

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const data = {
      userId: loginData?.id,
      isOnlineOffline: true
    };
    socket.emit('userOnline', data);
  }, []); //

  const handleBeforeUnload = () => {
    const data = {
      userId: loginData?.id,
      isOnlineOffline: false
    };
    socket.emit('userOffline', data);
  };

  return (
    <>
      <PushNotifications />
      <HelmetProvider>
        <BrowserRouter>
          {/* <AxiosInterceptor> */}
          <Router />
          {/* </AxiosInterceptor> */}
        </BrowserRouter>
      </HelmetProvider>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff'
          }
        }}
      />
    </>
  );
}

export default App;
