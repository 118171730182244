import React, { useEffect, useState } from 'react';
import OwnerHeader from '../../../../layouts/carOwner/header';
import Logo from '../../../../assets/img/noProfile.jpg';
import noImage from 'assets/img/no_image.jpg';
import { useNavigate, useParams } from 'react-router-dom';
// import Input from '../../../../components/input';
import { useResponsive } from '../../../../themes/useResponsive';
import ColoredButton from '../../../../components/button/colorFullButtons';
import licenseImage from '../../../../assets/img/no_image.jpg';
import { bookingDetail, setIsStatusLoading } from 'store/slice/carOwner/bookingStatus';
import { useDispatch, useSelector } from 'react-redux';
import AleartDialog from 'components/dialog/aleartDialog';
import { acceptRejectAndCompeleteBooking } from 'store/slice/carOwner/bookingStatus';
import moment from 'moment';
import MapComponent from '../../../../components/mapComponent';
import { createPersonalChatRoomId } from 'store/slice/carOwner/message';
import PopOverSpinner from 'components/popOverSpinner';

const BookingDetails = () => {
  const parms = useParams();
  const navigate = useNavigate();
  // const [isStatusLoading, setIsStatusLoading] = useState(false);
  const { screenType } = useResponsive();
  const dispatch = useDispatch();
  const { bookingData, isStatusLoading } = useSelector((state) => state.bookingsStatus);

  const markers = [
    {
      position: {
        lat: bookingData?.latitude ? bookingData?.latitude : -17.7894532,
        lng: bookingData?.longitude ? bookingData?.longitude : 15.7057792
      }
    }
  ];

  const [showAleartDialog, setShowAleartDialog] = useState({});
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    let request = {
      language: 'en',
      bookingId: parms?.id
    };
    dispatch(bookingDetail(request));
  };

  const handleAction = () => {
    const request = {
      language: 'en',
      bookingId: parms?.id,
      bookingStatus: showAleartDialog?.status
    };
    console.log('request -- ', request);
    dispatch(setIsStatusLoading(true));
    dispatch(acceptRejectAndCompeleteBooking(request, navigate));

    setShowAleartDialog((showAleartDialog) => ({
      ...showAleartDialog,
      id: '',
      show: false,
      status: 0
    }));
  };
  const handleCreateChat = () => {
    const data = {
      recieverUserId: bookingData?.userData?.userId,
      language: 'en'
    };
    console.log('data - ', data);
    dispatch(createPersonalChatRoomId(data, navigate));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <OwnerHeader
        title={'Booking Details'}
        previousPage={'My Booking'}
        currentPage={'Booking Details'}
        previousPageUrl={'/car-owner/mybooking'}
      />

      {isStatusLoading ? <PopOverSpinner /> : ''}

      <div className="row mt-4 gy-3">
        <div className="col-lg-4 ">
          <div className={`card ${screenType != 'desktop' ? 'h-100' : 'h-75'}`}>
            <div className="card-body d-flex flex-column align-items-center">
              <div className="mb-3 ">
                <img
                  src={
                    bookingData?.userData?.profileImage ? bookingData?.userData?.profileImage : Logo
                  }
                  className="rounded-circle"
                  alt="Profile"
                  style={{ width: '130px', height: '130px', objectFit: 'cover' }}
                />
              </div>
              <h5 className="card-title pt-1" style={{ fontFamily: 'Circular Std Bold' }}>
                {bookingData?.userData?.userName}
              </h5>
              <h3
                className="card-title mb-4"
                style={{ fontFamily: 'Circular Std Book', color: '#8892A3' }}>
                +{bookingData?.userData?.countryCode} {bookingData?.userData?.phoneNumber}
              </h3>
              <ColoredButton
                title={'Message'}
                style={{
                  backgroundColor: '#6CD2C2'
                }}
                onClick={handleCreateChat}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4 gy-3">
          <div className={`card ${screenType != 'desktop' ? 'h-100' : 'h-75'}`}>
            <div className="card-body ">
              <img
                className="card-img-top"
                src={
                  bookingData?.productData?.carImagesUrl?.length >= 1
                    ? bookingData?.productData?.carImagesUrl[0]
                    : noImage
                }
                alt="Card image cap"
                height={'180px'}
                style={{ objectFit: 'cover', borderRadius: 15 }}
              />
              <center>
                <h5
                  className="pt-3"
                  style={{
                    color: '#132649',
                    fontFamily: 'Circular Std Medium',
                    paddingTop: '0.7rem'
                  }}>
                  {bookingData?.productData?.modelName}
                </h5>
                <div
                  className="card-body d-flex flex-column align-items-center"
                  style={{ marginBottom: '1.5rem' }}>
                  <ColoredButton
                    title={
                      <>
                        <span
                          style={{
                            color: '#FFBB51',
                            fontSize: 16,
                            fontFamily: 'Circular Std Book'
                          }}>
                          N$
                        </span>
                        <span style={{ color: '#132649' }}>{bookingData?.totalAmount}</span>
                        <span
                          style={{
                            color: '#8892A3',
                            fontSize: 15,
                            fontFamily: 'Circular Std Book'
                          }}>
                          /
                          {bookingData?.bookingType === 'perDay'
                            ? 'Per Day'
                            : bookingData?.bookingType === 'perHour'
                              ? 'Per Hour'
                              : 'Per Week'}
                        </span>
                      </>
                    }
                    style={{
                      backgroundColor: '#e7a43c4a',
                      cursor: 'default'
                    }}
                  />
                </div>
              </center>
            </div>
          </div>
        </div>
        <div className="col-lg-4 gy-3">
          <div className={`card ${screenType !== 'desktop' ? 'h-100' : 'h-75'}`}>
            <div className="card-body mb-5">
              <h5
                className="card-title"
                style={{ fontFamily: 'Circular Std Bold', color: '#132649', fontSize: 28 }}>
                Booking
              </h5>
              <div className="row pt-4 pl-2">
                <div className="col-6">
                  <p className="card-List-Left">Fuel</p>
                  <p className="card-List-Left">Booking Date</p>
                  <p className="card-List-Left">End Date</p>
                  <p className="card-List-Left">Booking ID</p>
                  <p className="card-List-Left">Refundable Deposit</p>
                </div>
                <div className="col-6">
                  <p className="card-List-Right">
                    {bookingData?.fuelType?.type === 'withfuel' ? 'With Fuel' : 'Without Fuel'}
                  </p>
                  <p className="card-List-Right">
                    {moment(bookingData?.bookingDate?.from).format('Do MMM, YYYY')}
                  </p>
                  <p className="card-List-Right">
                    {moment(bookingData?.bookingDate?.to).format('Do MMM, YYYY')}
                  </p>
                  <p className="card-List-Right">#{bookingData?.orderId}</p>
                  <p className="card-List-Right">
                    {bookingData?.refundableAmount ? bookingData?.refundableAmount : ' N$2000'}
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                {/* <ColoredButton
                  title={'Complete'}
                  style={{ background: '#132649', height: '40px', width: '99.83px' }}
                /> */}
                <ColoredButton
                  title={'Complete'}
                  height={'45px'}
                  style={{
                    backgroundColor: '#132649'
                  }}
                  onClick={() => {
                    setShowAleartDialog((showAleartDialog) => ({
                      ...showAleartDialog,
                      show: true,
                      status: 2
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row ">
        <div className="col-lg-8">
          <h5
            className="card-title mb-4"
            style={{
              fontFamily: 'Circular Std Bold',
              color: '#132649',
              fontSize: 30,
              marginTop: screenType != 'desktop' ? '5rem' : ''
            }}>
            Car Location
          </h5>
          <MapComponent markers={markers} />
        </div>
        <div className="col-lg-4 mb-4">
          <h5
            className="card-title mb-4"
            style={{
              fontFamily: 'Circular Std Bold',
              color: '#132649',
              fontSize: 30,
              marginTop: screenType != 'desktop' ? '3rem' : ''
            }}>
            License Details
          </h5>
          <img
            src={
              bookingData?.userData?.userDocument.length >= 1
                ? bookingData?.userData?.userDocument[0]
                : licenseImage
            }
            className="img-fluid"
            alt="License Details"
            style={{
              height: '240.99px',
              width: '419.13px',
              objectFit: 'cover',
              borderRadius: '15px'
            }}
          />
        </div>
      </div>

      <div className="row align-items-center">
        <div
          className="col-sm-8 text-muted fs-2 mt-5 "
          style={{ fontFamily: 'Circular Std Medium' }}>
          Rules & Precautions
        </div>
      </div>
      <div className="row" style={{ marginTop: '-16px' }}>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="card" style={{ borderRadius: '15px' }}>
            <div className="card-body" style={{ padding: 0 }}>
              <div>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="8"
                  style={{
                    fontFamily: 'Circular Std Black',
                    borderRadius: '15px',
                    color: '#132649'
                  }}>
                  Purchasing Insurance Prepaying for Gasoline
                </textarea>
              </div>
            </div>
          </div>

          {/* <Input
            type="number"
            // onChange={(val) => {
            //   setMyCarData((myCarData) => ({
            //     ...myCarData,
            //     mileageCapPerHour: {
            //       km: myCarData?.mileageCapPerHour?.km,
            //       amount: val
            //     },
            //     mileageCapPerHourAmountErr: ''
            //   }));
            // }}
            onChange={(val) => console.log(val)}
            // value={myCarData?.mileageCapPerHour?.amount}
            // formError={myCarData?.mileageCapPerHourAmountErr}
            // placeholder={'Enter Per Hours'}
            endName={
              <div className="circularBold" style={{ color: '#6CD2C2', fontSize: 20 }}>
                N$
              </div>
            }
          />
          {screenType === 'desktop' && (
            <Link to="/carOwner/myBooking/refund-payment">
              <button
                className="btn btn-warning w-75 mt-4"
                type="button"
                style={{
                  fontFamily: 'Circular Std Black'
                }}>
                Refund & Complete
              </button>
            </Link>
          )} */}
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="card" style={{ borderRadius: '15px' }}>
            <div className="card-body">
              <h5 className="card-title">Refund Payments</h5>
              <div className="row">
                <div className="col-md-4">
                  <p className="card-List-Left">Refundable Deposit</p>
                  {/* <p className="card-List-Left">Mileage Cap</p>
                  <p className="card-List-Left">Penalty Charges</p> */}
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="card-List-Right">N$2000</p>
                      {/* <p className="card-List-Right">N$25</p>
                      <p className="card-List-Right">N$25</p> */}
                    </div>
                  </div>
                </div>
                <hr style={{ width: '500px' }} />
                <div className="col-md-4">
                  <p
                    className="card-List-Left"
                    style={{
                      fontFamily: 'Circular Std Book',
                      color: '#132649',
                      fontSize: '25px'
                    }}>
                    Final Fare
                  </p>
                </div>
                <div className="col-md-8">
                  <p
                    className="card-List-Right"
                    style={{
                      fontFamily: 'Circular Std Bold',
                      color: '#132649',
                      fontSize: '25px'
                    }}>
                    <span
                      style={{
                        fontFamily: 'Circular Std Bold',
                        color: '#FFBB51',
                        fontSize: '20px'
                      }}>
                      N$
                    </span>
                    {bookingData?.totalAmount - 500}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {screenType != 'desktop' && (
          <Link to="/carOwner/myBooking/refund-payment">
            <button
              className="btn btn-warning w-75 mt-4"
              type="button"
              style={{
                fontFamily: 'Circular Std Black'
              }}>
              Refund & Complete
            </button>
          </Link>
        )} */}
      </div>
      <AleartDialog
        title={`${showAleartDialog.status == 2 ? 'Complete' : 'Reject'} booking request`}
        message={`Are you sure, you want to ${showAleartDialog.status == 2 ? 'complete' : 'reject'} this booking?`}
        showDialog={showAleartDialog.show}
        // isLoading={isStatusLoading}
        onDialogClose={() => {
          setShowAleartDialog((showAleartDialog) => ({
            ...showAleartDialog,
            show: false,
            status: 0
          }));
        }}
        btnConfirm={() => {
          handleAction();
        }}
      />
    </>
  );
};
export default BookingDetails;
