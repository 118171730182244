import React, { useState } from 'react';
// import { useResponsive } from '../../../themes/useResponsive';
import noImage from '../../../../assets/img/no_image.jpg';
import { Icon } from '@iconify/react';
import SmallButton from '../../../../components/button/smallButton';
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

export default function CarBox(props) {
  const { data, deleteRef, setCarId } = props;
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const handleEdit = () => {
    let id = data?.id;
    navigate(`/car-owner/mycars/edit-Car/${id}`);
  };

  const handleDelete = (id) => {
    setCarId(id);
    deleteRef.current && deleteRef.current.click();
  };
  return (
    <>
      <div
        className="card w-100 border-0"
        style={{ marginTop: '1rem', borderRadius: 25, background: 'none' }}>
        <div
          className="latestCars-imageBox"
          style={{
            // backgroundImage: `url("${data?.carImagesUrl?.length >= 1 ? data?.carImagesUrl[0] : noImage}")`,
            borderRadius: 15,
            height: 169.76,
            width: '100%',
            backgroundSize: 'cover'
            // background: 'red',
          }}>
          <Carousel
            controls={false}
            // interval={2000}
            slide={false}
            activeIndex={index}
            onSelect={handleSelect}>
            {data?.carImagesUrl?.length != 0 ? (
              data?.carImagesUrl?.map((item, i) => {
                return (
                  <Carousel.Item key={i}>
                    <img
                      className="d-block"
                      src={item}
                      width={'100%'}
                      height={'169.76px'}
                      style={{ objectFit: 'cover' }}
                      alt="First slide"
                    />
                  </Carousel.Item>
                );
              })
            ) : (
              <div className="carousel-item active">
                <img
                  className="d-block"
                  src={noImage}
                  width={'100%'}
                  style={{ objectFit: 'cover' }}
                  height={'169.76px'}
                  alt="First slide"
                />
              </div>
            )}
          </Carousel>
          {/* {data?.averageRating && ( */}
          <div
            style={{
              position: 'absolute',
              top: 10,
              left: 10,
              borderRadius: 15,
              background: 'white',
              display: 'inline-flex',
              padding: 2
            }}>
            <span className="badge text-dark d-flex">
              <Icon icon="fluent:star-12-filled" height={18} color="#F1AE01" />
              &nbsp;
              <div style={{ fontSize: 17 }}> {data?.averageRating ? data?.averageRating : 4}</div>
            </span>
          </div>
          {/* )} */}
          <div className="myCar-deleteIcon" style={{ opacity: 0.5 }}>
            <Icon
              icon="ant-design:delete-outlined"
              color="white"
              height={30}
              onClick={() => handleDelete(data?.id)}
            />
          </div>
        </div>

        <div className="pl-2">
          <div className="latestCars-title pt-2 ">
            {data?.modelName.charAt(0).toUpperCase() + data?.modelName.slice(1)}
          </div>
          <small className="d-inline text-muted ">
            {' '}
            {data?.transmission.charAt(0).toUpperCase() + data?.transmission.slice(1).toLowerCase()}
          </small>
          &nbsp;&nbsp; <small className="d-inline text-muted">.</small>&nbsp;&nbsp;
          <small className="d-inline text-muted">{data?.carFuelType}</small>&nbsp;&nbsp;
          <small className="d-inline text-muted">.</small>&nbsp;&nbsp;
          <small className="d-inline text-muted">{data?.seatNumber} Seats</small>
          <div
            className="pt-3 pb-2 pr-3"
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="text-warning circularBook pt-1" style={{ fontSize: 20 }}>
              N$
              <div className="d-inline" style={{ color: '#132649', fontFamily: 'Circular Std' }}>
                {data?.withFule
                  ? data?.withFule?.amountPerDay
                  : data?.withoutFule
                    ? data?.withoutFule?.amountPerDay
                    : 0}
              </div>
              <div className="d-inline" style={{ color: '#132649', fontSize: 17, fontWeight: 500 }}>
                /day
              </div>
            </div>
            <SmallButton
              width={100}
              title={'Edit'}
              style={{ background: '#132649' }}
              onClick={() => handleEdit()}
            />
          </div>
        </div>
      </div>
    </>
  );
}
