import React, { useContext, useState } from 'react';
import TabBottomLine from './tabBottomLine';
import { useResponsive } from '../../../../themes/useResponsive';
import CarInfo from '../addEditCar/carInfo';
import BasicDetail from '../addEditCar/basicDetail';
import MileageCap from '../addEditCar/mileageCap';
import CarFeatures from '../addEditCar/carFeature';
import RulesAndPrecaution from '../addEditCar/rulesandPrecaution';
import MyCarContext from '../../../../components/context/myCarContext';

export default function Tabs() {
  const [activeTab, setActiveTab] = useState('carInfo');
  const { screenType } = useResponsive();
  const { basicInfoRef, carInfoRef, mileageInfoRef, featuresRef, rulesRef } =
    useContext(MyCarContext);

  return (
    <>
      <ul
        className="nav nav-pills mb-3 pt-5 mt-4"
        id="pills-tab"
        role="tablist"
        onClick={(event) => {
          let value = event.target.id;
          let id = value.split('-')[1];
          setActiveTab(id);
        }}>
        <li
          className="nav-item"
          role="presentation"
          style={{ width: screenType === 'MOBILE' ? '15%' : '20%' }}>
          <button
            ref={carInfoRef}
            id="pills-carInfo-tab"
            data-bs-toggle="pill"
            data-bs-target="#car-info"
            className="tab-button active">
            Car info
            {activeTab === 'carInfo' && <TabBottomLine />}
          </button>
        </li>
        <li
          className="nav-item"
          role="presentation"
          style={{ width: screenType === 'MOBILE' ? '15%' : '20%' }}>
          <button
            ref={basicInfoRef}
            id="pills-BasicInfo-tab"
            data-bs-toggle="pill"
            data-bs-target="#Basic-Info"
            className="tab-button">
            Basic details
            {activeTab === 'BasicInfo' && <TabBottomLine />}
          </button>
        </li>
        <li className="nav-item" role="presentation" style={{ width: '20%' }}>
          <button
            ref={mileageInfoRef}
            id="pills-MileageInfo-tab"
            data-bs-toggle="pill"
            data-bs-target="#Mileage-Info"
            className="tab-button">
            Mileage cap
            {activeTab === 'MileageInfo' && <TabBottomLine />}
          </button>
        </li>
        <li
          className="nav-item"
          role="presentation"
          style={{ width: screenType === 'MOBILE' ? '30%' : '20%' }}>
          <button
            ref={featuresRef}
            id="pills-FeaturesInfo-tab"
            data-bs-toggle="pill"
            data-bs-target="#Features-Info"
            className="tab-button">
            Car features & Specification
            {activeTab === 'FeaturesInfo' && <TabBottomLine />}
          </button>
        </li>
        <li className="nav-item" role="presentation" style={{ width: '20%' }}>
          <button
            ref={rulesRef}
            id="pills-RulesInfo-tab"
            data-bs-toggle="pill"
            data-bs-target="#Rules-Info"
            className="tab-button">
            Rules & Precautions
            {activeTab === 'RulesInfo' && <TabBottomLine />}
          </button>
        </li>
      </ul>

      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="car-info"
          role="tabpanel"
          aria-labelledby="pills-carInfo-tab">
          <CarInfo />
        </div>
        <div
          className="tab-pane fade"
          id="Basic-Info"
          role="tabpanel"
          aria-labelledby="pills-BasicInfo-tab">
          <BasicDetail />
        </div>
        <div
          className="tab-pane fade"
          id="Mileage-Info"
          role="tabpanel"
          aria-labelledby="pills-MileageInfo-tab">
          <MileageCap />
        </div>
        <div
          className="tab-pane fade"
          id="Features-Info"
          role="tabpanel"
          aria-labelledby="pills-FeaturesInfo-tab">
          <CarFeatures />
        </div>
        <div
          className="tab-pane fade"
          id="Rules-Info"
          role="tabpanel"
          aria-labelledby="pills-RulesInfo-tab">
          <RulesAndPrecaution />
        </div>
      </div>
    </>
  );
}
