import { useEffect, useState } from 'react';
import OwnerHeader from '../../../layouts/carOwner/header';
import CountBox from '../countBox';
import PendingRequests from './pendingRequest';
import ActiveBooking from './activeBooking';
import CompletedBooking from './completedBooking';
import {
  acceptRejectAndCompeleteBooking,
  myBookingStatus,
  setIsStatusLoading
} from '../../../store/slice/carOwner/bookingStatus';
import { totalCounts } from '../../../store/slice/carOwner/bookingStatus';
import { useDispatch, useSelector } from 'react-redux';
import MyCarContext from 'components/context/myCarContext';
import AleartDialog from 'components/dialog/aleartDialog';
import pendingBookingIcon from 'assets/img/owner-icons/pending-booking.png';
import ActiveBookingIcon from 'assets/img/owner-icons/active-booking.png';
import completedBookingIcon from 'assets/img/owner-icons/complete-booking.png';
import PopOverSpinner from 'components/popOverSpinner';

const MyBookings = () => {
  const dispatch = useDispatch();
  const [lisType, setListType] = useState(1);
  const [showAleartDialog, setShowAleartDialog] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListloading] = useState(false);
  // const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [filterData, setFilterData] = useState({});
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);
  const { allBookingsList, totalCount, bookingTotalCount, isStatusLoading } = useSelector(
    (state) => state.bookingsStatus
  );

  useEffect(() => {
    getAllBookingStatus();
    counts();
    setIsListloading(true);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllBookingStatus();
  }, [lisType]);

  useEffect(() => {
    getAllBookingStatus();
  }, [filterData]);

  useEffect(() => {
    setFilterData((filterData) => ({
      ...filterData,
      start: state[0].startDate,
      end: state[0].endDate
    }));
  }, [state]);

  const getAllBookingStatus = (perPage) => {
    const data = {
      language: 'en',
      page: 1,
      perPage: perPage ? perPage : limit,
      type: 2,
      bookingType: lisType,
      orderId: '',
      startDate: filterData.start,
      endDate: filterData.end,
      sortByDate: filterData.sortByDate
    };
    dispatch(myBookingStatus(data, setIsLoading, setIsListloading));
  };
  const counts = () => {
    let request = {
      language: 'en'
    };
    dispatch(totalCounts(request));
  };

  const handleAction = () => {
    const request = {
      language: 'en',
      bookingId: showAleartDialog?.id,
      bookingStatus: showAleartDialog?.status
    };
    dispatch(setIsStatusLoading(true));
    dispatch(acceptRejectAndCompeleteBooking(request));

    setShowAleartDialog((showAleartDialog) => ({
      ...showAleartDialog,
      id: '',
      show: false,
      status: 0
    }));
  };

  return (
    <>
      <OwnerHeader title={'My Bookings'} />
      {isListLoading || isStatusLoading ? <PopOverSpinner /> : ''}
      <>
        <div className="my-5 pt-4">
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
              <CountBox
                onClick={() => {
                  setLimit(10);
                  setListType(1);
                  setIsListloading(true);
                }}
                title={'Pending Requests'}
                count={totalCount?.pendingBookingCount}
                bg={'#FFEAEA'}
                image={pendingBookingIcon}
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
              <CountBox
                onClick={() => {
                  setLimit(10);
                  setListType(3);
                  setIsListloading(true);
                }}
                title={'Active Booking'}
                count={totalCount?.activeCarCount}
                bg={'#ECFFE0'}
                image={ActiveBookingIcon}
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 mt-2">
              <CountBox
                onClick={() => {
                  setLimit(10);
                  setListType(2);
                  setIsListloading(true);
                }}
                title={'Completed Booking'}
                count={totalCount?.completedBooking}
                bg={'#FCEBFF'}
                image={completedBookingIcon}
              />
            </div>
          </div>
        </div>
        <MyCarContext.Provider
          value={{
            allBookingsList,
            totalCount,
            setShowAleartDialog,
            showAleartDialog,
            state,
            setState,
            filterData,
            setFilterData,
            setLimit,
            limit,
            bookingTotalCount,
            getAllBookingStatus,
            isLoading,
            setIsLoading
          }}>
          <div>
            {lisType === 1 && (
              <PendingRequests isListLoading={isListLoading} isStatusLoading={isStatusLoading} />
            )}
            {lisType === 3 && <ActiveBooking />}
            {lisType === 2 && <CompletedBooking />}
          </div>
        </MyCarContext.Provider>

        <AleartDialog
          title={`${showAleartDialog.status == 3 ? 'Accept' : 'Reject'} booking request`}
          message={`Are you sure, you want to ${showAleartDialog.status == 3 ? 'Accept' : 'Reject'} this booking?`}
          showDialog={showAleartDialog.show}
          // isLoading={isStatusLoading}
          onDialogClose={() => {
            // handlePublishEvent('NO');
            setShowAleartDialog((showAleartDialog) => ({
              ...showAleartDialog,
              id: '',
              show: false,
              status: 0
            }));
          }}
          disAgreeBtnTitle={'Close'}
          agreeBtnTitle={showAleartDialog.status == 3 ? 'Accept' : 'Reject'}
          btnConfirm={() => {
            // handlePublishEvent('YES');
            handleAction();
          }}
        />
      </>
    </>
  );
};

export default MyBookings;
