import { Icon } from '@iconify/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../index.css';
import { useResponsive } from '../../../../themes/useResponsive';
import '../../payment/CardStyle.css';
import MobileViewDataCard from '../../subComponent/dataList/MobileView';
import DesktopViewDataCard from '../../subComponent/dataList/desktopView';
import MyCarContext from 'components/context/myCarContext';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ColoredButton from 'components/button/colorFullButtons';
import Spinner from 'react-bootstrap/Spinner';
const ActiveBooking = () => {
  const { screenType } = useResponsive();
  const navigate = useNavigate();
  const {
    allBookingsList,
    totalCount,
    state,
    setState,
    setFilterData,
    bookingTotalCount,
    limit,
    setLimit,
    getAllBookingStatus,
    isLoading,
    setIsLoading
  } = useContext(MyCarContext);
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleIconClick = () => {
    setShowCalendar(!showCalendar);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="row align-items-center mb-4">
        <div className="col-sm-8 fs-2 mt-1 carOwner-tab-title">
          Active Bookings /{' '}
          <span style={{ color: '#73D239', fontFamily: 'Circular Std Medium' }}>
            {totalCount?.activeCarCount}
          </span>{' '}
        </div>
        <div className="col-sm-4 d-flex justify-content-end mt-3 ">
          <form className="form-search form-inline rounded-5">
            <div className="dropdown">
              <button
                style={{ width: 150 }}
                className="sort-List dropdown-toggle dropdown-toggle-split"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <label style={{ fontSize: '16px' }}> Sort by</label>
                <Icon icon={'formkit:down'} width={'20px'} />
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  className="dropdown-item"
                  onClick={() => {
                    setFilterData((filterData) => ({
                      ...filterData,
                      sortByDate: -1
                    }));
                  }}>
                  New-Old
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    setFilterData((filterData) => ({
                      ...filterData,
                      sortByDate: 1
                    }));
                  }}>
                  Old-New
                </a>
                {/* <a className="dropdown-item" href="#">
                  Something else here
                </a> */}
              </div>
            </div>
            <div
              className="checklist bg-white rounded-4 ml-2 d-flex justify-content-center align-items-center"
              style={{ height: 40, width: 40 }}>
              <Icon
                icon="octicon:calendar-16"
                style={{ color: 'black', cursor: 'pointer' }}
                onClick={handleIconClick}
              />
              {showCalendar && (
                <div
                  ref={calendarRef}
                  style={{ position: 'absolute', top: '30px', left: '0', zIndex: 5 }}>
                  {/* <Calendar onChange={handleCalendarChange} value={date} /> */}
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => setState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>

      <div className="row card-container">
        <div className="col-xl-12">
          <div className="task-list-box" id="landing-task">
            {allBookingsList?.length >= 1 ? (
              <>
                {allBookingsList?.map((item, index) => {
                  return (
                    <>
                      {screenType != 'desktop' ? (
                        <MobileViewDataCard
                          isNumber
                          data={item}
                          key={index}
                          onClick={() =>
                            navigate(`/car-owner/mybooking/booking-details/${item.id}`)
                          }
                        />
                      ) : (
                        <DesktopViewDataCard
                          isNumber
                          noMargin
                          data={item}
                          key={index}
                          onClick={() =>
                            navigate(`/car-owner/mybooking/booking-details/${item.id}`)
                          }
                        />
                      )}
                    </>
                  );
                })}

                <div className="d-flex justify-content-center mt-3">
                  {isLoading ? (
                    <Spinner animation="border" style={{ color: '#6CD2C2' }} />
                  ) : (
                    bookingTotalCount > limit && (
                      <ColoredButton
                        onClick={() => {
                          let perPage = limit + 10;
                          setLimit(perPage);
                          setIsLoading(true);
                          getAllBookingStatus(perPage);
                        }}
                        title="View More"
                        style={{ background: '#6CD2C2', width: '130px' }}
                      />
                    )
                  )}
                </div>
              </>
            ) : (
              <div className="noData" style={{ height: '50vh' }}>
                No Data Found
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ActiveBooking;
