import React, { useEffect, useState } from 'react';
import './index.css';
import OwnerHeader from '../../../layouts/carOwner/header';
import ChatList from './chatList';
import ChatData from './chatData';
import noListFound from '../../../assets/img/Group1544.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCarOwnerMessageList,
  receiveReadMessage,
  setChatUserData
} from 'store/slice/carOwner/message';
import { loginData } from 'components/constant';
import Header from 'components/header';
import { socket } from 'helpers/socketio-client';
import PopOverSpinner from 'components/popOverSpinner';
import Input from 'components/input';
export default function index() {
  const dispatch = useDispatch();
  const { messageListData, chatUserDataAfterRedirect } = useSelector(
    (state) => state.carOwnerMessage
  );
  const [isFirst, setIsFirst] = useState(true);
  const [isListLoading, setIsListloading] = useState(false);
  const [searchData, setSearchData] = useState();

  useEffect(() => {
    getChatList();
    setIsListloading(true);
  }, []);

  useEffect(() => {
    socket.on('afterReadMessage', handleReadMessage);
  }, [socket]);

  const handleReadMessage = (data) => {
    dispatch(receiveReadMessage(data));
  };

  const handleClickChat = (item) => {
    readMessage(item);
    dispatch(setChatUserData(item));
    // getChatList();
  };

  useEffect(() => {
    if (chatUserDataAfterRedirect) {
      dispatch(setChatUserData(chatUserDataAfterRedirect));
    }
  }, [chatUserDataAfterRedirect]);

  useEffect(() => {
    if (messageListData.length > 0 && !chatUserDataAfterRedirect && isFirst) {
      readMessage(messageListData[0]);
      setIsFirst(false);
      dispatch(setChatUserData(messageListData[0]));
    }
  }, [messageListData]);

  useEffect(() => {
    getChatList();
  }, [searchData]);

  const readMessage = (item) => {
    const data = {
      userId: loginData?.id, // Replace with actual user ID
      chatRoomId: item?.chatRoomId // Replace with actual chat room ID
    };
    // Send readMessage event with additional data
    socket.emit('readMessage', data);
  };

  const getChatList = () => {
    const data = {
      search: searchData,
      page: 1,
      perPage: 10,
      language: 'en'
    };
    dispatch(getCarOwnerMessageList(data, setIsListloading));
  };

  return loginData && loginData?.userType == 3 ? (
    <div className="w-100" style={{ background: '#e5e5e5' }}>
      <Header background color />

      {isListLoading ? <PopOverSpinner /> : ''}
      <div className="container bg-white p-4 " style={{ marginTop: '2rem' }}>
        <div
          style={{
            marginLeft: '-1.5rem',
            marginRight: '-1rem',
            marginTop: '2rem',
            background: 'white'
          }}>
          <div className="row">
            <div className="col-12 col-lg-5 col-xl-3 border-right mt-2 ">
              <Input
                placeholder="Search by name"
                endIcon="system-uicons:search"
                onChange={(value) => setSearchData(value)}
              />
              {messageListData.length > 0 ? (
                <>
                  {messageListData?.map((item, index) => {
                    return (
                      <ChatList data={item} key={index} onClick={() => handleClickChat(item)} />
                    );
                  })}
                </>
              ) : (
                <div className="noDataFound">
                  <img className="no-data-found-image" src={noListFound} alt="image" />
                </div>
              )}
            </div>

            <ChatData isListLoading={isListLoading} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      <OwnerHeader title={'Messages'} />
      {isListLoading ? <PopOverSpinner /> : ''}
      <div
        style={{
          marginLeft: '-1.5rem',
          marginRight: '-1rem',
          marginTop: '3.5rem',
          background: 'white'
        }}>
        <div className="row">
          <div className="col-12 col-lg-5 col-xl-3 border-right mt-2 ">
            <Input
              placeholder="Search by name"
              endIcon="system-uicons:search"
              onChange={(value) => setSearchData(value)}
            />

            {/* <Icon icon="system-uicons:search" /> */}
            {messageListData.length > 0 ? (
              <>
                {' '}
                {messageListData?.map((item, index) => {
                  return <ChatList data={item} key={index} onClick={() => handleClickChat(item)} />;
                })}
              </>
            ) : (
              <>
                <div className="noDataFound">
                  <img
                    className="no-data-found-image"
                    src={noListFound}
                    alt="image"
                    style={{ objectFit: 'cover' }}
                  />
                </div>
              </>
            )}
          </div>

          <ChatData isListLoading={isListLoading} />
        </div>
      </div>
    </>
  );
}
