export const TOAST_TYPES = {
  warning: 'warning',
  error: 'error',
  success: 'success',
  copy: 'copy',
  thanks: 'thanks',
  fav: 'fav',
  report: 'report'
};

export const notificationConst = [
  {
    type: 'notificationSendSuccesfully',
    id: 1,
    navigateUrl: ''
  },
  {
    type: 'notificationSendByAdmin',
    id: 2,
    navigateUrl: ''
  },
  {
    type: 'carBooking',
    id: 3,
    navigateUrl: ''
  },
  {
    type: 'bookingCar',
    id: 4,
    navigateUrl: ''
  },
  {
    type: 'bookingAccepted',
    id: 5,
    navigateUrl: '/payment'
  },
  {
    type: 'bookingReject',
    id: 6,
    navigateUrl: ''
  },
  {
    type: 'bookingComplete',
    id: 7,
    navigateUrl: ''
  },
  {
    type: 'addcar',
    id: 8,
    navigateUrl: ''
  }
];
