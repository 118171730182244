import React, { useContext } from 'react';
import { useResponsive } from '../../../../../themes/useResponsive';
import Input from '../../../../../components/input';
import MyCarContext from '../../../../../components/context/myCarContext';
import Button from '../../../../../components/button';
import SelectInput from 'components/select';

export default function CarFeatures() {
  const { screenType } = useResponsive();
  const { myCarData, setMyCarData, rulesRef } = useContext(MyCarContext);
  const handleNext = () => {
    if (!myCarData?.seatNumber) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        seatNumberErr: 'Please enter numbers of seat'
      }));
      return;
    }
    if (!myCarData?.transmission) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        transmissionErr: 'Please enter Transmission (ex:automatic)'
      }));
      return;
    }
    if (!myCarData?.airBags) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        airBagsErr: 'Please enter numbers of air bags'
      }));
      return;
    }
    if (!myCarData?.audioFeatures) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        audioFeaturesErr: 'Please enter audio feature (ex:JBL)'
      }));
      return;
    }
    if (!myCarData?.fuelConsumption) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        fuelConsumptionErr: 'Please enter fuel consumption'
      }));
      return;
    }
    if (!myCarData?.brakes) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        brakesErr: 'Please enter bracks type'
      }));
      return;
    }
    if (!myCarData?.carFuelType) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        carFuelTypeErr: 'Please select car fuel type'
      }));
      return;
    }
    rulesRef.current && rulesRef.current.click();
  };
  const transmissionData = [
    {
      id: 'manual',
      label: 'Manual'
    },
    {
      id: 'automatic',
      label: 'Automatic'
    }
  ];
  const carFuelTypeData = [
    {
      id: 'Diesel',
      label: 'Diesel'
    },
    {
      id: 'Petrol',
      label: 'Petrol'
    }
  ];
  return (
    <div className="py-4 ">
      <div className="row mt-2">
        <div className={screenType === 'desktop' ? 'col-4 ' : 'col-12 '}>
          <div className="myCar-label">Seat</div>
          <div className="form-group pt-3">
            <Input
              type="number"
              onChange={(val) => {
                setMyCarData((myCarData) => ({ ...myCarData, seatNumber: val, seatNumberErr: '' }));
              }}
              value={myCarData?.seatNumber}
              formError={myCarData?.seatNumberErr}
              placeholder={'Enter number of seat'}
            />
          </div>
        </div>
        <div className={screenType === 'desktop' ? 'col-4 ' : 'col-12 pt-3'}>
          <div className="myCar-label">Transmission</div>
          <div className="form-group pt-3">
            <SelectInput
              onChange={(val) => {
                setMyCarData((myCarData) => ({
                  ...myCarData,
                  transmission: val.target.value,
                  transmissionErr: ''
                }));
              }}
              value={myCarData?.transmission}
              formError={myCarData?.transmissionErr}
              options={transmissionData?.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item?.label}
                  </option>
                );
              })}
              Placeholder={'Enter transmission'}
            />
          </div>
        </div>
        <div className={screenType === 'desktop' ? 'col-4 ' : 'col-12 pt-3'}>
          <div className="myCar-label">Air Bags</div>
          <div className="form-group pt-3">
            <Input
              type="number"
              onChange={(val) => {
                setMyCarData((myCarData) => ({
                  ...myCarData,
                  airBags: val,
                  airBagsErr: ''
                }));
              }}
              value={myCarData?.airBags}
              formError={myCarData?.airBagsErr}
              placeholder={'Enter number Of air bags'}
            />
          </div>
        </div>
        <div className={screenType === 'desktop' ? 'col-4 pt-3 ' : 'col-12 pt-3 '}>
          <div className="myCar-label">Audio Features</div>
          <div className="form-group pt-3">
            <Input
              onChange={(val) => {
                setMyCarData((myCarData) => ({
                  ...myCarData,
                  audioFeatures: val,
                  audioFeaturesErr: ''
                }));
              }}
              value={myCarData?.audioFeatures}
              formError={myCarData?.audioFeaturesErr}
              placeholder={'Enter audio features'}
            />
          </div>
        </div>
        <div className={screenType === 'desktop' ? 'col-4 pt-3 ' : 'col-12 pt-3 '}>
          <div className="myCar-label">Fuel Consumption</div>
          <div className="form-group pt-3">
            <Input
              type="number"
              onChange={(val) => {
                setMyCarData((myCarData) => ({
                  ...myCarData,
                  fuelConsumption: val,
                  fuelConsumptionErr: ''
                }));
              }}
              value={myCarData?.fuelConsumption}
              formError={myCarData?.fuelConsumptionErr}
              placeholder={'Enter fuel consumption'}
              endName={
                <div
                  className="circularBold"
                  style={{ color: '#6CD2C2', fontSize: 20, paddingRight: 1 }}>
                  KMPL
                </div>
              }
            />
          </div>
        </div>
        <div
          style={{ marginBottom: screenType === 'desktop' ? '' : '4rem' }}
          className={screenType === 'desktop' ? 'col-4 pt-3' : 'col-12 pt-3'}>
          <div className="myCar-label">Brakes</div>
          <div className="form-group pt-3">
            <Input
              onChange={(val) => {
                setMyCarData((myCarData) => ({
                  ...myCarData,
                  brakes: val,
                  brakesErr: ''
                }));
              }}
              value={myCarData?.brakes}
              formError={myCarData?.brakesErr}
              placeholder={'Enter types of brakes'}
            />
          </div>
        </div>
        <div className={screenType === 'desktop' ? 'col-4 pt-3 ' : 'col-12 pt-3'}>
          <div className="myCar-label">Car Fuel Type</div>
          <div className="form-group pt-3">
            <SelectInput
              onChange={(val) => {
                setMyCarData((myCarData) => ({
                  ...myCarData,
                  carFuelType: val.target.value,
                  carFuelTypeErr: ''
                }));
              }}
              value={myCarData?.carFuelType}
              formError={myCarData?.carFuelTypeErr}
              options={carFuelTypeData?.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item?.label}
                  </option>
                );
              })}
              Placeholder={'Select Fuel Type '}
            />
          </div>
        </div>
      </div>
      <div
        className="position-absolute"
        style={{
          bottom: screenType != 'MOBILE' ? 0 : -10,
          right: screenType != 'MOBILE' ? 15 : 0,
          left: screenType != 'MOBILE' ? '' : 0
        }}>
        <Button onClick={handleNext} title="Next" />
      </div>
    </div>
  );
}
