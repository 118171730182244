import React, { useEffect, useState } from 'react';
import noImage from '../../../assets/img/no_image.jpg';
import { Icon } from '@iconify/react';
import SmallButton from '../../../components/button/smallButton';
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

export default function MyCarBox(props) {
  const { data, deleteRef, setCarId } = props;
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const handleDelete = (id) => {
    setCarId(id);
    deleteRef.current && deleteRef.current.click();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="card" style={{ marginTop: '1rem', borderRadius: 25 }}>
        <div
          className="latestCars-imageBox"
          style={{
            // backgroundImage: `url("${data?.carImagesUrl?.length >= 1 ? data?.carImagesUrl[0] : noImage}")`,
            borderRadius: 10,
            height: 169.76,
            width: '100%'
          }}>
          <Carousel
            controls={false}
            // interval={2000}
            slide={false}
            activeIndex={index}
            onSelect={handleSelect}>
            {data?.carImagesUrl?.length != 0 ? (
              data?.carImagesUrl?.map((item, i) => {
                return (
                  <Carousel.Item key={i}>
                    <img
                      className="d-block"
                      src={item}
                      style={{ objectFit: 'cover' }}
                      width={'100%'}
                      height={'169.76px'}
                      alt="First slide"
                    />
                  </Carousel.Item>
                );
              })
            ) : (
              <div className="carousel-item active">
                <img
                  className="d-block"
                  src={noImage}
                  style={{ objectFit: 'cover' }}
                  width={'100%'}
                  height={'169.76px'}
                  alt="First slide"
                />
              </div>
            )}
          </Carousel>
          <div
            style={{
              position: 'absolute',
              top: 10,
              left: 10,
              borderRadius: 15,
              background: 'white',
              display: 'inline-flex',
              padding: 2
            }}>
            <span className="badge text-dark d-flex">
              <Icon icon="fluent:star-12-filled" height={18} color="#F1AE01" />
              &nbsp;
              <div style={{ fontSize: 17 }}>{data?.averageRating ? data?.averageRating : 4}</div>
            </span>
          </div>
          {/* {data?.averageRating && (
          
          )} */}
          <div className="myCar-deleteIcon">
            <Icon
              icon="ant-design:delete-outlined"
              color="white"
              height={30}
              onClick={() => handleDelete(data?.id)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between p-2">
          <div className="align-items-center ml-3">
            <div className="latestCars-title ">{data?.modelName}</div>
            <div>
              <small className="d-inline text-muted ">
                {data?.transmission.charAt(0).toUpperCase() +
                  data?.transmission.slice(1).toLowerCase()}
              </small>
              &nbsp;&nbsp; <small className="d-inline text-muted">.</small>&nbsp;&nbsp;
              <small className="d-inline text-muted">{data?.carFuelType}</small>&nbsp;&nbsp;
              <small className="d-inline text-muted">.</small>&nbsp;&nbsp;
              <small className="d-inline text-muted">{data?.seatNumber} Seats</small>
            </div>
          </div>
          <SmallButton
            width={100}
            title={'Edit'}
            style={{ background: '#132649', marginTop: '0.7rem' }}
            onClick={() => navigate(`/car-owner/mycars/edit-Car/${data?.id}`)}
          />
        </div>
      </div>
    </>
  );
}
