import Button from 'components/button';
import ColoredButton from 'components/button/colorFullButtons';
import React from 'react';
import Modal from 'react-bootstrap/Modal';

const AleartDialog = ({ ...props }) => {
  const {
    title,
    message,
    showDialog,
    onDialogClose,
    btnConfirm,
    agreeBtnTitle,
    disAgreeBtnTitle,
    isLoading
  } = props;

  return (
    <>
      <Modal show={showDialog} centered onHide={() => (!isLoading ? onDialogClose() : '')}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLongTitle">
              {title}
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{message}</p>

          <div className="row mt-5">
            <div className="col-6">
              <ColoredButton
                title={disAgreeBtnTitle ? disAgreeBtnTitle : 'No'}
                style={{ background: 'transparent', border: '1px solid #ffbb51' }}
                color="#ffbb51"
                onClick={onDialogClose}
                disabled={isLoading ? true : false}
              />
            </div>
            <div className="col-6">
              <Button
                title={agreeBtnTitle ? agreeBtnTitle : 'Yes'}
                onClick={btnConfirm}
                isLoading={isLoading ? true : false}
                disabled={isLoading ? true : false}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AleartDialog;
