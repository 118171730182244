import { Icon } from '@iconify/react';
import React from 'react';
import noImage from '../../../../assets/img/no_image.jpg';
import { useNavigate } from 'react-router-dom';
import ColoredButton from '../../../../components/button/colorFullButtons';
import { addtofavorite } from '../../../../store/slice/carUser/favorite';
import { useDispatch } from 'react-redux';
import '../../../../assets/css/styles.css';
import { loginData } from 'components/constant';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
export default function LatestCardImageBox(props) {
  const {
    data,
    screenType,
    cameFrom,
    bookingHistory,
    message,
    excludeLinks,
    updateFav,
    onClick,
    isFavorite
  } = props;
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    if (!excludeLinks) {
      navigate(`/car-details/${data?.id}`);
    }
  };
  const handleFavorite = (isFavorite) => {
    let request = {
      carId: data?.id,
      language: 'en',
      status: !isFavorite
    };

    dispatch(addtofavorite(request, updateFav));
  };

  return (
    <>
      <div className="latesCars-Box">
        <div
          className="latestCars-imageBox "
          style={{
            borderRadius: 10,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: screenType === 'MOBILE' ? '100%' : '93%'
          }}>
          <Carousel
            controls={false}
            // interval={3000}
            slide={false}
            activeIndex={index}
            onSelect={handleSelect}>
            {data?.carImagesUrl && data?.carImagesUrl?.length > 0 ? (
              data?.carImagesUrl?.map((item, i) => {
                return (
                  <Carousel.Item key={i}>
                    <img
                      className="d-block"
                      onClick={handleClick}
                      src={item}
                      width={'100%'}
                      style={{ objectFit: 'cover', borderRadius: 15 }}
                      height={'169.76px'}
                      alt="First slide"
                    />
                  </Carousel.Item>
                );
              })
            ) : (
              <div className="carousel-item active">
                <img
                  className="d-block"
                  style={{ objectFit: 'cover', borderRadius: 15 }}
                  onClick={handleClick}
                  src={noImage}
                  width={'100%'}
                  height={'169.76px'}
                  alt="First slide"
                />
              </div>
            )}
          </Carousel>
          {/* {data?.averageRating && ( */}
          <div
            style={{
              position: 'absolute',
              top: 10,
              left: 10,
              borderRadius: 15,
              background: 'white',
              display: 'inline-flex',
              padding: 2
            }}>
            <span className="badge text-dark d-flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.3em"
                height="1.3em"
                viewBox="0 0 32 32">
                <path
                  fill="#F1AE01"
                  d="m18.7 4.627l2.247 4.31a2.27 2.27 0 0 0 1.686 1.189l4.746.65c2.538.35 3.522 3.479 1.645 5.219l-3.25 2.999a2.225 2.225 0 0 0-.683 2.04l.793 4.398c.441 2.45-2.108 4.36-4.345 3.24l-4.536-2.25a2.282 2.282 0 0 0-2.006 0l-4.536 2.25c-2.238 1.11-4.786-.79-4.345-3.24l.793-4.399c.14-.75-.12-1.52-.682-2.04l-3.251-2.998c-1.877-1.73-.893-4.87 1.645-5.22l4.746-.65a2.23 2.23 0 0 0 1.686-1.189l2.248-4.309c1.144-2.17 4.264-2.17 5.398 0"></path>
              </svg>
              &nbsp;
              <div style={{ fontSize: 17 }}>{data?.averageRating ? data?.averageRating : 4}</div>
            </span>
          </div>
          {/* )} */}

          {/* Conditionally exclude this block if it's the user profile */}
          {loginData && cameFrom !== 'userProfile' && (
            <>
              <div
                onClick={() => handleFavorite(data?.isFavorite ? data?.isFavorite : isFavorite)}
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  borderRadius: 10,
                  background: 'black',
                  width: 40,
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  padding: 8
                }}>
                <Icon
                  icon={
                    data?.isFavorite || isFavorite
                      ? 'teenyicons:heart-solid'
                      : 'teenyicons:heart-outline'
                  }
                  color="#F1AE01"
                  width={30}
                />
              </div>
            </>
          )}
        </div>
        <div className="pl-2" onClick={handleClick}>
          <div className="latestCars-title pt-2 ">
            {data?.modelName?.charAt(0).toUpperCase() + data?.modelName?.slice(1)}
          </div>
          <small className="d-inline latset-cars-text">
            {data?.transmission?.charAt(0).toUpperCase() +
              data?.transmission?.slice(1).toLowerCase()}
          </small>
          &nbsp;&nbsp; <small className="d-inline latset-cars-text">.</small>&nbsp;&nbsp;
          <small className="d-inline latset-cars-text">
            {/* {data?.carFuelType} */}
            {data?.carFuelType ? data?.carFuelType : 'Diesel'}
          </small>
          &nbsp;&nbsp;
          <small className="d-inline latset-cars-text">.</small>&nbsp;&nbsp;
          <small className="d-inline latset-cars-text">{data?.seatNumber} Seats</small>
          {cameFrom === 'userProfile' ? (
            <></>
          ) : (
            <div className="pt-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="text-warning" style={{ fontSize: 25, color: '#F1AE01' }}>
                N$
                <div className="d-inline" style={{ color: '#132649', fontFamily: 'Circular Std' }}>
                  {data?.withFule
                    ? data?.withFule?.amountPerDay
                    : data?.withoutFule
                      ? data?.withoutFule?.amountPerDay
                      : 0}
                </div>
                <div
                  className="d-inline"
                  style={{ color: '#132649', fontSize: 17, fontWeight: 500 }}>
                  /day
                </div>
              </div>

              {bookingHistory ? (
                <ColoredButton
                  onClick={onClick}
                  title={message ? 'Message' : 'Re-Book?'}
                  style={{ background: '#132649', height: '40px', width: 'auto' }}
                />
              ) : (
                <button
                  onClick={handleClick}
                  // onClick={handleClick}
                  type="button"
                  style={{
                    background: '#132649',
                    color: 'white',
                    height: 41.5,
                    borderRadius: 15,
                    position: 'relative',
                    marginRight: '1rem',
                    paddingLeft: '1rem',
                    paddingRight: '0.8rem',
                    fontFamily: 'Circular Std Medium'
                  }}>
                  {/* {currentBooking ? 'Message' : 'Book'} &nbsp; */}
                  Book &nbsp;
                  <span
                    style={{
                      position: 'absolute',
                      top: 4.5,
                      right: -13,
                      border: '2px solid white',
                      borderRadius: 25,
                      background: '#F1AE01'
                    }}>
                    <span>
                      <Icon icon="charm:chevron-right" color="white" height={'23'} width={'25'} />
                    </span>
                  </span>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
