import React, { useEffect, useState } from 'react';
import Header from '../../../components/header';
import Footer from '../../../components/footer';

import './style.css';
// import { Icon } from '@iconify/react';
import { useResponsive } from '../../../themes/useResponsive';
import { useDispatch, useSelector } from 'react-redux';
import { getTheListOfPolicy } from 'store/slice/landingPage';
export default function CancellationPolicy() {
  const { screenType } = useResponsive();
  // const listItems = [
  //   {
  //     text: 'Purchasing Insurance',
  //     icon: 'icon-park-outline:dot',
  //     color: '#F1AE01',
  //     height: 25
  //   },
  //   {
  //     text: 'Prepaying for Gasoline',
  //     icon: 'icon-park-outline:dot',
  //     color: '#F1AE01',
  //     height: 25
  //   },
  //   {
  //     text: 'Purchasing Insurance, Reason No. 1: Your Own Auto Insurance Covers You',
  //     icon: 'icon-park-outline:dot',
  //     color: '#F1AE01',
  //     height: 25
  //   },
  //   {
  //     text: 'Ignoring One Possible Caveat: “Loss of Use” Insurance',
  //     icon: 'icon-park-outline:dot',
  //     color: '#F1AE01',
  //     height: 25
  //   },
  //   {
  //     text: 'Ignoring Potential Offers for Upgrades',
  //     icon: 'icon-park-outline:dot',
  //     color: '#F1AE01',
  //     height: 25
  //   }
  // ];
  const [scroll, setScroll] = useState(0);
  const dispatch = useDispatch();
  const { getpolicyList } = useSelector((state) => state.landingPage);

  const handleNavigation = (e) => {
    const window = e.currentTarget;

    setScroll(window.scrollY);
  };

  useEffect(() => {
    setScroll(window.scrollY);

    window.addEventListener('scroll', (e) => handleNavigation(e));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    let request = {
      policyType: 2,
      language: 'en'
    };
    dispatch(getTheListOfPolicy(request));
  };
  return (
    <>
      <div>
        <Header scroll={scroll} />

        <div
          className="HeadingCancellation d-flex justify-content-center align-items-center"
          style={{
            fontSize: screenType === 'MOBILE' ? '2rem' : '',
            height: '50%',
            background: 'linear-gradient(to right, #ffe0b0a6, #87cead)'
          }}>
          Cancellation Policy
        </div>

        <div className="container">
          <div>
            {/* <h4 className="paragraphH4">{getpolicyList?.title}</h4> */}
            <div
              className="H4detail mt-5"
              dangerouslySetInnerHTML={{ __html: getpolicyList?.description }}
            />
          </div>
          {/* <div className="row mx-0">
            <div className="col-md-12 col-sm-12">
              <h4 className="paragraphH4">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              </h4>
              <div className="shadow-sm p-3 mb-5 rounded">
                <ul
                  className="listingg"
                  style={{
                    color: '#132649',
                    fontFamily: 'Circular Std Book',
                    padding: '1px',
                    marginLeft: '-0.3rem',
                    lineHeight: 2,
                    listStyleType: 'none'
                  }}>
                  {listItems?.map((item, index) => {
                    return (
                      <li key={index}>
                        <Icon icon={'icon-park-outline:dot'} color="#F1AE01" height={25} />
                        <span style={{ marginLeft: '0.5rem' }}>{item?.text}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-5 mb-5 H4detail">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry standard dummy text ever since the 1500s when an unknown printer
            took a galley of type and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting, remaining
            essentially unchanged. It was popularised in the 1960s with the release of Letraset
            sheets containing Lorem Ipsum.It has survived not only five centuries, but also the leap
            into electronic typesetting, remaining essentially unchanged. It was popularised in the
            1960s with the release of Letraset sheets containing Lorem Ipsum.It has survived not
            only five centuries, but also the leap into electronic typesetting, remaining
            essentially unchanged. It was popularised in the 1960s with the release of Letraset
            sheets containing Lorem Ipsum.
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

// export default function CancellationPolicy() {
//   const { screenType } = useResponsive();
//   const listItems = [
//     {
//       text: 'Purchasing Insurance',
//       icon: 'icon-park-outline:dot',
//       color: '#F1AE01',
//       height: 25
//     },
//     {
//       text: 'Prepaying for Gasoline',
//       icon: 'icon-park-outline:dot',
//       color: '#F1AE01',
//       height: 25
//     },
//     {
//       text: 'Purchasing Insurance, Reason No. 1: Your Own Auto Insurance Covers You',
//       icon: 'icon-park-outline:dot',
//       color: '#F1AE01',
//       height: 25
//     },
//     {
//       text: 'Ignoring One Possible Caveat: “Loss of Use” Insurance',
//       icon: 'icon-park-outline:dot',
//       color: '#F1AE01',
//       height: 25
//     },
//     {
//       text: 'Ignoring Potential Offers for Upgrades',
//       icon: 'icon-park-outline:dot',
//       color: '#F1AE01',
//       height: 25
//     }
//   ];
//   const [scroll, setScroll] = useState(0);
//   const handleNavigation = (e) => {
//     const window = e.currentTarget;

//     setScroll(window.scrollY);
//   };

//   useEffect(() => {
//     setScroll(window.scrollY);

//     window.addEventListener('scroll', (e) => handleNavigation(e));
//   }, []);
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   return (
//     <>
//       <Header scroll={scroll} />
//       <div
//         className="HeadingCancellation d-flex justify-content-center align-items-center"
//         style={{
//           fontSize: screenType === 'MOBILE' ? '2rem' : '',
//           height: '50%',
//           background: 'linear-gradient(to right, #ffe0b0a6, #87cead)'
//         }}>
//         Cancellation Policy
//       </div>

//       <div className="container">
//         <div>
//           <h4 className="paragraphH4">
//             Lorem Ipsum is simply dummy text of the printing and typesetting industry.
//           </h4>
//           <div className="H4detail">
//             Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
//             has been the industry standard dummy text ever since the 1500s when an unknown printer
//             took a galley of type and scrambled it to make a type specimen book. It has survived not
//             only five centuries, but also the leap into electronic typesetting, remaining
//             essentially unchanged. It was popularised in the 1960s with the release of Letraset
//             sheets containing Lorem Ipsum.
//           </div>
//         </div>
//         <div className="row mx-0">
//           <div className="col-md-12 col-sm-12">
//             <h4 className="paragraphH4">
//               Lorem Ipsum is simply dummy text of the printing and typesetting industry.
//             </h4>
//             <div className="shadow-sm p-3 mb-5 rounded">
//               <ul
//                 className="listingg"
//                 style={{
//                   color: '#132649',
//                   fontFamily: 'Circular Std Book',
//                   padding: '1px',
//                   marginLeft: '-0.3rem',
//                   lineHeight: 2,
//                   listStyleType: 'none'
//                 }}>
//                 {listItems?.map((item, index) => {
//                   return (
//                     <li key={index}>
//                       <Icon icon={'icon-park-outline:dot'} color="#F1AE01" height={25} />
//                       <span style={{ marginLeft: '0.5rem' }}>{item?.text}</span>
//                     </li>
//                   );
//                 })}
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="mt-5 mb-5 H4detail">
//           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
//           been the industry standard dummy text ever since the 1500s when an unknown printer took a
//           galley of type and scrambled it to make a type specimen book. It has survived not only
//           five centuries, but also the leap into electronic typesetting, remaining essentially
//           unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
//           Lorem Ipsum.It has survived not only five centuries, but also the leap into electronic
//           typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
//           release of Letraset sheets containing Lorem Ipsum.It has survived not only five centuries,
//           but also the leap into electronic typesetting, remaining essentially unchanged. It was
//           popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum.
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// }
