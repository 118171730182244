import React, { useEffect } from 'react';
import moment from 'moment';
import { useResponsive } from '../../../../../themes/useResponsive';
import OutlineButton from '../../../../../components/button/outlineButton';
import defaultImage from '../../../../../assets/img/noProfile.jpg';
import noDataImage from 'assets/img/no_image.jpg';
export default function DesktopViewDataCard({
  data,
  onClick,
  showCommision,
  btnTitle,
  btnColor,
  isNumber,
  noMargin
}) {
  const { screenType } = useResponsive();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className="row p-2 bg-white d-flex justify-content-center rounded-3 px-0 my-2"
        onClick={onClick}>
        <div className="col-3 pt-1" style={{ marginLeft: noMargin ? '' : '-2rem' }}>
          {isNumber ? (
            <div className="checklist d-flex form-check font-size-15 rounded-5">
              <img
                src={data?.userData?.profileImage ? data?.userData?.profileImage : defaultImage}
                alt=""
                className="rounded-circle avatar-sm"
              />
              &nbsp;
              <div>
                <label
                  className="ms-1 task-title pt-1"
                  style={{
                    fontFamily: 'Circular Std Bold',
                    fontSize: screenType === 'small' ? '16px' : '18px',
                    color: '#132649'
                  }}>
                  {data?.userData?.userName}
                </label>
                <div
                  style={{
                    color: '#5c6064',
                    fontFamily: 'Circular Std Book',
                    fontSize: screenType === 'MOBILE' ? '12px' : '14px',
                    marginTop: '-0.5rem'
                  }}>
                  +{data?.userData?.countryCode} {data?.userData?.phoneNumber}
                </div>
              </div>
            </div>
          ) : (
            <div className="checklist form-check font-size-15 rounded-5">
              <img
                src={data?.userData?.profileImage ? data?.userData?.profileImage : defaultImage}
                alt=""
                className="rounded-circle avatar-sm"
              />
              <label
                className="ms-1 task-title"
                style={{
                  fontFamily: 'Circular Std Bold',
                  fontSize: screenType === 'small' ? '16px' : '18px',
                  color: '#132649'
                }}>
                {data?.userData?.userName}
              </label>
            </div>
          )}
        </div>
        <div className="col-4" style={{ marginLeft: '-1rem' }}>
          <div
            className="checklist form-check font-size-15 rounded-4  w-100 align-items-center"
            style={{ background: '#F6F6F8' }}>
            <img
              src={
                data?.productData?.carImagesUrl?.length >= 1
                  ? data?.productData?.carImagesUrl[0]
                  : noDataImage
              }
              alt=""
              className="rounded-circle avatar-sm"
              style={{ marginLeft: '-1rem' }}
            />
            <label
              className="ms-1 task-title pt-3"
              style={{
                fontFamily: 'Circular Std Bold',
                fontSize: screenType === 'MOBILE' ? '16px' : '18px',
                color: '#132649'
              }}>
              {data?.productData?.modelName}
            </label>
            &nbsp;
            {btnTitle && (
              <OutlineButton
                style={{ float: 'right', marginTop: '0.7rem' }}
                title={btnTitle}
                color={btnColor}
              />
            )}
          </div>
        </div>
        <div className="col d-flex align-items-center">
          <div
            style={{
              color: '#5c6064',
              fontFamily: 'Circular Std Book',
              fontSize: screenType === 'MOBILE' ? '12px' : '15px'
            }}>
            {moment(data?.bookingDate?.from).format('Do MMM, YYYY')}
          </div>
        </div>
        <div className="col d-flex align-items-center">
          <div
            style={{
              color: '#5c6064',
              fontFamily: 'Circular Std Book',
              fontSize: screenType === 'MOBILE' ? '12px' : '15px'
            }}>
            ID : {data?.orderId}
          </div>
        </div>
        <div
          className={`col ${showCommision ? '' : 'd-flex'} align-items-center pt-2`}
          style={{ marginRight: '-0.5rem' }}>
          <div className="d-flex">
            <h4
              style={{
                color: '#132649',
                fontFamily: 'Circular Std Medium',
                fontWeight: 300,
                fontSize: screenType === 'small' ? '14px' : '16px'
              }}>
              <span
                style={{
                  color: '#FFBB51',
                  fontFamily: 'Circular Std',
                  fontWeight: 100
                }}>
                N$
              </span>
              <span style={{ fontWeight: 600, fontSize: '18px' }}> {data?.totalAmount}</span>&nbsp;
              <span
                style={{
                  color: '#5c6064',
                  fontSize: '15px',
                  fontFamily: 'Circular Std Medium'
                }}>
                {data?.bookingType === 'perDay'
                  ? 'Per Day'
                  : data?.bookingType === 'perHour'
                    ? 'Per Hour'
                    : 'Per Week'}
              </span>
            </h4>
          </div>
          {showCommision && (
            <div
              style={{
                color: '#5c6064',
                fontSize: '14px',
                fontWeight: 400,
                fontFamily: 'Circular Std Book Italic'
              }}>
              Incl.20% commission {`(N$${(data?.totalAmount * 20) / 100})`}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
