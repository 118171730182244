import React from 'react';
import './index.css';
export default function SelectInput(props) {
  const { options, onChange, value, Placeholder, formError, label } = props;
  return (
    <>
      <select
        onChange={onChange}
        value={value}
        placeholder={label}
        className="custom-select form-select form-select-lg mb-3"
        aria-label=".form-select-lg example">
        {Placeholder && <option value={''}>{Placeholder}</option>}
        {options}
      </select>
      <div
        style={{
          color: 'red',
          fontSize: 13,
          marginTop: '-0.5rem',
          paddingLeft: 5
        }}
        className="text-error">
        {formError && formError}
      </div>
    </>
  );
}
