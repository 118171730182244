import React, { useContext, useEffect, useState } from 'react';
import '../style.css';
import TotalFairDetail from './totalFairDetail';
import MyCarContext from 'components/context/myCarContext';
import Input from 'components/input';
import SelectInput from 'components/select';
import ColoredButton from 'components/button/colorFullButtons';
import { useDispatch } from 'react-redux';
import {
  amountCalculate,
  bookingCar,
  checkCarAvailByCarId,
  checkCouponDetails
} from 'store/slice/carUser/carBooking';
import moment from 'moment';
import {
  getDaysOrWeeksAhead,
  getTimeAhead,
  getUTCTimestamp,
  makeAnArray,
  todayDate
} from 'utils/appConstant';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export default function Booking() {
  const { carDetails, formData, setFormData, calculatedAmount } = useContext(MyCarContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [seats, setSeats] = useState([]);
  const [endDateTime, setEndDateTime] = useState();
  const [couponCode, setCouponCode] = useState();
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [isCarAvalible, setIsCarAvalible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (carDetails) {
      var count =
        formData?.bookingType == 'perHour'
          ? 23
          : formData?.bookingType == 'perDay'
            ? 6
            : formData?.bookingType == 'perWeek'
              ? 5
              : 0;
      setSeats(makeAnArray(count));
    }
  }, [carDetails]);

  useEffect(() => {
    if (formData && formData?.startDate && formData?.startTime && formData.numberOfTime) {
      let aheadDateTime = '';
      if (formData?.bookingType == 'perHour') {
        aheadDateTime = getTimeAhead(
          new Date(`${formData?.startDate} ${formData?.startTime}`),
          Number(formData.numberOfTime)
        );
      } else if (formData?.bookingType == 'perDay') {
        aheadDateTime = getDaysOrWeeksAhead(
          new Date(`${formData?.startDate} ${formData?.startTime}`),
          Number(formData.numberOfTime),
          'day'
        );
      } else {
        aheadDateTime = getDaysOrWeeksAhead(
          new Date(`${formData?.startDate} ${formData?.startTime}`),
          Number(formData.numberOfTime),
          'week'
        );
      }
      setEndDateTime((endDateTime) => ({
        ...endDateTime,
        endDate: moment(aheadDateTime).format('YYYY-MM-DD'),
        endTime: moment(aheadDateTime).format('HH:mm')
      }));
    }
  }, [formData]);

  useEffect(() => {
    if (endDateTime?.endDate && endDateTime?.endTime) {
      checkCarAvail();
    }
  }, [endDateTime]);
  useEffect(() => {
    if (isCarAvalible) {
      handleCalculate();
    }
  }, [formData]);

  const checkCarAvail = () => {
    const data = {
      language: 'en',
      carId: carDetails?.id,
      fromBookingDate: getUTCTimestamp(formData?.startTime, formData?.startDate),
      toBookingDate: getUTCTimestamp(endDateTime?.endTime, endDateTime?.endDate)
    };
    dispatch(checkCarAvailByCarId(data, setIsCarAvalible));
  };

  const checkCouponValid = () => {
    setFormData((formData) => ({
      ...formData,
      couponCode: couponCode
    }));
    const data = {
      language: 'en',
      couponCode: couponCode
    };
    couponCode ? dispatch(checkCouponDetails(data, setIsCouponValid, setFormData)) : '';
  };

  const handleCalculate = () => {
    var amount = 0;
    if (formData?.fuelType == 'withfuel') {
      amount =
        formData?.bookingType == 'perHour'
          ? carDetails?.withFule?.amountPerHour
          : formData?.bookingType == 'perDay'
            ? carDetails?.withFule?.amountPerDay
            : carDetails?.withFule?.amountPerWeek;
    } else {
      amount =
        formData?.bookingType == 'perHour'
          ? carDetails?.withoutFule?.amountPerHour
          : formData?.bookingType == 'perDay'
            ? carDetails?.withoutFule?.amountPerDay
            : carDetails?.withoutFule?.amountPerWeek;
    }
    const data = {
      bookingType: formData?.bookingType,
      amount: amount,
      numberOfTime: formData?.numberOfTime,
      couponId: isCouponValid ? couponCode : '',
      refundableAmount: carDetails?.refundableAmount ? carDetails?.refundableAmount : 2000,
      deliveryCharges:
        formData?.deliveryOrPickup === 'Delivery' ? carDetails?.deliveryPickupCharge : 0
    };
    dispatch(amountCalculate(data));
  };

  const handleSubmit = () => {
    if (!formData?.startDate) {
      return toast.error('Please select start date');
    }
    if (!formData?.startTime) {
      return toast.error('Please select start time');
    }
    if (!formData?.numberOfTime) {
      return toast.error(
        `Please select ${
          formData?.bookingType == 'perHour'
            ? 'hours'
            : formData?.bookingType == 'perDay'
              ? 'days'
              : formData?.bookingType == 'perWeek'
                ? 'weeks'
                : ''
        }`
      );
    }
    if (!formData?.deliveryOrPickup) {
      return toast.error('Please select delivery or pickup');
    }
    if (!isCarAvalible) {
      return toast.error('Sorry car is not available for selected time');
    }

    const data = {
      language: 'en',
      carId: carDetails?.id,
      startDateTime: getUTCTimestamp(formData?.startTime, formData?.startDate),
      endDateTime: getUTCTimestamp(endDateTime?.endTime, endDateTime?.endDate),
      bookingType: formData?.bookingType,
      totalAmount: calculatedAmount?.finalAmount,
      discountAmount: calculatedAmount?.discountAmount,
      couponId: formData.couponCode ? formData.couponCode : '',
      location: {
        address: '',
        coordinate: []
      },
      deliveryOrPickup: {
        type: formData?.deliveryOrPickup,
        charges: carDetails?.deliveryPickupCharge ? carDetails?.deliveryPickupCharge : 0
      },
      fuelType: {
        type: formData?.fuelType,
        charges: calculatedAmount?.amount
      }
    };
    setIsLoading(true);
    dispatch(bookingCar(data, navigate, setIsLoading));
  };
  return (
    <>
      <TotalFairDetail />
      <div className="row mt-4">
        <div className="col-md-4">
          <div className="myCar-label">Start Date</div>
          <Input
            type="date"
            minVal={todayDate()}
            placeholderText="Start date"
            value={formData?.startDate}
            onChange={(date) => {
              console.log('date', date);
              setFormData((formData) => ({
                ...formData,
                startDate: date
              }));
            }}
          />
        </div>
        <div className="col-md-4">
          {' '}
          <div className="myCar-label">Start Time</div>
          <Input
            type="time"
            placeholderText="Pickup date"
            value={formData?.startTime}
            onChange={(time) =>
              setFormData((formData) => ({
                ...formData,
                startTime: time
              }))
            }
          />
        </div>
        <div className="col-md-4">
          {' '}
          <div className="myCar-label">
            Select{' '}
            {formData?.bookingType == 'perHour'
              ? 'Hours'
              : formData?.bookingType == 'perDay'
                ? 'Days'
                : formData?.bookingType == 'perWeek'
                  ? 'Weeks'
                  : ''}
          </div>
          <SelectInput
            onChange={(e) => {
              setFormData((formData) => ({
                ...formData,
                numberOfTime: e.target.value
              }));
            }}
            Placeholder={` Select
            ${
              formData?.bookingType == 'perHour'
                ? 'Hours'
                : formData?.bookingType == 'perDay'
                  ? 'Days'
                  : formData?.bookingType == 'perWeek'
                    ? 'Weeks'
                    : ''
            }`}
            value={formData?.numberOfTime ? formData?.numberOfTime : ''}
            options={seats?.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item?.label}
                </option>
              );
            })}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-4">
          {' '}
          <div className="myCar-label">End Date</div>
          <Input
            type="date"
            placeholderText="End date"
            disabled={true}
            value={endDateTime?.endDate}
          />
        </div>
        <div className="col-md-4">
          {' '}
          <div className="myCar-label">End Time</div>
          <Input
            type="time"
            disabled={true}
            placeholderText="Pickup date"
            value={endDateTime?.endTime}
          />
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4  mt-3">
          <div className="row">
            {' '}
            <div className="myCar-label">Pickup or Delivery</div>
            <div className="col-6">
              <ColoredButton
                color={formData?.deliveryOrPickup === 'Pickup' ? 'white' : '#8993A4'}
                style={{
                  background: formData?.deliveryOrPickup === 'Pickup' ? '#6CD2C2' : 'transparent',
                  border: '1px solid #8993A4'
                }}
                title="Pickup"
                onClick={() => {
                  setFormData((formData) => ({
                    ...formData,
                    deliveryOrPickup: 'Pickup'
                  }));
                }}
              />
            </div>
            <div className="col-6">
              <ColoredButton
                color={formData?.deliveryOrPickup === 'Delivery' ? 'white' : '#8993A4'}
                style={{
                  background: formData?.deliveryOrPickup === 'Delivery' ? '#6CD2C2' : 'transparent',
                  border: '1px solid #8993A4'
                }}
                title="Delivery"
                onClick={() => {
                  setFormData((formData) => ({
                    ...formData,
                    deliveryOrPickup: 'Delivery'
                  }));
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4  mt-3">
          {' '}
          <div className="myCar-label">Coupon Code</div>
          <Input
            placeholderText="Pickup date"
            value={couponCode}
            onBlur={checkCouponValid}
            onChange={(couponCode) => {
              setCouponCode(couponCode);
              setFormData((formData) => ({
                ...formData,
                couponError: ''
              }));
            }}
            formError={formData.couponError}
          />
        </div>
        <div className="col-md-4"></div>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{
          backgroundColor: '#132649',
          cursor: 'pointer',
          height: '80px',
          width: '100%',
          marginTop: '15rem'
        }}
        onClick={() => {
          !isLoading ? handleSubmit() : '';
        }}>
        <div
          className="mt-4"
          style={{
            fontFamily: 'Circular Std Black',
            color: '#F1AE01'
          }}>
          {isLoading ? 'Booking...' : 'Book Now'}
        </div>
      </div>
    </>
  );
}
