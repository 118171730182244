import React, { useEffect, useState } from 'react';
import ColoredButton from '../../components/button/colorFullButtons';
import toast from 'react-hot-toast';
import { addHelpByUser } from '../../store/slice/carUser/help';
import { useDispatch } from 'react-redux';
// import image from "../../assets/img/RentedLogoBG.png";
const ContactUs = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      language: 'en'
    }));
  }, []);

  const getHelp = () => {
    dispatch(addHelpByUser(formData));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    const validEmail = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.fullName) {
      toast.error('Please Enter your Name');
    } else if (formData.fullName.length > 20) {
      toast.error('Name cannot exceed 20 characters');
    }
    if (!formData.email) {
      toast.error('Please enter your email address');
      return;
    } else if (!validEmail.test(formData.email)) {
      toast.error('Please enter a valid email address');
      return;
    }
    getHelp();
    toast.success('Message send successfully');
    setFormData({
      fullName: '',
      email: '',
      message: '',
      language: ''
    });
  };

  return (
    <div className="shadow p-3 mb-5 bg-white rounded mx-auto mt-5 col-md-6 col-sm-10">
      <h1 className="formHeading text-center">Hey! Have any queries or suggestions?</h1>
      <div className="mx-auto mt-5" style={{ maxWidth: '80%' }}>
        <div>
          <div className="mb-3">
            <input
              style={{ paddingTop: '0.9rem' }}
              onChange={handleChange}
              name="fullName"
              className="form-control"
              placeholder="Full Name"
              value={formData.fullName}
              aria-label="Username"
              aria-describedby="basic-addon1"
              required
            />
          </div>
          <div className="mb-3">
            <input
              style={{ paddingTop: '0.9rem' }}
              onChange={handleChange}
              name="email"
              className="form-control"
              placeholder="Email Address"
              value={formData.email}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="mb-3">
            <textarea
              onChange={handleChange}
              name="message"
              className="form-control pt-3 pl-4"
              placeholder="Write Your Messages..."
              value={formData.message}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div
            className="text-center"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ColoredButton
              onClick={handleSubmit}
              title="send"
              style={{
                background: '#F1AE01',
                height: '45px',
                width: '212.31px',
                alignItem: 'center'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
