import { createSlice } from '@reduxjs/toolkit';
import api from '../../../services';
import toast from 'react-hot-toast';
import { RENTEDWHEELSAPI } from '../../../services/apiConstant';
import { loginData } from 'components/constant';
// import { act } from 'react-dom/test-utils';

const slice = createSlice({
  name: 'carOwnerMessage',
  initialState: {
    messageListData: [],
    createChatRoomId: null,
    chatOneToOneData: [],
    messageData: [],
    chatUserData: null,
    totalMessageCount: 0,
    chatUserDataAfterRedirect: null
    // isRecive: false
  },
  reducers: {
    getCarOwnerMessageListSuccess: (state, action) => {
      state.messageListData = action.payload;
    },
    getChatOneTwoOneSucess: (state, action) => {
      if (state.messageData.length === 0) {
        state.messageData = state.messageData.concat(action.payload.messageData);
      } else {
        state.messageData = [...action.payload.messageData, ...state.messageData];
      }
      state.totalMessageCount = action.payload.totalMessageCount;
    },
    receiveMessageDataSucess: (state, action) => {
      if (state.chatUserData?.chatRoomId === action.payload.chatRoomId) {
        state.messageData = state.messageData.concat(action.payload);
      }
      const index = state.messageListData.findIndex(
        (x) => x.chatRoomId === action.payload.chatRoomId
      );
      const data = state.messageListData[index];
      if (data) {
        data.lastText = action.payload.chatMessage;
        data.messageType = action.payload.messageType;
        data.unreadCount =
          state.chatUserData?.chatRoomId !== action.payload.chatRoomId && data.unreadCount
            ? data.unreadCount + 1
            : state.chatUserData?.chatRoomId !== action.payload.chatRoomId
              ? 1
              : 0;
        state.messageListData[index] = data;
      }

      // Remove data from index and place it at 0 index
      let removedElement = state.messageListData.splice(index, 1)[0];
      state.messageListData.unshift(removedElement);
    },
    receiveReadMessageSuccess: (state, action) => {
      // Extract the payload from the action
      const { messageId, newUnreadCount } = action.payload;

      // Map through messageListData and update unreadCount for the matching messageId
      const updatedMessageListData = state.messageListData.map((message) => {
        // Check if the message ID matches the ID of the message being updated
        if (message?.id === messageId) {
          // Update the unreadCount property for this message
          return {
            ...message,
            unreadCount: newUnreadCount
          };
        } else {
          // Return the message as is if it doesn't match the ID being updated
          return message;
        }
      });

      // Return the updated state with the modified messageListData
      return {
        ...state,
        messageListData: updatedMessageListData
      };
    },
    chatUserDataSucess: (state, action) => {
      state.chatUserData = action.payload;
    },
    clearMessageDataSucess: (state) => {
      state.messageData = [];
    },
    createPersonalChatRoomIdSucess: (state, action) => {
      state.chatUserDataAfterRedirect = action.payload;
    },
    clearChatUserDataAfterRedirectSucess: (state) => {
      state.chatUserDataAfterRedirect = null;
    }
  }
});
export default slice.reducer;
/**********************ACTIONS************************************************ */

const {
  getCarOwnerMessageListSuccess,
  getChatOneTwoOneSucess,
  receiveMessageDataSucess,
  chatUserDataSucess,
  clearMessageDataSucess,
  createPersonalChatRoomIdSucess,
  clearChatUserDataAfterRedirectSucess,
  receiveReadMessageSuccess
} = slice.actions;

export const getCarOwnerMessageList =
  (request, setIsloading, setIsListloading) => async (dispatch) => {
    api
      .post(`${RENTEDWHEELSAPI.carOwner.getAllUserChattingList}`, request)
      .then((response) => {
        let result = response.data;
        if (result.status == 1) {
          dispatch(getCarOwnerMessageListSuccess(result.data));
          setIsloading(false);
        } else {
          toast.error(result.message);
          setIsloading(false);
        }
        setIsListloading(false);
      })
      .catch(() => {});
  };

export const createChatRoom = (data, navigate) => async () => {
  api.post(`${RENTEDWHEELSAPI.chat.createChatRoodId}`, data).then((response) => {
    let result = response.data;
    if (result.status === 1) {
      navigate(`/carOwner/messages/${result?.chatRoomId}`);
    }
  });
};
export const getChattingOneTwoOne = (data, setIsLoading) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.chat.chatOneTwoOne}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        setIsLoading(false);
        dispatch(
          getChatOneTwoOneSucess({ messageData: result.data, totalMessageCount: result.totalCount })
        );
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const receiveMessageData = (data) => async (dispatch) => {
  dispatch(receiveMessageDataSucess(data.finalData));
};
export const receiveReadMessage = (data) => async (dispatch) => {
  dispatch(receiveReadMessageSuccess(data));
};

export const setChatUserData = (data) => async (dispatch) => {
  dispatch(clearMessageDataSucess());
  dispatch(chatUserDataSucess(data));
};

// export const clearMessageData = () => async (dispatch) => {
//   dispatch(clearMessageDataSucess());
// };

export const clearChatUserDataAfterRedirect = () => async (dispatch) => {
  dispatch(clearChatUserDataAfterRedirectSucess());
};

export const createPersonalChatRoomId = (data, navigate) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.chat.createPersonalChatRoomId}`, data)
    .then((response) => {
      let result = response.data;
      console.log('result', result?.userData);
      if (result.status == 1) {
        dispatch(
          createPersonalChatRoomIdSucess(
            result?.userData ? result?.userData : result?.data?.chatRoomId
          )
        );
        navigate(loginData && loginData?.userType == 3 ? '/messages' : '/car-owner/messages');
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
