import React, { useEffect, useState } from 'react';
import Header from '../../../components/header';
import { Link, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useResponsive } from '../../../themes/useResponsive';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { createPayment, getbookingDetails } from 'store/slice/carUser/carBooking';
import moment from 'moment';
import { formatCreditCardNumber, formatExpirationDate } from 'utils/appConstant';
import Success from '../../owner/payment/Success';
import gif from 'assets/img/Search.gif';
import Carousel from 'react-bootstrap/Carousel';
import noImage from 'assets/img/No_image_available.png';
import { Spinner } from 'react-bootstrap';

const CarPayment = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { screenType } = useResponsive();
  const { bookingDetailsData } = useSelector((state) => state.booking);
  const [formData, setFormData] = useState();
  const [isSuccessPayment, setIsSuccessPayment] = useState(false);
  const [successPaymentData, setSuccessPaymentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        setFormData((formData) => ({
          ...formData,
          ipAddress: data.ip
        }));
      })
      .catch((error) => console.log(error));
    setFormData((formData) => ({
      ...formData,
      browserHeaders: navigator.userAgent
    }));
  }, []);

  useEffect(() => {
    if (id) {
      bookingDetails();
    }
  }, [id]);

  const bookingDetails = () => {
    const data = {
      language: 'en',
      bookingId: id
    };
    dispatch(getbookingDetails(data));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = () => {
    const date = formData?.expiry?.split('/');
    const cardNumber = formData?.number?.replace(/\s/g, '');

    if (!formData?.name) {
      setFormData((formData) => ({
        ...formData,
        nameErr: 'Please enter name '
      }));
      return;
    }
    if (!cardNumber) {
      setFormData((formData) => ({
        ...formData,
        cardErr: 'Please enter card number'
      }));
      return;
    }
    if (cardNumber?.length < 16) {
      setFormData((formData) => ({
        ...formData,
        cardErr: 'Please enter valid card number'
      }));
      return;
    }
    if (date?.length != 2) {
      setFormData((formData) => ({
        ...formData,
        expErr: 'Please enter expiry date'
      }));
      return;
    }
    setIsLoading(true);
    const data = {
      language: 'en',
      carId: bookingDetailsData?.productData?.id,
      bookingId: bookingDetailsData?.id,
      cardHolderFullName: formData?.name,
      cardNumber: Number(cardNumber),
      expiryMonth: Number(date[0]),
      expiryYear: Number(date[1]),
      amount: bookingDetailsData?.totalAmount,
      ipAddress: formData?.ipAddress,
      browserHeaders: formData?.browserHeaders,
      description: 'Card Transaction'
    };

    dispatch(createPayment(data, setIsSuccessPayment, setIsLoading, setSuccessPaymentData));
  };

  const handleInputChange = ({ target }) => {
    setFormData((formData) => ({
      ...formData,
      nameErr: '',
      cardErr: '',
      expErr: ''
    }));
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    }
    setFormData((formData) => ({
      ...formData,
      [target.name]: target.value
    }));
  };
  return (
    <>
      <div className="w-100" style={{ background: '#E5E5E5', minHeight: '100vh' }}>
        <Header background color />
        {bookingDetailsData ? (
          <div className="container bg-white  p-4" style={{ marginTop: '4.5rem' }}>
            {bookingDetailsData?.paymentStatus === 1 ? (
              !isSuccessPayment ? (
                <>
                  <div
                    style={{
                      fontFamily: 'Circular Std Medium',
                      cursor: 'pointer'
                    }}>
                    <Link to={'/'} style={{ color: '#63575778', textDecoration: 'none' }}>
                      Home
                    </Link>
                    &nbsp;/&nbsp;<span>Payment</span>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <div className="position-relative">
                        <Carousel
                          controls={false}
                          slide={false}
                          activeIndex={index}
                          onSelect={handleSelect}>
                          {bookingDetailsData?.productData?.carImagesUrl?.length != 0 ? (
                            bookingDetailsData?.productData?.carImagesUrl?.map((item, i) => {
                              return (
                                <Carousel.Item key={i}>
                                  <img
                                    className="d-block"
                                    src={item}
                                    width={'100%'}
                                    height={'250px'}
                                    style={{ borderRadius: 15, objectFit: 'cover' }}
                                    alt="First slide"
                                  />
                                </Carousel.Item>
                              );
                            })
                          ) : (
                            <div className="carousel-item active">
                              <img
                                className="d-block"
                                src={noImage}
                                width={'100%'}
                                height={'250px'}
                                style={{ borderRadius: 15, objectFit: 'cover' }}
                                alt="First slide"
                              />
                            </div>
                          )}
                        </Carousel>
                        {bookingDetailsData?.productData?.averageRating >= 1 && (
                          <div className="position-absolute" style={{ top: 0, left: 5 }}>
                            <span
                              className="badge text-dark d-flex mt-2 px-3 "
                              style={{
                                background: 'WHITE',
                                height: 30,
                                borderRadius: 15,
                                paddingTop: '0.3rem'
                              }}>
                              <Icon icon="fluent:star-12-filled" height={18} color="#F1AE01" />
                              &nbsp;&nbsp;
                              <div
                                style={{
                                  fontSize: 15,
                                  fontFamily: 'Circular Std Medium',
                                  paddingTop: '0.2rem'
                                }}>
                                {bookingDetailsData?.productData?.averageRating
                                  ? bookingDetailsData?.productData?.averageRating
                                  : 0}
                              </div>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <div>
                        <div className="mt-2">
                          <h3 style={{ fontFamily: 'Circular Std Black', color: '#132649' }}>
                            {bookingDetailsData?.productData?.modelName}
                          </h3>
                          {screenType === 'MOBILE' ? (
                            <div
                              style={{
                                fontFamily: 'Circular Std Book',

                                fontSize: 20,
                                marginTop: '-8px'
                              }}>
                              <small className="d-inline d-md-inline">
                                {moment(bookingDetailsData?.bookingDate.from).format(
                                  'ddd DD MMM YYYY'
                                )}{' '}
                                - For{' '}
                                {bookingDetailsData?.bookingType == 'perHour'
                                  ? 'Hour'
                                  : bookingDetailsData?.bookingType == 'perDay'
                                    ? 'Day'
                                    : bookingDetailsData?.bookingType == 'perWeek'
                                      ? 'Week'
                                      : ''}
                              </small>
                            </div>
                          ) : (
                            <div
                              style={{
                                fontFamily: 'Circular Std Book',

                                fontSize: 20,
                                marginTop: '-8px'
                              }}>
                              <small className="d-block d-md-inline">
                                {moment(bookingDetailsData?.bookingDate.from).format(
                                  'ddd DD MMM YYYY'
                                )}{' '}
                                - For{' '}
                                {bookingDetailsData?.bookingType == 'perHour'
                                  ? 'Hour'
                                  : bookingDetailsData?.bookingType == 'perDay'
                                    ? 'Day'
                                    : bookingDetailsData?.bookingType == 'perWeek'
                                      ? 'Week'
                                      : ''}
                              </small>
                            </div>
                          )}
                          <hr />
                          <div className="row pl-2">
                            <div className="col-6">
                              <p className="Left-list">Trip Fare</p>
                              <p className="Left-list">
                                Refundable Deposit
                                <br />
                                <small className="small d-inline d-md-inline">
                                  Collect deposit from owner after completed booking
                                </small>
                              </p>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                              <div className="text-end">
                                <p className="Right-list">
                                  N$
                                  {bookingDetailsData?.totalAmount
                                    ? bookingDetailsData?.totalAmount
                                    : 0}
                                </p>

                                <p className="Right-list">
                                  <span className="plusSign">+ </span>N$
                                  {bookingDetailsData?.refundableAmount
                                    ? bookingDetailsData?.refundableAmount
                                    : 2000}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <hr />
                  <div className="row ">
                    <div className="col-12">
                      <div
                        style={{ color: '#132649', fontSize: 45, fontFamily: 'Circular Std Book' }}>
                        {bookingDetailsData?.totalAmount ? bookingDetailsData?.totalAmount : 0}
                        <div
                          className="d-inline"
                          style={{
                            color: '#FFBB51',
                            fontFamily: 'Circular Std Book',
                            fontSize: 17,
                            fontWeight: 500
                          }}>
                          N$
                          <div className="small" style={{ fontSize: '20px' }}>
                            Payable amount
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="rounded-4 bg-light p-3 pt-4 pb-5">
                          <h4 style={{ fontFamily: 'Circular Std Black', color: '#132649' }}>
                            Card Details
                          </h4>
                          <div className="justify-content-center pt-2">
                            <input
                              type="text"
                              name="name"
                              className="form-control text-muted rounded-3 bg-white"
                              placeholder="Enter Card Name"
                              onChange={handleInputChange}
                            />
                          </div>
                          <div
                            style={{
                              color: 'red',
                              fontSize: 14,
                              marginTop: '0.5rem',
                              paddingLeft: 5
                            }}
                            className="text-error">
                            {formData?.nameErr}
                          </div>
                          <div className="justify-content-center mt-3">
                            <input
                              type="tel"
                              name="number"
                              className="form-control text-muted rounded-3 bg-white"
                              placeholder="Card Number"
                              maxLength="19"
                              onChange={handleInputChange}
                            />
                          </div>
                          <div
                            style={{
                              color: 'red',
                              fontSize: 14,
                              marginTop: '0.5rem',
                              paddingLeft: 5
                            }}
                            className="text-error">
                            {formData?.cardErr}
                          </div>
                          <div className="justify-content-center mt-3">
                            <input
                              type="tel"
                              name="expiry"
                              className="form-control text-muted rounded-3 bg-white mr-2"
                              style={{ display: 'inline-block' }}
                              placeholder="Exp Date"
                              onChange={handleInputChange}
                            />
                          </div>
                          <div
                            style={{
                              color: 'red',
                              fontSize: 14,
                              marginTop: '0.5rem',
                              paddingLeft: 5
                            }}
                            className="text-error">
                            {formData?.expErr}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-center"
                    onClick={() => {
                      if (!isLoading) {
                        handleSubmit();
                      }
                    }}
                    style={{
                      backgroundColor: '#132649',
                      cursor: 'pointer',
                      height: '80px',
                      width: '100%',
                      marginTop: '15rem'
                    }}>
                    <div
                      className="mt-4"
                      style={{
                        fontFamily: 'Circular Std Black',
                        color: '#F1AE01'
                      }}>
                      {isLoading && (
                        <Spinner as="span" animation="grow" size="sm" style={{ marginRight: 5 }} />
                      )}
                      {!isLoading && (
                        <>
                          {`Pay N$ ${bookingDetailsData?.totalAmount ? bookingDetailsData?.totalAmount : 0}`}
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <Success successPaymentData={successPaymentData} />
              )
            ) : (
              <div
                style={{
                  minHeight: '90vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <div>
                  <img src={gif} width={'100%'} />
                  <h5 className="modal-title text-center">This link has been expired</h5>
                </div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default CarPayment;
