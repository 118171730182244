import { Icon } from '@iconify/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Input from '../../../../../components/input';
import SelectInput from '../../../../../components/select';
import { useResponsive } from '../../../../../themes/useResponsive';
import SmallButton from '../../../../../components/button/smallButton';
import Button from '../../../../../components/button';
import MyCarContext from '../../../../../components/context/myCarContext';
import { useSelector } from 'react-redux';
import { RENTEDWHEELSAPI } from '../../../../../services/apiConstant';
import api from '../../../../../services';
import toast from 'react-hot-toast';
export default function CarInfo() {
  const { screenType } = useResponsive();
  const carImagesRef = useRef();
  const carInsuranceRef = useRef();
  const [carImages, setCarImages] = useState([]);
  const [carImagesPath, setCarImagesPath] = useState([]);
  const { myCarData, setMyCarData, basicInfoRef } = useContext(MyCarContext);
  const { carCategoryList, carCompanyList, carDetails } = useSelector((state) => state.review);
  const years = Array.from({ length: 50 }, (_, index) => new Date().getFullYear() - index);

  useEffect(() => {
    if (carDetails) {
      setCarImages(carDetails?.carImagesPath);
      setCarImagesPath(carDetails?.carImagesUrl);
    }
  }, [carDetails]);
  //  next button click function
  const handleCarInfo = () => {
    if (myCarData?.carImagesPath?.length < 1) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        carImageErr: 'Please add minimum one car image '
      }));
      return;
    }
    if (!myCarData?.modelName) {
      setMyCarData((myCarData) => ({ ...myCarData, modelNameErr: 'Please enter model name' }));
      return;
    }
    if (!myCarData?.modelYear) {
      setMyCarData((myCarData) => ({ ...myCarData, modelYearErr: 'Please select model year' }));
      return;
    }
    if (!myCarData?.carName) {
      setMyCarData((myCarData) => ({ ...myCarData, carNameErr: 'Please select car company' }));
      return;
    }
    if (!myCarData?.insurancePolicyUrl) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        insurancePolicyErr: 'Please add  insurance policy image'
      }));
      return;
    }
    if (!myCarData?.carNumber) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        carNumberErr: 'Please enter license plate number'
      }));
      return;
    }
    if (!myCarData?.vehicleType) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        vehicleTypeErr: 'Please select vehicle type'
      }));
      return;
    }
    basicInfoRef.current && basicInfoRef.current.click();
  };
  // upload car images
  useEffect(() => {
    setMyCarData((myCarData) => ({ ...myCarData, carImagesPath: carImages, carImageErr: '' }));
  }, [carImages]);

  function onImagesUpload(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      const formData = new FormData();
      formData.append('file', file);
      api
        .post(`${RENTEDWHEELSAPI.auth.changeProfileImage}?folderName=carImages`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (response?.data?.status === 1) {
            let filename = response?.data?.data[0]?.imageName;
            let temp = [...carImages];
            temp.push(filename);
            setCarImages(temp);
            let imageUrl = response.data.data[0].imagePath;
            if (imageUrl) {
              let temp = [...carImagesPath];
              temp.push(imageUrl);
              setCarImagesPath(temp);
            }
          } else {
            toast.error(response.data.messag);
          }
        })
        .catch(() => {
          ('error');
        });
    }
  }
  const handleRemoveCarImage = (index) => {
    let imageTemp = [...carImages];
    imageTemp.splice(index, 1);
    setCarImages(imageTemp);
    let imagePathemp = [...carImagesPath];
    imagePathemp.splice(index, 1);
    setCarImagesPath(imagePathemp);
  };

  // upload insurance image
  const onInsuranceImageUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    api
      .post(`${RENTEDWHEELSAPI.auth.changeProfileImage}?folderName=carInsuranceImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        if (response?.data?.status === 1) {
          let filename = response?.data?.data[0]?.imageName;
          setMyCarData((myCarData) => ({
            ...myCarData,
            insurancePolicy: filename,
            insurancePolicyErr: ''
          }));
          let imageUrl = response.data.data[0].imagePath;
          if (imageUrl) {
            myCarData.insurancePolicyUrl = imageUrl;
            setMyCarData((myCarData) => ({
              ...myCarData,
              insurancePolicyUrl: imageUrl
            }));
          }
        } else {
          toast.error(response.data.messag);
        }
      })
      .catch(() => {
        ('error');
      });
  };
  return (
    <>
      <div className="py-4 ">
        {screenType === 'desktop' ? (
          <div className="myCar-label">Car Image</div>
        ) : (
          <div className="d-flex justify-content-between">
            <div className="myCar-label">Car Image</div>
            <SmallButton
              onClick={() => {
                if (carImages?.length <= 4) {
                  carImagesRef?.current.click();
                }
              }}
              title={'Add Image'}
            />
          </div>
        )}
        <div className="text-muted pt-1" style={{ fontFamily: 'Circular Std Book' }}>
          Multiple photographs of car’s interior & exterior (Max 5)
        </div>
        <input
          ref={carImagesRef}
          style={{ display: 'none', cursor: 'pointer', objectFit: 'cover' }}
          type="file"
          accept="image/*"
          onChange={onImagesUpload}
        />
        {/* car Image */}
        {screenType === 'desktop' ? (
          <>
            <div className="d-flex gap-2 mt-3">
              <div
                onClick={() => {
                  if (carImages?.length <= 4) {
                    carImagesRef.current && carImagesRef.current.click();
                  }
                }}
                className="myCar-addImageBox">
                <div>
                  <Icon
                    className="ml-3"
                    icon={'pepicons-pop:plus-circle-filled'}
                    color="#FFBB51"
                    height={45}
                  />
                  <div className="circularMedium pt-1">Add Image</div>
                </div>
              </div>
              <div className="d-flex" style={{ overflow: 'auto', gap: 15, scrollbarWidth: 'thin' }}>
                {carImagesPath?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="latestCars-imageBox"
                      style={{
                        height: 131,
                        maxWidth: 262,
                        minWidth: 262,
                        borderRadius: 10,
                        position: 'relative'
                      }}>
                      <img src={item} alt="" height={'100%'} width={'100%'} />

                      <div className="myCar-deleteIcon" style={{ opacity: 0.5 }}>
                        <Icon
                          icon="ant-design:delete-outlined"
                          color="white"
                          height={30}
                          onClick={() => handleRemoveCarImage(index)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                color: 'red',
                fontSize: 13,
                marginTop: '0.5rem',
                paddingLeft: 5
              }}
              className="text-error">
              {myCarData?.carImageErr}
            </div>
          </>
        ) : (
          <>
            {carImagesPath?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="latestCars-imageBox"
                  style={{
                    height: 170,
                    width: '100%',
                    borderRadius: 10,
                    position: 'relative',
                    marginTop: '1rem'
                  }}>
                  <img src={item} alt="" height={'100%'} width={'100%'} />
                  <div className="myCar-deleteIcon" style={{ opacity: 0.5 }}>
                    <Icon
                      icon="ant-design:delete-outlined"
                      color="white"
                      height={30}
                      onClick={() => handleRemoveCarImage(index)}
                    />
                  </div>
                </div>
              );
            })}
            <div
              style={{
                color: 'red',
                fontSize: 13,
                marginTop: '1.5rem',
                paddingLeft: 5
              }}
              className="text-error">
              {myCarData?.carImageErr}
            </div>
          </>
        )}
        {/*  */}

        <div className="row mt-4">
          <div className={screenType != 'desktop' ? 'col-12' : 'col-4'}>
            <div className="myCar-label">Model Name</div>
            <div className="form-group pt-3">
              <Input
                onChange={(val) => {
                  setMyCarData((myCarData) => ({ ...myCarData, modelName: val, modelNameErr: '' }));
                }}
                value={myCarData?.modelName}
                formError={myCarData?.modelNameErr}
                placeholder={'Enter model name'}
              />
            </div>
          </div>
          <div className={screenType != 'desktop' ? 'col-12 mt-3' : 'col-4'}>
            <div className="myCar-label">Model Year</div>
            <div className="form-group pt-3">
              <SelectInput
                onChange={(val) => {
                  setMyCarData((myCarData) => ({
                    ...myCarData,
                    modelYear: val.target.value,
                    modelYearErr: ''
                  }));
                }}
                value={myCarData?.modelYear}
                formError={myCarData?.modelYearErr}
                options={years?.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
                Placeholder={'Please select  model year'}
              />
            </div>
          </div>
          <div className={screenType != 'desktop' ? 'col-12 mt-3' : 'col-4'}>
            <div className="myCar-label">Car Company</div>
            <div className="form-group pt-3">
              <SelectInput
                onChange={(val) => {
                  setMyCarData((myCarData) => ({
                    ...myCarData,
                    carName: val.target.value,
                    carNameErr: ''
                  }));
                }}
                value={myCarData?.carName}
                formError={myCarData?.carNameErr}
                options={carCompanyList?.map((item, index) => {
                  return (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  );
                })}
                Placeholder={'Please select car company'}
              />
            </div>
          </div>
          <div className={screenType != 'desktop' ? 'col-12 mt-3' : 'col-4 mb-5 pt-3'}>
            <input
              ref={carInsuranceRef}
              style={{ display: 'none', cursor: 'pointer' }}
              type="file"
              accept="image/*"
              onChange={onInsuranceImageUpload}
            />
            {screenType === 'MOBILE' ? (
              <>
                <div className="d-flex justify-content-between">
                  <div className="myCar-label">Insurance Policy</div>
                  <SmallButton
                    onClick={() => {
                      carInsuranceRef.current && carInsuranceRef.current.click();
                    }}
                    title={'Add Image'}
                  />
                </div>
                <div
                  style={{
                    color: 'red',
                    fontSize: 13,
                    marginTop: '0.5rem',
                    paddingLeft: 5
                  }}
                  className="text-error">
                  {myCarData?.insurancePolicyErr}
                </div>
                {myCarData?.insurancePolicyUrl && (
                  <div
                    className="latestCars-imageBox"
                    style={{
                      height: 170,
                      width: '100%',
                      borderRadius: 10,
                      position: 'relative'
                    }}>
                    <img
                      src={myCarData?.insurancePolicyUrl}
                      alt=""
                      height={'100%'}
                      width={'100%'}
                    />
                    <div
                      onClick={() =>
                        setMyCarData((myCarData) => ({
                          ...myCarData,
                          insurancePolicyUrl: '',
                          insurancePolicy: ''
                        }))
                      }
                      className="addCar-deleteIcon">
                      <Icon icon="ant-design:delete-outlined" color="white" height={20} />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="myCar-label">Insurance Policy</div>
                <div className="d-flex gap-2 mt-3">
                  <div
                    onClick={() => {
                      carInsuranceRef.current && carInsuranceRef.current.click();
                    }}
                    className="myCar-addImageBox">
                    <div>
                      <Icon
                        className="ml-3"
                        icon={'pepicons-pop:plus-circle-filled'}
                        color="#FFBB51"
                        height={45}
                      />
                      <div className="circularMedium pt-1">Add Image</div>
                    </div>
                  </div>
                  {myCarData?.insurancePolicyUrl && (
                    <div
                      className="latestCars-imageBox"
                      style={{
                        height: 131,
                        width: 262,
                        borderRadius: 10,
                        position: 'relative'
                      }}>
                      <img
                        src={myCarData?.insurancePolicyUrl}
                        alt=""
                        height={'100%'}
                        width={'100%'}
                      />
                      <div
                        onClick={() =>
                          setMyCarData((myCarData) => ({
                            ...myCarData,
                            insurancePolicyUrl: '',
                            insurancePolicy: ''
                          }))
                        }
                        className="myCar-deleteIcon"
                        style={{ opacity: 0.5 }}>
                        <Icon icon="ant-design:delete-outlined" color="white" height={30} />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    color: 'red',
                    fontSize: 13,
                    marginTop: '0.5rem',
                    paddingLeft: 5
                  }}
                  className="text-error">
                  {myCarData?.insurancePolicyErr}
                </div>
              </>
            )}
          </div>
          <div className={screenType != 'desktop' ? 'col-12 mt-3' : 'col-4 pt-3'}>
            <div className="myCar-label">License Plate</div>
            <div className="form-group pt-3">
              <Input
                onChange={(val) => {
                  setMyCarData((myCarData) => ({ ...myCarData, carNumber: val, carNumberErr: '' }));
                }}
                value={myCarData?.carNumber}
                formError={myCarData?.carNumberErr}
                placeholder={'Enter license plate number'}
              />
            </div>
          </div>
          <div
            style={{ marginBottom: screenType === 'desktop' ? '' : '4rem' }}
            className={screenType != 'desktop' ? 'col-12 mt-3' : 'col-4 pt-3 mb-5'}>
            <div className="myCar-label">Vehicle Type</div>
            <div className="form-group pt-3">
              <SelectInput
                onChange={(event) => {
                  setMyCarData((myCarData) => ({
                    ...myCarData,
                    vehicleType: event.target.value,
                    vehicleTypeErr: ''
                  }));
                }}
                options={carCategoryList?.map((item, index) => {
                  return (
                    <option key={index} value={item?.id}>
                      {item?.name?.charAt(0).toUpperCase() + item?.name?.slice(1)}
                    </option>
                  );
                })}
                value={myCarData?.vehicleType}
                formError={myCarData?.vehicleTypeErr}
                Placeholder={'Please select vehicle type'}
              />
            </div>
          </div>
        </div>
        <div
          className="position-absolute"
          style={{
            bottom: screenType != 'MOBILE' ? 0 : -10,
            right: screenType != 'MOBILE' ? 15 : 0,
            left: screenType != 'MOBILE' ? '' : 0
          }}>
          <Button onClick={handleCarInfo} title="Next" />
        </div>
      </div>
    </>
  );
}
