import React from 'react';
import { useResponsive } from 'themes/useResponsive';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import moment from 'moment';

export default function HostCard(props) {
  const { profileImage, title, joinedDate, totalTrip, userallRating } = props;
  const { screenType } = useResponsive();
  return (
    <>
      <div
        className="hostCard text-center p-2 "
        style={{
          borderRadius: 25,
          width: screenType === 'MOBILE' ? '100%' : '97%',
          minHeight: '309px'
        }}>
        <div className="mb-3" style={{ width: '100px', height: '100px', margin: '0 auto' }}>
          <img
            src={profileImage}
            style={{ objectFit: 'cover', width: '100%', height: '100%', borderRadius: '50%' }}
          />
        </div>
        <h4 className="host-title">{title}</h4>
        <div className="d-inline hostCard-subHead">{totalTrip} Trips</div>
        &nbsp;&nbsp;
        <div className="d-inline hostCard-subHead" style={{ fontSize: 20 }}>
          . Joined
        </div>
        &nbsp;
        <div className="d-inline hostCard-subHead ">{joinedDate}</div>
        <div className="my-2">
          {/* <Icon icon={'ic:baseline-star'} color="#F1AE01" height={'25'} />
          <Icon icon={'ic:baseline-star'} color="#F1AE01" height={'25'} />
          <Icon icon={'ic:baseline-star'} color="#F1AE01" height={'25'} />
          <Icon icon={'ic:baseline-star'} color="#F1AE01" height={'25'} />
          <Icon icon={'ic:baseline-star'} color="#F1AE01" height={'25'} /> */}
          {Array(5)
            .fill()
            .map((_, index) =>
              userallRating[0]?.rating >= index + 1 ? (
                <AiFillStar key={index} style={{ color: 'orange', fontSize: '2rem' }} />
              ) : (
                <AiOutlineStar key={index} style={{ color: 'orange', fontSize: '2rem' }} />
              )
            )}
        </div>
        <p className="text-dark" style={{ fontFamily: 'Circular Std Book', fontSize: 18 }}>
          {userallRating[0]?.review}
        </p>
        <div className=" hostCard-subHead">
          {title}. - {moment(userallRating[0]?.createdAt).format('MMM, YYYY')}
        </div>
      </div>
    </>
  );
}
