import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import { Link } from 'react-router-dom';
import LatestCardImageBox from '../landingPage/subComponent/latestCars/latestCardImageBox';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCarByUser, updateFavAllCarList } from '../../store/slice/landingPage';
import { loginData } from 'components/constant';
import secureLocalStorage from 'react-secure-storage';
import PopOverSpinner from 'components/popOverSpinner';
import Spinner from 'components/spinner';
import ColoredButton from 'components/button/colorFullButtons';

const SmartSearch = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState(false);
  const [searchData, setSearchData] = useState();
  const { getAllCarData, carTotalCount } = useSelector((state) => state.landingPage);
  const [limit, setLimit] = useState(10);
  // const [isToggled, setIsToggled] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const data = secureLocalStorage.getItem('searchData');
    setSearchData(JSON.parse(data));
  }, []);
  useEffect(() => {
    if (searchData) {
      getAllCar();
      setIsloading(true);
    }
  }, [searchData]);

  const getAllCar = (perPage) => {
    const data = {
      userId: loginData?.id ? loginData?.id : null,
      cityId: searchData?.cityId,
      search: searchData?.search,
      fuelType: '',
      seatNumber: searchData?.seatNumber,
      transmission: '',
      address: searchData?.address,
      longitude: searchData?.longitude,
      latitude: searchData?.latitude,
      maxDistance: '',
      fromBookingDate: searchData?.fromBookingDate
        ? `${searchData?.fromBookingDate} ${searchData.time}`
        : '',
      toBookingDate: searchData?.toBookingDate ? searchData?.toBookingDate : '',
      carFuelType: searchData?.carFuelType,
      language: 'en',
      page: 1,
      limit: perPage ? perPage : limit,
      type: '1',
      vehicleType: searchData?.vehicleType,
      carName: searchData?.companyId,
      mileageCap: searchData?.maxDistance,
      mileageType: ''
    };

    dispatch(getAllCarByUser(data, setIsloading));
  };

  const updateFav = (request) => {
    dispatch(updateFavAllCarList(request));
  };

  return (
    <>
      <div className="w-100" style={{ background: '#e5e5e5', minHeight: '100vh' }}>
        <Header background color />
        <div className="container bg-white p-5" style={{ minHeight: '100vh' }}>
          <div
            style={{
              fontFamily: 'Circular Std ',
              cursor: 'pointer',
              marginTop: '0.5rem'
            }}>
            <br /> <br />
            {searchData?.address ? (
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ backgroundColor: '#E9F8F6', padding: '1rem' }}>
                <div style={{ fontFamily: 'Circular Std Bold', fontSize: '1rem', maxWidth: '70%' }}>
                  {searchData?.address} <br />
                </div>
              </div>
            ) : (
              ''
            )}
            <Link to={'/'} style={{ color: '#63575778', textDecoration: 'none', fontSize: '20px' }}>
              Smart Search
            </Link>
            &nbsp;/&nbsp;<span style={{ fontSize: '20px' }}>Items</span>
            {isLoading && getAllCarData.length == 0 ? <PopOverSpinner /> : ''}
            {getAllCarData && getAllCarData.length > 0 ? (
              <>
                <div className="row mx-2 mt-3">
                  {getAllCarData?.map((item, index) => {
                    return (
                      <div className="col-lg-4 col-md-6 col-sm-12 gy-3" key={index}>
                        <LatestCardImageBox key={index} data={item} updateFav={updateFav} />
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex justify-content-center mt-5">
                  {isLoading ? (
                    <Spinner animation="border" style={{ color: '#6CD2C2' }} />
                  ) : (
                    carTotalCount > limit && (
                      <ColoredButton
                        onClick={() => {
                          let perPage = limit + 10;
                          setLimit(perPage);
                          getAllCar(perPage);
                        }}
                        title="View More"
                        style={{ background: '#6CD2C2', width: '130px' }}
                      />
                    )
                  )}
                </div>
              </>
            ) : (
              <div className="noData" style={{ height: '50vh' }}>
                No Data Found
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartSearch;
