import React from 'react';
// import { Icon } from '@iconify/react';
import ColoredButton from 'components/button/colorFullButtons';
import gif from 'assets/img/successfulPayment.svg';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const Success = ({ ...props }) => {
  const navigate = useNavigate();
  const { successPaymentData } = props;
  console.log('successPaymentData -- ', successPaymentData);
  return (
    <>
      <div className="card">
        <div className="card-header"></div>
        <div className="card-body">
          <blockquote className="blockquote mb-0">
            <div className="container">
              <div
                style={{
                  minHeight: '5vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <div>
                  <img src={gif} width={'100%'} />
                </div>
              </div>
              {/* <div className="d-flex justify-content-center">
                <Icon icon="icon-park-solid:success" style={{ color: 'green', fontSize: '4rem' }} />
                <br />
              </div> */}
              <div>
                <div
                  style={{ color: '#F1AE01' }}
                  className="d-flex justify-content-center display-4 col-sm-12">
                  Payment Successful
                </div>
              </div>
              <div className="align-content-md-center">
                <div className="row mt-5">
                  <div className="col-lg-6 col-sm-12 mx-auto">
                    <div className="row">
                      <div className="col-md-8 col-6">
                        {/* <p className="card-List-Left">Payment Type</p> */}
                        {/* <p className="card-List-Left">Bank</p> */}
                        {/* <p className="card-List-Left">Mobile</p> */}
                        {/* <p className="card-List-Left">Email</p> */}
                        <p className="card-List-Left">Amount Paid</p>
                        <p className="card-List-Left">Transaction Date</p>
                      </div>
                      <div className="col-md-4 col-6">
                        {/* <p className="card-List-Right">Net Banking</p> */}
                        {/* <p className="card-List-Right">XYZ</p> */}
                        {/* <p className="card-List-Right">+1178956963</p> */}
                        {/* <p className="card-List-Right">abc@gmail.com</p> */}
                        <p className="card-List-Right">N${successPaymentData?.amount}</p>
                        <p className="card-List-Right">
                          {moment(successPaymentData?.date).format('MM-DD-YYYY')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center mt-5">
                <div className="mx-2">
                  <ColoredButton
                    title={'Back to Home'}
                    style={{ background: '#F1AE01', height: '40px' }}
                    onClick={() => navigate('/')}
                  />
                </div>
                {/* <div className="mx-2">
                  <ColoredButton
                    title={'Done'}
                    style={{ background: 'green', height: '40px', width: '99.83px' }}
                  />
                </div> */}
              </div>
            </div>
          </blockquote>
        </div>
      </div>

      {/* <div className="container">
        <div className="d-flex justify-content-center">
          <Icon icon="icon-park-solid:success" style={{ color: 'green', fontSize: '4rem' }} />
          <br />
        </div>
        <div>
          <div className="d-flex justify-content-center display-4 text-success">
            Payment Successfull
          </div>
        </div>

        <div className="row mt-5" style={{ marginLeft: '10rem' }}>
          <div className="col-6">
            <p className="card-List-Left">Payment Type</p>
            <p className="card-List-Left">Bank</p>
            <p className="card-List-Left">Mobile</p>
            <p className="card-List-Left">Email</p>
            <p className="card-List-Left">Amount Paid</p>
            <p className="card-List-Left">Transaction Id</p>
          </div>
          <div className="col-6">
            <p className="card-List-Right">fyhsdjcsk</p>
            <p className="card-List-Right">fdgvbfb</p>
            <p className="card-List-Right">fdcb</p>
            <p className="card-List-Right"> fvgbcfb</p>
            <p className="card-List-Right">gcfxhg</p>
          </div>
          <div className="d-flex justify-content-center ">
            <ColoredButton
              title={'back'}
              style={{ background: 'green', height: '40px', width: '99.83px' }}
            />
            <ColoredButton
              title={'ok'}
              style={{ background: 'green', height: '40px', width: '99.83px' }}
            />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Success;
