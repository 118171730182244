import React from 'react';
import profile from '../../../assets/img/noProfile.jpg';
import { Icon } from '@iconify/react';
import { useResponsive } from '../../../themes/useResponsive';
import noImage from '../../../assets/img/no_image.jpg';
export default function ReviewBox(props) {
  const { data, productData } = props;
  const { screenType } = useResponsive();

  console.log('data?.fuelType -- ', data?.fuelType);
  console.log('productData', productData);

  return (
    <>
      {screenType === 'desktop' ? (
        <div className="card mt-3" style={{ border: 'none' }}>
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div style={{ height: 80, width: 120 }}>
                  <img
                    src={
                      productData?.carImagesUrl?.length >= 1
                        ? productData?.carImagesUrl[0]
                        : data?.carImagesUrl?.length >= 1
                          ? data?.carImagesUrl[0]
                          : noImage
                    }
                    alt=""
                    height={'100%'}
                    width={'100%'}
                    style={{ borderRadius: '10px' }}
                  />
                </div>
                <div className="align-items-center p-2 ml-3">
                  <div className="latestCars-title ">
                    {data?.modelName ? data?.modelName : productData?.modelName}
                  </div>
                  <div>
                    <small className="d-inline text-muted ">
                      {data?.transmission ? data?.transmission : productData?.transmission}
                    </small>
                    {data?.fuelType ? (
                      <>
                        &nbsp;&nbsp; <small className="d-inline text-muted">.</small>&nbsp;&nbsp;
                        <small className="d-inline text-muted">{data?.fuelType}</small>
                      </>
                    ) : (
                      ''
                    )}
                    &nbsp;&nbsp;
                    <small className="d-inline text-muted">.</small>&nbsp;&nbsp;
                    <small className="d-inline text-muted">{data?.seatNumber} Seats</small>
                  </div>
                </div>
              </div>
              <div className="d-flex p-2">
                <div style={{ height: 40, width: 40 }}>
                  <img
                    src={data?.profileImage ? data?.profileImage : profile}
                    alt=""
                    height={'100%'}
                    width={'100%'}
                    style={{ borderRadius: 35, objectFit: 'cover' }}
                  />
                </div>
                <div
                  className="p-2"
                  style={{
                    fontFamily: 'Circular Std Medium',
                    fontSize: 18
                  }}>
                  {data?.firstName}
                </div>

                <span
                  className="badge text-dark d-flex mt-2 px-3 "
                  style={{
                    background: '#fac86a57',
                    height: 30,
                    borderRadius: 15,
                    paddingTop: '0.3rem'
                  }}>
                  <Icon icon="fluent:star-12-filled" height={18} color="#F1AE01" />
                  &nbsp;&nbsp;
                  <div
                    style={{
                      fontSize: 15,
                      fontFamily: 'Circular Std Medium',
                      paddingTop: '0.2rem'
                    }}>
                    {data?.rating}
                  </div>
                </span>
              </div>
            </div>
            <div
              className="py-3"
              style={{ fontFamily: 'Circular Std Book', fontSize: 17, color: '#686565d6' }}>
              {data?.review}
            </div>
          </div>
        </div>
      ) : (
        <div className="card mt-3" style={{ border: 'none' }}>
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div style={{ height: 100, width: 120 }}>
                  <img
                    src={data?.carImage ? data?.carImage : profile}
                    alt=""
                    height={'100%'}
                    width={'100%'}
                    style={{ borderRadius: '10px' }}
                  />
                </div>
                <div className="align-items-left px-2">
                  <div className="review-car-title ">{data?.modelName}</div>
                  <div>
                    <small className="d-inline text-muted ">{data?.transmission}</small>&nbsp;&nbsp;{' '}
                    <small className="d-inline text-muted">.</small>&nbsp;&nbsp;
                    <small className="d-inline text-muted">{data?.fuelType}</small>&nbsp;&nbsp;
                    <small className="d-inline text-muted">.</small>&nbsp;&nbsp;
                    <small className="d-inline text-muted">{data?.seatNumber} Seats</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <div className="d-flex ">
                <div style={{ height: 40, width: 40 }}>
                  <img
                    src={data?.profileImage ? data?.profileImage : profile}
                    alt=""
                    height={'100%'}
                    width={'100%'}
                    style={{ borderRadius: 35 }}
                  />
                </div>
                <div
                  className="p-2"
                  style={{
                    fontFamily: 'Circular Std Medium',
                    fontSize: 18
                  }}>
                  {data?.firstName}
                </div>
              </div>
              <span
                className="badge text-dark d-flex mt-2 px-2 "
                style={{
                  background: '#fac86a57',
                  height: 30,
                  borderRadius: 15,
                  paddingTop: '0.3rem'
                }}>
                <Icon icon="fluent:star-12-filled" height={18} color="#F1AE01" />
                &nbsp;&nbsp;
                <div
                  style={{
                    fontSize: 15,
                    fontFamily: 'Circular Std Medium',
                    paddingTop: '0.2rem'
                  }}>
                  {data?.rating}
                </div>
              </span>
            </div>
            <div
              className="py-3"
              style={{ fontFamily: 'Circular Std Book', fontSize: 17, color: '#686565d6' }}>
              {data?.review}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
