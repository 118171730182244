import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { RENTEDWHEELSAPI } from '../../../services/apiConstant';
import api from '../../../services';
// import { act } from 'react-dom/test-utils';
const slice = createSlice({
  name: 'review',
  initialState: {
    ratingAndReviewData: null,
    carOwnerCarsData: null,
    carCategoryList: null,
    carCompanyList: null,
    carDetails: null
  },
  reducers: {
    apiFetching: (state) => {
      state.isLoading = true;
    },
    getCarReviewSuccess: (state, action) => {
      state.ratingAndReviewData = action.payload;
    },
    getCarListSuccess: (state, action) => {
      state.carOwnerCarsData = action.payload;
    },
    getCagetCarCategoryrListSuccess: (state, action) => {
      state.carCategoryList = action.payload;
    },
    getCarCompanyListSuccess: (state, action) => {
      state.carCompanyList = action.payload;
    },
    getCarDetailsListSuccess: (state, action) => {
      state.carDetails = action.payload.data;
    },
    clearCarDetailsSuccess: (state, action) => {
      state.carDetails = action.payload;
    },
    updateCarSuccess: (state, action) => {
      state.carDetails = action.payload;
    },
    deleteCarSucess: (state, action) => {
      state.carOwnerCarsData.data = state.carOwnerCarsData.data.filter(
        (item) => item.id != action.payload.carId
      );
    },
    favoriteCarDetailsSuccess: (state, action) => {
      state.carDetails.isFavorite = action.payload.status;
    }
  }
});

export default slice.reducer;

/**********************ACTIONS************************************************ */
const {
  getCarReviewSuccess,
  getCarListSuccess,
  getCagetCarCategoryrListSuccess,
  getCarCompanyListSuccess,
  getCarDetailsListSuccess,
  updateCarSuccess,
  deleteCarSucess,
  clearCarDetailsSuccess,
  favoriteCarDetailsSuccess
} = slice.actions;

//  get review and rating for carOwner
export const getCarReview =
  (request, setIsloading, setLoadMoreLoading, setIsListloading) => async (dispatch) => {
    api
      .post(`${RENTEDWHEELSAPI.carOwner.getRatingReview}`, request)
      .then((response) => {
        let result = response.data;
        if (result.status === 1) {
          dispatch(getCarReviewSuccess(result));
        } else {
          toast.error(result.message);
        }
        if (setIsloading) {
          setIsloading(false);
        }
        if (setLoadMoreLoading) {
          setLoadMoreLoading(false);
        }
        setIsListloading(false);
      })
      .catch(() => {});
  };
//  get carOwner car list

export const getCarList = (request, setIsloading, setIsListloading) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.carOwner.getCarList}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(getCarListSuccess(result));
        setIsListloading(false);
      } else {
        toast.error(result.message);
      }
      if (setIsloading) {
        setIsloading(false);
      }
    })
    .catch(() => {});
};

export const getCagetCarCategoryrList = (request) => async (dispatch) => {
  api
    .get(`${RENTEDWHEELSAPI.carOwner.getCarCategory}?language=${request}`)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(getCagetCarCategoryrListSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const getCarCompanyList = (request) => async (dispatch) => {
  api
    .get(`${RENTEDWHEELSAPI.carOwner.getCarCompany}?language=${request}`)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(getCarCompanyListSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const addNewCar = (request, setMyCarData, navigate, setIsLoading) => async () => {
  api
    .post(`${RENTEDWHEELSAPI.carOwner.addNewCar}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        setMyCarData({});
        navigate('/car-owner/mycars');
        toast.success(response.data.message);
      } else {
        toast.error(result.message);
      }
      setIsLoading(false);
    })
    .catch(() => {});
};

export const getCarDetails = (request, setIsloading, setIsListloading) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.carOwner.getCarDetails}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(getCarDetailsListSuccess(result));
        setIsloading(false);
      } else {
        toast.error(result.message);
      }
      setIsListloading(false);
    })
    .catch(() => {});
};
export const clearCarDetails = () => async (dispatch) => {
  dispatch(clearCarDetailsSuccess(null));
};
export const updateCar = (request, setMyCarData, navigate, setIsLoading) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.carOwner.updateCar}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(updateCarSuccess([]));
        setMyCarData({});
        navigate('/car-owner/mycars');
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
      setIsLoading(false);
    })
    .catch(() => {});
};

export const deleteCar = (request, closeDeleteDialogRef) => async (dispatch) => {
  console.log(request, 'This is cardetails');
  api
    .post(`${RENTEDWHEELSAPI.carOwner.deleteCar}`, request)
    .then((response) => {
      let result = response.data;
      console.log(result, '_______response delete car');
      if (result.status == 1) {
        toast.success(response.data.message);
        dispatch(deleteCarSucess(request));
        closeDeleteDialogRef.current && closeDeleteDialogRef.current.click();
      } else {
        toast.error(response.data.message);
      }
    })
    .catch(() => {});
};

export const favoriteCarDetails = (request) => async (dispatch) => {
  dispatch(favoriteCarDetailsSuccess(request));
};
