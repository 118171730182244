import { createSlice } from '@reduxjs/toolkit';
import api from '../../../services';
import toast from 'react-hot-toast';
import { RENTEDWHEELSAPI } from '../../../services/apiConstant';

const slice = createSlice({
  name: 'help',
  initialState: {
    addHelpByUserData: []
  },
  reducers: {
    getHelpSuccess: (state, action) => {
      state.addHelpByUserData = action.payload;
    }
  }
});
export default slice.reducer;
/**********************ACTIONS************************************************ */

const { getHelpSuccess } = slice.actions;

export const addHelpByUser = (request) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.help.addHelpByUser}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(getHelpSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
