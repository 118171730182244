import { createSlice } from '@reduxjs/toolkit';
import api from '../../../services';
import toast from 'react-hot-toast';
import { RENTEDWHEELSAPI } from '../../../services/apiConstant';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

const slice = createSlice({
  name: 'booking',
  initialState: {
    favoriteCarsList: [],
    addtofavoriteCarsList: [],
    calculatedAmount: null,
    bookingDetailsData: null
  },
  reducers: {
    getfavoriteListSuccess: (state, action) => {
      state.favoriteCarsList = action.payload;
    },
    addtofavoriteListSuccess: (state, action) => {
      state.addtofavoriteCarsList = action.payload;
    },
    amountCalculateSuccess: (state, action) => {
      state.calculatedAmount = action.payload;
    },
    getbookingDetailsSuccess: (state, action) => {
      state.bookingDetailsData = action.payload;
    },
    clearCalculatedAmountSuccess(state) {
      state.calculatedAmount = null;
    }
  }
});
export default slice.reducer;
/**********************ACTIONS************************************************ */

const {
  getfavoriteListSuccess,
  addtofavoriteListSuccess,
  amountCalculateSuccess,
  getbookingDetailsSuccess,
  clearCalculatedAmountSuccess
} = slice.actions;
const token = secureLocalStorage.getItem(process.env.REACT_APP_TOKEN_STORAGE_KEY);

export const getfavoriteList = (request) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.favorite.getfavoriteList}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(getfavoriteListSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const addtofavoriteList = (request, setIsloading) => async (dispatch) => {
  console.log(token);
  axios
    .post(
      `${process.env.REACT_APP_API_URL}${RENTEDWHEELSAPI.favorite.addtofavoriteList}`,
      request,
      {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${token} `,
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      let result = response.data;

      if (result.status == 1) {
        dispatch(addtofavoriteListSuccess(result.data));
        setIsloading(false);
      } else {
        toast.error(result.message);
        setIsloading(false);
      }
    })
    .catch(() => {});
};

export const amountCalculate = (request) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.booking.amountCalculate}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(amountCalculateSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const checkCouponDetails = (request, setIsCouponValid, setFormData) => async () => {
  api
    .post(`${RENTEDWHEELSAPI.booking.checkCouponDetails}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        setIsCouponValid(true);
      } else {
        setIsCouponValid(false);
        setFormData((formData) => ({
          ...formData,
          couponError: 'Please enter a valid coupon'
        }));
      }
    })
    .catch(() => {});
};

export const bookingCar = (request, navigate, setIsLoading) => async () => {
  api
    .post(`${RENTEDWHEELSAPI.booking.carBooking}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        toast.success(result.message);
        setIsLoading(false);
        navigate('/');
      } else {
        toast.error(result.message);
        setIsLoading(false);
      }
    })
    .catch(() => {});
};

export const checkCarAvailByCarId = (request, setIsCarAvalible) => async () => {
  api
    .post(`${RENTEDWHEELSAPI.booking.checkCarAvailByCarId}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        setIsCarAvalible(true);
      } else {
        setIsCarAvalible(false);
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const getbookingDetails = (request) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.booking.getbookingDetails}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(getbookingDetailsSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const createPayment =
  (request, setIsSuccessPayment, setIsLoading, setSuccessPaymentData) => async () => {
    api
      .post(`${RENTEDWHEELSAPI.booking.createPayment}`, request)
      .then((response) => {
        let result = response.data;
        if (result.status == 1) {
          setIsSuccessPayment(true);
          toast.success(result.message);
          setSuccessPaymentData(result.data);
        } else {
          toast.error(result.message);
        }
        if (setIsLoading) {
          setIsLoading(false);
        }
      })
      .catch(() => {});
  };

export const clearCalculatedAmount = () => async (dispatch) => {
  dispatch(clearCalculatedAmountSuccess());
};
