import React, { useEffect, useRef, useState } from 'react';
import logo from '../../assets/img/authLogo.png';
import { useResponsive } from '../../themes/useResponsive';
import { loginData } from '../constant';
// import secureLocalStorage from 'react-secure-storage';
import ChangePassword from '../dialog/changePassword';
import profile from '../../assets/img/noProfile.jpg';
import UpdateProfile from '../dialog/updateProfile';
import { generateNewJwtToken, getUserProfile } from '../../store/slice/auth';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './style.css';
import { Icon } from '@iconify/react';
import SmartOverlay from './smartOverlay';
import ProfileOverlay from './profileOverlay';
// import { Overlay } from 'react-bootstrap';
import notificationLogo from 'assets/img/owner-icons/Notification.png';
import messageLogo from 'assets/img/owner-icons/message.png';
import { colors } from 'themes/color';
import { getnotificationListing } from 'store/slice/carUser/notification';
import secureLocalStorage from 'react-secure-storage';

export default function Header(props) {
  const { background, color } = props;
  const { screenType } = useResponsive();
  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState(0);

  // const navigate = useNavigate();
  const [showSmartSearch, setShowSmartSearch] = useState(false);
  const { userProfileData } = useSelector((state) => ({ ...state.auth }));
  const { NotificationsList } = useSelector((state) => state.notification);

  const dispatch = useDispatch();
  const target = useRef(null);
  // const navigate = useNavigate();
  const handleNavigation = (e) => {
    const window = e.currentTarget;
    setScroll(window.scrollY);
  };
  useEffect(() => {
    setScroll(window.scrollY);
    window.addEventListener('scroll', (e) => handleNavigation(e));
  }, []);
  const smartSearch = useRef(null);

  useEffect(() => {
    let token = secureLocalStorage.getItem(process.env.REACT_APP_TOKEN_STORAGE_KEY);
    if (loginData) {
      dispatch(getUserProfile());
      const data = {
        page: 1,
        limit: 10,
        language: 'en'
      };
      dispatch(getnotificationListing(data));
      if (!token) {
        dispatch(generateNewJwtToken(loginData?.id));
      }
    }
  }, []);
  useEffect(() => {
    if (scroll > 0) {
      setShow(false);
      setShowSmartSearch(false);
    }
  }, [scroll]);
  return (
    <>
      <ChangePassword />
      <UpdateProfile userProfileData={userProfileData} />
      <nav
        className="navbar fixed-top navbar-light"
        style={{
          backgroundImage:
            scroll > 0 || background ? 'linear-gradient(to right, #ffe0b0a6, #87cead)' : ''
          // height: '3.8rem'
        }}>
        <div className="container ">
          <Link
            className="navbar-brand"
            to={'/'}
            style={{
              width: screenType === 'MOBILE' ? '40%' : '127.68px',
              marginTop: '10px'
            }}>
            <img src={logo} alt="" height={'70%'} width={'100%'} />
          </Link>
          <span className="navbar-text d-flex">
            {!loginData ? (
              <>
                <Link
                  className="userNav nav-link"
                  style={{ color: scroll ? 'black' : screenType === 'MOBILE' ? 'black' : 'white' }}
                  to="/auth/login">
                  Login
                </Link>
                &nbsp;&nbsp;
                <a className="userNav nav-link" href="#">
                  <Icon icon={'radix-icons:dot-filled'} color="#F1AE01" />
                </a>
                &nbsp;&nbsp;
                <Link
                  className="userNav nav-link"
                  to="/auth/signUp"
                  style={{ color: scroll ? 'black' : screenType === 'MOBILE' ? 'black' : 'white' }}>
                  Sign up
                </Link>
              </>
            ) : (
              <>
                {screenType === 'MOBILE' || screenType === 'TABLET' ? (
                  <Icon
                    onClick={() => setShowSmartSearch(!showSmartSearch)}
                    ref={smartSearch}
                    icon={'akar-icons:search'}
                    height={screenType === 'TABLET' ? 60 : 40}
                    color={
                      scroll || color ? '#132649' : screenType === 'MOBILE' ? '#132649' : 'white'
                    }
                    style={{
                      paddingTop: '0.2rem',
                      paddingRight: screenType === 'MOBILE' ? '1rem' : '2rem',
                      marginTop: screenType === 'TABLET' ? '-0.7rem' : ''
                    }}
                  />
                ) : (
                  <div
                    className="d-flex align-items-center mx-5"
                    style={{ borderBottom: '2px solid #ced1d7d9', padding: '', height: 35 }}
                    ref={smartSearch}
                    onClick={() => setShowSmartSearch(!showSmartSearch)}>
                    <div
                      style={{
                        position: 'relative'
                        // marginTop: '-0.5rem'
                      }}>
                      <input
                        className="shadow-sm bg-transparent rounded pl-5"
                        placeholder="Smart Search"
                      />
                      <Icon
                        icon={'akar-icons:search'}
                        color={scroll > 0 || color ? '#132649' : 'white'}
                        height={20}
                        style={{
                          position: 'absolute',
                          left: '8px',
                          top: '50%',
                          transform: 'translateY(-45%)'

                          // color: '#132649'
                        }}
                      />
                    </div>
                  </div>
                )}
                <Link
                  className="userNav nav-link"
                  to="/notifications"
                  style={{
                    paddingTop: '0.4rem',
                    paddingRight: screenType === 'MOBILE' ? '1rem' : '2rem'
                  }}>
                  <div className="position-relative">
                    <img
                      src={notificationLogo}
                      width={24}
                      style={{
                        filter: scroll || color ? '' : 'brightness(15)',
                        marginTop: '0.2rem'
                      }}
                    />
                    {NotificationsList?.unreadCount >= 1 && (
                      <span
                        className="position-absolute top-0 start-100 translate-middle badge rounded-circle p-1"
                        style={{
                          background: scroll || color ? colors.bgbutton : 'white',
                          width: 25,
                          height: 25,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        <span style={{ fontSize: '0.7rem', color: colors.bt_text }}>
                          {NotificationsList?.unreadCount}
                        </span>
                        <span className="visually-hidden"></span>
                      </span>
                    )}
                  </div>
                </Link>
                <Link
                  className="userNav nav-link"
                  // onClick={() => navigate('/car-owner/messages')}
                  to={loginData && loginData?.userType == 3 ? '/messages' : '/car-owner/messages'}
                  style={{
                    paddingTop: '0.3rem',
                    paddingRight: screenType === 'MOBILE' ? '1rem' : '2rem'
                  }}>
                  <img
                    src={messageLogo}
                    width={28}
                    style={{ filter: scroll || color ? '' : 'brightness(15)', marginTop: '0.3rem' }}
                  />
                </Link>
                <a
                  className="userNav nav-link"
                  ref={target}
                  onClick={() => setShow(!show)}
                  style={{
                    height: 40,
                    width: 40
                  }}>
                  <img
                    src={
                      userProfileData?.profileImagePath
                        ? userProfileData?.profileImagePath
                        : profile
                    }
                    className="rounded-circle shadow-4"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    alt="Avatar"
                  />
                </a>
              </>
            )}
          </span>

          {/* smart search overlay  */}
          <SmartOverlay
            showSmartSearch={showSmartSearch}
            smartSearch={smartSearch}
            closeDialog={() => setShowSmartSearch(false)}
          />

          {/* profile overlay  */}

          <ProfileOverlay show={show} target={target} />
        </div>
      </nav>
    </>
  );
}
