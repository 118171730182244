import React, { useEffect } from 'react';
import Header from '../../../components/header';
import { Link } from 'react-router-dom';
import { useResponsive } from '../../../themes/useResponsive';
import LatestCar from '../../../assets/img/latestcars.png';
import MaskGroup4 from '../../../assets/img/Mask Group (4).png';
import MaskGroup5 from '../../../assets/img/Mask Group (5).png';

const Details = () => {
  const { screenType } = useResponsive();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="w-100" style={{ background: '#e5e5e5', height: 'auto' }}>
        <Header background color />

        <div className="container bg-white p-4" style={{ marginTop: '4.5rem' }}>
          <div
            style={{
              fontFamily: 'Circular Std Medium',
              cursor: 'pointer'
            }}>
            <Link to={'/'} style={{ color: '#63575778', textDecoration: 'none' }}>
              Home
            </Link>
            &nbsp;/&nbsp;
            <Link to={'/my-bookings'} style={{ color: '#63575778', textDecoration: 'none' }}>
              My Booking
            </Link>
            &nbsp;/&nbsp;<span>Details</span>
            <div className="shadow p-3 mb-2 bg-white rounded row mt-5" style={{ height: '40%' }}>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="position-relative">
                  <img src={LatestCar} style={{ height: '20%', width: '100%' }} />
                  <div
                    style={{
                      position: 'absolute',
                      top: 10,
                      left: 10,
                      borderRadius: 15,
                      background: 'white',
                      display: 'inline-flex',
                      padding: 2
                    }}>
                    <span className="badge text-dark d-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.3em"
                        height="1.3em"
                        viewBox="0 0 32 32">
                        <path
                          fill="#F1AE01"
                          d="m18.7 4.627l2.247 4.31a2.27 2.27 0 0 0 1.686 1.189l4.746.65c2.538.35 3.522 3.479 1.645 5.219l-3.25 2.999a2.225 2.225 0 0 0-.683 2.04l.793 4.398c.441 2.45-2.108 4.36-4.345 3.24l-4.536-2.25a2.282 2.282 0 0 0-2.006 0l-4.536 2.25c-2.238 1.11-4.786-.79-4.345-3.24l.793-4.399c.14-.75-.12-1.52-.682-2.04l-3.251-2.998c-1.877-1.73-.893-4.87 1.645-5.22l4.746-.65a2.23 2.23 0 0 0 1.686-1.189l2.248-4.309c1.144-2.17 4.264-2.17 5.398 0"></path>
                      </svg>
                      &nbsp;
                      <div style={{ fontSize: 17 }}> 4.5</div>
                    </span>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      borderRadius: 10,
                      background: 'black',
                      width: 40,
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      padding: 8
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.5em"
                      height="1.5em"
                      viewBox="0 0 15 15">
                      <path
                        fill="#F1AE01"
                        d="m7.5 13.5l-.354.354a.5.5 0 0 0 .708 0zM1.536 7.536l-.354.353zm5-5l-.354.353zM7.5 3.5l-.354.354a.5.5 0 0 0 .708 0zm.964-.964l-.353-.354zm-.61 10.61L1.889 7.182l-.707.707l5.964 5.965zm5.257-5.964l-5.965 5.964l.708.708l5.964-5.965zM6.182 2.889l.964.965l.708-.708l-.965-.964zm1.672.965l.964-.965l-.707-.707l-.965.964zM10.964 1c-1.07 0-2.096.425-2.853 1.182l.707.707A3.037 3.037 0 0 1 10.964 2zM14 5.036c0 .805-.32 1.577-.89 2.146l.708.707A4.036 4.036 0 0 0 15 5.036zm1 0A4.036 4.036 0 0 0 10.964 1v1A3.036 3.036 0 0 1 14 5.036zM4.036 2c.805 0 1.577.32 2.146.89l.707-.708A4.036 4.036 0 0 0 4.036 1zM1 5.036A3.036 3.036 0 0 1 4.036 2V1A4.036 4.036 0 0 0 0 5.036zm.89 2.146A3.035 3.035 0 0 1 1 5.036H0c0 1.07.425 2.096 1.182 2.853z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-8 col-md-8 col-sm-12"
                style={{ marginTop: screenType === 'MOBILE' ? '1REM' : '' }}>
                <div className="d-grid align-items-center h-100 w-100">
                  <div>
                    <h3 style={{ fontFamily: 'Circular Std Bold', color: '#132649' }}>
                      White Dodge Challenger
                    </h3>
                    <h6 style={{ fontFamily: 'Circular Std Book', color: 'grey' }}>
                      Booking ID : #0125496
                    </h6>
                  </div>
                  {screenType === 'MOBILE' ? (
                    <div>
                      <small
                        className="d-inline d-md-inline"
                        style={{
                          fontFamily: 'Circular Std Book',
                          fontSize: '25px',
                          color: '#F1AE01'
                        }}>
                        N$
                        <span
                          style={{
                            fontFamily: 'Circular Std Book',
                            fontSize: '25px',
                            color: '#132649'
                          }}>
                          1530
                        </span>
                        <span
                          style={{
                            fontFamily: 'Circular Std Book',
                            fontSize: '15px',
                            color: '#132649'
                          }}>
                          /day
                        </span>
                      </small>
                    </div>
                  ) : (
                    <div>
                      <small
                        className="d-inline d-md-inline"
                        style={{
                          fontFamily: 'Circular Std Book',
                          fontSize: '25px',
                          color: '#F1AE01'
                        }}>
                        N$
                        <span
                          style={{
                            fontFamily: 'Circular Std Book',
                            fontSize: '25px',
                            color: '#132649'
                          }}>
                          1530
                        </span>
                        <span
                          style={{
                            fontFamily: 'Circular Std Book',
                            fontSize: '15px',
                            color: '#132649'
                          }}>
                          /day
                        </span>
                      </small>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5">
              <h4 style={{ fontFamily: 'Circular Std Black', color: '#132649', fontSize: 30 }}>
                Penaltys
              </h4>
              {screenType === 'MOBILE' ? (
                <div>
                  <div
                    className="card"
                    style={{
                      width: '100%',
                      borderRadius: 24
                    }}>
                    <img className="card-img-top" src={MaskGroup5} alt="Card image cap" />
                  </div>

                  <div>
                    <div className="card" style={{ width: '100%', borderRadius: 24 }}>
                      <img className="card-img-top" src={MaskGroup5} alt="Card image cap" />
                    </div>
                  </div>
                  <div>
                    <div className="card" style={{ width: '100%', borderRadius: 24 }}>
                      <img className="card-img-top" src={MaskGroup4} alt="Card image cap" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-4 align-items-start mt-0">
                  <div
                    className="card"
                    style={{
                      width: '15rem',
                      height: '15rem',
                      backgroundColor: 'transparent',
                      borderRadius: 24
                    }}>
                    <img className="card-img-top" src={MaskGroup5} alt="Card image cap" />
                  </div>

                  <div>
                    <div
                      className="card"
                      style={{ width: '15rem', height: '15rem', borderRadius: 24 }}>
                      <img className="card-img-top" src={MaskGroup5} alt="Card image cap" />
                    </div>
                  </div>
                  <div>
                    <div
                      className="card"
                      style={{ width: '15rem', height: '15rem', borderRadius: 24 }}>
                      <img className="card-img-top" src={MaskGroup4} alt="Card image cap" />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="d-flex justify-content-between"
              style={{
                backgroundColor: '#EEAC02',
                height: '60px',
                width: '100%',
                marginTop: '15rem'
              }}>
              <div
                className="mx-5 mt-3"
                style={{
                  fontFamily: 'Circular Std Medium',
                  color: 'white'
                }}>
                Penalty Charges
              </div>
              <div
                className="mr-5 mt-3"
                style={{
                  fontFamily: 'Circular Std Medium',
                  color: 'white'
                }}>
                N$675
              </div>
            </div>
            <div
              className="d-flex justify-content-center mt-2"
              style={{
                backgroundColor: '#132649',
                height: '80px',
                width: '100%'
              }}>
              <div
                className="mt-4"
                style={{
                  fontFamily: 'Circular Std Book',
                  color: '#F1AE01'
                }}>
                Re-Book
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
