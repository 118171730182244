import { useEffect } from 'react';
import OwnerHeader from '../../../layouts/carOwner/header';
import '../payment/CardStyle.css';
import '../payment/Style.css';
import NotificationList from '../../commonComponent/notificationList';

export default function Notification() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <OwnerHeader title={'Notification'} />
      <div className="mt-4 pt-1">
        <NotificationList />
      </div>
    </>
  );
}
