import { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BlogCard from './blogCard';
import SliderButton from '../../../../components/sliderButton';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBlogsList } from '../../../../store/slice/landingPage';
import { useResponsive } from 'themes/useResponsive';

export default function Blogs() {
  const dispatch = useDispatch();

  const { screenType } = useResponsive();
  const { blogsData } = useSelector((state) => state.landingPage);
  useEffect(() => {
    fetchBlogDataList();
  }, []);
  const fetchBlogDataList = () => {
    const data = {
      language: 'en',
      page: 1,
      limit: 10
      // blogId: id,
    };
    dispatch(getAllBlogsList(data));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 2 // optional, default to 1.
    }
  };
  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide }
    } = rest;
    return (
      <div className="carousel-button-group">
        <SliderButton previous={() => previous()} next={() => next()} index={currentSlide} />
      </div>
    );
  };
  return (
    <div>
      <Carousel
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        autoPlay={false}
        arrows={false}
        infinite={true}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px">
        {blogsData?.map((item, index) => {
          return <BlogCard key={index} data={item} screenType={screenType} />;
        })}
      </Carousel>
    </div>
  );
}
