import React, { useEffect, useRef, useState } from 'react';
import Tabs from '../subComponent/tabs';
import OwnerHeader from '../../../../layouts/carOwner/header';
import { useResponsive } from '../../../../themes/useResponsive';
import MyCarContext from '../../../../components/context/myCarContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCagetCarCategoryrList,
  getCarCompanyList,
  getCarDetails
} from '../../../../store/slice/carOwner/review';
// import Button from '../../../../components/button';
import { useParams } from 'react-router-dom';
import PopOverSpinner from 'components/popOverSpinner';
export default function AddEditCar() {
  const { screenType } = useResponsive();
  const [myCarData, setMyCarData] = useState({});
  const [isListLoading, setIsListloading] = useState(false);
  const [fuelData, setFuelData] = useState({
    withFule: false,
    withoutFule: false
  });

  const basicInfoRef = useRef();
  const carInfoRef = useRef();
  const mileageInfoRef = useRef();
  const featuresRef = useRef();
  const rulesRef = useRef();
  const { id } = useParams();

  const dispatch = useDispatch();
  const { carDetails } = useSelector((state) => state.review);
  useEffect(() => {
    if (carDetails) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        ...carDetails,
        amountPerHour: carDetails?.withFule?.amountPerHour,
        amountPerDay: carDetails?.withFule?.amountPerDay,
        amountPerWeek: carDetails?.withFule?.amountPerWeek,
        amountPerHours: carDetails?.withoutFule?.amountPerHour,
        amountPerDays: carDetails?.withoutFule?.amountPerDay,
        amountPerWeeks: carDetails?.withoutFule?.amountPerWeek,
        address: carDetails?.address?.address
      }));
      setFuelData((fuelData) => ({
        ...fuelData,
        withFule: carDetails.withFule ? true : false,
        withoutFule: carDetails.withoutFule ? true : false
      }));
    }
  }, [carDetails]);
  useEffect(() => {
    dispatch(getCagetCarCategoryrList('en'));
    dispatch(getCarCompanyList('en'));
    setIsListloading(true);
  }, []);

  useEffect(() => {
    if (id) {
      getCarDetailshandle();
    } else {
      setIsListloading(false);
    }
  }, [id]);

  const getCarDetailshandle = () => {
    let request = {
      carId: id,
      language: 'en'
    };
    dispatch(getCarDetails(request, setIsListloading));
  };

  return (
    <>
      <div
        className="position-relative"
        style={{
          marginLeft:
            screenType === 'desktop' ? '-1.5rem' : screenType === 'TABLET' ? '-1.7rem' : '-0.8rem',
          marginRight: screenType === 'desktop' ? '-1.5rem' : '',
          marginTop: '-1.5rem',
          background: 'white',
          minHeight: '100vh',
          maxHeight: 'auto',
          padding: screenType === 'desktop' ? 25 : '',
          paddingLeft: screenType === 'TABLET' ? 15 : ''
        }}>
        <OwnerHeader title={'Car Details'} />
        {isListLoading ? <PopOverSpinner /> : ''}
        <div
          style={{
            marginLeft: screenType === 'desktop' ? '' : '-0.9rem',
            background: 'white',
            padding: screenType === 'desktop' ? '' : 10,
            marginRight: screenType === 'desktop' ? '' : '-1.7rem'
          }}>
          <MyCarContext.Provider
            value={{
              myCarData,
              fuelData,
              setFuelData,
              setMyCarData,
              basicInfoRef,
              carInfoRef,
              mileageInfoRef,
              featuresRef,
              rulesRef
            }}>
            <Tabs />
          </MyCarContext.Provider>
        </div>
      </div>
    </>
  );
}
