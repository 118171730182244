import React from 'react';
import './index.css';
import { Spinner } from 'react-bootstrap';
export default function Button(props) {
  const { title, onClick, disabled, style, isLoading } = props;
  return (
    <div className="form-group">
      <button
        style={{ ...style, minWidth: '140px' }}
        disabled={disabled}
        onClick={onClick}
        className="btn btn-block">
        {isLoading && <Spinner as="span" animation="grow" size="sm" style={{ marginRight: 5 }} />}
        {title}
      </button>
    </div>
  );
}
