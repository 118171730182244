import React, { useContext, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import '../style.css';
import { useResponsive } from 'themes/useResponsive';
import MyCarContext from 'components/context/myCarContext';
import ColoredButton from 'components/button/colorFullButtons';

export default function BookingRates() {
  const { screenType } = useResponsive();
  const { carDetails, formData, setFormData } = useContext(MyCarContext);
  const [mileageCapHeight, setMileageCapHeight] = useState(false);
  const [rulesAndPrecautions, setRulesAndPrecautions] = useState([]);

  useEffect(() => {
    const listData = carDetails?.rulesAndPrecautions.split('\n');
    setRulesAndPrecautions(listData);
  }, [carDetails]);

  return (
    <>
      <div className="col-lg-6 col-md-12 col-sm-12 ">
        <div
          className="d-flex justify-content-start gap-3 "
          style={{
            display: screenType === 'MOBILE' ? 'flex' : 'contents',
            justifyContent: screenType === 'MOBILE' ? 'row' : 'column'
          }}>
          <button
            className={`d-flex align-items-end p-3 ${formData?.bookingType === 'perHour' ? 'First-yellow-division' : 'border second-division'}`}
            onClick={() => setFormData((formData) => ({ ...formData, bookingType: 'perHour' }))}>
            <div>
              {formData?.bookingType === 'perHour' && (
                <span style={{ position: 'absolute', top: '5px', right: '5px' }}>
                  <Icon icon={'mdi:tick-circle'} color="#FFFFFF" height={25} />
                </span>
              )}
              <span className="main-text">
                {carDetails?.withFule
                  ? carDetails?.withFule?.amountPerHour.toLocaleString()
                  : carDetails?.withoutFule?.amountPerHour.toLocaleString()}
              </span>
              <span className="main-text-1">N$</span>
              <div
                className="second-sub-text"
                style={{
                  color: formData?.bookingType === 'perHour' ? 'white' : '',
                  textAlign: 'left'
                }}>
                Per hour
              </div>
            </div>
          </button>
          <button
            className={`d-flex align-items-end p-3 ${formData?.bookingType === 'perDay' ? 'First-yellow-division' : 'border second-division'}`}
            onClick={() => setFormData((formData) => ({ ...formData, bookingType: 'perDay' }))}>
            <div>
              {formData?.bookingType === 'perDay' && (
                <span style={{ position: 'absolute', top: '5px', right: '5px' }}>
                  <Icon icon={'mdi:tick-circle'} color="#FFFFFF" height={25} />
                </span>
              )}
              <span className="main-text">
                {carDetails?.withFule
                  ? carDetails?.withFule?.amountPerDay.toLocaleString()
                  : carDetails?.withoutFule?.amountPerDay.toLocaleString()}
              </span>
              <span className="main-text-1">N$</span>
              <div
                className="second-sub-text"
                style={{
                  color: formData?.bookingType === 'perDay' ? 'white' : '',
                  textAlign: 'left'
                }}>
                Per day
              </div>
            </div>
          </button>
          <button
            className={`d-flex align-items-end p-3 ${formData?.bookingType === 'perWeek' ? 'First-yellow-division' : 'border second-division'}`}
            onClick={() => setFormData((formData) => ({ ...formData, bookingType: 'perWeek' }))}>
            <div>
              {formData?.bookingType === 'perWeek' && (
                <span style={{ position: 'absolute', top: '5px', right: '5px' }}>
                  <Icon icon={'mdi:tick-circle'} color="#FFFFFF" height={25} />
                </span>
              )}
              <span className="main-text">
                {carDetails?.withFule
                  ? carDetails?.withFule?.amountPerWeek.toLocaleString()
                  : carDetails?.withoutFule?.amountPerWeek.toLocaleString()}
              </span>
              <span className="main-text-1">N$</span>
              <div
                className="second-sub-text"
                style={{
                  color: formData?.bookingType === 'perWeek' ? 'white' : '',
                  textAlign: 'left'
                }}>
                Per week
              </div>
            </div>
          </button>
        </div>
        <h4 className="HeadingAll mt-4">
          Mileage cap
          <hr />
        </h4>
        <div className="d-flex justify-content-between pr-2">
          <div className="car-detail-featureName">Per Hours</div>
          <div className="car-detail-featureDetail">
            {carDetails?.mileageCapPerHour?.km} km Free
            <div className="car-detail-featureDetailItalic">
              (Pay N$ {carDetails?.mileageCapPerHour?.amount}/km after
              {carDetails?.mileageCapPerHour?.km}km.)
            </div>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between  pr-2">
          <div className="car-detail-featureName"> Per Day</div>
          <div className="car-detail-featureDetail">
            {carDetails?.mileageCapPerDay?.km} km Free
            <div className="car-detail-featureDetailItalic">
              (Pay N${carDetails?.mileageCapPerDay?.amount}/km after{' '}
              {carDetails?.mileageCapPerDay?.km}km.)
            </div>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between  pr-2">
          <div className="car-detail-featureName"> Per Week</div>
          <div className="car-detail-featureDetail">
            {carDetails?.mileageCapPerWeek?.km} km Free
            <div className="car-detail-featureDetailItalic">
              (Pay N$ {carDetails?.mileageCapPerWeek?.amount}/km after{' '}
              {carDetails?.mileageCapPerWeek?.km}km.)
            </div>
          </div>
        </div>
        <div
          style={{
            height: !mileageCapHeight ? (screenType === 'MOBILE' ? 100 : 300) : 'auto',
            overflow: 'hidden'
          }}>
          <h4 className="mt-5 HeadingAll">
            Car Features & Specifications
            <hr />
          </h4>
          <div className="d-flex justify-content-between pr-3">
            <div className="car-detail-featureName">Seat</div>
            <div className="car-detail-featureDetail">{carDetails?.seatNumber} Seat</div>
          </div>
          <hr />
          <div className="d-flex justify-content-between pr-3">
            <div className="car-detail-featureName">Transmission</div>
            <div className="car-detail-featureDetail">{carDetails?.transmission}</div>
          </div>
          <hr />{' '}
          <div className="d-flex justify-content-between pr-3">
            <div className="car-detail-featureName">Audio</div>
            <div className="car-detail-featureDetail">{carDetails?.audioFeatures}</div>
          </div>
          <hr />
          <div className="d-flex justify-content-between pr-3">
            <div className="car-detail-featureName">Fuel Consumption</div>
            <div className="car-detail-featureDetail">{carDetails?.fuelConsumption}</div>
          </div>
          <hr />{' '}
          <div className="d-flex justify-content-between pr-3">
            <div className="car-detail-featureName">Brakes</div>
            <div className="car-detail-featureDetail">{carDetails?.brakes}</div>
          </div>
          <hr />{' '}
          <div className="d-flex justify-content-between pr-3">
            <div className="car-detail-featureName">Air Bags</div>
            <div className="car-detail-featureDetail">{carDetails?.airBags} </div>
          </div>
          <hr />
        </div>
        <div className="d-flex justify-content-center mt-2">
          <ColoredButton
            onClick={() => setMileageCapHeight(!mileageCapHeight)}
            style={{ background: '#132649' }}
            title={
              <div className="circularBook px-3" style={{ fontSize: 17 }}>
                {' '}
                {mileageCapHeight ? 'Show less' : 'Show all'}
                <span style={{ color: '#F1AE01' }}> Features</span>
              </div>
            }
          />
        </div>
        <h4 className="mt-5 HeadingAll">Precautions Renters</h4>
        <ul
          style={{
            color: '#132649',
            fontFamily: 'Circular Std Medium',
            padding: '1px',
            marginLeft: '-0.3rem',
            lineHeight: 2,
            listStyleType: 'none'
          }}>
          {rulesAndPrecautions &&
            rulesAndPrecautions.map((item, index) => {
              return item ? (
                <li key={index}>
                  <Icon icon={'icon-park-outline:dot'} color="#F1AE01" height={20} />
                  {item}
                </li>
              ) : (
                ''
              );
            })}
        </ul>
      </div>
      {screenType != 'MOBILE' && (
        <div className="col-lg-1 d-flex" style={{ height: 'auto' }}>
          <div className="vr"></div>
        </div>
      )}
    </>
  );
}
