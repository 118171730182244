import { useEffect, useState } from 'react';
import OwnerHeader from '../../../layouts/carOwner/header';
import ReviewBox from './reviewBox';
import { useDispatch, useSelector } from 'react-redux';
import { getCarReview } from '../../../store/slice/carOwner/review';
import UserReviewBox from './userReviewBox';
import TabBottomLine from '../myCar/subComponent/tabBottomLine';
import Spinner from 'components/spinner';
import ColoredButton from 'components/button/colorFullButtons';
import PopOverSpinner from 'components/popOverSpinner';

// let limit = 10;
export default function RatingAndReview() {
  const [isLoading, setIsloading] = useState(false);
  const [rateListType, setRateList] = useState(1);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [activeTab, setActiveTab] = useState('MyCar');
  const [isListLoading, setIsListloading] = useState(false);
  const { ratingAndReviewData } = useSelector((state) => state.review);

  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    fetchReviewData();
    setIsListloading(true);
  }, []);
  useEffect(() => {
    fetchReviewData();
  }, [rateListType]);
  const fetchReviewData = (perPage) => {
    let request = {
      page: 1,
      perPage: perPage ? perPage : limit,
      ratingType: rateListType,
      language: 'en'
    };
    dispatch(getCarReview(request, setIsloading, setLoadMoreLoading, setIsListloading));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <OwnerHeader title={'My Ratings & Reviews'} />
      {isListLoading ? <PopOverSpinner /> : ''}
      <div className="mt-5 pt-4">
        <ul
          className="nav nav-pills"
          id="pills-tab"
          role="tablist"
          onClick={(event) => {
            let value = event.target.id;
            let id = value.split('-')[1];
            setActiveTab(id);
          }}
          style={{ width: '100vw !important' }}>
          <li className="nav-item" role="presentation" style={{ width: '50%' }}>
            <button
              id="pills-MyCar-tab"
              data-bs-toggle="pill"
              data-bs-target="#MyCar"
              onClick={() => {
                setLimit(10);
                setIsloading(true);
                setRateList(1);
              }}
              className="tab-button active">
              My Car Review
              {activeTab === 'MyCar' && <TabBottomLine />}
            </button>
          </li>
          <li className="nav-item" role="presentation" style={{ width: '50%' }}>
            <button
              id="pills-MyAccount-tab"
              data-bs-toggle="pill"
              data-bs-target="#MyAccount"
              onClick={() => {
                setLimit(10);
                setRateList(2);
                setIsloading(true);
              }}
              className="tab-button">
              My Account Review
              {activeTab === 'MyAccount' && <TabBottomLine />}
            </button>
          </li>
        </ul>
        <div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="MyCar"
              role="tabpanel"
              aria-labelledby="pills-MyCar-tab">
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  {ratingAndReviewData?.data?.length >= 1 ? (
                    <>
                      {ratingAndReviewData?.data?.map((item, index) => {
                        return <ReviewBox data={item} key={index} />;
                      })}
                      <div className="d-flex justify-content-center mt-5">
                        {loadMoreLoading ? (
                          <Spinner animation="border" style={{ color: '#6CD2C2' }} />
                        ) : (
                          ratingAndReviewData?.totalCount > limit && (
                            <ColoredButton
                              onClick={() => {
                                let perPage = limit + 10;
                                setLimit(perPage);
                                setLoadMoreLoading(true);
                                fetchReviewData(perPage);
                              }}
                              title="View More"
                              style={{ background: '#6CD2C2', width: '130px' }}
                            />
                          )
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="noData" style={{ height: '82vh' }}>
                      No Data Found
                    </div>
                  )}
                </>
              )}
            </div>
            <div
              className="tab-pane fade"
              id="MyAccount"
              role="tabpanel"
              aria-labelledby="pills-MyAccount-tab">
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  {ratingAndReviewData?.data?.length >= 1 ? (
                    <>
                      {ratingAndReviewData?.data?.map((item, index) => {
                        return <UserReviewBox data={item} key={index} />;
                      })}
                      <div className="d-flex justify-content-center mt-5">
                        {loadMoreLoading ? (
                          <Spinner animation="border" style={{ color: '#6CD2C2' }} />
                        ) : (
                          ratingAndReviewData?.totalCount > limit && (
                            <ColoredButton
                              onClick={() => {
                                let perPage = limit + 10;
                                setLimit(perPage);
                                setLoadMoreLoading(true);
                                fetchReviewData(perPage);
                              }}
                              title="View More"
                              style={{ background: '#6CD2C2', width: '130px' }}
                            />
                          )
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="noData" style={{ height: '82vh' }}>
                      No Data Found
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
