import { Icon } from '@iconify/react';
import React from 'react';
export default function ExperienceImageBox(props) {
  const { image, text, screenType } = props;
  return (
    <div
      className="bg-image-container"
      style={{
        backgroundImage: `url("${image}")`,
        backgroundSize: 'cover',
        borderRadius: 25,
        height: screenType === 'MOBILE' ? 301 : screenType === 'TABLET' ? 250 : 301,
        width: screenType === 'MOBILE' ? '100%' : '95%'
      }}>
      <div className="gradient">
        <div className="text-container">
          <div className="d-inline">
            <Icon icon="mingcute:battery-charging-line" width={'30'} color="#F1AE01" />
          </div>
          &nbsp; &nbsp;
          <div className="d-inline" style={{ fontFamily: 'Circular Std Bold', fontSize: 22 }}>
            {text}
          </div>
        </div>
      </div>
    </div>
  );
}
