import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './ratingDialog.css';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import carImagee from '../../assets/img/no_image.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { loginData } from 'components/constant';
import { ratingReview } from 'store/slice/carUser/notification';
import Button from 'components/button';
const RatingsAndReviews = ({ ...props }) => {
  const { showDialog, onDialogClose } = props;
  // const [number, setNumber] = useState(0);
  const dispatch = useDispatch();
  const { bookingDetailsData } = useSelector((state) => state.booking);
  const [ratingData, setRatingData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setEror] = useState();

  const handleSubmit = () => {
    const rateDataList = [];
    let rateData = {};
    if (ratingData?.carRating) {
      rateData = {
        carId: bookingDetailsData?.productData?.id,
        toUserId: loginData?.id,
        bookingId: bookingDetailsData.id,
        ratingType: 1, //1 = car ,
        rating: ratingData?.carRating,
        review: ratingData?.review
      };
      rateDataList.push(rateData);
    }
    if (ratingData?.userRating) {
      rateData = {
        carId: bookingDetailsData?.productData?.id,
        toUserId:
          loginData?.userType == 3
            ? bookingDetailsData?.sellerData?.sellerId
            : bookingDetailsData?.userData?.userId,
        bookingId: bookingDetailsData?.id,
        ratingType: loginData?.userType == 3 ? 2 : 3, //2 = car owner,3=user,
        rating: Number(ratingData?.userRating),
        review: ratingData?.review
      };
      rateDataList.push(rateData);
    }
    if (rateDataList.length == 0) {
      return setEror('Please select number of rating');
    }
    const data = {
      language: 'en',
      data: rateDataList
    };
    setIsLoading(true);
    dispatch(ratingReview(data, setIsLoading, handleClose));
    // handleClose();
  };
  const handleClose = () => {
    setRatingData({});
    onDialogClose();
  };
  console.log(bookingDetailsData, 'bookingDetailsData');
  return (
    <>
      <Modal show={showDialog} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex justify-content-between">
              <h5 className="modal-title" id="exampleModalLongTitle">
                How`s Your Experience..??
              </h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <hr />

        <Modal.Body>
          <div className="d-flex align-items-center">
            <img
              style={{ width: 80, height: 80, objectFit: 'cover' }}
              src={
                bookingDetailsData?.productData?.carImagesUrl?.length > 0
                  ? bookingDetailsData?.productData?.carImagesUrl[0]
                  : carImagee
              }
              alt="name"
            />
            <div className="ml-3">
              <h4 style={{ fontFamily: 'Circular Std Bold', marginBottom: 0 }}>
                {bookingDetailsData?.productData?.modelName}
              </h4>
              <h6 style={{ fontFamily: 'Circular Std Bold', marginTop: 0 }}>
                Booking ID: {bookingDetailsData?.orderId}
              </h6>
            </div>
          </div>
          {loginData?.userType == 3 ? (
            <div className="d-flex mt-3">
              <h5 className="mt-1 pr-5 " style={{ fontFamily: 'Circular Std Bold' }}>
                Rate your car
              </h5>

              {Array(5)
                .fill()
                .map((_, index) =>
                  ratingData?.carRating >= index + 1 ? (
                    <AiFillStar
                      key={index}
                      // onMouseOver={() => !number && setHoverStar(index + 1)}
                      // onMouseLeave={() => setHoverStar(undefined)}
                      style={{ color: 'orange', fontSize: '2rem' }}
                      onClick={() => {
                        setRatingData((ratingData) => ({
                          ...ratingData,
                          carRating: index + 1
                        }));
                        setEror('');
                      }}
                    />
                  ) : (
                    <AiOutlineStar
                      key={index}
                      // onMouseOver={() => !number && setHoverStar(index + 1)}
                      // onMouseLeave={() => setHoverStar(undefined)}
                      style={{ color: 'orange', fontSize: '2rem' }}
                      onClick={() => {
                        // setNumber(index + 1);
                        setRatingData((ratingData) => ({
                          ...ratingData,
                          carRating: index + 1
                        }));
                        setEror('');
                      }}
                    />
                  )
                )}
            </div>
          ) : (
            ''
          )}

          <div className="product">
            {/* <img style={{ width: 60, height: 60, objectFit: 'cover' }} src={hostImage} alt="name" /> */}
            <div className="d-flex mt-2 ">
              <h5 className="pr-3 mr-1 pt-1" style={{ fontFamily: 'Circular Std Bold' }}>
                {loginData?.userType == 3 ? 'Rate your owner' : 'Rate your car renter'}
              </h5>

              {Array(5)
                .fill()
                .map((_, index) =>
                  ratingData?.userRating >= index + 1 ? (
                    <AiFillStar
                      key={index}
                      // onMouseOver={() => !number && setHoverStar(index + 1)}
                      // onMouseLeave={() => setHoverStar(undefined)}
                      style={{ color: 'orange', fontSize: '2rem' }}
                      onClick={() => {
                        setRatingData((ratingData) => ({
                          ...ratingData,
                          userRating: index + 1
                        }));
                        setEror('');
                      }}
                    />
                  ) : (
                    <AiOutlineStar
                      key={index}
                      // onMouseOver={() => !number && setHoverStar(index + 1)}
                      // onMouseLeave={() => setHoverStar(undefined)}
                      style={{ color: 'orange', fontSize: '2rem' }}
                      onClick={() => {
                        setRatingData((ratingData) => ({
                          ...ratingData,
                          userRating: index + 1
                        }));
                        setEror('');
                      }}
                    />
                  )
                )}
            </div>
          </div>
          <br />

          <textarea
            placeholder={'Comment here...'}
            className="form-control"
            value={ratingData?.review}
            onChange={(e) =>
              setRatingData((ratingData) => ({
                ...ratingData,
                review: e.target.value
              }))
            }></textarea>
          <p style={{ color: 'red' }}>{error}</p>
          {/* <button
            // className={` ${!ratingData?.carRating && !ratingData?.userRating && 'disabled'}`}
            style={{
              background: 'orange',
              color: '#fff',
              marginTop: '10px',
              width: '100%',
              borderRadius: '6px',
              padding: '10px 0',
              border: 'none',
              fontWeight: 'bolder',
              cursor: 'pointer'
              // pointerEvents: ratingData?.carRating && ratingData?.userRating ? 'auto' : 'none'
            }}
            onClick={() => (!isLoading ? handleSubmit() : '')}>
            Submit
          </button> */}
          <Button
            title={isLoading ? 'Submiting...' : 'Submit'}
            onClick={() => (!isLoading ? handleSubmit() : '')}
            isLoading={isLoading}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RatingsAndReviews;
