export const config = [
  {
    title: 'Dashboard',
    icon: 'heroicons-outline:view-grid',
    path: '/car-owner/dashboard'
  },
  {
    title: 'My Bookings',
    icon: 'lets-icons:check-ring-round',
    path: '/car-owner/mybooking'
  },
  {
    title: 'My Cars',
    icon: 'pepicons-pencil:car',
    path: '/car-owner/mycars'
  },
  {
    title: 'Payment',
    icon: 'lets-icons:wallet-alt',
    path: '/car-owner/payment'
  },
  {
    title: 'Messages',
    icon: 'system-uicons:mail',
    path: '/car-owner/messages'
  },
  {
    title: 'My Rating & Review',
    icon: 'solar:ticker-star-linear',
    path: '/car-owner/myrating-review'
  }
];
