import moment from 'moment/moment';
import Payment from 'payment';
import secureLocalStorage from 'react-secure-storage';

export const makeAnArray = (n) => {
  return Array.from({ length: n }, (u, i) => ({ id: i + 1, label: i + 1 }));
};

export const getTimeAhead = (date, numberOfTime) => {
  const currentTime = date;
  const timeAhead = new Date(currentTime);
  timeAhead.setHours(currentTime.getHours() + numberOfTime);
  return timeAhead;
};

export const getDaysOrWeeksAhead = (date, numberOfTime, flag) => {
  let currentDate = date;
  const dateAhead = flag == 'week' ? numberOfTime * 7 : numberOfTime;
  currentDate.setDate(currentDate.getDate() + dateAhead);
  return currentDate;
};

const convertDate = (str) => {
  var date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join('/');
};

export const getUTCTimestamp = (time, date) => {
  let convertedDate = convertDate(date);
  let selectedDateTime = moment(convertedDate + ' ' + time, 'DD/MM/YYYY HH:mm');
  let startDateTimeInUTC = convertToMilliseconds(selectedDateTime);
  return startDateTimeInUTC;
};

const convertToMilliseconds = (date) => {
  const utcDate = moment.utc(date);
  const timestamp = utcDate.valueOf();
  return timestamp;
};

export const checkdate = (dateTime) => {
  const currentDate = moment();
  const inputDateTime = moment(dateTime);

  const diffSeconds = currentDate.diff(inputDateTime, 'seconds');

  if (diffSeconds < 60) {
    return 'Just now';
  } else if (diffSeconds < 3600) {
    const diffMinutes = Math.floor(diffSeconds / 60);
    return diffMinutes + ' min ago';
  } else if (inputDateTime.isSame(currentDate, 'day')) {
    return 'Today';
  } else if (inputDateTime.isSame(currentDate.clone().subtract(1, 'day'), 'day')) {
    return 'Yesterday';
  } else {
    const diffDays = Math.floor(diffSeconds / (3600 * 24));
    return diffDays + ' days ago';
  }
};

// Card functions

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 6)}`;
  }

  return clearValue;
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 3;

  if (allValues.number) {
    // const issuer = Payment.fns.cardType(allValues.number);
  }

  return clearValue.slice(0, maxLength);
}

export const todayDate = () => {
  return moment(new Date()).format('yyyy-MM-DD');
};

export const deviceToken = secureLocalStorage.getItem('FCM_TOKEN_KEY');

export const addDataToStart = (data, array) => {
  array.unshift(data);
  return array;
};

export const addArrayFromLast = (arr1, arr2) => {
  for (let i = arr1.length - 1; i >= 0; i--) {
    arr2.push(arr1[i]);
  }
  return arr2;
};
