import React from 'react';
import { useNavigate } from 'react-router-dom';
import ColoredButton from '../../../../components/button/colorFullButtons';
import moment from 'moment';
import profileImage from '../../../../assets/img/noProfile.jpg';
import noDataImage from 'assets/img/no_image.jpg';
import { useResponsive } from 'themes/useResponsive';
export default function CarRequestCard(props) {
  const { data, setShowAleartDialog } = props;
  const { screenType } = useResponsive();
  const navigate = useNavigate();
  return (
    <>
      <div
        className={
          screenType === 'MOBILE' ? 'col-12' : screenType === 'TABLET' ? 'col-6' : 'col-lg-4'
        }>
        <div
          style={{
            backgroundColor: '#F6F6F8',
            width: '100%',
            borderRadius: 15
          }}>
          <div
            className="shadow-sm rounded-5 p-4"
            onClick={() => navigate(`/car-owner/mybooking/user-profile/${data.id}`)}>
            <div className=" ">
              <div className="row px-2">
                <div className="col-2 ">
                  <img
                    className="car-image"
                    src={data?.userData?.profileImage ? data?.userData?.profileImage : profileImage}
                    alt="img"
                    style={{ backgroundSize: 'cover' }}
                  />
                </div>
                <div className="client-name-number pt-1 col-10  ">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div>
                        <h4
                          className="client-name fs-4"
                          style={{ fontFamily: 'circular std Bold' }}>
                          {data?.userData?.userName}
                        </h4>
                      </div>
                      <div
                        style={{
                          color: '#a6a7a9',
                          fontFamily: 'Circular Std Book',
                          fontSize: '1rem',
                          marginTop: '-0.4rem'
                        }}>
                        +{data?.userData?.countryCode} {data?.userData?.phoneNumber}
                      </div>
                    </div>
                    <div>
                      <div>
                        <span className="d-flex">
                          <p
                            className="m-0"
                            style={{
                              color: '#F1AE01',
                              fontFamily: 'circular std Book',
                              paddingTop: '0.4rem'
                            }}>
                            N$
                          </p>
                          <h3 style={{ fontFamily: 'circular std Bold' }} className="fs-3">
                            {data?.totalAmount}
                          </h3>
                        </span>
                        <div
                          style={{
                            color: '#a6a7a9',
                            fontFamily: 'Circular Std Book',
                            fontSize: '1rem',
                            marginTop: '-0.4rem'
                          }}>
                          {data?.bookingType === 'perDay'
                            ? 'Per Day'
                            : data?.bookingType === 'perHour'
                              ? 'Per Hour'
                              : 'Per Week'}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                  
                   
                  </div> */}
                </div>
              </div>
            </div>
            <div className="car-name-image p-2 px-3" style={{ background: '#E5E5E5' }}>
              <div>
                <img
                  className="car-image"
                  src={
                    data?.productData?.carImagesUrl?.length >= 1
                      ? data?.productData?.carImagesUrl[0]
                      : noDataImage
                  }
                  alt="img"
                  style={{ backgroundSize: 'cover' }}
                />
              </div>
              <div>
                <div
                  style={{
                    color: '#132649',
                    fontFamily: 'Circular Std Medium',
                    paddingTop: '0.7rem'
                  }}>
                  {data?.productData?.modelName}
                </div>
              </div>
            </div>
          </div>

          <div
            className="d-flex justify-content-between card px-3"
            style={{
              backgroundColor: '#F6F6F8',
              border: 'none',
              width: '100%',
              marginTop: '0.8rem'
            }}>
            <div className="card-body ">
              <div className="d-flex justify-content-between req-details ">
                <div className="req-details-left fs-5"> Fuel</div>
                <div className="req-details-right text-end fs-5">
                  {data?.fuelType?.type === 'withfuel' ? 'With Fuel' : 'Without Fuel'}
                </div>
              </div>
              <div className="d-flex justify-content-between req-details ">
                <div className="req-details-left fs-5"> Start Time</div>
                <div className="req-details-right text-end fs-5">
                  {moment.utc(data?.bookingDate.from).local().format('hh:mm:ss A')}
                </div>
              </div>
              <div className="d-flex justify-content-between req-details ">
                <div className="req-details-left fs-5"> Booking Date</div>
                <div className="req-details-right text-end fs-5">
                  {moment.utc(data?.bookingDate.from).local().format('Do MMM, YYYY')}
                </div>
              </div>
              <div className="d-flex justify-content-between req-details fs-5">
                <div className="req-details-left"> End Date</div>
                <div className="req-details-right text-end fs-5">
                  {moment.utc(data?.bookingDate.to).local().format('Do MMM, YYYY')}
                </div>
              </div>
              <div className="d-flex justify-content-between req-details ">
                <div className="req-details-left fs-5"> Delivery & Pickup</div>
                <div className="req-details-right text-end fs-5">
                  {data?.deliveryOrPickup?.type}
                </div>
              </div>
              <div className="row mt-4 ">
                <div className="col-6">
                  <ColoredButton
                    title={'Accept'}
                    onClick={() => {
                      setShowAleartDialog((showAleartDialog) => ({
                        ...showAleartDialog,
                        id: data.id,
                        show: true,
                        status: 3
                      }));
                      // handleAction(data, 3);
                    }}
                    style={{
                      backgroundColor: '#44DE94'
                    }}
                    className={'rounded-4'}
                  />
                </div>
                <div className="col-6">
                  <ColoredButton
                    title={'Reject'}
                    onClick={() => {
                      setShowAleartDialog((showAleartDialog) => ({
                        ...showAleartDialog,
                        id: data.id,
                        show: true,
                        status: 4
                      }));
                      // handleAction(data, 2);
                    }}
                    style={{
                      backgroundColor: '#FF6D6D'
                    }}
                    className={'rounded-4'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
