import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Cars from '../../../../assets/img/latestcars.png';
import LatestCardImageBox from './latestCardImageBox';
import { useResponsive } from '../../../../themes/useResponsive';
import SliderButton from '../../../../components/sliderButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateFavList } from 'store/slice/landingPage';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
export default function LatestCars(props) {
  const { allLatestCarList } = useSelector((state) => state.landingPage);
  const { cameFrom } = props;
  const dispatch = useDispatch();
  const { screenType } = useResponsive();
  const updateFav = (request) => {
    dispatch(updateFavList(request, updateFav));
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide }
    } = rest;
    return (
      <div className="carousel-button-group">
        <SliderButton previous={() => previous()} next={() => next()} index={currentSlide} />
      </div>
    );
  };
  return (
    <div>
      <Carousel
        renderButtonGroupOutside={!cameFrom ? true : false}
        customButtonGroup={<ButtonGroup />}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        autoPlay={false}
        arrows={false}
        infinite={screenType === 'Mobile' ? false : true}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px">
        {allLatestCarList?.map((item, index) => {
          return (
            <LatestCardImageBox
              key={index}
              image={Cars}
              data={item}
              screenType={screenType}
              cameFrom={cameFrom}
              updateFav={updateFav}
            />
          );
        })}
      </Carousel>
    </div>
  );
}
