import React from 'react';

export default function DestinationImageBox(props) {
  const { image, text, screenType, onClick } = props;
  return (
    <>
      <div className="destination">
        <div
          onClick={onClick}
          className="destination-Box"
          style={{
            backgroundImage: `url("${image}")`,
            backgroundSize: 'cover',
            borderRadius: 25,
            height: screenType === 'MOBILE' ? 160 : screenType === 'TABLET' ? 160 : 175,
            width: screenType === 'MOBILE' ? 160 : screenType === 'TABLET' ? 160 : 175
          }}></div>
        <div className="destinationText text-center pt-2">
          <div>{text}</div>
        </div>
      </div>
    </>
  );
}

// import React from 'react';

// export default function DestinationImageBox(props) {
//   const { image, text, screenType, onClick } = props;
//   return (
//     <>
//       <div className="destination">
//         <div
//           onClick={onClick}
//           className="destination-Box"
//           style={{
//             backgroundImage: `url("${image}")`,
//             borderRadius: 25,
//             backgroundSize: 'cover',
//             // backgroundRepeat: 'no-repeat',
//             // backgroundPosition: 'center',
//             height: screenType === 'MOBILE' ? 160 : screenType === 'TABLET' ? 160 : 175,
//             width: screenType === 'MOBILE' ? 160 : screenType === 'TABLET' ? 160 : 175
//           }}></div>
//         <div className="destinationText text-center pt-2">
//           <div> {text}</div>
//         </div>
//       </div>
//     </>
//   );
// }
