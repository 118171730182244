import Modal from 'react-bootstrap/Modal';
import './ratingDialog.css';
import Button from 'components/button';
import ColoredButton from 'components/button/colorFullButtons';
import { useNavigate } from 'react-router-dom';

const LoginDialog = ({ ...props }) => {
  const { show, setShow, heading } = props;
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLongTitle">
              Login
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ fontFamily: 'Circular Std Book' }}>{heading}</h5>
          <div className="row mt-5">
            <div className="col-6">
              <ColoredButton
                title={'Close'}
                style={{ background: 'transparent', border: '1px solid #ffbb51' }}
                color="#ffbb51"
                onClick={handleClose}
              />
            </div>
            <div className="col-6">
              <Button title={'Login Now'} onClick={() => navigate('/auth/login')} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginDialog;
