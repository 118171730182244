import React, { useState } from 'react';
import ForgotEmail from './forgotEmail';
import ForgotOtp from './forgotOtp';
import ResetPassword from './resetPassword';
import { getOtpForResetPass } from '../../../store/slice/auth';
import { useDispatch } from 'react-redux';

export default function ForgotPassword() {
  const [forgotData, setForgotData] = useState({});

  const dispatch = useDispatch();
  const handleGetOtp = () => {
    let request = {
      email: forgotData?.email
    };
    dispatch(getOtpForResetPass(request, setForgotData));
  };
  return (
    <>
      <div style={{ paddingTop: '4rem' }} className="px-1">
        {forgotData?.showOtpScreen ? (
          <ForgotOtp
            setForgotData={setForgotData}
            forgotData={forgotData}
            handleGetOtp={handleGetOtp}
          />
        ) : forgotData?.otpVerified ? (
          <ResetPassword setForgotData={setForgotData} forgotData={forgotData} />
        ) : (
          <ForgotEmail
            setForgotData={setForgotData}
            forgotData={forgotData}
            handleGetOtp={handleGetOtp}
          />
        )}
      </div>
    </>
  );
}
