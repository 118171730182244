import React, { useEffect, useState } from 'react';
import OwnerHeader from '../../../../layouts/carOwner/header';
import image from '../../../../assets/img/paymentBg.png';
import profile from '../../../../assets/img/noProfile.jpg';
import ReviewBox from '../../ratingAndReview/reviewBox';
import './style.css';
import { useResponsive } from '../../../../themes/useResponsive';
import ColoredButton from '../../../../components/button/colorFullButtons';
// import carImage from '../../../../assets/img/latestcars.png';
// import profileImage from '../../../../assets/img/host.png';
import AleartDialog from '../../../../components/dialog/aleartDialog';

import { useDispatch, useSelector } from 'react-redux';
import {
  acceptRejectAndCompeleteBooking,
  bookingDetail,
  recentBookingList
} from 'store/slice/carOwner/bookingStatus';
import { useNavigate, useParams } from 'react-router-dom';
import Swiper from 'components/swiper';

const UserProfile = () => {
  // const { ratingAndReviewData } = useSelector((state) => state.review);
  const [showAleartDialog, setShowAleartDialog] = useState({});
  const { bookingData, recentBookingListData } = useSelector((state) => state.bookingsStatus);
  const [isStatusLoading, setIsStatusLoading] = useState(false);

  const navigate = useNavigate();
  const parms = useParams();
  const { screenType } = useResponsive();

  const dispatch = useDispatch();

  useEffect(() => {
    fetchbookingDetailData();
  }, []);

  useEffect(() => {
    if (bookingData && bookingData?.productData?.id) {
      fetchrecentBookingList();
    }
  }, [bookingData]);

  const fetchbookingDetailData = () => {
    let request = {
      language: 'en',
      bookingId: parms?.id
    };
    dispatch(bookingDetail(request));
  };

  const fetchrecentBookingList = () => {
    let request = {
      language: 'en',
      page: 1,
      perPage: 10,
      carId: bookingData?.productData?.id
    };
    dispatch(recentBookingList(request));
  };

  const handleAction = () => {
    const request = {
      language: 'en',
      bookingId: parms?.id,
      bookingStatus: showAleartDialog?.status
    };
    dispatch(
      acceptRejectAndCompeleteBooking(request, navigate, setIsStatusLoading, setShowAleartDialog)
    );
    // setShowAleartDialog((showAleartDialog) => ({
    //   ...showAleartDialog,
    //   show: false,
    //   status: 0
    // }));
  };

  // const reviewData = [
  //   {
  //     carImage: carImage,
  //     carName: 'White Dodge Challenger',
  //     transmission: 'Automatic',
  //     fuelType: 'With Fuel',
  //     seatNumber: 2,
  //     profileImage: profileImage,
  //     firstName: 'Denilo Olico',
  //     rating: 4.5,
  //     review:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type"
  //   },
  //   {
  //     carImage: carImage,
  //     carName: 'White Dodge Challenger',
  //     transmission: 'Automatic',
  //     fuelType: 'With Fuel',
  //     seatNumber: 2,
  //     profileImage: profileImage,
  //     firstName: 'Denilo Olico',
  //     rating: 4.5,
  //     review:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type"
  //   }
  // ];

  return (
    <>
      <OwnerHeader
        title={'User Profile'}
        previousPage={'My Booking'}
        currentPage={'User Profile'}
        previousPageUrl={'/car-owner/mybooking'}
      />
      <div className="pt-4" style={{ height: 'auto' }}>
        <div
          className="card border-0 "
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
            width: '100%',
            height: screenType != 'desktop' ? 'auto' : '150px',
            marginTop: '54px'
          }}>
          <div className="main-divv d-flex justify-content-between align-items-center">
            <div
              className="second-image"
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '1px'
              }}>
              <img
                // src={profile}
                src={
                  bookingData?.userData?.profileImage
                    ? bookingData?.userData?.profileImage
                    : profile
                }
                alt="Logo-Image"
                className="rounded-circle"
                style={{
                  marginLeft: '1rem',
                  marginBottom: '-2rem',
                  width: '10rem',
                  height: '10rem',
                  marginTop: screenType != 'desktop' ? '-1.2rem' : '-0.2rem',
                  objectFit: 'cover'
                }}
              />
            </div>
            {screenType != 'desktop' ? (
              <div className="d-block ">
                <div className="client-image-div pt-2">
                  <div className="client-name-number" style={{ paddingRight: '1rem' }}>
                    <div>
                      <h4 className="client-name" style={{ fontFamily: 'circular std Bold' }}>
                        {bookingData?.userData?.userName}
                      </h4>
                    </div>
                    <div
                      style={{
                        fontFamily: 'circular std Book',
                        color: '#5c6064'
                      }}>
                      {bookingData?.userData?.phoneNumber}
                    </div>
                  </div>
                </div>
                <div className="d-block gap-1 mr-3 pt-3 ">
                  <ColoredButton
                    title={'Accept'}
                    height={'45px'}
                    style={{
                      backgroundColor: '#44DE94'
                    }}
                    onClick={() => {
                      setShowAleartDialog((showAleartDialog) => ({
                        ...showAleartDialog,
                        show: true,
                        status: 3
                      }));
                    }}
                  />
                  <ColoredButton
                    title={'Reject'}
                    height={'45px'}
                    style={{
                      backgroundColor: '#FF6D6D'
                    }}
                    onClick={() => {
                      setShowAleartDialog((showAleartDialog) => ({
                        ...showAleartDialog,
                        show: true,
                        status: 4
                      }));
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-between w-100 mt-3 ">
                <div className="client-image-div pt-2">
                  <div className="client-name-number">
                    <div>
                      <h4
                        className="client-name"
                        style={{ fontFamily: 'circular std Bold', marginLeft: '15px' }}>
                        {bookingData?.userData?.userName}
                      </h4>
                    </div>
                    <div
                      style={{
                        fontFamily: 'circular std Book',
                        marginLeft: '15px',
                        color: '#5c6064'
                      }}>
                      +{bookingData?.userData?.countryCode}&nbsp;
                      {bookingData?.userData?.phoneNumber}
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-4 mr-3 pt-3 ">
                  <ColoredButton
                    title={'Accept'}
                    height={'45px'}
                    style={{
                      backgroundColor: '#44DE94'
                    }}
                    onClick={() => {
                      setShowAleartDialog((showAleartDialog) => ({
                        ...showAleartDialog,
                        show: true,
                        status: 3
                      }));
                    }}
                  />
                  <ColoredButton
                    title={'Reject'}
                    height={'45px'}
                    style={{
                      backgroundColor: '#FF6D6D'
                    }}
                    onClick={() => {
                      setShowAleartDialog((showAleartDialog) => ({
                        ...showAleartDialog,
                        show: true,
                        status: 4
                      }));
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {recentBookingListData ? (
          <div className="row align-items-center">
            <div className="col-sm-8 text-muted fs-2 carOwner-tab-title mt-5">Recent Bookings</div>
            <div className="mt-4">
              <Swiper
                cameFrom="userProfile"
                sliedData={recentBookingListData}
                excludeLinks={true}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        {bookingData?.ratingdata?.length > 0 ? (
          <>
            <div className="row align-items-center mt-5 mb-5">
              <div className="col-sm-8 text-muted fs-2 carOwner-tab-title mt-5">
                {' '}
                Rating & Reviews ({bookingData?.ratingdata.length})
              </div>
            </div>
            {bookingData?.ratingdata?.map((item, index) => {
              return <ReviewBox data={item} key={index} productData={bookingData?.productData} />;
            })}
          </>
        ) : (
          ''
        )}
      </div>

      <AleartDialog
        title={`${showAleartDialog.status == 3 ? 'Accept' : 'Reject'} booking request`}
        message={`Are you sure, you want to ${showAleartDialog.status == 3 ? 'accept' : 'reject'} this booking?`}
        showDialog={showAleartDialog.show}
        isLoading={isStatusLoading}
        onDialogClose={() => {
          setShowAleartDialog((showAleartDialog) => ({
            ...showAleartDialog,
            show: false,
            status: 0
          }));
        }}
        btnConfirm={() => {
          handleAction();
        }}
      />
    </>
  );
};
export default UserProfile;
