import React, { useEffect } from 'react';
import host from '../../../assets/img/noProfile.jpg';
import { socket } from '../../../helpers/socketio-client';
import { useDispatch } from 'react-redux';
import { receiveMessageData } from 'store/slice/carOwner/message';
import { loginData } from 'components/constant';
import InfiniteScroll from 'react-infinite-scroll-component';
import { checkdate } from 'utils/appConstant';
import Spinner from 'components/spinner';

export default function Messages(props) {
  const { messageData, setPage, isLoading } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    socket.on('receiveChatMessage', handleReceiveMessage);
  }, [socket]);

  const handleReceiveMessage = (data) => {
    console.log('data message -- ', data);
    if (data) {
      dispatch(receiveMessageData(data));
    }
  };

  return (
    <>
      {messageData?.map((item, index) => {
        return (
          <InfiniteScroll
            dataLength={messageData?.length ? messageData?.length : 0}
            style={{ display: 'flex', flexDirection: 'column-reverse' }} //To
            key={index}
            next={setPage}
            inverse={true}
            hasMore={true}
            scrollableTarget="scrollableDiv">
            <div className="position-relative" key={index}>
              <div className="chat-messages p-4">
                {item.chatMessage == 'This chat has been initiated' ? (
                  <div style={{ textAlign: 'center', color: 'gray' }}>
                    This chat has been initiated
                  </div>
                ) : (
                  <div
                    className={
                      loginData?.id == item.recieverUserId
                        ? 'chat-message-left'
                        : 'chat-message-right'
                    }>
                    {loginData?.id == item.recieverUserId ? (
                      <div>
                        <img
                          src={item?.userProfile ? item?.userProfile : host}
                          className="rounded-circle "
                          alt="Sharon Lessman"
                          width="40"
                          height="40"
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="container">
                      <div
                        className={
                          loginData?.id == item.recieverUserId
                            ? 'left-side-chat py-2 px-3 mr-2'
                            : 'right-side-chat py-2 px-3 mr-2'
                        }>
                        {item?.chatMessage}
                      </div>
                      <div
                        className="container"
                        style={{
                          display: 'flex',
                          justifyContent: loginData?.id == item.recieverUserId ? 'start' : 'end'
                        }}>
                        <div style={{ fontSize: 10 }}>
                          {item?.chatSendDateTime ? checkdate(Number(item?.chatSendDateTime)) : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </InfiniteScroll>
        );
      })}
      {isLoading ? <Spinner /> : <></>}
    </>
  );
}
