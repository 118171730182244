import Modal from 'react-bootstrap/Modal';
import './ratingDialog.css';
import Button from 'components/button';
import ColoredButton from 'components/button/colorFullButtons';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { loginData } from 'components/constant';
import { socket } from 'helpers/socketio-client';

const LogoutDialog = ({ ...props }) => {
  const { show, setShow } = props;
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
  };
  const handleLogout = () => {
    secureLocalStorage.clear();
    const data = {
      userId: loginData.id,
      isOnlineOffline: false
    };
    socket.emit('userOffline', data);
    navigate('/');
    window.location.reload();
  };
  return (
    <>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLongTitle">
              Logout
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ fontFamily: 'Circular Std Book' }}>Are you sure you want to logout?</h5>
          <div className="row mt-5">
            <div className="col-6">
              <ColoredButton
                title={'Close'}
                style={{ background: 'transparent', border: '1px solid #ffbb51' }}
                color="#ffbb51"
                onClick={handleClose}
              />
            </div>
            <div className="col-6">
              <Button title={'Logout'} onClick={handleLogout} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LogoutDialog;
