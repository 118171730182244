import React from 'react';
import './index.css';
export default function ColoredButton(props) {
  const { title, onClick, disabled, style, height, color, className } = props;
  return (
    <div className="form-group">
      <button
        style={{
          ...style,
          // minWidth: '120px',
          height: height ? height : '50px',
          color: color ? color : 'white'
        }}
        disabled={disabled}
        onClick={onClick}
        className={`colored-btn btn-block ${className}`}>
        {title}
      </button>
    </div>
  );
}
