import { Icon } from '@iconify/react';
import React from 'react';

export default function SliderButton(props) {
  const { next, previous, index, data, prev } = props;
  return (
    <div className="pt-5" style={{ textAlign: 'center' }}>
      <Icon
        onClick={previous}
        icon="ant-design:left-circle-outlined"
        height={'50'}
        color={index === 0 ? 'grey' : '#000C11'}
      />
      <Icon
        onClick={next}
        icon="ant-design:right-circle-outlined"
        color={prev >= data?.length ? 'grey' : '#000C11'}
        height={'50'}
      />
    </div>
  );
}
