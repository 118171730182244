import React, { useState } from 'react';
import { useResponsive } from '../../themes/useResponsive';
import Input from '../input';
import { useRef } from 'react';
import { changePassword } from '../../store/slice/auth';
import { useDispatch } from 'react-redux';

export default function ChangePassword() {
  const { screenType } = useResponsive();
  const [changePassData, setChangePassData] = useState({});
  const closeRef = useRef();
  const dispatch = useDispatch();
  const handleChangePassword = () => {
    if (!changePassData.oldPassword) {
      setChangePassData((changePassData) => ({
        ...changePassData,
        oldPasswordErr: 'Please enter old password'
      }));
      return;
    }
    if (changePassData.oldPassword.length <= 7) {
      setChangePassData((changePassData) => ({
        ...changePassData,
        oldPasswordErr: 'Password must be minimum 8 characters '
      }));
      return;
    }
    if (!changePassData.newPassword) {
      setChangePassData((changePassData) => ({
        ...changePassData,
        newPasswordErr: 'Please enter new password'
      }));
      return;
    }
    if (changePassData.newPassword.length <= 7) {
      setChangePassData((changePassData) => ({
        ...changePassData,
        newPasswordErr: 'Password must be minimum 8 characters '
      }));
      return;
    }
    if (!changePassData.confirmPassword) {
      setChangePassData((changePassData) => ({
        ...changePassData,
        confirmPasswordErr: 'Please confirm password'
      }));
      return;
    }
    if (changePassData.confirmPassword.length <= 7) {
      setChangePassData((changePassData) => ({
        ...changePassData,
        confirmPasswordErr: 'Password must be minimum  8 characters '
      }));
      return;
    }
    if (changePassData.confirmPassword != changePassData.newPassword) {
      setChangePassData((changePassData) => ({
        ...changePassData,
        confirmPasswordErr: 'Password not match '
      }));
      return;
    }
    let request = {
      oldPassword: changePassData?.oldPassword,
      newPassword: changePassData?.newPassword
    };
    dispatch(changePassword(request, setChangePassData, closeRef));
  };
  return (
    <>
      <div
        className="modal fade bd-example-modal-lg"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
          style={{ width: screenType == 'desktop' ? '40%' : '96%' }}>
          <div className={`modal-content py-2 ${screenType != 'desktop' ? '' : 'px-4'} `}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Change Password
              </h5>
              <button
                type="button"
                ref={closeRef}
                className="close"
                data-dismiss="modal"
                aria-label="Close"></button>
              <button
                type="button"
                className="smallBtn"
                style={{ width: 100 }}
                onClick={handleChangePassword}>
                Save
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <Input
                  onChange={(val) => {
                    setChangePassData((changePassData) => ({
                      ...changePassData,
                      oldPassword: val,
                      oldPasswordErr: ''
                    }));
                  }}
                  type={!changePassData?.showOldPass ? 'password' : 'text'}
                  endIcon={changePassData?.showOldPass ? 'formkit:eye' : 'humbleicons:eye-off'}
                  passwordClick={() =>
                    setChangePassData((changePassData) => ({
                      ...changePassData,
                      showOldPass: !changePassData?.showOldPass
                    }))
                  }
                  value={changePassData?.oldPassword}
                  formError={changePassData?.oldPasswordErr}
                  placeholder={'Old Password'}
                  icon={'circum:lock'}
                />
              </div>
              <div className="form-group">
                <Input
                  onChange={(val) => {
                    setChangePassData((changePassData) => ({
                      ...changePassData,
                      newPassword: val,
                      newPasswordErr: ''
                    }));
                  }}
                  type={!changePassData?.showPass ? 'password' : 'text'}
                  endIcon={changePassData?.showPass ? 'formkit:eye' : 'humbleicons:eye-off'}
                  passwordClick={() =>
                    setChangePassData((changePassData) => ({
                      ...changePassData,
                      showPass: !changePassData?.showPass
                    }))
                  }
                  value={changePassData?.newPassword}
                  formError={changePassData?.newPasswordErr}
                  placeholder={'New Password'}
                  icon={'circum:lock'}
                />
              </div>
              <div className="form-group  ">
                <Input
                  onChange={(val) => {
                    setChangePassData((changePassData) => ({
                      ...changePassData,
                      confirmPassword: val,
                      confirmPasswordErr: ''
                    }));
                  }}
                  formError={changePassData?.confirmPasswordErr}
                  value={changePassData?.confirmPassword}
                  type={!changePassData?.showConfirmPass ? 'password' : 'text'}
                  endIcon={changePassData?.showConfirmPass ? 'formkit:eye' : 'humbleicons:eye-off'}
                  passwordClick={() =>
                    setChangePassData((changePassData) => ({
                      ...changePassData,
                      showConfirmPass: !changePassData?.showConfirmPass
                    }))
                  }
                  placeholder={'Confirm New Password'}
                  icon={'circum:lock'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
