import React from 'react';
import { useResponsive } from '../../../themes/useResponsive';
import defaultImage from '../../../assets/img/noProfile.jpg';
import { checkdate } from 'utils/appConstant';

export default function ChatList(props) {
  const { screenType } = useResponsive();
  const { data, onClick } = props;

  return (
    <>
      <a className="list-group-item list-group-item-action border-0 px-2 py-2" onClick={onClick}>
        <div className="d-flex align-items-start">
          <div style={{ position: 'relative' }}>
            <img
              src={data?.userImageUrl ? data?.userImageUrl : defaultImage}
              className="rounded-circle mr-1"
              alt="Vanessa Tucker"
              width="55"
              height="55"
              style={{ objectFit: 'cover' }}
            />
            {data?.online ? (
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: '#6CD2C2',
                  borderRadius: '50%',
                  position: 'absolute',
                  bottom: '2px',
                  right: '7px'
                }}></div>
            ) : (
              ''
            )}
          </div>
          <div
            className="flex-grow-1 ml-2 "
            style={{
              color: '#132649',
              fontFamily: 'Circular Std Medium',
              fontSize: 24
            }}>
            <div className="d-flex justify-content-between">
              <div style={{ fontSize: '1.2rem' }}>{data?.fullName}</div>
              <div
                className="pt-2"
                style={{
                  fontSize: 13,
                  marginRight: screenType === 'MOBILE' ? '' : '-1rem',
                  color: '#a0aba7'
                }}>
                {data?.chatSendDateTime ? checkdate(Number(data?.chatSendDateTime)) : '-'}
              </div>
            </div>

            <div
              className="d-flex justify-content-between"
              style={{
                marginBottom: data?.unreadCount ? '-0.5rem' : '0.5rem',
                width: '100%'
              }}>
              <div
                className="small"
                style={{
                  fontFamily: 'Circular Std Book',
                  whiteSpace: 'nowrap',
                  width: '140px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                {data?.lastText}
              </div>
              <div>
                {data?.unreadCount ? (
                  <p
                    className="number-of-messages float-right"
                    style={{ marginRight: screenType === 'MOBILE' ? '' : '-1rem' }}>
                    {data?.unreadCount}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </a>
      <div className="border"></div>
    </>
  );
}
