import { createSlice } from '@reduxjs/toolkit';
import api from '../../../services';
import toast from 'react-hot-toast';
import { RENTEDWHEELSAPI } from '../../../services/apiConstant';
// import { act } from 'react-dom/test-utils';

const slice = createSlice({
  name: 'favorite',
  initialState: {
    favoriteCarsList: [],
    addtofavoriteCarsList: []
  },
  reducers: {
    getfavoriteListSuccess: (state, action) => {
      state.favoriteCarsList = action.payload;
    },
    updateFavListSuccess: (state, action) => {
      let newObjId = action.payload.carId;
      state.favoriteCarsList.data = state.favoriteCarsList?.data?.filter(
        (item) => item.id !== newObjId
      );
    }
  }
});
export default slice.reducer;
/**********************ACTIONS************************************************ */

const { getfavoriteListSuccess, updateFavListSuccess } = slice.actions;

export const getfavoriteList = (request, setIsLoading) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.favorite.getfavoriteList}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(getfavoriteListSuccess(result));
      } else {
        toast.error(result.message);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
    })
    .catch(() => {});
};
export const addtofavorite = (request, updateFav) => async () => {
  api
    .post(`${RENTEDWHEELSAPI.favorite.addtofavorite}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        updateFav(request);
        console.log('response == ', result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
  // console.log('request', request);
};

export const updateFavList = (request) => async (dispatch) => {
  dispatch(updateFavListSuccess(request));
};

// export const addtofavoriteList = (request, setIsloading) => async (dispatch) => {
//   console.log(token);
//   axios
//     .post(
//       `${process.env.REACT_APP_API_URL}${RENTEDWHEELSAPI.favorite.addtofavoriteList}`,
//       request,
//       {
//         headers: {
//           accept: 'application/json',
//           Authorization: `Bearer ${token} `,
//           'Content-Type': 'application/json'
//         }
//       }
//     )
//     .then((response) => {
//       let result = response.data;
//       console.log(result);
//       if (result.status == 1) {
//         dispatch(addtofavoriteListSuccess(result.data));
//         setIsloading(false);
//       } else {
//         toast.error(result.message);
//         setIsloading(false);
//       }
//     })
//     .catch(() => {});
// };
