import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { useResponsive } from '../../themes/useResponsive';
import ContactUs from './contactUs';
import FAQs from './fAQ';
import './style.css';
import TabBottomLine from '../../pages/owner/myCar/subComponent/tabBottomLine';
import { useLocation } from 'react-router-dom';

const HelpPage = () => {
  const [activeTab, setActiveTab] = useState('contact');
  const { screenType } = useResponsive();
  const location = useLocation();
  const faqRef = useRef();
  useEffect(() => {
    if (location.pathname === '/FAQs') {
      faqRef.current && faqRef.current.click();
    }
  }, [location]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        <Header color />
        <div
          className="HeadingHelp d-flex justify-content-center align-items-center"
          style={{
            fontSize: screenType === 'MOBILE' ? '2rem' : '',
            height: '50%',
            background: 'linear-gradient(to right, #ffe0b0a6, #87cead)'
          }}>
          Help
        </div>
        <ul
          className="nav nav-pills"
          id="pills-tab"
          role="tablist"
          onClick={(event) => {
            let value = event.target.id;
            let id = value.split('-')[1];
            setActiveTab(id);
          }}
          style={{ width: '100vw !important' }}>
          <li
            className="nav-item"
            role="presentation"
            // style={{ width: screenType === 'MOBILE' ? '50%' : '30%' }}
            style={{ width: '50%' }}>
            <button
              id="pills-contact-tab"
              data-bs-toggle="pill"
              data-bs-target="#contact"
              className="tab-button active">
              Contact Us
              {activeTab === 'contact' && <TabBottomLine />}
            </button>
          </li>
          <li className="nav-item" role="presentation" style={{ width: '50%' }}>
            <button
              ref={faqRef}
              id="pills-FAQs-tab"
              data-bs-toggle="pill"
              data-bs-target="#FAQs"
              className="tab-button">
              FAQs
              {activeTab === 'FAQs' && <TabBottomLine />}
            </button>
          </li>
        </ul>
        <div className="container">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab">
              <ContactUs />
            </div>
            <div
              className="tab-pane fade"
              id="FAQs"
              role="tabpanel"
              aria-labelledby="pills-FAQs-tab">
              <FAQs />
            </div>
          </div>
        </div>
      </div>

      <br></br>
      <hr />

      <Footer />
    </>
  );
};

export default HelpPage;
