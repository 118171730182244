import React, { useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DestinationImageBox from './destinationImageBox';
import { useResponsive } from '../../../../themes/useResponsive';
import SliderButton from '../../../../components/sliderButton';
import { useState } from 'react';
import { destinationsList } from '../../../../store/slice/landingPage';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import image from '../../../../assets/img/Phorche.png';
export default function Destination() {
  const { destinationCity } = useSelector((state) => state.landingPage);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const { screenType } = useResponsive();
  const dispatch = useDispatch();
  // const [searchData, setSearchData] = useState();
  useEffect(() => {
    fetchCityList();
    // const data = secureLocalStorage.getItem('searchData');
    // setSearchData(JSON.parse(data));
  }, []);

  // useEffect(() => {
  //   if (searchData) {
  //     setFormData((formData) => ({
  //       ...formData,
  //       ...searchData,
  //       cityId: ''
  //     }));
  //   }
  // }, [searchData]);

  useEffect(() => {
    if (formData?.cityId) {
      secureLocalStorage.setItem('searchData', JSON.stringify(formData));
      navigate('/smart-search');
    }
  }, [formData]);

  const fetchCityList = () => {
    let request = {
      language: 'en'
      // blogId: id
    };
    dispatch(destinationsList(request));
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
      slidesToSlide: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    }
  };
  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide }
    } = rest;
    return (
      <div className="carousel-button-group">
        <SliderButton previous={() => previous()} next={() => next()} index={currentSlide} />
      </div>
    );
  };
  return (
    <div>
      <Carousel
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        autoPlay={false}
        arrows={false}
        infinite={true}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px">
        {destinationCity?.map((item, index) => {
          return (
            <DestinationImageBox
              onClick={() => {
                setFormData((formData) => ({
                  ...formData,
                  cityId: item.id
                }));
                // dispatch(setSearchData(formData, navigate));
              }}
              key={index}
              image={item?.cityImagePath ? item?.cityImagePath : image}
              text={item?.cityName}
              borderRadius={'10px'}
              screenType={screenType}
            />
          );
        })}
      </Carousel>
    </div>
  );
}
