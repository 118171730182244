import React from 'react';
export default function SwiperImageBox(props) {
  const { image, text, screenType, onClick } = props;
  return (
    <div
      className="bg-image-container"
      onClick={onClick}
      style={{
        backgroundImage: `url("${image}")`,
        backgroundSize: 'cover',
        borderRadius: 25,
        height: screenType === 'MOBILE' ? 290 : screenType === 'TABLET' ? 250 : 300,
        width: screenType === 'MOBILE' ? '97%' : '93%'
      }}>
      <div className="gradient">
        <div className="text-container">
          <div className="box-text">{text}</div>
        </div>
      </div>
    </div>
  );
}
