import React, { useContext } from 'react';
import { useResponsive } from '../../../../../themes/useResponsive';
import Input from '../../../../../components/input';
import MyCarContext from '../../../../../components/context/myCarContext';
import Button from '../../../../../components/button';

export default function MileageCap() {
  const { screenType } = useResponsive();
  const { myCarData, setMyCarData, featuresRef } = useContext(MyCarContext);
  const handleNext = () => {
    if (!myCarData?.mileageCapPerHour?.km) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        mileageCapPerHourKmErr: 'Please enter per hour km'
      }));
      return;
    }
    if (!myCarData?.mileageCapPerHour?.amount) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        mileageCapPerHourAmountErr: 'Please enter per hour amount'
      }));
      return;
    }
    if (!myCarData?.mileageCapPerDay?.km) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        mileageCapPerDayKmErr: 'Please enter per day km'
      }));
      return;
    }
    if (!myCarData?.mileageCapPerDay?.amount) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        mileageCapPerDayAmountErr: 'Please enter per day amount'
      }));
      return;
    }
    if (!myCarData?.mileageCapPerWeek?.km) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        mileageCapPerWeekKmErr: 'Please enter per week km'
      }));
      return;
    }
    if (!myCarData?.mileageCapPerWeek?.amount) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        mileageCapPerWeekAmountErr: 'Please enter per week amount'
      }));
      return;
    }
    if (!myCarData?.deliveryPickupCharge) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        deliveryPickupChargeErr: 'Please enter delevery and pickup charges'
      }));
      return;
    }

    featuresRef.current && featuresRef.current.click();
  };
  return (
    <div className="py-4 ">
      <div className="row mt-2">
        <div className={screenType === 'desktop' ? 'col-4 ' : 'col-12 '}>
          <div className="myCar-label">Per Hours</div>
          <div className="form-group pt-3">
            <Input
              type="number"
              onChange={(val) => {
                setMyCarData((myCarData) => ({
                  ...myCarData,
                  mileageCapPerHour: {
                    km: val,
                    amount: myCarData?.mileageCapPerHour?.amount
                  },
                  mileageCapPerHourKmErr: ''
                }));
              }}
              value={myCarData?.mileageCapPerHour?.km}
              formError={myCarData?.mileageCapPerHourKmErr}
              placeholder={'Enter per hours'}
              endName={
                <div className="circularBold" style={{ color: '#6CD2C2', fontSize: 20 }}>
                  KM
                </div>
              }
            />
            <Input
              type="number"
              onChange={(val) => {
                setMyCarData((myCarData) => ({
                  ...myCarData,
                  mileageCapPerHour: {
                    km: myCarData?.mileageCapPerHour?.km,
                    amount: val
                  },
                  mileageCapPerHourAmountErr: ''
                }));
              }}
              value={myCarData?.mileageCapPerHour?.amount}
              formError={myCarData?.mileageCapPerHourAmountErr}
              placeholder={'Enter per hours'}
              endName={
                <div className="circularBold" style={{ color: '#6CD2C2', fontSize: 20 }}>
                  N$
                </div>
              }
            />
          </div>
        </div>
        <div className={screenType === 'desktop' ? 'col-4 ' : 'col-12 pt-3'}>
          <div className="myCar-label">Per Day</div>
          <div className="form-group pt-3">
            <Input
              type="number"
              onChange={(val) => {
                setMyCarData((myCarData) => ({
                  ...myCarData,
                  mileageCapPerDay: {
                    km: val,
                    amount: myCarData?.mileageCapPerDay?.amount
                  },
                  mileageCapPerDayKmErr: ''
                }));
              }}
              value={myCarData?.mileageCapPerDay?.km}
              formError={myCarData?.mileageCapPerDayKmErr}
              placeholder={'Enter per day'}
              endName={
                <div className="circularBold" style={{ color: '#6CD2C2', fontSize: 20 }}>
                  KM
                </div>
              }
            />
            <Input
              type="number"
              onChange={(val) => {
                setMyCarData((myCarData) => ({
                  ...myCarData,
                  mileageCapPerDay: {
                    km: myCarData?.mileageCapPerDay?.km,
                    amount: val
                  },
                  mileageCapPerDayAmountErr: ''
                }));
              }}
              value={myCarData?.mileageCapPerDay?.amount}
              formError={myCarData?.mileageCapPerDayAmountErr}
              placeholder={'Enter per day'}
              endName={
                <div className="circularBold" style={{ color: '#6CD2C2', fontSize: 20 }}>
                  N$
                </div>
              }
            />
          </div>
        </div>
        <div className={screenType === 'desktop' ? 'col-4 ' : 'col-12 pt-3'}>
          <div className="myCar-label">Per Week</div>
          <div className="form-group pt-3">
            <Input
              type="number"
              onChange={(val) => {
                setMyCarData((myCarData) => ({
                  ...myCarData,
                  mileageCapPerWeek: {
                    km: val,
                    amount: myCarData?.mileageCapPerWeek?.amount
                  },
                  mileageCapPerWeekKmErr: ''
                }));
              }}
              value={myCarData?.mileageCapPerWeek?.km}
              formError={myCarData?.mileageCapPerWeekKmErr}
              endName={
                <div className="circularBold" style={{ color: '#6CD2C2', fontSize: 20 }}>
                  KM
                </div>
              }
              placeholder={'Enter  per week'}
            />
            <Input
              type="number"
              onChange={(val) => {
                setMyCarData((myCarData) => ({
                  ...myCarData,
                  mileageCapPerWeek: {
                    km: myCarData?.mileageCapPerWeek?.km,
                    amount: val
                  },
                  mileageCapPerWeekAmountErr: ''
                }));
              }}
              value={myCarData?.mileageCapPerWeek?.amount}
              formError={myCarData?.mileageCapPerWeekAmountErr}
              placeholder={'Enter per week'}
              endName={
                <div className="circularBold" style={{ color: '#6CD2C2', fontSize: 20 }}>
                  N$
                </div>
              }
            />
          </div>
        </div>
        <div
          style={{ marginBottom: screenType === 'desktop' ? '' : '4rem' }}
          className={screenType === 'desktop' ? 'col-4 pt-3' : 'col-12 pt-3'}>
          <div className="myCar-label">Delivery & Pickup Charge</div>
          <div className="form-group pt-3">
            <Input
              type="number"
              onChange={(val) => {
                setMyCarData((myCarData) => ({
                  ...myCarData,
                  deliveryPickupCharge: val,
                  deliveryPickupChargeErr: ''
                }));
              }}
              value={myCarData?.deliveryPickupCharge}
              formError={myCarData?.deliveryPickupChargeErr}
              placeholder={'Enter delivery & pickup charge'}
              endName={
                <div className="circularBold" style={{ color: '#6CD2C2', fontSize: 20 }}>
                  N$
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div
        className="position-absolute"
        style={{
          bottom: screenType != 'MOBILE' ? 0 : -10,
          right: screenType != 'MOBILE' ? 15 : 0,
          left: screenType != 'MOBILE' ? '' : 0
        }}>
        <Button onClick={handleNext} title="Next" />
      </div>
    </div>
  );
}
