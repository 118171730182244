import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ShareSocial } from 'react-share-social';
// import toast from 'react-hot-toast';

const ShareDialog = ({ ...props }) => {
  const { showDialog, setShowDialog, url } = props;
  const handleClose = () => {
    setShowDialog(false);
  };
  return (
    <>
      <Modal show={showDialog} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLongTitle">
              Share
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ShareSocial
            url={url}
            socialTypes={['facebook', 'twitter', 'reddit', 'linkedin', 'whatsapp']}
            onSocialButtonClicked={(data) => {
              // toast.success('dd');
              navigator.clipboard.writeText(data);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareDialog;
