import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import logo from '../../assets/img/authLogo.png';
import { Icon } from '@iconify/react';
import { useResponsive } from '../../themes/useResponsive';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
// import { setSearchData } from 'store/slice/landingPage';
export default function Footer() {
  const { screenType } = useResponsive();
  const { destinationCity } = useSelector((state) => state.landingPage);
  const [formData, setFormData] = useState({});
  // const [searchData, setSearchData] = useState();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  useEffect(() => {
    // const data = secureLocalStorage.getItem('searchData');
    // setSearchData(JSON.parse(data));
  }, []);

  // useEffect(() => {
  //   if (searchData) {
  //     setFormData((formData) => ({
  //       ...formData,
  //       ...searchData,
  //       cityId: ''
  //     }));
  //   }
  // }, [searchData]);

  useEffect(() => {
    if (formData?.cityId) {
      secureLocalStorage.setItem('searchData', JSON.stringify(formData));
      navigate('/smart-search');
    }
  }, [formData]);

  return (
    <>
      <div className="container" style={{ height: 'fit-content' }}>
        <Container>
          <Row className="py-5 ">
            <Col xs={12} sm={6} md={3} lg={3}>
              <img src={logo} alt="logo" height={60} width={219.52} />
              <p className="pt-3">
                <small
                  className="text-muted"
                  style={{ fontFamily: 'Circular Std Book', fontSize: 16 }}>
                  Connecting Namibia through community-driven car sharing. Real cars, real people,
                  real adventures.
                </small>
              </p>
            </Col>
            <Col xs={12} sm={6} md={3} lg={3}>
              <div
                className="text-left pt-3"
                style={{ marginLeft: screenType != 'MOBILE' ? '4rem' : '' }}>
                <h5 className="pb-3 footer-head ">Location</h5>
                {destinationCity?.map((item, index) => (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setFormData((formData) => ({
                        ...formData,
                        cityId: item.id
                      }));
                      // dispatch(setSearchData(formData, navigate));
                    }}
                    key={index}
                    className="py-2 footer-subHead">
                    {item?.cityName}
                  </div>
                ))}
              </div>
            </Col>
            <Col xs={12} sm={6} md={3} lg={3}>
              <div
                className="text-left pt-3"
                style={{ marginLeft: screenType === 'TABLET' ? '2rem' : '' }}>
                <h5 className="pb-3 footer-head">Explore</h5>
                <div className="py-2 footer-subHead">
                  <Link
                    to="/car-blog"
                    className="text-decoration-none"
                    style={{ color: '#212529' }}>
                    Blog
                  </Link>
                </div>
                <div className="py-2 footer-subHead">
                  <Link to="/FAQs" className="text-decoration-none" style={{ color: '#212529' }}>
                    FAQs
                  </Link>
                </div>
                <div className="py-2 footer-subHead">
                  <Link to="/help" className="text-decoration-none" style={{ color: '#212529' }}>
                    Help
                  </Link>
                </div>
                <div className="py-2 footer-subHead">
                  <Link
                    to="/cancellation-policy"
                    className="text-decoration-none"
                    style={{ color: '#212529' }}>
                    Cancellation Policy
                  </Link>
                </div>
                <div className="py-2 footer-subHead">
                  <Link
                    to="/privacy-policy"
                    className="text-decoration-none"
                    style={{ color: '#212529' }}>
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={3} lg={3}>
              <div className="text-left pt-3">
                <h5 className="pb-3 footer-head">Social links</h5>
                <a className="btn-social mx-2" href="#!" aria-label="Larry Parker Twitter Profile">
                  <Icon icon={'logos:facebook'} height={'35'} />
                </a>
                <a className="btn-social mx-2" href="#!" aria-label="Larry Parker Facebook Profile">
                  <Icon icon={'logos:twitter'} height={'35'} />
                </a>
                <a className="btn-social mx-2" href="#!" aria-label="Larry Parker Facebook Profile">
                  <Icon icon={'skill-icons:instagram'} height={'35'} />
                </a>
                <a className="btn-social mx-2" href="#!" aria-label="Larry Parker Facebook Profile">
                  <Icon icon={'logos:youtube-icon'} height={'35'} />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        className="container-fluid text-center text-muted py-3 "
        style={{ background: '#E5E5E5', fontFamily: 'Circular Std Book', fontSize: 18 }}>
        ©2024 RentedWheels, Inc. All rights reserved.{' '}
      </div>
    </>
  );
}
