import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import secureLocalStorage from 'react-secure-storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBkNyAVjJ7IlSZyakBO8gFkpO1Ip-a_74M',
  authDomain: 'rentedwheels.firebaseapp.com',
  projectId: 'rentedwheels',
  storageBucket: 'rentedwheels.appspot.com',
  messagingSenderId: '138537029442',
  appId: '1:138537029442:web:662d00ffad85ca5e38c2db',
  measurementId: 'G-RKRHED8PWN'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Analytics service
export const analytics = getAnalytics(app);
// Messaging service
export const messaging = getMessaging(app);

export const requestForToken = async () => {
  try {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: `BI-JiI0hUECOWOOL-k22Lg_pq86C7KB0vMvlPblabYBAYCQn5JpNFHdSNxKKtHTSTl1QrodUnstVPDP4Nays2Jo`
      });
      console.log('Token generated : ', token);
      secureLocalStorage.setItem('FCM_TOKEN_KEY', token);
      // Cookies.set(REACT_KEY.FCM_TOKEN_KEY, token);
    } else if (permission === 'denied') {
      //notifications are blocked
      console.log('Please allow notification persmission to have updates to your app.');
    } else {
      console.log('Please allow notification persmission to have updates to your app.');
    }
  } catch (err) {
    console.log(err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('payload', messaging);
      resolve(payload);
    });
  });
