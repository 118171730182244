import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { checkdate } from 'utils/appConstant';
import moment from 'moment/moment';
import { notificationConst } from 'utils/appEnums';
import { getbookingDetails } from 'store/slice/carUser/carBooking';
import { getnotificationListing, readNotification } from 'store/slice/carUser/notification';
import RatingsAndReviews from 'components/dialog/ ratingsAndReviews';
import { useResponsive } from 'themes/useResponsive';
import ColoredButton from 'components/button/colorFullButtons';
import Spinner from 'react-bootstrap/Spinner';
import { colors } from 'themes/color';
import { loginData } from 'components/constant';
import { Icon } from '@iconify/react';
import PopOverSpinner from 'components/popOverSpinner';

const NotificationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const { screenType } = useResponsive();
  const { NotificationsList } = useSelector((state) => state.notification);
  const [isListLoading, setIsListloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAleartDialog, setShowAleartDialog] = useState({});
  useEffect(() => {
    getAllNotifications();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    setIsListloading(true);
  }, []);

  const getAllNotifications = (perPage) => {
    setIsLoading(true);
    const data = {
      page: 1,
      limit: perPage ? perPage : limit,
      language: 'en'
    };
    dispatch(getnotificationListing(data, setIsLoading, setIsListloading));
  };
  console.log(NotificationsList, 'getnotificationListing');

  const handleNavigate = (data) => {
    const index = notificationConst.findIndex((x) => x.id === data?.notificationType);
    if (data?.notificationType == 7) {
      const bookingData = {
        language: 'en',
        bookingId: data.bookingId
      };
      dispatch(getbookingDetails(bookingData));
      setShowAleartDialog((showAleartDialog) => ({
        ...showAleartDialog,
        show: true
      }));
    }

    if (index > -1) {
      notificationConst[index].navigateUrl
        ? navigate(`${notificationConst[index].navigateUrl}/${data.bookingId}`)
        : '';
    }
  };
  const handleReadNotification = (id) => {
    let request = {
      notificationId: id ? id : '',
      notiType: id ? 2 : 1
    };
    dispatch(readNotification(request, setIsLoading));
  };
  return (
    <>
      {isListLoading ? <PopOverSpinner /> : ''}

      <div>
        {NotificationsList?.data?.length >= 1 ? (
          <>
            <div className="d-flex justify-content-end" style={{ marginTop: '-1.8rem' }}>
              <ColoredButton
                onClick={() => handleReadNotification()}
                title={'Read All'}
                height="35px"
                style={{ paddingLeft: '2rem', paddingRight: '2rem', background: '#6CD2C2' }}
              />
            </div>
            {NotificationsList?.data?.map((item, index) => {
              return (
                <>
                  <div
                    key={index}
                    onClick={() => {
                      if (!item?.isRead) {
                        handleReadNotification(item?.id);
                      }
                      if (loginData?.userType != 2) {
                        handleNavigate(item);
                      }
                    }}>
                    <div id="task-item-1" style={{ marginBottom: '-1.8rem' }}>
                      <div className="card task-box " style={{ border: 'none' }}>
                        <div className="card-body ">
                          <div className="row align-items-center">
                            <div className="col-xl-10 col-sm-8  ">
                              <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <h3 className="headingNotification">{item.title}</h3>
                                  {screenType === 'MOBILE' && !item?.isRead && (
                                    <div>
                                      <Icon icon="oui:dot" color="#6CD2C2" />
                                    </div>
                                  )}
                                </div>
                                <p className="paragraph-notificaton">
                                  Booking ID: {item?.orderId}
                                  <br />
                                  {item?.message}
                                </p>
                                {(item.notificationType == 5 && loginData?.userType != 2) ||
                                item?.notificationType == 7 ? (
                                  <ColoredButton
                                    title={item?.notificationType == 7 ? 'Rate Now' : 'Pay Now'}
                                    onClick={() => handleNavigate(item)}
                                    className={`fs-6 pay-now`}
                                    style={{
                                      backgroundColor: colors.bgbutton,
                                      color: '#F1AE01 !important ',
                                      maxWidth: 110
                                    }}
                                  />
                                ) : null}
                              </div>
                            </div>
                            <div className="col-xl-2 col-sm-4 text-right">
                              {screenType != 'MOBILE' && !item?.isRead && (
                                <div style={{ marginBottom: '1.5rem' }}>
                                  <Icon icon="oui:dot" color="#6CD2C2" />
                                </div>
                              )}
                              <div
                                className="paragraph-notificaton "
                                style={{
                                  marginTop: screenType === 'MOBILE' ? '0.2rem' : ''
                                }}>
                                {checkdate(Number(item?.createDateTime))} at{' '}
                                {moment(item?.createDateTime).format('hh:mm A')}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            <div className="d-flex justify-content-center mt-5">
              {isLoading && !isListLoading ? (
                <Spinner animation="border" style={{ color: '#6CD2C2' }} />
              ) : (
                NotificationsList?.totalCount > limit && (
                  <ColoredButton
                    onClick={() => {
                      let perPage = limit + 10;
                      setLimit(perPage);
                      getAllNotifications(perPage);
                    }}
                    title="View More"
                    style={{ background: '#6CD2C2', width: '130px' }}
                  />
                )
              )}
            </div>
          </>
        ) : (
          <div className="noData" style={{ height: '80vh' }}>
            No Data Found
          </div>
        )}

        <RatingsAndReviews
          showDialog={showAleartDialog.show}
          onDialogClose={() => {
            setShowAleartDialog((showAleartDialog) => ({
              ...showAleartDialog,
              show: false
            }));
          }}
        />
      </div>
    </>
  );
};

export default NotificationList;
