import React, { useEffect } from 'react';
import { useResponsive } from '../../../../themes/useResponsive';
import ColoredButton from '../../../../components/button/colorFullButtons';
import OwnerHeader from '../../../../layouts/carOwner/header';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { createPersonalChatRoomId } from 'store/slice/carOwner/message';
import { useNavigate } from 'react-router-dom';
import { bookingDetail } from 'store/slice/carOwner/bookingStatus';
import { useParams } from 'react-router-dom';
import noImage from 'assets/img/no_image.jpg';

const History = () => {
  const { screenType } = useResponsive();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bookingData } = useSelector((state) => state.bookingsStatus);
  const { id } = useParams();
  useEffect(() => {
    fetchbookingDetailData();
  }, []);
  const fetchbookingDetailData = () => {
    let request = {
      language: 'en',
      bookingId: id
    };
    dispatch(bookingDetail(request));
  };
  const handleCreateChat = () => {
    const data = {
      recieverUserId: bookingData?.userData?.userId,
      language: 'en'
    };
    dispatch(createPersonalChatRoomId(data, navigate));
  };
  console.log('bookingData', bookingData);
  return (
    <>
      <div>
        <OwnerHeader
          title={'History'}
          previousPage={'My Booking'}
          currentPage={'History'}
          previousPageUrl={'/car-owner/mybooking'}
        />

        <div className="row mt-4">
          <div className="col-lg-4  gy-3">
            <div className={`card ${screenType != 'desktop' ? 'h-100' : 'h-75'}`}>
              <div className="card-body d-flex flex-column align-items-center">
                <div className="mb-3 ">
                  <img
                    src={bookingData?.userData?.profileImage}
                    className="rounded-circle"
                    alt="Profile"
                    style={{ width: '130px', height: '130px' }}
                  />
                </div>
                <h5 className="card-title pt-1" style={{ fontFamily: 'Circular Std Bold' }}>
                  {bookingData?.userData?.userName}
                </h5>
                <h3
                  className="card-title mb-4"
                  style={{ fontFamily: 'Circular Std Book', color: '#8892A3' }}>
                  +{bookingData?.userData?.countryCode} {bookingData?.userData?.phoneNumber}
                </h3>
                <ColoredButton
                  title={'Message'}
                  style={{
                    backgroundColor: '#6CD2C2'
                  }}
                  onClick={handleCreateChat}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 gy-3">
            <div className={`card ${screenType != 'desktop' ? 'h-100' : 'h-75'}`}>
              <div className="card-body ">
                <img
                  className="card-img-top"
                  src={
                    bookingData?.productData?.carImagesUrl?.length >= 1
                      ? bookingData?.productData?.carImagesUrl[0]
                      : noImage
                  }
                  alt="Card image cap"
                  height={'180px'}
                  style={{ objectFit: 'cover', borderRadius: 15 }}
                />
                <center>
                  <h5
                    className="pt-3"
                    style={{
                      color: '#132649',
                      fontFamily: 'Circular Std Medium',
                      paddingTop: '0.7rem'
                    }}>
                    {bookingData?.productData?.modelName}
                  </h5>
                  <div
                    className="card-body d-flex flex-column align-items-center"
                    style={{ marginBottom: '1.5rem' }}>
                    <ColoredButton
                      title={
                        <>
                          <span
                            style={{
                              color: '#FFBB51',
                              fontSize: 16,
                              fontFamily: 'Circular Std Book'
                            }}>
                            N$
                          </span>
                          <span style={{ color: '#132649' }}>{bookingData?.totalAmount} </span>
                          <span
                            style={{
                              color: '#8892A3',
                              fontSize: 15,
                              fontFamily: 'Circular Std Book'
                            }}>
                            /{' '}
                            {bookingData?.bookingType === 'perDay'
                              ? 'Per Day'
                              : bookingData?.bookingType === 'perHour'
                                ? 'Per Hour'
                                : 'Per Week'}
                          </span>
                        </>
                      }
                      style={{
                        backgroundColor: '#e7a43c4a'
                      }}
                    />
                  </div>
                </center>
              </div>
            </div>
          </div>
          <div className="col-lg-4 gy-3">
            <div className={`card ${screenType != 'desktop' ? 'h-100' : 'h-75'}`}>
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ fontFamily: 'Circular Std Bold', color: '#132649', fontSize: 28 }}>
                  Booking
                </h5>
                <div className="row pt-4 pl-2">
                  <div className="col-6">
                    <p className="card-List-Left">Fuel</p>
                    <p className="card-List-Left">Booking Date</p>
                    <p className="card-List-Left">End Date</p>
                    <p className="card-List-Left">Booking ID</p>
                    <p className="card-List-Left">Refundable Deposit</p>
                  </div>
                  <div className="col-6">
                    <p className="card-List-Right">
                      {bookingData?.fuelType?.type === 'withfuel' ? 'With Fuel' : 'Without Fuel'}
                    </p>
                    <p className="card-List-Right">
                      {moment(bookingData?.bookingDate?.from).format('Do MMM, YYYY')}
                    </p>
                    <p className="card-List-Right">
                      {moment(bookingData?.bookingDate?.to).format('Do MMM, YYYY')}
                    </p>
                    <p className="card-List-Right">#{bookingData?.orderId}</p>
                    <p className="card-List-Right">
                      {bookingData?.refundableAmount ? bookingData?.refundableAmount : ' N$2000'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default History;
