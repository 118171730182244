import React, { useEffect, useState } from 'react';
import Header from '../../../components/header';
import { Link, useParams } from 'react-router-dom';
import { useResponsive } from '../../../themes/useResponsive';
import './style.css';
import { getCarDetails } from '../../../store/slice/carOwner/review';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import Details from './subcomponents/details';
import MyCarContext from 'components/context/myCarContext';
import BookingRates from './subcomponents/bookingRates';
import InsuranceAndLocation from './subcomponents/insuranceAndLocation';
import CustomerReviews from './subcomponents/CustomerReviews';
import Continue from './subcomponents/Continue';
import Booking from './subcomponents/booking';
import { clearCalculatedAmount } from 'store/slice/carUser/carBooking';
import { Icon } from '@iconify/react';
import PopOverSpinner from 'components/popOverSpinner';
import { loginData } from 'components/constant';

export default function CarDetails() {
  const { screenType } = useResponsive();
  const { carDetails } = useSelector((state) => state.review);
  const [isLoading, setIsloading] = useState(false);
  const { calculatedAmount } = useSelector((state) => state.booking);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [bookingTab, setBookingTab] = useState(false);
  const [formData, setFormData] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      fetchCarDetail();
    }
  }, [id]);
  const fetchCarDetail = () => {
    let request = {
      userId: loginData?.id,
      carId: id,
      language: 'en'
    };
    dispatch(getCarDetails(request, setIsloading));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setIsloading(true);
    dispatch(clearCalculatedAmount());
  }, []);
  return (
    <>
      <Helmet>
        <title>Car-Details</title>
      </Helmet>
      {isLoading ? <PopOverSpinner /> : ''}
      <div className="w-100" style={{ background: '#e5e5e5', height: 'auto' }}>
        {fullScreenImage ? (
          <div
            style={{
              height: '100vh',
              width: '100%',
              backgroundColor: 'black',
              position: 'relative'
            }}>
            <div
              style={{
                width: 'auto',
                height: '100%',
                justifyContent: 'center',
                display: 'flex',
                backgroundImage: `url(${carDetails?.insurancePolicyUrl})`,
                position: 'relative',
                overflow: 'hidden',
                transform: 'matrix(1, 0, 0, 1, 0, 0)',
                backgroundColor: 'black',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}>
              <div
                onClick={() => setFullScreenImage(false)}
                style={{
                  height: 30,
                  width: 30,
                  borderRadius: '50%',
                  background: 'black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: 20,
                  right: 50
                }}>
                <Icon icon="ri:close-line" alt="" color="white" height={30} />
              </div>
            </div>
          </div>
        ) : (
          <>
            <Header background color />
            <div className="container bg-white p-4" style={{ marginTop: '4.5rem' }}>
              <div
                style={{
                  fontFamily: 'Circular Std Medium',
                  cursor: 'pointer'
                }}>
                <Link to={'/'} style={{ color: '#63575778', textDecoration: 'none' }}>
                  Home
                </Link>
                &nbsp;/&nbsp;
                <span
                  style={{ color: bookingTab ? '#63575778' : '' }}
                  onClick={() => {
                    setFormData({});
                    setBookingTab(false);
                    dispatch(clearCalculatedAmount());
                  }}>
                  Car details
                </span>
                {bookingTab ? (
                  <>
                    &nbsp;/&nbsp; <span>Car booking</span>
                  </>
                ) : (
                  ''
                )}
              </div>
              <MyCarContext.Provider
                value={{
                  carDetails,
                  formData,
                  setFormData,
                  setBookingTab,
                  calculatedAmount,
                  setFullScreenImage
                }}>
                {bookingTab ? (
                  <Booking />
                ) : (
                  <div className="pt-4">
                    <Details />
                    <div className="row gx-5 mt-5">
                      <BookingRates />
                      <div
                        className="col-lg-5 col-md-12 col-sm-12"
                        style={{ marginLeft: screenType === 'desktop' ? '-2rem' : '' }}>
                        <InsuranceAndLocation />
                        <CustomerReviews />
                      </div>
                    </div>
                    <div className="mt-5">
                      <Continue />
                    </div>
                  </div>
                )}
              </MyCarContext.Provider>
            </div>
          </>
        )}
      </div>
    </>
  );
}
