import React from 'react';
import './index.css';
import { Icon } from '@iconify/react';
export default function Input(props) {
  const {
    icon,
    placeholder,
    type,
    onChange,
    value,
    formError,
    endIcon,
    passwordClick,
    onBlur,
    endName,
    disabled,
    minVal,
    name
  } = props;
  return (
    <>
      <div className="input-group mb-3">
        {icon && (
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              <Icon icon={icon} height={30} color="#757c83" />
            </span>
          </div>
        )}
        <input
          disabled={disabled}
          style={{ paddingTop: '0.9rem' }}
          type={type ? type : 'text'}
          onChange={(val) => onChange(val.target.value)}
          name={name}
          className="form-control"
          onBlur={onBlur}
          placeholder={placeholder}
          value={value}
          aria-label="Username"
          min={minVal}
          aria-describedby="basic-addon1"
        />
        {endName && (
          <div className="input-group-append">
            <span className="input-group-text">{endName}</span>
          </div>
        )}
        {endIcon && (
          <div className="input-group-append">
            <span className="input-group-text">
              <Icon
                onClick={passwordClick}
                icon={endIcon}
                height={30}
                color="#757c83"
                style={{ cursor: 'pointer' }}
              />
            </span>
          </div>
        )}
      </div>
      <div
        style={{
          color: 'red',
          fontSize: 13,
          marginTop: '-0.5rem',
          paddingLeft: 5
        }}
        className="text-error">
        {formError && formError}
      </div>
    </>
  );
}
