import { createSlice } from '@reduxjs/toolkit';
import { RENTEDWHEELSAPI } from '../../../services/apiConstant';
import api from '../../../services';
import toast from 'react-hot-toast';

// import axios from 'axios';

const slice = createSlice({
  name: 'bookingsStatus',
  initialState: {
    allBookingsList: [],
    totalCount: [],
    completeBookings: [],
    bookingData: [],
    paymentData: [],
    recentBookingListData: [],
    bookingTotalCount: null,
    isStatusLoading: false
  },
  reducers: {
    getAllBookingListSuccess: (state, action) => {
      state.allBookingsList = action.payload.data;
      state.bookingTotalCount = action.payload.totalCount;
    },
    totalCountsListSuccess: (state, action) => {
      state.totalCount = action.payload;
    },
    completedBookingListSuccess: (state, action) => {
      state.completeBookings = action.payload;
    },
    acceptRejectAndCompeleteBookingSuccess: (state, action) => {
      let reqData = action.payload;
      state.allBookingsList = state.allBookingsList.filter((item) => item.id !== reqData);
      state.totalCount.pendingBookingCount = state.totalCount.pendingBookingCount - 1;
      state.isStatusLoading = false;
    },
    bookingDetailSuccess: (state, action) => {
      state.bookingData = action.payload;
    },
    paymentHistorySuccess: (state, action) => {
      state.paymentData = action.payload;
    },
    recentBookingListSuccess: (state, action) => {
      state.recentBookingListData = action.payload;
    },
    updateFavBookingListSuccess: (state, action) => {
      const index = state.allBookingsList.findIndex(
        (x) => x.productData.id === action.payload.carId
      );
      state.allBookingsList[index].isFavorite = action.payload.status;
    },
    isStatusLoadingSuccess: (state, action) => {
      state.isStatusLoading = action.payload;
    }
  }
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

const {
  getAllBookingListSuccess,
  totalCountsListSuccess,
  completedBookingListSuccess,
  acceptRejectAndCompeleteBookingSuccess,
  bookingDetailSuccess,
  paymentHistorySuccess,
  recentBookingListSuccess,
  updateFavBookingListSuccess,
  isStatusLoadingSuccess
} = slice.actions;

export const myBookingStatus =
  (request, setIsLoading, setIsListloading, setIsBookingLoading) => async (dispatch) => {
    api
      .post(`${RENTEDWHEELSAPI.bookingsStatus.myBookingStatus}`, request)
      .then((response) => {
        let result = response.data;
        if (result.status == 1) {
          dispatch(getAllBookingListSuccess(result));
        } else {
          toast.error(result.message);
        }
        if (setIsLoading) {
          setIsLoading(false);
        }
        setIsListloading(false);
        setIsBookingLoading(false);
      })
      .catch(() => {});
  };
export const totalCounts = (request) => async (dispatch) => {
  api
    .get(`${RENTEDWHEELSAPI.bookingsStatus.totalCounts}`, request)
    .then((response) => {
      let result = response.data;

      if (result.status == 1) {
        dispatch(totalCountsListSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const completedBooking = (request) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.bookingsStatus.completedBooking}`, request)
    .then((response) => {
      let result = response.data;

      if (result.status == 1) {
        dispatch(completedBookingListSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const acceptRejectAndCompeleteBooking = (request, navigate) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.bookingsStatus.acceptRejectAndCompeleteBooking}`, request)
    .then((response) => {
      let result = response.data;

      if (result.status == 1) {
        if (navigate) {
          navigate('/car-owner/mybooking');
        } else {
          dispatch(acceptRejectAndCompeleteBookingSuccess(request.bookingId));
        }
      } else {
        toast.error(result.message);
      }
      dispatch(isStatusLoadingSuccess(false));
    })
    .catch(() => {});
};

export const setIsStatusLoading = (request) => async (dispatch) => {
  dispatch(isStatusLoadingSuccess(request));
};

export const bookingDetail = (request, setIsListloading) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.bookingsStatus.bookingDetail}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(bookingDetailSuccess(result.data));
      } else {
        toast.error(result.message);
      }
      setIsListloading(false);
    })
    .catch(() => {});
};
export const paymentHistory = (request, setIsLoading, setIsListloading) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.bookingsStatus.paymentHistory}`, request)
    .then((response) => {
      let result = response.data;

      if (result.status == 1) {
        dispatch(paymentHistorySuccess(result));
      } else {
        toast.error(result.message);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
      setIsListloading(false);
    })
    .catch(() => {});
};

export const recentBookingList = (request) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.bookingsStatus.recentBookingList}`, request)
    .then((response) => {
      let result = response.data;

      if (result.status == 1) {
        dispatch(recentBookingListSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const updateFavBookingList = (request) => async (dispatch) => {
  dispatch(updateFavBookingListSuccess(request));
};
