import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useSelector } from 'react-redux';
import SwiperImageBox from './swiperImageBox';
import { useState } from 'react';
import { useResponsive } from 'themes/useResponsive';
import SliderButton from 'components/sliderButton';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

export default function Swiper() {
  const [formData, setFormData] = useState({});
  const { allCompanyList } = useSelector((state) => state.landingPage);
  const { screenType } = useResponsive();

  // const [searchData, setSearchData] = useState();
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   const data = secureLocalStorage.getItem('searchData');
  //   setSearchData(JSON.parse(data));
  // }, []);

  // useEffect(() => {
  //   if (searchData) {
  //     setFormData((formData) => ({
  //       ...formData,
  //       ...searchData,
  //       companyId: ''
  //     }));
  //   }
  // }, [searchData]);

  useEffect(() => {
    if (formData?.companyId) {
      secureLocalStorage.setItem('searchData', JSON.stringify(formData));
      navigate('/smart-search');
    }
  }, [formData]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
      slidesToSlide: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide }
    } = rest;
    return (
      <div className="carousel-button-group">
        <SliderButton previous={() => previous()} next={() => next()} index={currentSlide} />
      </div>
    );
  };
  return (
    <div>
      <Carousel
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        autoPlay={false}
        arrows={false}
        infinite={screenType === 'Mobile' ? false : true}
        centerMode={true}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px">
        {allCompanyList?.map((item, index) => {
          return (
            <SwiperImageBox
              onClick={() => {
                setFormData((formData) => ({
                  ...formData,
                  companyId: item.id
                }));
              }}
              screenType={screenType}
              key={index}
              image={item?.companyImagePath}
              text={item.name}
            />
          );
        })}
      </Carousel>
    </div>
  );
}
