import React from 'react';

export default function SmallButton(props) {
  const { title, onClick, disabled, style, width } = props;

  return (
    <button
      style={{ ...style, width: width ? width : '140px' }}
      disabled={disabled}
      onClick={onClick}
      className="smallBtn">
      {title}
    </button>
  );
}
