import React from 'react';

export default function OutlineButton(props) {
  const { title, onClick, disabled, style, width, color } = props;

  return (
    <button
      style={{
        ...style,
        minWidth: width ? width : '90px',
        border: `2px solid ${color}`,
        height: '35px',
        borderRadius: 20,
        color: color,
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: 'Circular Std Bold'
      }}
      disabled={disabled}
      onClick={onClick}>
      {title}
    </button>
  );
}
