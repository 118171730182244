import React from 'react';
import { useResponsive } from '../../../themes/useResponsive';
import MobileViewDataCard from '../subComponent/dataList/MobileView';
import DesktopViewDataCard from '../subComponent/dataList/desktopView';

export default function DashBoardBookingList({ item }) {
  const { screenType } = useResponsive();

  return (
    <>
      {screenType === 'desktop' ? (
        <>
          <DesktopViewDataCard
            data={item}
            btnTitle={
              item?.bookingStatus === 3
                ? 'Active'
                : item?.bookingStatus === 2
                  ? 'Completed'
                  : item?.bookingStatus === 1
                    ? 'Pending'
                    : item?.bookingStatus === 4
                      ? 'Reject'
                      : item?.bookingStatus === 5
                        ? 'Cancelled'
                        : 'Expired'
            }
            isNumber={true}
            btnColor={
              item?.bookingStatus === 3 || item?.bookingStatus === 1
                ? '#FFBB51'
                : item?.bookingStatus === 2
                  ? '#6CD2C2'
                  : '#FF5252'
            }
          />
        </>
      ) : (
        <MobileViewDataCard
          data={item}
          btnTitle={
            item?.bookingStatus === 3
              ? 'active'
              : item?.bookingStatus === 2
                ? 'completed'
                : item?.bookingStatus === 1
                  ? 'pending'
                  : item?.bookingStatus
          }
          // btnTitle={item?.bookingStatus}
          isNumber={true}
          btnColor={item?.bookingStatus === 3 ? '#FFBB51' : '#6CD2C2'}
        />
      )}
    </>
  );
}
