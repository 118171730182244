import Header from '../../components/header';
import { Container } from 'react-bootstrap';
import Swiper from './subComponent/swiper';
import LatestCars from './subComponent/latestCars';
import Destination from './subComponent/destination';
import Experience from './subComponent/experience';
import Hosts from './subComponent/hosts';
import Blogs from './subComponent/blogs';
import Footer from '../../components/footer';
import { useEffect } from 'react';
import ImageWithSearch from './subComponent/imageWithSearch';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompany, getAllLatestCar } from '../../store/slice/landingPage';
import { loginData } from 'components/constant';

export default function LandingPage() {
  const { allCompanyList, allLatestCarList, destinationCity, topRatedCarsList, hostsList } =
    useSelector((state) => state.landingPage);
  const dispatch = useDispatch();

  useEffect(() => {
    const request = {
      language: 'en'
    };

    dispatch(getAllCompany(request));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const request = {
      userId: loginData?.id ? loginData?.id : '',
      page: 1,
      perPage: 10,
      language: 'en'
    };

    dispatch(getAllLatestCar(request));
  }, []);
  return (
    <>
      <Header scroll={scroll} />
      <header className="masthead">
        <ImageWithSearch />
      </header>
      <div className="container" style={{ display: allCompanyList.length <= 0 ? 'none' : 'block' }}>
        <h2 className="sectionHead">Rent any car you want!</h2>
      </div>

      <Container fluid className="pt-2">
        <Swiper />
      </Container>
      <div
        className="container "
        style={{ display: allLatestCarList?.length <= 0 ? 'none' : 'block' }}>
        <h2 className="sectionHead prevent-select">Latest Cars</h2>
        <LatestCars />
      </div>
      <div
        className="container "
        style={{ display: destinationCity?.length <= 0 ? 'none' : 'block' }}>
        <h2 className="sectionHead prevent-select "> Browse by destination</h2>
        <Destination />
      </div>
      <Container
        fluid
        className="linearBg "
        style={{ display: topRatedCarsList?.length <= 0 ? 'none' : 'block' }}>
        <div className="container  pt-1 pb-5 ">
          <h2 className="text-center sectionHead prevent-select"> Browse by experience</h2>
          <Experience />
        </div>
      </Container>

      <div className="container " style={{ display: hostsList?.length <= 0 ? 'none' : 'block' }}>
        <div className="text-center ">
          <h2 className="sectionHead prevent-select">Meet the hosts</h2>
        </div>
        <Hosts />
      </div>
      <Container fluid className="" style={{ background: '#E5E5E5', marginTop: '4rem' }}>
        <div className="container pt-1 pb-5">
          <h2 className="text-center sectionHead prevent-select"> Our latest blogs</h2>
          <Blogs />
        </div>
      </Container>

      <Footer />
    </>
  );
}
