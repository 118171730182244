import React, { useEffect, useRef, useState } from 'react';
import profile from '../../assets/img/noProfile.jpg';
import { Icon } from '@iconify/react';
import Input from '../input';
import PhoneInput from 'react-phone-input-2';
import { useResponsive } from '../../themes/useResponsive';
import { useDispatch } from 'react-redux';
import { updateUserProfile } from '../../store/slice/auth';
import { RENTEDWHEELSAPI } from '../../services/apiConstant';
import toast from 'react-hot-toast';
import api from '../../services';
export default function UpdateProfile(props) {
  const { userProfileData } = props;
  const [updateProfileData, setUpdateProfileData] = useState({});
  const { screenType } = useResponsive();
  const inputProfile = useRef();
  const dispatch = useDispatch();
  const closeRef = useRef();
  useEffect(() => {
    if (userProfileData) {
      setUpdateProfileData(userProfileData);
      setUpdateProfileData((updateProfileData) => ({
        ...updateProfileData,
        phone: `+${userProfileData?.countryCode + userProfileData?.phoneNumber}`
      }));
    }
  }, [userProfileData]);
  // ------------------------upload profile image---------------
  const onProfileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    api
      .post(`${RENTEDWHEELSAPI.auth.changeProfileImage}?folderName=userProfile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        if (response?.data?.status === 1) {
          let filename = response?.data?.data[0]?.imageName;
          setUpdateProfileData((updateProfileData) => ({
            ...updateProfileData,
            profileImage: filename
          }));
          let imageUrl = response.data.data[0].imagePath;
          if (imageUrl) {
            updateProfileData.profileImagePath = imageUrl;
            setUpdateProfileData((updateProfileData) => ({
              ...updateProfileData,
              profileImagePath: imageUrl
            }));
          }
        } else {
          toast.error(response.data.messag);
        }
      })
      .catch(() => {
        ('error');
      });
  };
  // ------------------------ updateProfile-------------
  const handleUpdateProfile = () => {
    // const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    if (!updateProfileData?.firstName) {
      setUpdateProfileData((updateProfileData) => ({
        ...updateProfileData,
        firstNameErr: 'Please enter your first name'
      }));
      return;
    }
    if (!updateProfileData?.lastName) {
      setUpdateProfileData((updateProfileData) => ({
        ...updateProfileData,
        lastNameErr: 'Please enter your last name'
      }));
      return;
    }

    // if (!updateProfileData.email) {
    //   setUpdateProfileData((updateProfileData) => ({
    //     ...updateProfileData,
    //     emailErr: 'Please enter your email address'
    //   }));
    //   return;
    // } else if (!updateProfileData?.email.includes('@')) {
    //   setUpdateProfileData((updateProfileData) => ({
    //     ...updateProfileData,
    //     emailErr: 'Please enter a valid email address'
    //   }));
    //   return;
    // } else if (!updateProfileData?.email.includes('.')) {
    //   setUpdateProfileData((updateProfileData) => ({
    //     ...updateProfileData,
    //     emailErr: 'Please enter a valid email address'
    //   }));
    //   return;
    // } else if (!validEmail.test(updateProfileData?.email)) {
    //   setUpdateProfileData((updateProfileData) => ({
    //     ...updateProfileData,
    //     emailErr: 'Please enter a valid email address'
    //   }));
    //   return;
    // }
    if (!updateProfileData?.phoneNumber) {
      setUpdateProfileData((updateProfileData) => ({
        ...updateProfileData,
        phoneNumberErr: 'Please enter your phone number'
      }));
      return;
    }
    let request = {
      firstName: updateProfileData?.firstName,
      lastName: updateProfileData?.lastName,
      countryCode: updateProfileData?.countryCode,
      phoneNumber: updateProfileData?.phoneNumber,
      profileImage: updateProfileData?.profileImage
    };
    dispatch(updateUserProfile(request, closeRef));
  };
  return (
    <>
      <div
        className="modal fade bd-example-modal-lg"
        id="updateProfile"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className={`modal-content py-2 ${screenType != 'desktop' ? '' : 'px-4'} `}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Edit Profile
              </h5>
              <button
                type="button"
                ref={closeRef}
                className="close"
                data-dismiss="modal"
                aria-label="Close"></button>
              <button
                type="button"
                className="smallBtn"
                style={{ width: 100 }}
                onClick={handleUpdateProfile}>
                Save
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div
                  className={screenType != 'desktop' ? 'col-sm-12' : 'col-sm-4'}
                  style={{
                    display: 'flex',
                    justifyContent: screenType != 'desktop' ? 'center' : '',
                    paddingBottom: screenType != 'desktop' ? '1rem' : ''
                  }}>
                  <div style={{ height: 195.3, width: 195.3, position: 'relative' }}>
                    {updateProfileData?.profileImagePath ? (
                      <img
                        src={updateProfileData?.profileImagePath}
                        className="rounded-circle shadow-4"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        alt="Avatar"
                      />
                    ) : (
                      <img
                        src={profile}
                        className="rounded-circle shadow-4"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        alt="Avatar"
                      />
                    )}
                    <div
                      onClick={() => {
                        inputProfile.current && inputProfile.current.click();
                      }}
                      style={{
                        background: '#6CD2C2',
                        borderRadius: 35,
                        height: 50,
                        width: 50,
                        border: '4px solid white',
                        position: 'absolute',
                        bottom: 10,
                        right: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      <Icon icon="circum:image-on" color="white" height={30} />
                    </div>
                    <input
                      ref={inputProfile}
                      style={{ display: 'none', cursor: 'pointer' }}
                      type="file"
                      accept="image/*"
                      onChange={onProfileChange}
                    />
                  </div>
                </div>

                <div className={screenType != 'desktop' ? 'col-sm-12' : 'col-sm-8'}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Input
                          onChange={(val) => {
                            setUpdateProfileData((updateProfileData) => ({
                              ...updateProfileData,
                              firstNameErr: '',
                              firstName: val
                            }));
                          }}
                          value={updateProfileData?.firstName}
                          placeholder={'First Name'}
                          icon={'fluent:person-28-regular'}
                          formError={updateProfileData?.firstNameErr}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Input
                          onChange={(val) => {
                            setUpdateProfileData((updateProfileData) => ({
                              ...updateProfileData,
                              lastNameErr: '',
                              lastName: val
                            }));
                          }}
                          value={updateProfileData?.lastName}
                          placeholder={'Last Name'}
                          icon={'fluent:person-28-regular'}
                          formError={updateProfileData?.lastNameErr}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group ">
                    <Input
                      disabled
                      // onChange={(val) => {
                      //   setUpdateProfileData((updateProfileData) => ({
                      //     ...updateProfileData,
                      //     emailErr: '',
                      //     email: val
                      //   }));
                      // }}
                      value={updateProfileData?.email}
                      placeholder={'Email Address'}
                      icon={'mdi-light:email'}
                      formError={updateProfileData?.emailErr}
                    />
                  </div>
                  <div className="form-group first ">
                    <div className="d-flex">
                      <div
                        style={{
                          background: '#f6f6f8 ',
                          paddingLeft: '0.7rem',
                          paddingTop: '0.7rem'
                        }}>
                        <Icon icon="ph:phone-call-light" height={30} color="#757c83" />
                      </div>
                      <PhoneInput
                        placeholder={'(00) 0000-0000'}
                        country={'na'}
                        value={updateProfileData?.phone}
                        inputStyle={{
                          width: '100%',
                          border: 'none',
                          height: '51px ',
                          fontSize: '19px'
                        }}
                        onChange={(value, inputdata) => {
                          setUpdateProfileData((updateProfileData) => ({
                            ...updateProfileData,
                            phoneNumberErr: '',
                            phone: value,
                            phoneNumber: value.slice(inputdata?.dialCode?.length),
                            countryCode: inputdata?.dialCode
                          }));
                        }}
                        inputClass="telInput"
                      />
                    </div>
                    <div
                      style={{
                        color: 'red',
                        fontSize: 13,
                        marginTop: '0.5rem',
                        paddingLeft: 5
                      }}
                      className="text-error">
                      {updateProfileData?.phoneNumberErr && updateProfileData?.phoneNumberErr}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
