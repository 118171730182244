import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ImageIcon from '../../../assets/img/Vector.png';
import Header from '../../../components/header';
import SelectInput from 'components/select';
import { Icon } from '@iconify/react';
import { useResponsive } from 'themes/useResponsive';
import { getAllDocumentType } from '../../../store/slice/carUser/uploadDocument';
import api from '../../../services';
import { useDispatch, useSelector } from 'react-redux';
import { RENTEDWHEELSAPI } from 'services/apiConstant';
import { addUserDocument } from '../../../store/slice/carUser/uploadDocument';
import { getUserDocument } from '../../../store/slice/carUser/uploadDocument';
import { deleteDocument } from '../../../store/slice/carUser/uploadDocument';
import toast from 'react-hot-toast';
import ColoredButton from 'components/button/colorFullButtons';
import AleartDialog from 'components/dialog/aleartDialog';
import PopOverSpinner from 'components/popOverSpinner';
import Spinner from 'components/spinner';
const IdentityPage = () => {
  const dispatch = useDispatch();
  const { allDocumentType, getDocumentsList } = useSelector((state) => state.uploadDocument);
  // const {  } = useSelector((state) => state.uploadDocument);
  // const { removeDocument } = useSelector((state) => state.uploadDocument);
  const [showAleartDialog, setShowAleartDialog] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [uploadImages, setuploadImages] = useState([]);
  const [ImagesPath, setImagesPath] = useState([]);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const { screenType } = useResponsive;
  const ImagesRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});

  const onChange = (event) => {
    // for (let i = 0; i < event.target.files.length; i++) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    setIsImageLoading(true);
    api
      .post(RENTEDWHEELSAPI.uploadDocument.uploadFile, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        if (response?.data?.status === 1) {
          let filename = response?.data?.data[0]?.imageName;
          let temp = [...uploadImages];
          temp.push(filename);
          setuploadImages(temp);
          let imageUrl = response.data.data[0].imagePath;
          if (imageUrl) {
            let temp = [...ImagesPath];
            temp.push(imageUrl);
            setImagesPath(temp);
          }
        } else {
          toast.error(response.data.message);
        }
        setIsImageLoading(false);
      })
      .catch(() => {
        ('error');
      });
    // }
  };

  useEffect(() => {
    fetchDocumentType();
  }, [showModal]);

  const fetchDocumentType = () => {
    let request = {
      language: 'en'
    };
    dispatch(getAllDocumentType(request));
  };
  const handleRemoveImage = (index) => {
    let imageTemp = [...uploadImages];
    imageTemp.splice(index, 1);
    setuploadImages(imageTemp);
    let imagePathemp = [...ImagesPath];
    imagePathemp.splice(index, 1);
    setImagesPath(imagePathemp);
  };

  useEffect(() => {
    getList();
    setIsLoading(true);
  }, []);

  const getList = () => {
    let request = {
      language: 'en'
    };

    dispatch(getUserDocument(request, setIsLoading));
  };

  const handleAdd = () => {
    if (!formData.documentType) {
      toast.error('Please select a document type');
      return;
    }
    if (uploadImages.length === 0) {
      toast.error('Please upload at least one image');
      return;
    }
    let request = {
      documentType: formData?.documentType,
      documents: uploadImages,
      language: 'en'
    };

    dispatch(addUserDocument(request, setShowModal, setFormData, setImagesPath, setuploadImages));
  };

  const handleDelete = () => {
    let request = {
      documentId: showAleartDialog.id,
      language: 'en'
    };

    dispatch(deleteDocument(request));
    setShowAleartDialog((showAleartDialog) => ({
      ...showAleartDialog,
      show: false,
      status: 0
    }));
  };

  return (
    <>
      <div className="w-100" style={{ background: '#e5e5e5', minHeight: '100vh' }}>
        <Header background color />

        <div className="container bg-white p-4" style={{ marginTop: '4.5rem' }}>
          <div
            style={{
              fontFamily: 'Circular Std Medium',
              cursor: 'pointer'
            }}>
            <Link to={'/'} style={{ color: '#63575778', textDecoration: 'none' }}>
              Home
            </Link>
            &nbsp;/&nbsp;
            <span>My Identity</span>
            {/* <div className="row mx-2 mt-5 gy-3">
              <div className="row mx-2 mt-5 gy-3">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <img
                    src={getDocumentsList?.documents}
                    style={{ height: '300px', width: '100%' }}
                  />
                  <div className="myCar-deleteIcon">
                    <Icon icon="ant-design:delete-outlined" color="white" height={30} />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <img
                    src={getDocumentsList?.documents}
                    style={{ height: '300px', width: '100%' }}
                  />
                  <div className="myCar-deleteIcon">
                    <Icon icon="ant-design:delete-outlined" color="white" height={30} />
                  </div>
                </div>
              </div>
            </div> */}
            {isLoading ? <PopOverSpinner /> : ''}
            <>
              <div
                className="row"
                style={{ position: 'relative', overflow: 'scroll', height: '75vh' }}>
                {getDocumentsList.length > 0 ? (
                  getDocumentsList?.map((item, index) => (
                    <div key={index} className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <img
                        src={item.documentPath}
                        style={{ height: '300px', width: '100%', objectFit: 'cover' }}
                      />
                      <div
                        className="myCar-deleteIcon"
                        // onClick={() => {
                        //   handleDelete(item.id);
                        // }}
                        onClick={() => {
                          setShowAleartDialog((showAleartDialog) => ({
                            ...showAleartDialog,
                            show: true,
                            id: item.id
                          }));
                        }}>
                        <Icon icon="ant-design:delete-outlined" color="#fff" height={30} />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="noData" style={{ position: 'absolute', top: '30vh' }}>
                    No Data Found
                  </div>
                )}
              </div>
            </>
            <div className="row mx-2 gy-3" style={{ cursor: 'auto' }}>
              <div
                className="d-flex justify-content-center"
                style={{
                  backgroundColor: '#132649',
                  height: '80px',
                  width: '100%'
                }}
                onClick={() => setShowModal(true)}>
                <div
                  className="mt-4"
                  style={{
                    fontFamily: 'Circular Std Black',
                    color: '#F1AE01'
                  }}>
                  <img src={ImageIcon} alt="Add" />
                  &nbsp;&nbsp;Add New
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div
          className="modal"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div
            className="modal-dialog"
            role="dialog"
            style={{ width: screenType === 'desktop' ? '50%' : '70%' }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Upload Document</h5>
              </div>
              <div className="modal-body">
                <SelectInput
                  value={formData?.documentType}
                  onChange={(e) => {
                    setFormData((formData) => ({
                      ...formData,
                      documentType: e.target.value
                    }));
                  }}
                  Placeholder="Please select document type"
                  options={allDocumentType?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                />
                {isImageLoading ? (
                  <Spinner />
                ) : (
                  <div className="d-flex justify-content-end">
                    {ImagesPath.length < 2 ? (
                      <ColoredButton
                        onClick={() => ImagesRef?.current.click()}
                        style={{ width: '120px', background: '#6CD2C2' }}
                        title={<div>Add Image</div>}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                )}
                <div className="row">
                  {ImagesPath?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="col-12 mt-2"
                        style={{
                          borderRadius: 10,
                          position: 'relative',
                          height: 230
                        }}>
                        <img src={item} alt="" height={'100%'} width={'100%'} />
                        <div onClick={() => handleRemoveImage(index)} className="addCar-deleteIcon">
                          <Icon icon="ant-design:delete-outlined" color="white" height={20} />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <input
                  ref={ImagesRef}
                  type="file"
                  style={{
                    display: 'none'
                  }}
                  className="imageUpload"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={onChange}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}>
                  Close
                </button>
                <button type="button" className="btn btn-primary" onClick={handleAdd}>
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <AleartDialog
        title={`Remove document`}
        message={`Are you sure, you want to remove this document?`}
        showDialog={showAleartDialog.show}
        onDialogClose={() => {
          setShowAleartDialog((showAleartDialog) => ({
            ...showAleartDialog,
            show: false,
            status: 0
          }));
        }}
        btnConfirm={() => {
          handleDelete();
        }}
      />
    </>
  );
};

export default IdentityPage;
