import React, { useState } from 'react';
import Input from '../../components/input';
import Button from '../../components/button';
import { useDispatch } from 'react-redux';
import { userSignIn } from '../../store/slice/auth';
import { Link } from 'react-router-dom';
import { deviceToken } from 'utils/appConstant';

export default function Login() {
  const [loginData, setLoginData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleLogin = () => {
    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    if (!loginData.email) {
      setLoginData((loginDataError) => ({
        ...loginDataError,
        emailErr: 'Please enter your email address'
      }));
      return;
    } else if (!loginData?.email.includes('@')) {
      setLoginData((loginDataError) => ({
        ...loginDataError,
        emailErr: 'Please enter a valid email address'
      }));
      return;
    } else if (!loginData?.email.includes('.')) {
      setLoginData((loginDataError) => ({
        ...loginDataError,
        emailErr: 'Please enter a valid email address'
      }));
      return;
    } else if (!validEmail.test(loginData?.email)) {
      setLoginData((loginDataError) => ({
        ...loginDataError,
        emailErr: 'Please enter a valid email address'
      }));
      return;
    }

    if (!loginData?.password) {
      setLoginData((loginDataError) => ({
        ...loginDataError,
        passwordErr: 'Please enter your password'
      }));
      return;
    }
    setIsLoading(true);

    let request = {
      email: loginData.email,
      password: loginData.password,
      deviceType: 'web',
      deviceToken: deviceToken
    };
    dispatch(userSignIn(request, setIsLoading));
  };
  return (
    <>
      <div style={{ paddingTop: '4rem' }} className="px-2">
        <div className=" pb-3">
          <h3 className="authHeading">Welcome Back! </h3>
          <h3 className="authHeading">Login Now.</h3>
        </div>
        <form action="#" method="post" className="pt-5">
          <div className="form-group first ">
            <Input
              onChange={(val) => {
                setLoginData((prevState) => ({ ...prevState, email: val, emailErr: '' }));
              }}
              value={loginData?.email}
              formError={loginData?.emailErr}
              placeholder={'Email Address'}
              icon={'mdi-light:email'}
            />
          </div>
          <div className="form-group last mt-3 mb-3">
            <Input
              type={!loginData?.showPass ? 'password' : 'text'}
              endIcon={loginData?.showPass ? 'formkit:eye' : 'humbleicons:eye-off'}
              passwordClick={() =>
                setLoginData((prevState) => ({
                  ...prevState,
                  showPass: !loginData?.showPass
                }))
              }
              onChange={(val) => {
                setLoginData((prevState) => ({ ...prevState, password: val, passwordErr: '' }));
              }}
              value={loginData?.password}
              formError={loginData?.passwordErr}
              placeholder={'Password'}
              icon={'circum:lock'}
            />
          </div>

          <div className="d-flex mb-5 align-items-center pt-3">
            <p className="hint-text">
              <Link className="hint-text" to="/auth/forgotPassword">
                Forgot Password?
              </Link>
            </p>
          </div>
        </form>
        <Button
          onClick={handleLogin}
          title={isLoading ? 'Loading' : 'Login Now'}
          isLoading={isLoading}
          disabled={isLoading}
        />
        <div className="d-flex mt-5 mb-5 align-items-center">
          <div className="text-left text-muted login-footer-text">
            {"Don't have an account ?"} &nbsp;
            <Link className="highlight" to="/auth/signUp">
              Create one
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
