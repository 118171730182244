import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import ColoredButton from '../button/colorFullButtons';
import { Overlay } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { loginData } from '../constant';
import { useResponsive } from '../../themes/useResponsive';
import profile from '../../assets/img/noProfile.jpg';
import { useSelector } from 'react-redux';
import LogoutDialog from 'components/dialog/logout';

const ProfileOverlay = (props) => {
  const { show, target } = props;
  const navigate = useNavigate();
  const { screenType } = useResponsive();
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const { userProfileData } = useSelector((state) => ({ ...state.auth }));
  const handleLogout = () => {
    setShowLogoutDialog(true);
  };

  return (
    <>
      <LogoutDialog show={showLogoutDialog} setShow={setShowLogoutDialog} />

      <Overlay target={target} show={show} placement="bottom">
        <div
          className="px-3 py-2 border border-2"
          {...props}
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            marginTop: '1rem',
            color: 'black',
            borderRadius: 10,
            width: screenType === 'MOBILE' ? 230 : 260,
            ...props.style
          }}>
          <div
            style={{
              background: '#E9F8F6',
              padding: '1rem',
              borderRadius: 10,
              position: 'relative',
              marginTop: '2.5rem'
            }}>
            <div
              style={{
                border: '4px solid white',
                width: 'fit-content',
                borderRadius: 35,
                position: 'absolute',
                top: -40
              }}>
              <img
                src={
                  userProfileData?.profileImagePath ? userProfileData?.profileImagePath : profile
                }
                className="rounded-circle shadow-4"
                style={{ width: 60, height: 60, objectFit: 'cover' }}
                alt="Avatar"
              />
            </div>
            <div
              type="button"
              data-toggle="modal"
              data-target="#updateProfile"
              //   onClick={() => setShow(!show)}
              style={{
                background: '#6CD2C2',
                borderRadius: 35,
                height: 40,
                width: 40,
                position: 'absolute',
                top: 10,
                right: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <Icon icon="gravity-ui:pencil-to-line" color="white" height={20} />
            </div>
            <div
              style={{
                color: '#132649',
                paddingTop: '1rem',
                fontFamily: 'Circular Std Bold',
                fontSize: 20,
                whiteSpace: 'nowrap',
                width: '80%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
              {userProfileData?.firstName} {userProfileData?.lastName}
            </div>
            <div
              className="text-muted"
              style={{
                fontSize: 16,
                whiteSpace: 'nowrap',
                fontFamily: 'Circular Std Book',
                width: '90%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
              {userProfileData?.email}
            </div>
            <div className="text-muted" style={{ fontSize: 16, fontFamily: 'Circular Std Book' }}>
              {`+${userProfileData?.countryCode} ${userProfileData?.phoneNumber}`}
            </div>
          </div>
          {loginData?.userType === 2 ? (
            <div>
              <div className="w-100 pt-3" style={{ marginBottom: '-1rem' }}>
                <ColoredButton
                  onClick={() => navigate('/car-owner/dashboard')}
                  width={'100%'}
                  style={{ background: '#6CD2C2' }}
                  title="My Dashboard"
                />
              </div>
              <hr />
              <div
                onClick={handleLogout}
                className="text-red text-center pb-2 menu-text"
                style={{ cursor: 'pointer', marginTop: '-0.5rem', color: '#FF6767' }}>
                Logout
              </div>
            </div>
          ) : (
            <div>
              <div
                className="d-flex justify-content-between py-3"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/my-bookings')}>
                <div className="text-muted text-primary menu-text">My Bookings</div>
                <div>
                  <Icon icon="grommet-icons:next" height={15} color="#757c83" />
                </div>
              </div>
              <div
                className="d-flex justify-content-between "
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/favorites-page')}>
                <div className="text-muted text-primary menu-text">Favorites</div>
                <div>
                  <Icon icon="grommet-icons:next" height={15} color="#757c83" />
                </div>
              </div>
              <div
                className="d-flex justify-content-between py-3"
                type="button"
                data-toggle="modal"
                data-target="#exampleModalCenter"
                style={{ cursor: 'pointer' }}>
                <div className="text-muted text-primary menu-text">Change Password</div>
                <div>
                  <Icon icon="grommet-icons:next" height={15} color="#757c83" />
                </div>
              </div>
              <div
                className="d-flex justify-content-between "
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/identity-page')}>
                <div className="text-muted text-primary menu-text">My Identity</div>
                <div>
                  <Icon icon="grommet-icons:next" height={15} color="#757c83" />
                </div>
              </div>
              <div
                className="d-flex justify-content-between pt-3 pb-2"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/rating-review-page')}>
                <div className="text-muted text-primary menu-text">My Ratings & Reviews</div>

                <div>
                  <Icon icon="grommet-icons:next" height={15} color="#757c83" />
                </div>
              </div>
              <hr />
              <div
                onClick={handleLogout}
                className="text-red text-center pb-2 menu-text"
                style={{ cursor: 'pointer', marginTop: '-0.5rem', color: '#FF6767' }}>
                Logout
              </div>
            </div>
          )}

          {/* {loginData?.userType === 2 && (
            <div className="w-100 pt-3" style={{ marginBottom: '-1rem' }}>
              <ColoredButton
                onClick={() => navigate('/car-owner/dashboard')}
                width={'100%'}
                style={{ background: '#6CD2C2' }}
                title="My Dashboard"
              />
            </div>
          )}
          <div
            className="d-flex justify-content-between py-3"
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/my-bookings')}>
            <div className="text-muted text-primary menu-text">My Bookings</div>
            <div>
              <Icon icon="grommet-icons:next" height={15} color="#757c83" />
            </div>
          </div>
          <div
            className="d-flex justify-content-between "
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/favorites-page')}>
            <div className="text-muted text-primary menu-text">Favorites</div>
            <div>
              <Icon icon="grommet-icons:next" height={15} color="#757c83" />
            </div>
          </div>
          <div
            className="d-flex justify-content-between py-3"
            type="button"
            data-toggle="modal"
            data-target="#exampleModalCenter"
            // onClick={() => setShow(!show)}
            style={{ cursor: 'pointer' }}>
            <div className="text-muted text-primary menu-text">Change Password</div>
            <div>
              <Icon icon="grommet-icons:next" height={15} color="#757c83" />
            </div>
          </div>
          <div
            className="d-flex justify-content-between "
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/identity-page')}>
            <div className="text-muted text-primary menu-text">My Identity</div>
            <div>
              <Icon icon="grommet-icons:next" height={15} color="#757c83" />
            </div>
          </div>
          <div
            className="d-flex justify-content-between pt-3 pb-2"
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/rating-review-page')}>
            <div className="text-muted text-primary menu-text">My Ratings & Reviews</div>

            <div>
              <Icon icon="grommet-icons:next" height={15} color="#757c83" />
            </div>
          </div>
          <hr />
          <div
            onClick={handleLogout}
            className="text-red text-center pb-2 menu-text"
            style={{ cursor: 'pointer', marginTop: '-0.5rem', color: '#FF6767' }}>
            Logout
          </div> */}
        </div>
      </Overlay>
    </>
  );
};

export default ProfileOverlay;
