import React, { useEffect, useState } from 'react';
// import { useResponsive } from '../../../themes/useResponsive';
import host from '../../../assets/img/noProfile.jpg';
import { socket } from '../../../helpers/socketio-client';
import { Icon } from '@iconify/react';
import Messages from './messages';
import { loginData } from 'components/constant';
import { useDispatch, useSelector } from 'react-redux';
import { getChattingOneTwoOne } from 'store/slice/carOwner/message';
// import ActionOverlay from 'components/popover/actionOverlay';

export default function ChatData({ ...props }) {
  const { isListLoading } = props;
  // const { screenType } = useResponsive();
  const dispatch = useDispatch();
  // const perPage = 5;
  const [page, setPage] = useState(1);
  // const [showChatAction, setShowChatAction] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reversedMessageData, setReversedMessageData] = useState([]);
  const { messageData, chatUserData, totalMessageCount } = useSelector(
    (state) => state.carOwnerMessage
  );
  const [text, setText] = useState();

  // const chatAction = useRef(null);

  useEffect(() => {
    if (chatUserData) {
      getChatofUser();
    }
  }, [chatUserData]);

  useEffect(() => {
    if (chatUserData) {
      getChatofUser();
    }
  }, [page]);

  useEffect(() => {
    if (messageData.length > 0) {
      setReversedMessageData(messageData.toReversed());
    }
  }, [messageData]);

  const getChatofUser = () => {
    const data = {
      chatRoomId: chatUserData?.chatRoomId,
      search: '',
      page: page,
      perPage: 10,
      language: 'en'
    };
    setIsLoading(true);
    dispatch(getChattingOneTwoOne(data, setIsLoading));
  };

  const handleEnter = (e) => {
    if (e.key == 'Enter') {
      handleOnEnter();
    }
  };

  const handleOnEnter = () => {
    const textData = text.trim();
    if (textData) {
      const data = {
        senderUserId: loginData.id,
        recieverUserId: chatUserData?.userId,
        chatText: textData,
        messageType: 'text',
        chatRoomId: chatUserData.chatRoomId,
        videoThumbnail: '',
        documentName: '',
        language: 'en'
      };
      console.log('data -- ', data);
      socket.emit('sendMessage', data);
      setText('');
    }
  };

  // const menuData = [
  //   {
  //     level: 'Clear chat',
  //     id: 'clear'
  //   }
  // ];

  return (
    <>
      {/* {screenType === 'desktop' && ( */}
      <div className="col-12 col-lg-7 col-xl-9">
        <div className="py-2 px-4 d-none d-lg-block m-3" style={{ background: '#F9F9F9' }}>
          <div className="d-flex align-items-center">
            <div className="position-relative">
              <img
                src={chatUserData?.userImageUrl ? chatUserData?.userImageUrl : host}
                className="rounded-circle mr-1"
                alt="Sharon Lessman"
                width="80"
                height="80"
                style={{ objectFit: 'cover' }}
              />
            </div>
            <div className="flex-grow-1 pl-3">
              <h3
                className="pt-4"
                style={{ fontFamily: 'Circular Std Medium', fontSize: '1.5rem' }}>
                {chatUserData?.fullName}
              </h3>
              {chatUserData?.online ? (
                <p
                  style={{
                    color: '#6CD2C2',
                    marginTop: '-0.3rem',
                    fontSize: 19,
                    fontFamily: 'Circular Std Book'
                  }}>
                  Active Now
                </p>
              ) : (
                ''
              )}
            </div>
            {/* <div>
              <Icon
                icon="entypo:dots-two-vertical"
                height={40}
                ref={chatAction}
                onClick={() => setShowChatAction(!showChatAction)}
              />
            </div> */}
          </div>
        </div>

        <div
          style={{
            maxHeight: '62vh',
            minHeight: '62vh',
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column-reverse'
          }}>
          <Messages
            messageData={reversedMessageData}
            setPage={() => (messageData.length < totalMessageCount ? setPage(page + 1) : '')}
            isLoading={!isListLoading ? isLoading : false}
          />
        </div>

        <div className="flex-grow-0 py-3 px-4 border-top ">
          <div
            className="rouded-5"
            style={{
              display: 'flex',
              alignItems: 'center',
              background: '#f6f6f8',
              justifyContent: 'space-between'
            }}>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setText(e.target.value)}
              onKeyUp={handleEnter}
              placeholder="Type your message"
              value={text}
              style={{ width: '80%', borderRadius: '20px !important' }}
            />
            <div className="d-flex align-items-center">
              {/* <div>
                <Icon icon="solar:microphone-outline" height={30} color="#6CD2C2" />
              </div> */}
              &nbsp; &nbsp; &nbsp; &nbsp;
              <div
                className="rounded-circle"
                onClick={() => {
                  handleOnEnter();
                }}
                style={{
                  background: '#F1AE01',
                  height: 48,
                  width: 48,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Icon icon={'bi:send'} color="white" height={26} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ActionOverlay showAction={showChatAction} action={chatAction} menuData={menuData} /> */}
    </>
  );
}
