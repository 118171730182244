import React, { useEffect, useState } from 'react';
import OwnerHeader from '../../../layouts/carOwner/header';
import image from '../../../assets/img/paymentBg.png';
import './CardStyle.css';
import '../../../index.css';
import { useResponsive } from '../../../themes/useResponsive';
import DesktopViewDataCard from '../subComponent/dataList/desktopView';
import MobileViewDataCard from '../subComponent/dataList/MobileView';
import { paymentHistory } from 'store/slice/carOwner/bookingStatus';
import { Icon } from '@iconify/react';

import { useDispatch, useSelector } from 'react-redux';
import { loginData } from 'components/constant';
import ColoredButton from 'components/button/colorFullButtons';
import Spinner from 'react-bootstrap/Spinner';
import { DateRange } from 'react-date-range';
import PopOverSpinner from 'components/popOverSpinner';

export default function Payment() {
  const [showCalendar, setShowCalendar] = useState(false);

  // const calendarRef = useRef(null);
  const { screenType } = useResponsive();
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState({});
  const { paymentData } = useSelector((state) => state.bookingsStatus);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListloading] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    transactionList();
    setIsListloading(true);
  }, []);

  useEffect(() => {
    setFilterData((filterData) => ({
      ...filterData,
      start: date[0].startDate,
      end: date[0].endDate
    }));
  }, [date]);

  const transactionList = (search, perPage) => {
    setIsLoading(true);
    const data = {
      language: 'en',
      type: loginData && loginData?.userType == 3 ? 1 : 2,
      page: 1,
      perPage: perPage ? perPage : limit,
      startDate: filterData?.start,
      endDate: filterData?.end,
      orderId: search ? search : ''
    };
    dispatch(paymentHistory(data, setIsLoading, setIsListloading));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const months = [
  //   'Jan',
  //   'Feb',
  //   'Mar',
  //   'Apr',
  //   'May',
  //   'Jun',
  //   'Jul',
  //   'Aug',
  //   'Sep',
  //   'Oct',
  //   'Nov',
  //   'Dec'
  // ];

  return (
    <>
      {' '}
      <OwnerHeader title={'Payments'} />;{isListLoading ? <PopOverSpinner /> : ''}
      <div className="pt-4" style={{ height: 'auto' }}>
        <div
          className="card"
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
            width: '100%',
            height: '150px',
            border: 'none'
          }}>
          <div>
            <center>
              <h4
                style={{
                  color: '#13264994',
                  fontFamily: 'Circular Std Book',
                  fontSize: '20px',
                  paddingTop: 20
                }}>
                Total Revenue{' '}
              </h4>

              <h2
                style={{
                  color: '#132649',
                  fontFamily: 'Circular Std Medium',
                  fontSize: '75px'
                }}>
                <span style={{ color: '#FFBB51', fontSize: '40px', fontWeight: 600 }}>N$</span>
                {paymentData?.totalRevenue ? paymentData?.totalRevenue : 0}
              </h2>
            </center>
          </div>
        </div>
        {/* <SearchBox /> */}
        <div className="row align-items-center mb-4">
          <div className="col-sm-8 fs-2 mt-1 carOwner-tab-title"> History</div>
          <div className="col-sm-4 d-flex justify-content-end mt-3 ">
            <form className="form-search form-inline rounded-5">
              <div className="px-4" style={{ background: 'white', borderRadius: 10 }}>
                <Icon icon={'ri:search-line'} height={30} color="#a0a6b1" />
                <input
                  className="payment-input"
                  placeholder="Booking Id"
                  style={{ width: '140px' }}
                  onChange={(e) => {
                    transactionList(e.target.value);
                    setFilterData((filterData) => ({
                      ...filterData,
                      search: e.target.value
                    }));
                  }}
                />
              </div>
              <div
                className="checklist bg-white rounded-4 ml-2 d-flex justify-content-center align-items-center"
                style={{ height: 40, width: 40 }}>
                <Icon icon="octicon:calendar-16" onClick={() => setShowCalendar(!showCalendar)} />
                {showCalendar && (
                  <div className="position-relative start-0">
                    <div
                      className="position-absolute  "
                      style={{
                        zIndex: 1,
                        right: screenType == 'MOBILE' ? '-5rem' : '-1.2rem',
                        top: '2rem'
                      }}>
                      {/* <Calendar onChange={handleCalendarChange} value={date} /> */}
                      <DateRange
                        editableDateInputs={true}
                        onChange={(item) => setDate([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                      />
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="row card-container">
          <div className="col-xl-12">
            <div className="task-list-box" id="landing-task">
              {paymentData?.data?.length >= 1 ? (
                <>
                  {paymentData?.data?.map((item, index) => {
                    return (
                      <>
                        {screenType === 'MOBILE' ? (
                          <MobileViewDataCard data={item} key={index} />
                        ) : (
                          <DesktopViewDataCard data={item} noMargin showCommision />
                        )}
                      </>
                    );
                  })}

                  <div className="d-flex justify-content-center mt-5">
                    {isLoading && !isListLoading ? (
                      <Spinner animation="border" style={{ color: '#6CD2C2' }} />
                    ) : (
                      paymentData?.total > limit && (
                        <ColoredButton
                          onClick={() => {
                            let perPage = limit + 10;
                            setLimit(perPage);

                            transactionList('', perPage);
                          }}
                          title="View More"
                          style={{ background: '#6CD2C2', width: '130px' }}
                        />
                      )
                    )}
                  </div>
                </>
              ) : (
                <div className="noData" style={{ height: '40vh' }}>
                  No Data Found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
