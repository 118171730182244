import React, { useEffect } from 'react';
import ColoredButton from '../button/colorFullButtons';
import SelectInput from '../select';
import { Icon } from '@iconify/react';
import { Overlay } from 'react-bootstrap';
import { useResponsive } from '../../themes/useResponsive';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
// import { setSearchData } from 'store/slice/landingPage';
import Autocomplete from 'react-google-autocomplete';
import { getCagetCarCategoryrList } from 'store/slice/carOwner/review';
import toast from 'react-hot-toast';
import secureLocalStorage from 'react-secure-storage';
import moment from 'moment';

const SmartOverlay = (props) => {
  const { showSmartSearch, smartSearch, closeDialog } = props;
  const { screenType } = useResponsive();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedButton, setSelectedButton] = useState(null);
  const [seats, setSeats] = useState([]);
  const [km, setKm] = useState([]);
  const [carId, setCarId] = useState('');
  // const { searchData } = useSelector((state) => state.landingPage);
  const { carCategoryList } = useSelector((state) => state.review);
  const [searchData, setSearchData] = useState();
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [formData, setFormData] = useState({});

  // const handleFormChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((formData) => ({
  //     ...formData,
  //     [name]: value
  //   }));
  // };

  useEffect(() => {
    setSeats(makeAnArray(20));
    setKm(makeAnArray(28));
    dispatch(getCagetCarCategoryrList('en'));

    const data = secureLocalStorage.getItem('searchData');
    setSearchData(JSON.parse(data));
  }, []);

  const makeAnArray = (n) => {
    return Array.from({ length: n }, (u, i) => ({ id: i + 1, label: i + 1 }));
  };

  useEffect(() => {
    if (searchData) {
      setFormData((formData) => ({
        ...formData,
        ...searchData,
        fuelType: searchData.fuelType,
        companyId: '',
        cityId: ''
      }));
      setCarId(searchData.vehicleType);
    }
  }, [searchData]);

  useEffect(() => {
    if (carId) {
      setFormData((formData) => ({
        ...formData,
        vehicleType: carId
      }));
    }
  }, [carId]);

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      longitude: lng
    }));
  }, [lng]);

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      latitude: lat
    }));
  }, [lat]);

  const getPlaceDetail = (place) => {
    setLat(place.geometry.location.lat);
    setLng(place.geometry.location.lng);
    setFormData((formData) => ({
      ...formData,
      address: place.formatted_address
    }));
  };

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const handleSearch = () => {
    // if (
    //   !formData?.address &&
    //   !formData?.fromBookingDate &&
    //   !formData?.toBookingDate &&
    //   !formData?.vehicleType &&
    //   !formData?.carFuelType &&
    //   !formData?.seatNumber &&
    //   !formData?.maxDistance
    // ) {
    //   return toast('Please select filter');
    // }
    // if (!formData?.address) {
    //   return toast('Please select location');
    // }
    if (formData.fromBookingDate > formData?.toBookingDate) {
      return toast('Please select valid date');
    }
    secureLocalStorage.setItem('searchData', JSON.stringify(formData));
    navigate('/smart-search');
    closeDialog();
    // dispatch(setSearchData(formData, navigate));/
  };

  return (
    <>
      <Overlay target={smartSearch.current} show={showSmartSearch} placement="bottom">
        <div
          className="px-3 py-2 border border-2"
          {...props}
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            marginTop: '1rem',
            color: 'black',
            borderRadius: 10,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: screenType === 'MOBILE' || screenType === 'TABLET' ? '80%' : '70%',
            ...props.style
          }}>
          <div className="d-flex justify-content-between mt-2 col-md-12 col-sm-12">
            <div>
              <h5 className="headings">Smart Search</h5>
            </div>
            <div>
              <button type="button" className="btn btn-primary" onClick={handleSearch}>
                search
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6  text-muted ">
              <div className="col-md-12">
                <div style={{ position: 'relative' }}>
                  <Icon
                    icon="fluent:location-20-regular"
                    style={{
                      position: 'absolute',
                      zIndex: '1',
                      top: '1.1rem',
                      marginLeft: '0.3rem'
                    }}
                  />
                  {/* <Input
                    onChange={(value) =>
                      setFormData((formData) => ({
                        ...formData,
                        search: value
                      }))
                    }
                    name="search"
                    className="form-control"
                    placeholder="10005, RobertMugabe, Avenue, ..."
                    value={formData?.search ? formData?.search : searchData?.search}
                    required
                  /> */}

                  {/* <Autocomplete
                    className="form-control"
                    // placeholder="10005, RobertMugabe, Avenue, ..."
                    options={{
                      types: ['address'],
                      componentRestrictions: { country: 'na' }
                    }}
                    value={formData?.address ? formData?.address : searchData?.address}
                    apiKey={process.env.REACT_APP_MAP_KEY}
                    onPlaceSelected={getPlaceDetail}
                  /> */}

                  <Autocomplete
                    className="form-control"
                    options={{
                      componentRestrictions: { country: 'na' }
                    }}
                    value={formData?.address ? formData?.address : ''}
                    onChange={(e) => {
                      setFormData((formData) => ({
                        ...formData,
                        address: e.target.value
                      }));
                    }}
                    apiKey={process.env.REACT_APP_MAP_KEY}
                    onPlaceSelected={getPlaceDetail}
                  />
                </div>
              </div>
              <div className="col-md-12 mt-2 text-muted">
                <div style={{ position: 'relative' }}>
                  <Icon
                    icon="solar:calendar-linear"
                    style={{
                      position: 'absolute',
                      zIndex: '1',
                      top: '1.1rem',
                      marginLeft: '0.3rem'
                    }}
                  />
                  <DatePicker
                    style={{ width: '31.5rem' }}
                    className="form-control"
                    selected={formData?.fromBookingDate ? formData?.fromBookingDate : null}
                    // selected={formData.fromBookingDate}
                    onChange={(date) =>
                      setFormData((formData) => ({
                        ...formData,
                        fromBookingDate: date ? moment(date).format('DD MMMM, YYYY') : null
                      }))
                    }
                    minDate={new Date()}
                    placeholderText="Pickup date"
                  />
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <div style={{ position: 'relative' }}>
                  <Icon
                    icon="solar:calendar-linear"
                    style={{
                      position: 'absolute',
                      zIndex: '1',
                      top: '1.1rem',
                      marginLeft: '0.3rem'
                    }}
                  />
                  <DatePicker
                    className="form-control"
                    selected={formData?.toBookingDate ? formData?.toBookingDate : null}
                    onChange={(date) =>
                      setFormData((formData) => ({
                        ...formData,
                        toBookingDate: date ? moment(date).format('DD MMMM, YYYY') : null
                      }))
                    }
                    minDate={new Date()}
                    placeholderText="Return date"
                  />
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <h5 className="headings" style={{ fontFamily: 'Circular Std Black' }}>
                  Vehicle Type
                </h5>
                {/* <div className="d-flex justify-content-between">
                  <div
                    className="border border-info col-4 rounded-3 mr-2"
                    style={{
                      maxHeight: '50vh',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <img
                      src={carImage1}
                      className="mx-3 my-3"
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                    <span className="text-center text-info carName ">SUV</span>
                  </div>
                  <div
                    className="border border-2 col-4 rounded-3 mr-2"
                    style={{
                      maxHeight: '50vh',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <img
                      src={carImage2}
                      className="mx-3 my-3"
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                    <span className="text-center text-muted carName ">Coupe</span>
                  </div>

                  <div
                    className="border border-2 col-4 rounded-3 mr-2"
                    style={{
                      maxHeight: '50vh',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <img
                      src={carImage3}
                      className="mx-3 my-3"
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                    <span className="text-center  text-muted carName ">Sedan</span>
                  </div>
                </div> */}
                <div className="d-flex justify-content-between" style={{ overflowY: 'scroll' }}>
                  {carCategoryList &&
                    carCategoryList.map((item, index) => {
                      return (
                        <div
                          className={
                            carId == item.id
                              ? 'border border-info col-4 rounded-3 mr-2'
                              : 'col-4 rounded-3 mr-2'
                          }
                          key={index}
                          onClick={() => {
                            setCarId(item.id);
                          }}
                          style={{
                            maxHeight: '50vh',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                          <img
                            src={item?.categoryImagePath}
                            className="mx-3 my-3"
                            style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }}
                          />
                          <span className="text-center text-info carName ">{item.name}</span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div
              className="col-lg-1 d-flex align-items-center justify-content-center"
              style={{ height: 'auto' }}>
              <div className="vr"></div>
            </div>
            <div className="col-lg-5 col-md-6 justify-content-start">
              <h5 className="headings mt-2">Pickup or Delivery</h5>
              <div className="d-flex justify-content-start">
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb-3">
                    {/* <ColoredButton
                      color={selectedButton === 'Diesel' ? 'white' : '#8993A4'}
                      style={{
                        background: selectedButton === 'Diesel' ? '#6CD2C2' : 'transparent',
                        border: '1px solid #8993A4'
                      }}
                      title="Diesel"
                      onClick={() => {
                        handleButtonClick('Diesel');
                        setFormData((formData) => ({
                          ...formData,
                          fuelType: 'Diesel'
                        }));
                      }}
                    /> */}
                    <ColoredButton
                      color={selectedButton === 'Diesel' ? 'white' : '#8993A4'}
                      style={{
                        background: selectedButton === 'Diesel' ? '#6CD2C2' : 'transparent',
                        border: '1px solid #8993A4',
                        width: '180px'
                      }}
                      title="Diesel"
                      onClick={() => {
                        handleButtonClick('Diesel');
                        setFormData((formData) => ({
                          ...formData,
                          carFuelType: 'Diesel'
                        }));
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 ">
                    {/* <ColoredButton
                      color={selectedButton === 'Petrol' ? 'white' : '#8993A4'}
                      style={{
                        background: selectedButton === 'Petrol' ? '#6CD2C2' : 'transparent',
                        border: '1px solid #8993A4'
                      }}
                      title="Petrol"
                      onClick={() => {
                        handleButtonClick('Petrol');
                        setFormData((formData) => ({
                          ...formData,
                          fuelType: 'petrol'
                        }));
                      }}
                    /> */}
                    <ColoredButton
                      color={selectedButton === 'Petrol' ? 'white' : '#8993A4'}
                      style={{
                        background: selectedButton === 'Petrol' ? '#6CD2C2' : 'transparent',
                        border: '1px solid #8993A4',
                        width: '180px'
                      }}
                      title="Petrol"
                      onClick={() => {
                        handleButtonClick('Petrol');
                        setFormData((formData) => ({
                          ...formData,
                          carFuelType: 'Petrol'
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>

              <h5 className="headings mt-2">No. of seats</h5>
              <div className="form-group pt-3">
                {/* <SelectInput
                  onChange={(e) => {
                    setFormData((formData) => ({
                      ...formData,
                      seatNumber: e.target.value
                    }));
                  }}
                  value={formData?.seatNumber}
                  options={seats?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item?.label}
                      </option>
                    );
                  })}
                /> */}
                <SelectInput
                  onChange={(e) => {
                    setFormData((formData) => ({
                      ...formData,
                      seatNumber: e.target.value
                    }));
                  }}
                  value={
                    formData?.seatNumber
                      ? formData?.seatNumber
                      : searchData?.seatNumber
                        ? searchData?.seatNumber
                        : ''
                  }
                  placeholder="Select no. of seats"
                  options={seats?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item?.label}
                      </option>
                    );
                  })}
                />
              </div>
              <h5 className="headings mt-2">Mileage Cap</h5>
              <div className="form-group pt-3">
                {/* <SelectInput
                  onChange={(e) => {
                    setFormData((formData) => ({
                      ...formData,
                      maxDistance: e.target.value
                    }));
                  }}
                  value={formData?.maxDistance}
                  options={km?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item?.label}
                      </option>
                    );
                  })}
                /> */}
                <SelectInput
                  onChange={(e) => {
                    setFormData((formData) => ({
                      ...formData,
                      maxDistance: e.target.value
                    }));
                  }}
                  // value={formData?.maxDistance}
                  value={
                    formData?.maxDistance
                      ? formData?.maxDistance
                      : searchData?.maxDistance
                        ? searchData?.maxDistance
                        : ''
                  }
                  placeholder="Select mileage cap"
                  options={km?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item?.label}
                      </option>
                    );
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </Overlay>
    </>
  );
};

export default SmartOverlay;
