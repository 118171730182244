import React, { useContext, useState } from 'react';
import MyCarContext from '../../../../../components/context/myCarContext';
import { useResponsive } from '../../../../../themes/useResponsive';
import Button from '../../../../../components/button';
import { useDispatch } from 'react-redux';
import { addNewCar, updateCar } from '../../../../../store/slice/carOwner/review';
import { useNavigate, useParams } from 'react-router-dom';

export default function RulesAndPrecaution() {
  const { id } = useParams();
  const {
    myCarData,
    setMyCarData,
    basicInfoRef,
    carInfoRef,
    mileageInfoRef,
    featuresRef,
    fuelData
    // fuelData
  } = useContext(MyCarContext);

  const { screenType } = useResponsive();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  //  add new car
  const handleSave = () => {
    if (myCarData?.carImage?.length < 1) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        carImageErr: 'Please Add minimum one car image '
      }));
      carInfoRef.current && carInfoRef.current.click();
    }
    if (!myCarData?.modelName) {
      setMyCarData((myCarData) => ({ ...myCarData, modelNameErr: 'Please enter model name' }));
      carInfoRef.current && carInfoRef.current.click();
    }
    if (!myCarData?.modelYear) {
      setMyCarData((myCarData) => ({ ...myCarData, modelYearErr: 'Please select model year' }));
      carInfoRef.current && carInfoRef.current.click();
    }
    if (!myCarData?.carName) {
      setMyCarData((myCarData) => ({ ...myCarData, carNameErr: 'Please select car company' }));
      carInfoRef.current && carInfoRef.current.click();
    }
    if (!myCarData?.insurancePolicyUrl) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        insurancePolicyErr: 'Please Add  insurance policy image'
      }));
      carInfoRef.current && carInfoRef.current.click();
    }
    if (!myCarData?.carNumber) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        carNumberErr: ' Please enter license plate number'
      }));
      carInfoRef.current && carInfoRef.current.click();
    }
    if (!myCarData?.vehicleType) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        vehicleTypeErr: 'Please select vehicle type'
      }));
      carInfoRef.current && carInfoRef.current.click();
      return;
    }
    if (!fuelData.withFule && !fuelData.withoutFule) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        fuleType: 'Please select fuel type'
      }));
      basicInfoRef.current && basicInfoRef.current.click();
      return;
    }
    if (fuelData.withFule && !myCarData?.amountPerHour) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        amountPerHourErr: 'Please enter per hour amount'
      }));
      basicInfoRef.current && basicInfoRef.current.click();
      return;
    }
    if (fuelData.withFule && !myCarData?.amountPerDay) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        amountPerDayErr: 'Please enter per day amount'
      }));
      basicInfoRef.current && basicInfoRef.current.click();
      return;
    }
    if (fuelData.withFule && !myCarData?.amountPerWeek) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        amountPerWeekErr: 'Please enter per week amount'
      }));
      basicInfoRef.current && basicInfoRef.current.click();
      return;
    }
    if (fuelData.withoutFule && !myCarData?.amountPerHours) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        amountPerHourErrs: 'Please enter per hour amount'
      }));
      basicInfoRef.current && basicInfoRef.current.click();
      return;
    }
    if (fuelData.withoutFule && !myCarData?.amountPerDays) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        amountPerDayErrs: 'Please enter per day amount'
      }));
      basicInfoRef.current && basicInfoRef.current.click();
      return;
    }
    if (fuelData.withoutFule && !myCarData?.amountPerWeeks) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        amountPerWeekErrs: 'Please enter per week amount'
      }));
      basicInfoRef.current && basicInfoRef.current.click();
      return;
    }
    if (!myCarData?.address) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        addressError: 'Please select location'
      }));
      basicInfoRef.current && basicInfoRef.current.click();
      return;
    }
    if (!myCarData?.cityName) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        cityNameErr: 'Please select city'
      }));
      basicInfoRef.current && basicInfoRef.current.click();
      return;
    }
    if (!myCarData?.mileageCapPerHour?.km) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        mileageCapPerHourKmErr: 'Please enter per hour km'
      }));
      mileageInfoRef.current && mileageInfoRef.current.click();
    }
    if (!myCarData?.mileageCapPerHour?.amount) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        mileageCapPerHourAmountErr: 'Please enter per hour amount'
      }));
      mileageInfoRef.current && mileageInfoRef.current.click();
    }
    if (!myCarData?.mileageCapPerDay?.km) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        mileageCapPerDayKmErr: 'Please enter per day km'
      }));
      mileageInfoRef.current && mileageInfoRef.current.click();
    }
    if (!myCarData?.mileageCapPerDay?.amount) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        mileageCapPerDayAmountErr: 'Please enter per day amount'
      }));
      mileageInfoRef.current && mileageInfoRef.current.click();
    }
    if (!myCarData?.mileageCapPerWeek?.km) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        mileageCapPerWeekKmErr: 'Please enter per week km'
      }));
      mileageInfoRef.current && mileageInfoRef.current.click();
    }
    if (!myCarData?.mileageCapPerWeek?.amount) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        mileageCapPerWeekAmountErr: 'Please enter per week amount'
      }));
      mileageInfoRef.current && mileageInfoRef.current.click();
    }
    if (!myCarData?.deliveryPickupCharge) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        deliveryPickupChargeErr: 'Please enter delevery and pickup charges'
      }));
      mileageInfoRef.current && mileageInfoRef.current.click();
      return;
    }
    if (!myCarData?.seatNumber) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        seatNumberErr: 'Please enter numbers of seat'
      }));
      featuresRef.current && featuresRef.current.click();
    }
    if (!myCarData?.transmission) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        transmissionErr: 'Please enter Transmission (ex:automatic)'
      }));
      featuresRef.current && featuresRef.current.click();
    }
    if (!myCarData?.airBags) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        airBagsErr: 'Please enter numbers of air bags'
      }));
      featuresRef.current && featuresRef.current.click();
    }
    if (!myCarData?.audioFeatures) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        audioFeaturesErr: 'Please enter audio feature (ex:JBL)'
      }));
      featuresRef.current && featuresRef.current.click();
    }
    if (!myCarData?.fuelConsumption) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        fuelConsumptionErr: 'Please enter fuel consumption'
      }));
      featuresRef.current && featuresRef.current.click();
    }
    if (!myCarData?.brakes) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        brakesErr: 'Please enter bracks type'
      }));
      featuresRef.current && featuresRef.current.click();
    }
    if (!myCarData?.carFuelType) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        carFuelTypeErr: 'Please select car fuel type'
      }));
      featuresRef.current && featuresRef.current.click();
      return;
    }
    if (!myCarData?.rulesAndPrecautions) {
      setMyCarData((myCarData) => ({
        ...myCarData,
        rulesAndPrecautionsErr: 'Please enter rules and precautions'
      }));
      return;
    }
    setIsLoading(true);
    if (id) {
      let obj = {
        carId: id,
        language: 'en',
        location: {
          type: 'Point',
          coordinates: [0, 0]
        }
      };
      console.log('fuelData - ', fuelData);

      const data = {
        language: myCarData?.language,
        carName: myCarData?.carName,
        cityName: myCarData?.cityName,
        modelName: myCarData?.modelName,
        carImagesPath: myCarData?.carImagesPath,
        modelYear: myCarData?.modelYear,
        carNumber: myCarData?.carNumber,
        insurancePolicyUrl: myCarData?.insurancePolicyUrl,
        vehicleType: myCarData?.vehicleType,
        mileageCapPerHour: myCarData?.mileageCapPerHour,
        mileageCapPerDay: myCarData?.mileageCapPerDay,
        mileageCapPerWeek: myCarData?.mileageCapPerWeek,
        deliveryPickupCharge: myCarData?.deliveryPickupCharge,
        seatNumber: myCarData?.seatNumber,
        transmission: myCarData?.transmission,
        airBags: myCarData?.airBags,
        audioFeatures: myCarData?.audioFeatures,
        fuelConsumption: myCarData?.fuelConsumption,
        brakes: myCarData?.brakes,
        rulesAndPrecautions: myCarData?.rulesAndPrecautions,
        bookTimeFrom: myCarData?.bookTimeFrom,
        bookTimeTo: myCarData?.bookTimeTo,
        withFule: fuelData?.withFule
          ? {
              amountPerDay: myCarData?.amountPerDay,
              amountPerWeek: myCarData?.amountPerWeek,
              amountPerHour: myCarData?.amountPerHour
            }
          : null,
        withoutFule: fuelData?.withoutFule
          ? {
              amountPerDay: myCarData?.amountPerDays,
              amountPerWeek: myCarData?.amountPerWeeks,
              amountPerHour: myCarData?.amountPerHours
            }
          : null,
        location: {
          address: myCarData?.address,
          coordinates: [myCarData?.longitude, myCarData?.latitude]
        },
        carFuelType: myCarData?.carFuelType
      };

      let request = { ...obj, ...data };
      dispatch(updateCar(request, setMyCarData, navigate, setIsLoading));
    } else {
      let obj = {
        language: 'en',
        location: {
          type: 'Point',
          coordinates: [0, 0]
        }
      };
      const data = {
        language: 'en',
        carName: myCarData?.carName,
        cityName: myCarData?.cityName,
        modelName: myCarData?.modelName,
        carImagesPath: myCarData?.carImagesPath,
        modelYear: myCarData?.modelYear,
        carNumber: myCarData?.carNumber,
        insurancePolicyUrl: myCarData?.insurancePolicyUrl,
        vehicleType: myCarData?.vehicleType,
        mileageCapPerHour: myCarData?.mileageCapPerHour,
        mileageCapPerDay: myCarData?.mileageCapPerDay,
        mileageCapPerWeek: myCarData?.mileageCapPerWeek,
        deliveryPickupCharge: myCarData?.deliveryPickupCharge,
        seatNumber: myCarData?.seatNumber,
        transmission: myCarData?.transmission,
        airBags: myCarData?.airBags,
        audioFeatures: myCarData?.audioFeatures,
        fuelConsumption: myCarData?.fuelConsumption,
        brakes: myCarData?.brakes,
        rulesAndPrecautions: myCarData?.rulesAndPrecautions,
        bookTimeFrom: myCarData?.bookTimeFrom,
        bookTimeTo: myCarData?.bookTimeTo,
        withFule: fuelData?.withFule
          ? {
              amountPerDay: myCarData?.amountPerDay,
              amountPerWeek: myCarData?.amountPerWeek,
              amountPerHour: myCarData?.amountPerHour
            }
          : null,
        withoutFule: fuelData?.withoutFule
          ? {
              amountPerDay: myCarData?.amountPerDays,
              amountPerWeek: myCarData?.amountPerWeeks,
              amountPerHour: myCarData?.amountPerHours
            }
          : null,
        location: {
          address: myCarData?.address,
          coordinates: [myCarData?.longitude, myCarData?.latitude]
        },
        carFuelType: myCarData?.carFuelType
      };
      let request = { ...obj, ...data };

      dispatch(addNewCar(request, setMyCarData, navigate, setIsLoading));
    }
  };
  return (
    <div className="py-4 ">
      <div className="form-group">
        <textarea
          placeholder="Enter rules and precautions"
          onChange={(val) => {
            setMyCarData((myCarData) => ({
              ...myCarData,
              rulesAndPrecautions: val.target.value,
              rulesAndPrecautionsErr: ''
            }));
          }}
          value={myCarData?.rulesAndPrecautions}
          className="form-control"
          id="exampleFormControlTextarea1"
          rows="3"></textarea>
        {myCarData?.rulesAndPrecautionsErr && (
          <div
            style={{
              color: 'red',
              fontSize: 13,
              paddingLeft: 5
            }}
            className="text-error">
            {myCarData?.rulesAndPrecautionsErr}
          </div>
        )}
      </div>
      <div
        className="position-absolute"
        style={{
          bottom: screenType != 'MOBILE' ? 0 : -10,
          right: screenType != 'MOBILE' ? '' : 0,
          left: screenType != 'MOBILE' ? '' : 0
        }}>
        <Button
          title="Save"
          onClick={handleSave}
          style={{ width: screenType != 'MOBILE' ? '500px' : '' }}
          isLoading={isLoading}
          disabled={isLoading}
        />
      </div>
    </div>
  );
}
