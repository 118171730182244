export const faqConfig = [
  {
    question: 'How do I sign up?',
    answer: `Signing up is quick and easy and shouldn’t take longer than 3 minutes. To join RentedWheels you must sign up using your Google account or with your Email address. Once you’ve created an account you can browse the RentedWheels platform, search for vehicles to rent or list your own vehicle to be rented. Once you have your account set up, additional information will be required in order to rent or list a vehicle for verification purposes. If you have all the necessary documents, this process shouldn’t take longer than 10 minutes.`
  },
  {
    question: 'How do I list my car?',
    answer: `Listing your car is free and should take about 10min. List your car and start putting your car to work. Sign Up to create an account and then click on “List your car”. You will go through a series of steps that require documents (License disc, Drivers License and Images of your vehicle). You will also add all the vehicle details and our calculator will suggest a daily rate that you should rent your car out for relative to other similar cars. Follow the steps and make sure you add nice clear photos to increase the likelihood of your car being rented out.`
  },
  {
    question: 'How do I know someone wants to rent my car?',
    answer: `Once your car is listed, you will start to receive rental requests via email and SMS. All drivers are pre-screened by us, but you decide who gets to rent your car. You must respond quickly to the requests and answer any questions the renter may have before accepting the booking request. Once payment has been made you will be notified along with when the car must be handed over. You then communicate with the renter to arrange handover on the rental start date. Remember – During the rental, your car is covered by fully comprehensive insurance policy.`
  },
  {
    question: 'How do I handover my car?',
    answer: `Agree on a time and place to give the renter your keys and do a vehicle inspection. Then you must sign the trip checklist with the renter that you both agree on the condition of the vehicle at the start. This document will be sent to you when you accept the booking request. The renter can collect the car from you or alternatively you can decide to drop off the vehicle at a predetermined place or an airport. An additional delivery fee will apply based on the distance. Once the rental period is over you will then meet again to accept the car back and sign the checklist that the car is in the same condition as it was received. Take a photo of the checklist and send it to us.`
  },
  {
    question: 'When do I get paid?',
    answer: `RentedWheels will pay of the total number of days rented at the daily rate into your bank account. If there are any extra charges, (e.g. the fuel is not at the same level as when you delivered the car), make sure to send us the trip checklist immediately after the rental ended and the receipts. We will then recover the amounts from the renter.`
  },
  {
    question: 'How do I share my experience about a renter?',
    answer: `After the rental you will receive a review request via email or SMS. This rating request will allow you to rate your experience and review the renter. Reviews are important to help us maintain our level of service and quality but also to identify and solve problems as they occur.`
  },
  {
    question: 'How do I respond to a rental request?',
    answer: `All communication happens on the platform and typically via email or SMS and WhatsApp. Clear communication is critical for both owner and renter across all touchpoints, but the owner dashboard is the main source of communication and notifications related to bookings and listings. (E.g When a renter wants to rent your car or extend an already booked trip, the RentedWheels system will message you via SMS and email.) Once a booking has been accepted and paid for then the contact details of the owner and renter are shared to facilitate the handover and any delivery or logistical requirements`
  },
  {
    question: 'How do I get in touch with RentedWheels?',
    answer: `RentedWheels has an online support desk which you can access at the bottom right of each web page. Most questions you may have can be answered through this channel but there is an option to email our customer experience centre using (support@rentedwheels.com).`
  },
  {
    question: 'How is the daily list price calculated?',
    answer: `There is a recommended daily rental rate that is calculated using a system generated formula and is based on the value of your vehicle. You can however manually set the daily rate for your vehicle but it may affect the demand and frequency of rental for your car relative to the owners with similar vehicles.`
  },
  {
    question: 'How do I get paid?',
    answer: `Owners get paid the total number of days rented at the daily rate less the RentedWheels fee on any day of every month. The payment is done into your nominated bank account. If there are any extra charges, (e.g. the fuel is not at the same level as when you delivered the car), make sure to send us the trip checklist immediately after the rental ended and the receipts. We will then recover the amounts from the renter.`
  },
  {
    question: 'How do I manage my vehicle listing?',
    answer: `It’s important to create an attractive vehicle listing. First share detailed vehicle features. Consider what a renter would want to know about your car. Anticipate questions and set clear guidelines and expectations. On your owners dashboard write a strong description, list features, and have high quality images of your vehicle.`
  },
  {
    question: 'What is needed for listing my vehicle?',
    answer: `Upload high-quality photos. Make a good first impression by posting great photos. Upload multiple, clear, exterior and interior photos taken from different angles. Check out more photo advice. Set competitive prices. Ensure your prices are in line with similar listings and reflect changing demand, which fluctuates throughout the week and year. Better yet, use Automatic Pricing, which dynamically adjusts your vehicle price to match demand. If you’re new, try lowering your price to attract your first few guests`
  },
  {
    question: 'How do you manage your booking?',
    answer: `You may receive — and then accept or decline — requests to book your car, or to change an already-booked trip. At most, you have 24 hours to respond to a trip request, we advise responding sooner, so as not to lose the booking`
  },
  {
    question: 'What if a Renter Instant Books?',
    answer: `If your listing is opted in to Book Instantly, our system will automatically accept the trip request for you. You’ll just need to respond to trip change requests and connect to arrange the handover`
  },
  {
    question: 'What if a Renter Instant Books?',
    answer: `If your listing is opted in to Book Instantly, our system will automatically accept the trip request for you. You’ll just need to respond to trip change requests and connect to arrange the handover`
  },
  {
    question: 'How do I make a booking?',
    answer: `Renters can book a car by searching our vehicle listings, choosing a car, and then submitting your booking request. First-time renters get ‘approved to drive’ when they book their first car. It gets easier and quicker after your first booking. You’ll need to set up a RentedWheels account before you can book a car. If you don’t have one, sign up here.`
  },
  {
    question: 'What is needed if it is my first time making a booking?',
    answer: `First-time renters get ‘approved to drive’ when you request to book your first car. You’ll need to set up a RentedWheels account before you can book a car.`
  },
  {
    question: 'How do I make a booking?',
    answer: `Renters can book a car by searching our vehicle listings, choosing a car, and then submitting your booking request. First-time renters get ‘approved to drive’ when they book their first car. It gets easier and quicker after your first booking. You’ll need to set up a RentedWheels account before you can book a car. If you don’t have one, sign up here.`
  },
  {
    question: 'What is needed if it is my first time making a booking?',
    answer: `First-time renters get ‘approved to drive’ when you request to book your first car. You’ll need to set up a RentWheels account before you can book a car.`
  },
  {
    question: 'What if I have a bad credit score?',
    answer: `RentedWheels has the right to decline your account set up or a trip request and remove you from our platform because of your credit score, evidence of a criminal background, a failed documentation check or any other reason.`
  },
  {
    question: 'When do I pay for my booking?',
    answer: `You pay for your trip after the owner accepts your request. When you click on “Request Booking”, an email and/or SMS is sent to the owner saying that you are ‘requesting’ his vehicle for the prescribed times. The owner confirms your booking and an email and/or SMS is sent back to you to confirm that the vehicle is available. The booking is now ‘pending payment’ until you make payment via the various payment methods*. Once your payment has cleared the owner and you will receive an email and/or sms that your payment has been received and that the rental request is now changed from ‘pending’ to ‘booked’.
    *When the renter makes use of alternative paying methods other than a credit card, the trip will only be marked as ‘booked’ once funds have cleared. This goes for extensions as well. Debit, Card, Credit, Card and EFT Account details. If you incur additional fees (excess kilometer, parking and speeding tickets, damage fees, etc.) we will charge either of the payment methods.`
  },
  {
    question: 'How do I extend my booking?',
    answer: `Once a trip has started, any extension is treated as a new trip. You must request an extension of your trip by sending a new booking request to the owner. For example, if a renter books a trip for 2 weeks and later successfully extends for an additional 3 days, the renter will be charged first for the 2 weeks, and then for a 3 day trip. You will receive an SMS before the end of your booking to remind you of the extended booking. Please request an extension as early as possible before the trip ends so that the owner is able to approve or deny the extension and to reduce the risk that someone else may book the vehicle straight after you. Renters are encouraged to contact the owner by phone to alert them to the request to extend, but it will also appear on the owner dashboard for approval once requested.`
  },
  {
    question: 'Can I review/rate the car I rented and the owner?',
    answer: `Yes, you can! And we highly appreciate it if you do. After the rental, you will receive an email and an SMS to remind you to rate/review the trip. You can also login to RentedWheels, click on your profile in the right top corner and go to ‘My Reviews’.`
  }
];
