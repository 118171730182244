import { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import host from '../../../../assets/img/noProfile.jpg';
import './style.css';
import HostCard from './hostCard';
import SliderButton from '../../../../components/sliderButton';
import { hostsListing } from 'store/slice/landingPage';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
export default function Hosts() {
  const { hostsList } = useSelector((state) => state.landingPage);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchList();
  }, []);
  const fetchList = () => {
    const data = {
      page: 1,
      perPage: 10,
      language: 'en'
    };
    dispatch(hostsListing(data));
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 2 // optional, default to 1.
    }
  };
  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide }
    } = rest;
    return (
      <div className="carousel-button-group">
        <SliderButton previous={() => previous()} next={() => next()} index={currentSlide} />
      </div>
    );
  };
  return (
    <div>
      <Carousel
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        autoPlay={false}
        arrows={false}
        infinite={true}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px">
        {hostsList?.map((item, index) => {
          return (
            <HostCard
              key={index}
              profileImage={item.profile ? item.profile : host}
              title={item?.userName}
              price={item?.price}
              seat={item?.seat}
              joinedDate={moment(item?.createdAt).format('MMM, YYYY')}
              totalTrip={item?.totalTrip}
              borderRadius={'10px'}
              userallRating={item.userallRating}
            />
          );
        })}
      </Carousel>
    </div>
  );
}
