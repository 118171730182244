import React, { useEffect, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { getChattingOneTwoOne } from 'store/slice/carOwner/message';
import Spinner from 'components/spinner';
import InfiniteScroll from 'react-infinite-scroll-component';

let limit = 10;
export default function Index() {
  const dispatch = useDispatch();
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const { messageData, totalMessageCount } = useSelector((state) => state.carOwnerMessage);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    getChatofUser();
  }, []);

  const handleScroll = () => {
    setIsloading(true);
    limit = limit + 10;
    getChatofUser(limit);
  };

  const getChatofUser = (limit) => {
    const data = {
      chatRoomId: 891574,
      search: '',
      page: 1,
      perPage: limit ? limit : 10,
      language: 'en'
    };
    setIsMessageLoading(true);
    dispatch(getChattingOneTwoOne(data, setIsMessageLoading));
  };

  return (
    <>
      <div style={{ background: 'red' }}>
        <InfiniteScroll
          dataLength={messageData?.length ? messageData?.length : 0}
          next={handleScroll}
          hasMore={messageData?.length < totalMessageCount}
          scrollableTarget="scrollableDiv">
          {messageData?.map((item, index) => {
            return (
              <div key={index} style={{ textAlign: 'center' }}>
                {' '}
                {item?.chatMessage}
              </div>
            );
          })}
          {isLoading ? <Spinner /> : <></>}
        </InfiniteScroll>
      </div>

      {messageData?.map((item, index) => {
        return (
          <div key={index} style={{ textAlign: 'center' }}>
            {' '}
            {item?.chatMessage}
          </div>
        );
      })}
      {isMessageLoading ? <Spinner /> : <></>}
    </>
  );
}
