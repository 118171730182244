export const RENTEDWHEELSAPI = {
  //auth
  auth: {
    userSignup: '/users/userSignup',
    userSignin: '/users/userSignin',
    getForgotOtp: '/users/send-otp',
    verfiyForgotOtp: '/users/verify-otp',
    resetPassword: '/users/reset-password',
    changePassword: '/users/userChangePassword',
    getUserProfile: '/users/getUserProfile',
    updateUserProfile: '/users/update-profile',
    changeProfileImage: '/users/uploadFileOnLocal',
    verifiedUserDetail: '/users/verify-user/',
    generateNewJwtToken: 'users/generateNewJwtToken/'
  },
  carOwner: {
    getRatingReview: '/car/rating-review',
    getCarList: '/car/getAllCarByUser',
    getCarCategory: '/admin/get-categories',
    getCarCompany: '/admin/get-company',
    addNewCar: 'car/register-car',
    updateCar: '/car/update-car',
    getCarDetails: '/car/getCarDetailsByUser',
    deleteCar: '/car/deleteCarDetailsById',
    getAllUserChattingList: 'chats/getAllUserChattingList'
  },

  chat: {
    getAllUserChattingList: '/chats/getAllUserChattingList',
    createChatRoodId: '/chats/createPersonalChatRoomId',
    chatOneTwoOne: '/chats/getChatingOnOneToOne',
    createPersonalChatRoomId: 'chats/createPersonalChatRoomId'
  },
  landingPage: {
    getCompany: '/admin/get-company',
    getLatestCar: '/car/getLatestCars',
    getAllCarByUser: '/car/getAllCarByUser',
    getAllBlogsList: '/admin/getAllBlogs',
    blogDescription: '/admin/getBlogDetails',
    destinationsList: '/admin/get-all-city?language=en',
    experiencedCarsList: '/car/experience',
    hostsListing: '/car/hostListing',
    getTheListOfPolicy: '/admin/getTheListOfPolicy'
  },
  favorite: {
    getfavoriteList: '/fav/getAllFavorites',
    addtofavorite: '/fav/addFavorites'
  },
  notification: {
    getnotificationListing: '/users/notificationListWithPagination',
    readNotification: 'users/readNotification',
    // getMyBookingDetails: '/booking/getMyBookingDetails',
    ratingReview: 'car/ratingReview'
  },
  help: {
    addHelpByUser: '/help/addHelp'
  },
  bookingsStatus: {
    myBookingStatus: '/booking/bookingHistory',
    paymentHistory: '/payment/transactionList',
    totalCounts: '/car/totalCount?language=en',
    completedBooking: '/booking/completedBooking',
    acceptRejectAndCompeleteBooking: 'booking/acceptRejectAndCompeleteBooking',
    bookingDetail: '/booking/bookingDetails',
    recentBookingList: 'booking/recentBookingList'
  },
  booking: {
    amountCalculate: 'booking/amountCalculate',
    checkCouponDetails: 'admin/checkCouponDetails',
    carBooking: 'booking/carBooking',

    checkCarAvailByCarId: 'booking/checkCarAvailByCarId',
    getbookingDetails: 'booking/bookingDetails',
    createPayment: 'payment/createPayment'
  },
  uploadDocument: {
    uploadFile: '/users/uploadFileOnLocal?folderName=image&language=en',
    getAllDocumentType: '/admin/getAllDocumentType?language=en',
    addUserDocument: '/users/addUserDocument',
    deleteDocument: '/users/deleteUserDocuments',
    getUserDocument: '/users/getUserDocument?language=en'
  }
};

export const API_RESPONSE = {
  SUCCESS: 1,
  FAILURE: 0
};
