import React from 'react';
import OwnerHeader from '../../../../layouts/carOwner/header';
import BackgroundImage from '../../../../assets/img/paymentBg.png';
import '../../payment/CardStyle.css';
import { Link } from 'react-router-dom';
import GroupImg from '../../../../assets/img/Group 1471.png';
import GroupImg1 from '../../../../assets/img/Group 1472.png';
import GroupImg2 from '../../../../assets/img/main.png';
import BgIcon from '../../../../assets/img/Rectangle 1060.png';
import blankBgIcon from '../../../../assets/img/Ellipse 763.png';
import { Icon } from '@iconify/react';
import { useResponsive } from '../../../../themes/useResponsive';
const RefundPayment = () => {
  const { screenType } = useResponsive();
  return (
    <>
      <div>
        <OwnerHeader
          title={'Refund Payment'}
          previousPage={'My Booking'}
          currentPage={'Booking Details'}
          previousPageUrl={'/carOwner/myBooking'}
          middlePage={'Booking Details'}
          middlePageUrl={'/carOwner/myBooking/booking-details'}
        />
        <div
          className="card"
          style={{
            backgroundImage: `url(${BackgroundImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
            width: '100%',
            height: '150px',
            marginTop: '50px'
          }}>
          <div>
            <center>
              <h4
                style={{
                  color: '#13264994',
                  fontFamily: 'Circular Std Book',
                  fontSize: '20px',
                  paddingTop: 20
                }}>
                Refundable Amount
              </h4>
              <h2
                style={{
                  color: '#132649',
                  fontFamily: 'Circular Std Medium',
                  fontSize: '75px'
                }}>
                <span style={{ color: '#FFBB51', fontSize: '40px', fontWeight: 600 }}>N$</span>
                675
              </h2>
            </center>

            <div className="row mt-5">
              <div className="col">
                <h4 className="carOwner-tab-title ">Banking Details</h4>
                <label
                  htmlFor="inputEmail4"
                  className="form-label"
                  style={{
                    color: '#132649',
                    fontFamily: 'Circular Std Black',
                    fontSize: '20px',
                    marginTop: '20px'
                  }}>
                  Account Number
                </label>
                <input type="email" className="form-control" id="inputEmail4" />
                <label
                  htmlFor="inputEmail4"
                  className="form-label"
                  style={{
                    color: '#132649',
                    fontFamily: 'Circular Std Black',
                    fontSize: '20px',
                    marginTop: '20px'
                  }}>
                  Confirm Account Number
                </label>
                <input type="email" className="form-control" id="inputEmail4" />
                <label
                  htmlFor="inputEmail4"
                  className="form-label"
                  style={{
                    color: '#132649',
                    fontFamily: 'Circular Std Black',
                    fontSize: '20px',
                    marginTop: '20px'
                  }}>
                  IFSC Code
                </label>
                <input type="email" className="form-control" id="inputEmail4" />
                <label
                  htmlFor="inputEmail4"
                  className="form-label"
                  style={{
                    color: '#132649',
                    fontFamily: 'Circular Std Black',
                    fontSize: '20px',
                    marginTop: '20px'
                  }}>
                  Accounts Holder Name
                </label>
                <input type="email" className="form-control" id="inputEmail4" />
                <div
                  className="d-grid gap-2 col-8"
                  style={{
                    marginTop: '20px'
                  }}>
                  <Link to="/carOwner/myBooking/activeBookings/refundPayment">
                    <button
                      className="btn btn-warning"
                      type="button"
                      style={{
                        fontFamily: 'Circular Std Black',
                        width: '250px'
                      }}>
                      Refund N$650
                    </button>
                  </Link>
                </div>
              </div>
              <div className={`${screenType === 'MOBILE' ? 'col mt-5' : 'col'}  `}>
                <h4 className="carOwner-tab-title">Banks</h4>

                <div
                  id="textarea"
                  className={`form-control ${screenType === 'MOBILE' ? 'w-100' : 'w-75'}  `}>
                  <div className="icon">
                    <img src={GroupImg} alt="" className="rounded-circle avatar-sm" />
                  </div>
                  <div className="icon rounded-circle avatar-sm" style={{ position: 'relative' }}>
                    <img
                      src={blankBgIcon}
                      alt=""
                      className="rounded-circle avatar-sm"
                      style={{ objectFit: 'cover' }}
                    />
                    <Icon
                      icon="ph:check-fat-fill"
                      color="white"
                      style={{ position: 'absolute', left: '40%' }}
                    />
                  </div>
                </div>
                <div
                  id="textarea"
                  className={`form-control ${screenType === 'MOBILE' ? 'w-100' : 'w-75'}  `}>
                  <div className="icon">
                    <img
                      src={GroupImg1}
                      alt=""
                      className="rounded-circle avatar-sm"
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                  <div className="icon rounded-circle avatar-sm" style={{ position: 'relative' }}>
                    <img
                      src={BgIcon}
                      alt=""
                      className="rounded-circle avatar-sm"
                      style={{ objectFit: 'cover' }}
                    />
                    <Icon
                      icon="ph:check-fat-fill"
                      color="white"
                      style={{ position: 'absolute', left: '40%' }}
                    />
                  </div>
                </div>
                <div
                  id="textarea"
                  className={`form-control ${screenType === 'MOBILE' ? 'w-100' : 'w-75'}  `}>
                  <div className="icon">
                    <img
                      src={GroupImg2}
                      alt=""
                      className="rounded-circle avatar-sm"
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                  <div className="icon rounded-circle avatar-sm" style={{ position: 'relative' }}>
                    <img
                      src={blankBgIcon}
                      alt=""
                      className="rounded-circle avatar-sm"
                      style={{ objectFit: 'cover' }}
                    />
                    <Icon
                      icon="ph:check-fat-fill"
                      color="white"
                      style={{ position: 'absolute', left: '40%' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RefundPayment;
