import React, { useContext } from 'react';
import { Icon } from '@iconify/react';
import MyCarContext from 'components/context/myCarContext';
import noImage from '../../../../assets/img/latestcars.png';
import MapComponent from '../../../../components/mapComponent';

export default function InsuranceAndLocation() {
  const { carDetails, setFullScreenImage } = useContext(MyCarContext);

  const markers = [
    {
      position: {
        lat: carDetails?.latitude ? carDetails?.latitude : -17.7894532,
        lng: carDetails?.longitude ? carDetails?.longitude : 15.7057792
      }
    }
  ];
  console.log('markers', markers);

  return (
    <>
      <div
        className="d-flex mb-2"
        style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 1 }}>
        <div
          style={{
            height: 20,
            width: 20,
            background: '#F1AE01',
            borderRadius: 15,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '2rem'
          }}>
          <Icon icon={'fluent:location-20-filled'} color="white" />
        </div>

        <div className="circularMedium" style={{ color: '#F1AE01', marginLeft: '10px' }}>
          Pickup location
        </div>
      </div>

      <div style={{ position: 'relative' }}>
        <MapComponent markers={markers} />
        <div
          style={{
            width: '90%',
            background: '#132649',
            color: 'white',
            height: '70px',
            borderRadius: '15px',
            position: 'absolute',
            top: 0,
            left: '45%',
            transform: 'translateX(-50%)', // Center horizontally
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '1rem'
          }}>
          <span style={{ marginLeft: '1.5rem', marginTop: '1rem' }}>
            {carDetails?.address?.address}
          </span>
        </div>
      </div>
      <h4 className="mt-5 HeadingAll">Home Delivery</h4>
      <hr />
      <div className="d-flex justify-content-between pr-2">
        <div className="circularBook text-left">
          Delivery & Pickup
          <div className="car-detail-featureDetailItalic">
            (Pay extra N${carDetails?.deliveryPickupCharge} delivery charges applicable.)
          </div>
        </div>
        {/* <div>
          <input onClick={handleClick} checked={checked} type="checkbox" height={25} />
        </div> */}
      </div>
      <h4 className="mt-5 mb-4 HeadingAll">Insurance Policy</h4>
      <div
        style={{
          position: 'relative',
          backgroundImage: `url(${carDetails?.insurancePolicyUrl ? carDetails?.insurancePolicyUrl : noImage})`,
          borderRadius: 14,
          height: '280px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
        {carDetails?.insurancePolicyUrl && (
          <div
            style={{
              width: '80px',
              height: '80px',
              background: 'white',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <div
              className="bg-black"
              style={{ height: 30 }}
              onClick={() => setFullScreenImage(true)}>
              <Icon icon="iconoir:expand" color="white" height={30} />
            </div>
          </div>
        )}
        {/* <img
          src={carDetails?.insurancePolicyUrl ? carDetails?.insurancePolicyUrl : noImage}
          style={{ objectFit: 'cover' }}
          alt=""
          width={'100%'}
          height={'220px'}
        /> */}
      </div>
    </>
  );
}
