import React, { useEffect, useState } from 'react';
import OwnerHeader from '../../../layouts/carOwner/header';
import CarBox from './subComponent/carBox';
import { useNavigate } from 'react-router-dom';
import { clearCarDetails, deleteCar, getCarList } from '../../../store/slice/carOwner/review';
import { useDispatch, useSelector } from 'react-redux';
import DeleteMyCar from '../../../components/dialog/deleteMyCar';
import { useRef } from 'react';
// import { useResponsive } from '../../../themes/useResponsive';
import ColoredButton from '../../../components/button/colorFullButtons';
import { loginData } from '../../../components/constant';
import Spinner from 'react-bootstrap/Spinner';
import PopOverSpinner from 'components/popOverSpinner';

export default function MyCars() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { carOwnerCarsData } = useSelector((state) => state.review);
  const [limit, setLimit] = useState(12);
  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListloading] = useState(false);
  const [carId, setCarId] = useState('');
  const deleteRef = useRef();
  const closeDeleteDialogRef = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
    setIsListloading(true);
  }, []);
  useEffect(() => {
    if (loginData) {
      fetchCarlist();
    }
  }, [loginData]);
  const fetchCarlist = (perPage) => {
    setIsLoading(true);
    let request = {
      search: '',
      page: 1,
      limit: perPage ? perPage : limit,
      language: 'en',
      userId: loginData?.id,
      fromBookingDate: '',
      toBookingDate: '',
      type: 2
    };
    dispatch(getCarList(request, setIsLoading, setIsListloading));
  };
  const handleConfirmDelete = () => {
    let request = {
      carId: carId
    };
    dispatch(deleteCar(request, closeDeleteDialogRef));
  };

  return (
    <>
      <OwnerHeader title={'My Cars'} />

      {isListLoading ? <PopOverSpinner /> : ''}
      <>
        <DeleteMyCar
          handleConfirm={handleConfirmDelete}
          closeDeleteDialogRef={closeDeleteDialogRef}
        />
        <div className="d-flex justify-content-between mt-4 pt-5 ">
          <div className="carOwner-heading">Add New Car</div>
          <ColoredButton
            onClick={() => {
              dispatch(clearCarDetails());
              navigate('/car-owner/mycars/add-Car');
            }}
            title={'Add New'}
            width={130}
            style={{ background: '#FFBB51' }}
          />
          <button
            ref={deleteRef}
            data-toggle="modal"
            data-target="#exampleModalDeleteCenter"
            style={{ display: 'none' }}></button>
        </div>

        <div className="row">
          {carOwnerCarsData?.data?.length >= 1 ? (
            <>
              {carOwnerCarsData?.data?.map((item, index) => {
                return (
                  <>
                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                      <CarBox data={item} index={index} deleteRef={deleteRef} setCarId={setCarId} />
                    </div>
                  </>
                );
              })}

              <div className="d-flex justify-content-center mt-5">
                {isLoading && !isListLoading ? (
                  <Spinner animation="border" style={{ color: '#6CD2C2' }} />
                ) : (
                  carOwnerCarsData?.totalCount > limit && (
                    <ColoredButton
                      onClick={() => {
                        let perPage = limit + 10;
                        setLimit(perPage);
                        fetchCarlist(perPage);
                      }}
                      title="View More"
                      style={{ background: '#6CD2C2', width: '130px' }}
                    />
                  )
                )}
              </div>
            </>
          ) : (
            <div className="noData" style={{ height: '70vh' }}>
              No Data Found
            </div>
          )}
        </div>
      </>
    </>
  );
}
