import { useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import experience2 from '../../../../assets/img/experience2.png';
import ExperienceImageBox from './experienceImageBox';
import { useResponsive } from '../../../../themes/useResponsive';
import { useDispatch, useSelector } from 'react-redux';
import { experiencedCarsList } from 'store/slice/landingPage';
import { loginData } from 'components/constant';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SliderButton from 'components/sliderButton';
export default function Experience() {
  const dispatch = useDispatch();
  const { topRatedCarsList } = useSelector((state) => state.landingPage);
  const { screenType } = useResponsive();

  useEffect(() => {
    fetchList();
  }, []);
  const fetchList = () => {
    const data = {
      userId: loginData?.id ? loginData?.id : '',
      page: 1,
      perPage: 10,
      language: 'en'
    };
    dispatch(experiencedCarsList(data));
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 2 // optional, default to 1.
    }
  };
  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide }
    } = rest;
    return (
      <div className="carousel-button-group">
        <SliderButton previous={() => previous()} next={() => next()} index={currentSlide} />
      </div>
    );
  };
  return (
    <div>
      <Carousel
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        autoPlay={false}
        arrows={false}
        infinite={true}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px">
        {topRatedCarsList?.map((item, index) => {
          return (
            <ExperienceImageBox
              key={index}
              image={
                item?.car?.carImagesUrl?.length >= 1 ? item?.car?.carImagesUrl[0] : experience2
              }
              text={item?.companyName ? item?.companyName : 'Ferrari'}
              icon={item?.icon}
              borderRadius={'10px'}
              screenType={screenType}
            />
          );
        })}
      </Carousel>
    </div>
  );
}
