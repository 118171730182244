import { useEffect } from 'react';
import Header from '../../../components/header';
import { Link } from 'react-router-dom';
import { useResponsive } from '../../../themes/useResponsive';
import { getCarReview } from '../../../store/slice/carOwner/review';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../components/spinner';
import { useState } from 'react';
import UserReviewBox from '../../../pages/owner/ratingAndReview/userReviewBox';
import ColoredButton from 'components/button/colorFullButtons';
import PopOverSpinner from 'components/popOverSpinner';

const MyRatingAndReview = () => {
  const { screenType } = useResponsive();
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const { ratingAndReviewData } = useSelector((state) => state.review);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
  }, []);
  useEffect(() => {
    fetchReviewData();
  }, []);
  const fetchReviewData = (perPage) => {
    let request = { page: 1, perPage: perPage ? perPage : limit, ratingType: 2, language: 'en' };
    dispatch(getCarReview(request, setIsLoading));
  };
  return (
    <>
      <div className="w-100" style={{ background: '#e5e5e5', minHeight: '100vh' }}>
        <Header background color />
        <div className="container bg-white p-4" style={{ marginTop: '4.5rem' }}>
          <div
            style={{
              fontFamily: 'Circular Std Medium',
              cursor: 'pointer'
            }}>
            <Link to={'/'} style={{ color: '#63575778', textDecoration: 'none' }}>
              Home
            </Link>
            &nbsp;/&nbsp;
            <span>My Ratings & Reviews</span>
            {isLoading && !ratingAndReviewData?.data ? <PopOverSpinner /> : ''}
            <div
              className="my-5"
              style={{
                marginLeft: screenType === 'MOBILE' ? '-1.5rem' : '',
                marginRight: screenType === 'MOBILE' ? '-1.5rem' : '',
                width: '100%'
              }}>
              {ratingAndReviewData?.data?.length >= 1 ? (
                <>
                  {ratingAndReviewData?.data?.map((item, index) => {
                    return (
                      <>
                        <UserReviewBox data={item} key={index} />
                      </>
                    );
                  })}

                  <div className="d-flex justify-content-center mt-5">
                    {isLoading ? (
                      <Spinner animation="border" style={{ color: '#6CD2C2' }} />
                    ) : (
                      ratingAndReviewData?.totalCount > limit && (
                        <ColoredButton
                          onClick={() => {
                            let perPage = limit + 10;
                            setLimit(perPage);
                            setIsLoading(true);
                            fetchReviewData(perPage);
                          }}
                          title="View More"
                          style={{ background: '#6CD2C2', width: '130px' }}
                        />
                      )
                    )}
                  </div>
                </>
              ) : (
                <div className="noData" style={{ height: '80vh' }}>
                  No Data Found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyRatingAndReview;
