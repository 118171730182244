import React, { useEffect } from 'react';
import { useResponsive } from '../../../../../themes/useResponsive';
import OutlineButton from '../../../../../components/button/outlineButton';
import moment from 'moment';
import defaultImage from '../../../../../assets/img/noProfile.jpg';
import noDataImage from 'assets/img/no_image.jpg';

export default function MobileViewDataCard({
  data,
  onClick,
  showCommision,
  btnTitle,
  btnColor,
  isNumber
}) {
  const { screenType } = useResponsive();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="row p-2 bg-white rounded-3 px-2 my-2" onClick={onClick}>
        <div className="col-12 d-flex justify-content-between ">
          <p
            style={{
              color: '#5c6064',
              fontFamily: 'Circular Std Book',
              fontSize: screenType === 'MOBILE' ? '12px' : '14px'
            }}>
            ID : {data?.orderId}
          </p>
          <p
            style={{
              color: '#5c6064',
              fontFamily: 'Circular Std Book',
              fontSize: screenType === 'MOBILE' ? '12px' : '14px'
            }}>
            {moment(data?.bookingDate?.from).format('Do MMM, YYYY')}
          </p>
        </div>
        <div className="col-12 d-flex justify-content-between ">
          {isNumber ? (
            <div className="checklist d-flex form-check font-size-15 rounded-5">
              <img
                src={data?.userData?.profileImage ? data?.userData?.profileImage : defaultImage}
                alt=""
                className="rounded-circle avatar-sm"
                style={{ marginLeft: '-2rem' }}
              />
              &nbsp; &nbsp;
              <div>
                <label
                  className="ms-1 task-title"
                  style={{
                    fontFamily: 'Circular Std Bold',
                    fontSize: '18px',
                    color: '#132649'
                  }}>
                  {data?.userData?.userName}
                </label>
                <div
                  style={{
                    color: '#5c6064',
                    fontFamily: 'Circular Std Book',
                    fontSize: '15px',
                    marginTop: '-0.5rem'
                  }}>
                  +{data?.userData?.countryCode} {data?.userData?.phoneNumber}
                </div>
              </div>
            </div>
          ) : (
            <div className="checklist form-check font-size-15 rounded-5">
              <img
                src={data?.userData?.profileImage ? data?.userData?.profileImage : defaultImage}
                alt=""
                className="rounded-circle avatar-sm"
                style={{ marginLeft: '-2rem' }}
              />
              &nbsp;
              <label
                className="ms-1 task-title"
                style={{
                  fontFamily: 'Circular Std Bold',
                  fontSize: '18px',
                  color: '#132649'
                }}>
                {data?.userData?.userName}
              </label>
            </div>
          )}
          <div className="d-flex pt-3">
            <h4
              style={{
                color: '#132649',
                fontFamily: 'Circular Std Medium',
                fontWeight: 300,
                fontSize: screenType === 'MOBILE' ? '14px' : '16px'
              }}>
              <span
                style={{
                  color: '#FFBB51',
                  fontFamily: 'Circular Std',
                  fontWeight: 100
                }}>
                N$
              </span>
              <span style={{ fontWeight: 600, fontSize: '18px' }}> {data?.totalAmount}</span>&nbsp;
              <span
                style={{
                  color: '#5c6064',
                  fontSize: '15px',
                  fontFamily: 'Circular Std Mixed'
                }}>
                {data?.bookingType === 'perDay'
                  ? 'Per Day'
                  : data?.bookingType === 'perHour'
                    ? 'Per Hour'
                    : 'Per Week'}
              </span>
            </h4>
          </div>
        </div>
        <div className="col-12 pt-3" style={{ marginLeft: '-1rem' }}>
          <div
            className="d-flex justify-content-between checklist form-check font-size-15 rounded-4  w-100 py-1 mr-2"
            style={{ background: '#F6F6F8' }}>
            <div>
              <img
                src={
                  data?.productData?.carImagesUrl?.length >= 1
                    ? data?.productData?.carImagesUrl[0]
                    : noDataImage
                }
                alt=""
                className="rounded-circle avatar-sm"
                style={{ marginLeft: '-1rem' }}
              />
              &nbsp;
              <label
                className="ms-1 task-title"
                style={{
                  fontFamily: 'Circular Std Bold',
                  fontSize: screenType === 'MOBILE' ? '16px' : '18px',
                  color: '#132649'
                }}>
                {data?.productData?.modelName}
              </label>
            </div>
            &nbsp;
            {btnTitle && (
              <div className="mt-1">
                <OutlineButton title={btnTitle} color={btnColor} />
              </div>
            )}
          </div>
        </div>
        {showCommision && (
          <div className="col-12 pt-3">
            <div className="d-flex">
              <h4
                style={{
                  color: '#132649',
                  fontFamily: 'Circular Std Medium',
                  fontWeight: 300,
                  fontSize: screenType === 'MOBILE' ? '14px' : '16px'
                }}>
                <span
                  style={{
                    color: '#FFBB51',
                    fontFamily: 'Circular Std',
                    fontWeight: 100
                  }}>
                  N$
                </span>
                <span style={{ fontWeight: 600, fontSize: '18px' }}> 1025</span>&nbsp;
                <span
                  style={{
                    color: '#5c6064',
                    fontSize: '15px',
                    fontFamily: 'Circular Std Mixed'
                  }}>
                  Per day
                </span>
              </h4>
            </div>

            <div
              style={{
                color: '#5c6064',
                fontSize: '12px',
                fontWeight: 400,
                fontFamily: 'Circular Std Book Italic'
              }}>
              Incl.20% commission(N$205)
            </div>
          </div>
        )}
      </div>
    </>
  );
}
