import React, { useEffect, useRef } from 'react';
import OwnerHeader from '../../../layouts/carOwner/header';
import CountBox from '../countBox';
import { useResponsive } from '../../../themes/useResponsive';
import MyCarBox from './myCarBox';
import SmallButton from '../../../components/button/smallButton';
import { useDispatch, useSelector } from 'react-redux';
import { clearCarDetails, deleteCar, getCarList } from '../../../store/slice/carOwner/review';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from '../../../components/spinner';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import DashBoardBookingList from './dashBoardBookingList';
import { loginData } from '../../../components/constant';
import { totalCounts } from '../../../store/slice/carOwner/bookingStatus';
import { myBookingStatus } from '../../../store/slice/carOwner/bookingStatus';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DeleteMyCar from 'components/dialog/deleteMyCar';
import pendingBookingIcon from 'assets/img/owner-icons/pending-booking.png';
import ActiveBookingIcon from 'assets/img/owner-icons/active-booking.png';
import completedBookingIcon from 'assets/img/owner-icons/complete-booking.png';
import myCarIcon from 'assets/img/owner-icons/my-car.png';
import PopOverSpinner from 'components/popOverSpinner';
import ColoredButton from 'components/button/colorFullButtons';

let limit = 10;
export default function Dashboard() {
  const { screenType } = useResponsive();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeDeleteDialogRef = useRef();
  const [carId, setCarId] = useState('');
  const deleteRef = useRef();
  const [isLoading, setIsloading] = useState(false);
  const [isListLoading, setIsListloading] = useState(false);
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const [bookingLimit, setbookingLimit] = useState(10);
  const { carOwnerCarsData } = useSelector((state) => state.review);
  const { allBookingsList, bookingTotalCount, totalCount } = useSelector(
    (state) => state.bookingsStatus
  );
  const [filterData, setFilterData] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getAllBookingStatus();
  }, [filterData]);

  const handleIconClick = () => {
    setShowCalendar(!showCalendar);
  };
  useEffect(() => {
    setIsListloading(true);
    window.scrollTo(0, 0);
    counts();
  }, []);
  useEffect(() => {
    getAllBookingStatus();
    counts();
  }, []);
  const getAllBookingStatus = (perPage) => {
    setIsBookingLoading(true);
    const data = {
      language: 'en',
      page: 1,
      perPage: perPage ? perPage : bookingLimit,
      type: 2,
      bookingType: '',
      orderId: '',
      startDate: filterData.start,
      endDate: filterData.end,
      sortByDate: filterData.sortByDate
    };
    dispatch(myBookingStatus(data, '', setIsListloading, setIsBookingLoading));
  };
  // get car list
  useEffect(() => {
    if (loginData) {
      fetchCarlist();
    }
  }, [loginData]);
  const fetchCarlist = () => {
    let request = {
      search: '',
      page: 1,
      limit: 10,
      language: 'en',
      userId: loginData?.id,
      type: 2
    };
    dispatch(getCarList(request, setIsloading));
  };
  const counts = () => {
    let request = {
      language: 'en'
    };
    dispatch(totalCounts(request));
  };
  const handleScroll = () => {
    setIsloading(true);
    limit = limit + 10;
    fetchCarlist(limit);
  };

  const handleConfirmDelete = () => {
    let request = {
      carId: carId
    };
    dispatch(deleteCar(request, closeDeleteDialogRef));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <OwnerHeader title={'Dashboard'} />
      {isListLoading ? <PopOverSpinner /> : ''}
      <>
        <div
          className="my-5 pt-4"
          style={{ height: 'auto', marginRight: '-1rem', paddingLeft: '1rem' }}>
          <div className="row">
            <div className={screenType != 'desktop' ? 'col-12' : 'col-8'}>
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12 dashBoard-col">
                  <CountBox
                    title={'Pending Requests'}
                    count={totalCount?.pendingBookingCount}
                    bg={'#FFEAEA'}
                    image={pendingBookingIcon}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12  dashBoard-col">
                  <CountBox
                    title={'Active Booking'}
                    count={totalCount?.activeCarCount}
                    bg={'#ECFFE0'}
                    image={ActiveBookingIcon}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12  dashBoard-col">
                  <CountBox
                    title={'Completed Booking'}
                    count={totalCount?.completedBooking}
                    bg={'#FCEBFF'}
                    image={completedBookingIcon}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12  dashBoard-col">
                  <CountBox
                    title={'Car'}
                    count={totalCount?.totalCarCount}
                    bg={'#FFF2DF'}
                    image={myCarIcon}
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-sm-8 fs-2 mt-3 carOwner-tab-title">Bookings</div>
                <div className="col-sm-4 d-flex justify-content-end mt-3 ">
                  <form className="form-search form-inline rounded-5">
                    <div className="dropdown">
                      <button
                        style={{ width: 150 }}
                        className="sort-List dropdown-toggle dropdown-toggle-split"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <label style={{ fontSize: '16px' }}>Sort by</label>
                        <Icon icon={'formkit:down'} width={'20px'} />
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            setFilterData((filterData) => ({
                              ...filterData,
                              sortByDate: -1
                            }));
                          }}>
                          New-Old
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            setFilterData((filterData) => ({
                              ...filterData,
                              sortByDate: 1
                            }));
                          }}>
                          Old-New
                        </a>
                        {/* <a className="dropdown-item" href="#">
                        Something else here
                      </a> */}
                      </div>
                    </div>
                    <div
                      className="checklist bg-white rounded-4 ml-2 d-flex justify-content-center align-items-center"
                      style={{ height: 40, width: 40 }}>
                      <Icon
                        icon="octicon:calendar-16"
                        style={{ color: 'black', cursor: 'pointer' }}
                        onClick={handleIconClick}
                      />
                      {showCalendar && (
                        <div
                          ref={calendarRef}
                          style={{ position: 'absolute', top: '30px', left: '0', zIndex: 5 }}>
                          {/* <Calendar onChange={handleCalendarChange} value={date} /> */}
                          <DateRange
                            editableDateInputs={true}
                            onChange={(item) => setState([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={state}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
                {/* {allBookingsList
                  .filter((item) => {
                    const bookingDate = new Date(item.startDate); // adjust this according to your booking date field
                    return (
                      (!filterData.start || bookingDate >= filterData.start) &&
                      (!filterData.end || bookingDate <= filterData.end)
                    );
                  })
                  .map((item, index) => (
                    <DashBoardBookingList item={item} key={index} />
                  ))} */}

                {allBookingsList?.map((item, index) => {
                  return <DashBoardBookingList item={item} key={index} />;
                })}
                <div className="d-flex justify-content-center mt-5">
                  {isBookingLoading && !isListLoading ? (
                    <Spinner animation="border" style={{ color: '#6CD2C2' }} />
                  ) : (
                    bookingTotalCount > bookingLimit && (
                      <ColoredButton
                        onClick={() => {
                          let perPage = bookingLimit + 10;
                          setbookingLimit(perPage);
                          getAllBookingStatus(perPage);
                        }}
                        title="View More"
                        style={{ background: '#6CD2C2', width: '130px' }}
                      />
                    )
                  )}
                </div>
              </div>
            </div>

            {screenType === 'desktop' && (
              <div className="col-4">
                <div
                  className="py-4 px-3"
                  id="scrollableDiv"
                  style={{
                    background: 'white',
                    borderRadius: 15,
                    overflow: 'auto',
                    height: '89vh'
                  }}>
                  <div className="d-flex justify-content-between pb-3">
                    <div className="carOwner-heading">Cars</div>
                    <SmallButton
                      onClick={() => {
                        dispatch(clearCarDetails());
                        navigate('/car-owner/mycars/add-Car');
                      }}
                      title={'Add New'}
                      width={100}
                    />
                  </div>

                  <InfiniteScroll
                    dataLength={carOwnerCarsData?.data?.length ? carOwnerCarsData?.data?.length : 0}
                    next={handleScroll}
                    hasMore={true}
                    scrollableTarget="scrollableDiv">
                    {carOwnerCarsData?.data?.length >= 1 ? (
                      <>
                        {carOwnerCarsData?.data?.map((item, index) => {
                          return (
                            <>
                              <MyCarBox
                                data={item}
                                key={index}
                                deleteRef={deleteRef}
                                setCarId={setCarId}
                              />
                            </>
                          );
                        })}
                        {isLoading && <Spinner />}
                      </>
                    ) : (
                      <div className="noData" style={{ height: '70vh' }}>
                        No Data Found
                      </div>
                    )}
                  </InfiniteScroll>
                </div>
              </div>
            )}
          </div>
        </div>
        <DeleteMyCar
          handleConfirm={handleConfirmDelete}
          closeDeleteDialogRef={closeDeleteDialogRef}
        />
        <button
          ref={deleteRef}
          data-toggle="modal"
          data-target="#exampleModalDeleteCenter"
          style={{ display: 'none' }}></button>
      </>
    </>
  );
}
