import React, { useEffect } from 'react';
import Header from '../../components/header';
import { Link, useParams } from 'react-router-dom';
import './style.css';
import { blogDescription } from '../../store/slice/landingPage';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useResponsive } from 'themes/useResponsive';

export const BlogDescription = () => {
  const { blogDetail } = useSelector((state) => state.landingPage);
  const { screenType } = useResponsive();
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (id) {
      let request = {
        language: 'en',
        blogId: id
      };
      dispatch(blogDescription(request));
    }
  }, []);
  // useEffect(() => {
  //   fetchblogDetail();
  // }, []);
  // const fetchblogDetail = () => {
  //   if (id) {
  //     let request = {
  //       language: "en",
  //       blogId: id,
  //     };
  //     dispatch(blogDescription(request));
  //   }
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="w-100" style={{ background: '#e5e5e5', minHeight: '100vh' }}>
        <Header background color />

        <div className="container bg-white p-4" style={{ marginTop: '4.5rem' }}>
          <div
            style={{
              fontFamily: 'Circular Std Medium',
              cursor: 'pointer'
            }}>
            <Link to={'/'} style={{ color: '#63575778', textDecoration: 'none' }}>
              Home
            </Link>
            &nbsp;/&nbsp;
            <Link to={'/car-blog'} style={{ color: '#63575778', textDecoration: 'none' }}>
              My Blogs
            </Link>
            &nbsp;/&nbsp;<span>Details</span>
          </div>
          <h3 className="ml-3 mt-4" style={{ fontFamily: 'Circular Std Black' }}>
            {blogDetail?.addTags}
          </h3>
          <h6 className="ml-3" style={{ fontFamily: 'Circular Std Book', color: '#8992A4' }}>
            {moment(blogDetail?.updatedAt).format('Do MMM, YYYY')}
          </h6>
          <div className="col-md-12 col-sm-12">
            <img
              src={blogDetail?.blogThumbnail}
              style={{
                objectFit: 'cover',
                borderRadius: '1rem',
                width: '100%',
                height: screenType == 'MOBILE' ? '10rem' : '35rem'
              }}
            />
            <p className="mt-3 blog-paragraph">{blogDetail?.writeBlogs}</p>
            {/* <p className="blog-paragraph">{blogDetail?.writeBlogs}</p>
            <p className="blog-paragraph">{blogDetail?.writeBlogs}</p> */}
          </div>
        </div>
      </div>
    </>
  );
};
