import React from 'react';

const PopOverSpinner = () => {
  return (
    <>
      <div
        style={{
          position: 'fixed',
          width: '100 %',
          height: '100 %',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgb(109 107 107 / 50%)',
          zIndex: 2,
          cursor: 'pointer'
        }}>
        <div
          className="position-absolute top-50 start-50 translate-middle"
          style={{ color: 'var(--bs-lt-red)', zIndex: 1000 }}>
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(PopOverSpinner);
