import { Navigate, useRoutes } from 'react-router-dom';
import UserLayout from '../layouts/user';
import LandingPage from '../pages/landingPage';
import AuthLayout from '../layouts/auth';
import Login from '../pages/auth/login';
import ForgotPassword from '../pages/auth/forgotPassword.js';
import Register from '../pages/auth/register/register.js';
import { loginData } from '../components/constant/index.js';
import NotFoundPage from '../pages/404page/index.js';
import CarOwnerLayout from '../layouts/carOwner/index.js';
import UserEmailVerfication from '../pages/auth/userEmailVerification/index.js';
import Messages from '../pages/owner/messages/index.js';
import HelpPage from '../pages/help/index.js';
// import FAQs from '../pages/help/fAQ.js';
import { CarBlog } from '../pages/carBlog/index.js';
import { BlogDescription } from '../pages/carBlog/BlogDescription.js';
import FavoritesPage from '../pages/customer/favorites/index.js';
import IdentityPage from '../pages/customer/myIdentity/index.js';
import MyRatingAndReview from '../pages/customer/ratingsReviews/index.js';
import SmartSearch from '../pages/smartSearch/index.js';
import NotificationLanding from '../pages/customer/homePanel/notificationLanding';
import CarPayment from '../pages/customer/carDetails/carPayment';
import Details from '../pages/customer/myBookings/detail';
import { MyBooking } from '../pages/customer/myBookings';
import CancellationPolicy from '../pages/customer/cancellation';
import PrivacyPolicy from '../pages/customer/privacyPolicy';
import CarDetails from '../pages/customer/carDetails';
import RefundPayment from '../pages/owner/myBookings/activeBooking/refundPayment';
import BookingDetails from '../pages/owner/myBookings/activeBooking/bookingDetails';
import MyBookings from '../pages/owner/myBookings';
import Notification from '../pages/owner/notifications';
import UserProfile from '../pages/owner/myBookings/pendingRequest/userProfile';
import History from '../pages/owner/myBookings/completedBooking/history';
import Payment from '../pages/owner/payment';
import AddEditCar from '../pages/owner/myCar/addEditCar';
import MyCars from '../pages/owner/myCar';
import RatingAndReview from '../pages/owner/ratingAndReview';
import Dashboard from '../pages/owner/dashBoard';
import Test from '../pages/test';

export default function Router() {
  const routes = useRoutes([
    // -------------------   user routing -----------------------

    {
      path: '/',
      element: <UserLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: '/car-details/:id', element: <CarDetails /> },
        { path: '/privacy-policy/', element: <PrivacyPolicy /> },
        { path: '/cancellation-policy/', element: <CancellationPolicy /> },
        { path: '/help/', element: <HelpPage /> },
        { path: '/FAQs', element: <HelpPage /> },
        { path: '/car-blog', element: <CarBlog /> },
        { path: '/car-blog/blog-detail/:id', element: <BlogDescription /> },
        { path: '/my-bookings', element: <MyBooking /> },
        { path: '/booking-details', element: <Details /> },
        { path: '/favorites-page', element: <FavoritesPage /> },
        { path: '/identity-page', element: <IdentityPage /> },
        { path: '/rating-review-page', element: <MyRatingAndReview /> },
        { path: '/payment/:id', element: <CarPayment /> },
        { path: '/smart-search', element: <SmartSearch /> },
        { path: '/notifications', element: <NotificationLanding /> },
        { path: '/test', element: <Test /> },
        {
          path: '/messages',
          element:
            loginData && loginData?.userType == 3 ? (
              <Messages />
            ) : loginData && loginData?.userType == 2 ? (
              <Navigate to="/car-owner/messages" />
            ) : (
              <Navigate to="/auth/login" />
            )
        },

        {
          path: '/UserEmailVerfication/:id',
          element: loginData ? <Navigate to="/" /> : <UserEmailVerfication />
        }
      ]
    },
    // -------------------   Auth routing -----------------------
    {
      path: '/auth',
      element: loginData ? <Navigate to="/" /> : <AuthLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'forgotPassword', element: <ForgotPassword /> },
        { path: 'signUp', element: <Register /> }
      ]
    },
    // -------------------   carOwner routing -----------------------

    {
      path: '/car-owner',
      element: loginData ? <CarOwnerLayout /> : <Navigate to="/" />,
      children: [
        { path: '/car-owner/dashboard', element: <Dashboard /> },
        { path: '/car-owner/myrating-review', element: <RatingAndReview /> },
        { path: '/car-owner/mycars', element: <MyCars /> },
        { path: '/car-owner/mycars/add-Car', element: <AddEditCar /> },
        {
          path: '/car-owner/payment',
          element: <Payment />
        },
        { path: '/car-owner/mybooking', element: <MyBookings /> },

        { path: '/car-owner/mybooking/booking-history/:id', element: <History /> },
        {
          path: '/car-owner/mybooking/refund-payment',
          element: <RefundPayment />
        },
        { path: '/car-owner/mycars/edit-Car/:id', element: <AddEditCar /> },
        {
          path: '/car-owner/messages',
          element:
            loginData && loginData?.userType == 2 ? <Messages /> : <Navigate to="/messages" />
        },
        { path: '/car-owner/messages/:id', element: <Messages /> },
        { path: '/car-owner/notification', element: <Notification /> },
        { path: '/car-owner/mybooking/user-profile/:id', element: <UserProfile /> },
        { path: '/car-owner/mybooking/booking-details/:id', element: <BookingDetails /> },
        { path: '/car-owner/mybooking/activeBookings/refundPayment', element: <RefundPayment /> }
      ]
    },
    { path: '*', element: <NotFoundPage /> }
  ]);

  return routes;
}
