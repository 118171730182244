import React, { useEffect, useState } from 'react';
import Header from '../../../components/header';
import { Link } from 'react-router-dom';
import { CurrentBooking } from './currentBooking';
import BookingHistory from './bookingHistory';
import TabBottomLine from '../../../pages/owner/myCar/subComponent/tabBottomLine';
import { useDispatch, useSelector } from 'react-redux';
import { myBookingStatus } from 'store/slice/carOwner/bookingStatus';
import { updateFavBookingList } from 'store/slice/carOwner/bookingStatus';
import PopOverSpinner from 'components/popOverSpinner';
export const MyBooking = () => {
  const [activeTab, setActiveTab] = useState('carInfo');
  const { allBookingsList, bookingTotalCount } = useSelector((state) => state.bookingsStatus);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getAllList();
    setIsListLoading(true);
  }, []);

  useEffect(() => {
    getAllList();
  }, [activeTab]);

  const getAllList = (perPage) => {
    setIsLoading(true);
    const data = {
      language: 'en',
      page: 1,
      perPage: perPage ? perPage : limit,
      type: 1,
      bookingType: activeTab === 'carInfo' ? 3 : 2,
      orderId: '',
      startDate: '',
      endDate: '',
      sortByDate: '',
      carNumber: ''
    };
    dispatch(myBookingStatus(data, setIsLoading, setIsListLoading));
  };

  const updateFav = (request) => {
    dispatch(updateFavBookingList(request));
  };
  console.log('allBookingsList', allBookingsList);
  return (
    <>
      <div className="w-100" style={{ background: '#e5e5e5', minHeight: '100vh' }}>
        <Header background color />

        <div className="container bg-white p-4" style={{ marginTop: '4.5rem' }}>
          <div
            style={{
              fontFamily: 'Circular Std Medium',
              cursor: 'pointer'
            }}>
            <Link to={'/'} style={{ color: '#63575778', textDecoration: 'none' }}>
              Home
            </Link>
            &nbsp;/&nbsp;<span>My Booking</span>
            {isListLoading ? <PopOverSpinner /> : ''}
            <>
              <ul
                className="nav nav-pills mt-5"
                id="pills-tab"
                role="tablist"
                onClick={(event) => {
                  setLimit(10);
                  let value = event.target.id;
                  let id = value.split('-')[1];
                  setActiveTab(id);
                  setIsListLoading(true);
                }}
                style={{ width: '100vw !important' }}>
                <li
                  className="nav-item "
                  role="presentation"
                  // style={{ width: screenType === 'MOBILE' ? '50%' : '30%' }}
                  style={{ width: '50%' }}>
                  <button
                    id="pills-carInfo-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#car-info"
                    className="tab-button active">
                    Current Booking
                    {activeTab === 'carInfo' && <TabBottomLine />}
                  </button>
                </li>
                <li className="nav-item" role="presentation" style={{ width: '50%' }}>
                  <button
                    id="pills-BasicInfo-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#Basic-Info"
                    className="tab-button">
                    Booking History
                    {activeTab === 'BasicInfo' && <TabBottomLine />}
                  </button>
                </li>
              </ul>
              <div>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="car-info"
                    role="tabpanel"
                    aria-labelledby="pills-carInfo-tab">
                    <CurrentBooking
                      allBookingsList={allBookingsList}
                      setLimit={setLimit}
                      limit={limit}
                      isLoading={!isListLoading ? isLoading : false}
                      bookingTotalCount={bookingTotalCount}
                      getAllList={getAllList}
                      updateFav={updateFav}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Basic-Info"
                    role="tabpanel"
                    aria-labelledby="pills-BasicInfo-tab">
                    <BookingHistory
                      allBookingsList={allBookingsList}
                      setLimit={setLimit}
                      limit={limit}
                      isLoading={!isListLoading ? isLoading : false}
                      bookingTotalCount={bookingTotalCount}
                      getAllList={getAllList}
                      updateFav={updateFav}
                    />
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};
