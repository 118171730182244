import React from 'react';
import './index.css';
import { TimePicker } from 'antd';
export default function index(props) {
  const { placeholder, onChange } = props;

  const format = 'HH:mm';

  return (
    <>
      <TimePicker
        // value={value}
        placeholder={placeholder}
        format={format}
        onChange={onChange}
        // value={value}
        className="time-picker"
        suffixIcon={null}
        allowClear={false}
        customStyles={{
          dateInput: {
            borderLef: 0,
            borderRightWidth: 0,
            borderTopWidth: 0
          }
        }}
      />
    </>
  );
}
