import { Icon } from '@iconify/react';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function BlogCard({ ...props }) {
  const { data, screenType } = props;
  const navigate = useNavigate();

  return (
    <div
      className="card"
      style={{
        borderRadius: 19,
        position: 'relative',
        width: screenType === 'MOBILE' ? '100%' : '97%'
      }}
      onClick={() => navigate(`/car-blog/blog-detail/${data?.id}`)}>
      <div
        className="bg-image-container"
        style={{
          backgroundImage: `url("${data?.blogThumbnail}")`,
          backgroundSize: 'cover',
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          height: screenType === 'MOBILE' ? 200 : screenType === 'TABLET' ? 210 : 230,
          width: screenType === 'MOBILE' ? '100%' : screenType === 'TABLET' ? '100%' : '100%'
        }}>
        <div className="gradient">
          <div className="text-container">
            <div className="body ">
              <div className="d-inline blog-head">{data?.mainTitle}</div>
              &nbsp;
              <Icon icon={'radix-icons:dot-filled'} />
              &nbsp;
              <div className="d-inline blog-head">{data.mainTitle}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-body"
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}>
        <small
          className="d-inline text-muted"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>
          {moment(data?.createdAt).format('Do MMM, YYYY')}
        </small>
        <h5 className="card-title pt-2 ">{data?.addTags}</h5>
        <div
          className="d-inline text-muted"
          style={{
            fontFamily: 'Circular Std Book',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordWrap: 'nowrap',
            width: '100px'
          }}>
          {data?.writeBlogs}
        </div>
      </div>
    </div>
  );
}
