import { Icon } from '@iconify/react';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import './style.css';
import { faqConfig } from './config';

const FAQs = () => {
  return (
    <>
      <h5 className="popularQueriesHeading text-center mt-5">Popular Queries</h5>
      <Accordion
        defaultActiveKey="0"
        flush
        className="col-md-10 mx-auto accordion accordion-flush accordion-header shadow-none"
        id="accordionFlushExample">
        {faqConfig?.map((item, index) => {
          return (
            <Accordion.Item eventKey={index > 0 ? index : '0'} flush key={index}>
              <Accordion.Header className="accordion-header">
                <Icon icon={'icon-park-outline:dot'} color="#F1AE01" height={25} />
                <span className="Queries">{item.question}</span>
              </Accordion.Header>
              <Accordion.Body flush>
                <span className="subparagraphAccordian">{item.answer}</span>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}

        {/* <Accordion.Item eventKey="0" flush>
          <Accordion.Header className="accordion-header">
            <Icon icon={'icon-park-outline:dot'} color="#F1AE01" height={25} />
            <span className="Queries">What happen if i return the car late?</span>
          </Accordion.Header>
          <Accordion.Body flush>
            <span className="subparagraphAccordian">
              There are many variations of passages of Lorem Ipsum available, but the majority have
              suffered alteration in some form, by injected humour, or randomised words which dont
              look even slightly believable.
            </span>
          </Accordion.Body>
        </Accordion.Item> */}
        {/* <Accordion.Item eventKey="1" flush>
          <Accordion.Header flush>
            <Icon icon={'icon-park-outline:dot'} color="#F1AE01" height={25} />
            <span className="Queries">Is there akm limit to how much i can drive</span>
          </Accordion.Header>
          <Accordion.Body flush>
            <span className="subparagraphAccordian">
              There are many variations of passages of Lorem Ipsum available, but the majority have
              suffered alteration in some form, by injected humour, or randomised words which dont
              look even slightly believable.
            </span>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" flush>
          <Accordion.Header flush>
            <Icon icon={'icon-park-outline:dot'} color="#F1AE01" height={25} />
            <span className="Queries" style={{ color: '#132649' }}>
              Do i have to return the car to same location where I picked it up?
            </span>
          </Accordion.Header>
          <Accordion.Body flush>
            <span className="subparagraphAccordian">
              There are many variations of passages of Lorem Ipsum available, but the majority have
              suffered alteration in some form, by injected humour, or randomised words which dont
              look even slightly believable.
            </span>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" flush>
          <Accordion.Header flush>
            {' '}
            <Icon icon={'icon-park-outline:dot'} color="#F1AE01" height={25} />
            <span className="Queries">What happen if i return the car late?</span>
          </Accordion.Header>
          <Accordion.Body flush>
            <span className="subparagraphAccordian">
              There are many variations of passages of Lorem Ipsum available, but the majority have
              suffered alteration in some form, by injected humour, or randomised words which dont
              look even slightly believable.
            </span>
          </Accordion.Body>
        </Accordion.Item> */}
      </Accordion>
    </>
  );
};

export default FAQs;
