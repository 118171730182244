import React from 'react';
import Input from '../../../components/input';
import Button from '../../../components/button';
import { Link } from 'react-router-dom';

export default function ForgotEmail(props) {
  const { setForgotData, forgotData, handleGetOtp } = props;

  const handleEmail = () => {
    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    if (!forgotData.email) {
      setForgotData((forgotData) => ({
        ...forgotData,
        emailErr: 'Please enter your email address'
      }));
      return;
    } else if (!forgotData?.email.includes('@')) {
      setForgotData((forgotData) => ({
        ...forgotData,
        emailErr: 'Please enter a valid email address'
      }));
      return;
    } else if (!forgotData?.email.includes('.')) {
      setForgotData((forgotData) => ({
        ...forgotData,
        emailErr: 'Please enter a valid email address'
      }));
      return;
    } else if (!validEmail.test(forgotData?.email)) {
      setForgotData((forgotData) => ({
        ...forgotData,
        emailErr: 'Please enter a valid email address'
      }));
      return;
    }
    handleGetOtp();
  };
  return (
    <>
      <div className="pb-3">
        <h3 className="authHeading">Oops! </h3>
        <h3 className="authHeading">Forgot Password.</h3>
      </div>
      <form action="#" method="post" className="pt-5">
        <div className="form-group first ">
          <Input
            onChange={(val) => {
              setForgotData((forgotData) => ({ ...forgotData, email: val, emailErr: '' }));
            }}
            value={forgotData?.email}
            formError={forgotData?.emailErr}
            placeholder={'Email Address'}
            icon={'mdi-light:email'}
          />
        </div>
        <div className="d-flex mb-5 align-items-center pt-3">
          <p className="hint-text">
            <Link className="hint-text" to="/auth/login">
              Back To Login ?
            </Link>
          </p>
        </div>
      </form>
      <Button onClick={handleEmail} title={'Forgot Password'} />
    </>
  );
}
