import React, { useEffect, useState } from 'react';
import Header from '../../../components/header';
import { Link } from 'react-router-dom';
import LatestCardImageBox from '../../landingPage/subComponent/latestCars/latestCardImageBox';
import { useResponsive } from '../../../themes/useResponsive';
import { useDispatch, useSelector } from 'react-redux';
import { getfavoriteList } from '../../../store/slice/carUser/favorite';
import { updateFavList } from 'store/slice/carUser/favorite';
import ColoredButton from 'components/button/colorFullButtons';
import Spinner from 'react-bootstrap/Spinner';
import PopOverSpinner from 'components/popOverSpinner';

const FavoritesPage = () => {
  const { screenType } = useResponsive();
  const { favoriteCarsList } = useSelector((state) => state.favorite);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    fetchFavoriteList();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateFav = (request) => {
    dispatch(updateFavList(request));
  };
  const fetchFavoriteList = (perPage) => {
    setIsLoading(true);
    let request = {
      page: 1,
      perPage: perPage ? perPage : limit,
      language: 'en'
      // status: !isFavorite
    };
    dispatch(getfavoriteList(request, setIsLoading));
  };
  console.log('favoriteCarsList', favoriteCarsList);
  return (
    <>
      <div className="w-100" style={{ background: '#e5e5e5' }}>
        <Header background color />

        <div className="container bg-white p-4" style={{ marginTop: '4.5rem', minHeight: '100vh' }}>
          <div
            style={{
              fontFamily: 'Circular Std Medium',
              cursor: 'pointer',
              marginTop: screenType === 'MOBILE' ? '1rem' : ''
            }}>
            <Link to={'/'} style={{ color: '#63575778', textDecoration: 'none' }}>
              Home
            </Link>
            &nbsp;/&nbsp;
            <span>Favorites</span>
            {isLoading && !favoriteCarsList?.data ? <PopOverSpinner /> : ''}
            <div className="row mx-2 mt-5">
              {favoriteCarsList?.data?.length >= 1 ? (
                <>
                  {favoriteCarsList?.data?.map((item, index) => {
                    return (
                      <div className="col-lg-4 col-md-6 col-sm-12 gy-3" key={index} data={item}>
                        <LatestCardImageBox
                          // excludeLinks={true}
                          data={item}
                          // formData={favoriteList}
                          // setFormData={setfavoriteList}
                          updateFav={updateFav}
                        />
                      </div>
                    );
                  })}

                  <div className="d-flex justify-content-center mt-5">
                    {isLoading ? (
                      <Spinner animation="border" style={{ color: '#6CD2C2' }} />
                    ) : (
                      favoriteCarsList?.TotalFavoriteCars > limit && (
                        <ColoredButton
                          onClick={() => {
                            let perPage = limit + 10;
                            setLimit(perPage);
                            fetchFavoriteList(perPage);
                          }}
                          title="View More"
                          style={{ background: '#6CD2C2', width: '130px' }}
                        />
                      )
                    )}
                  </div>
                </>
              ) : (
                <div className="noData" style={{ height: '80vh' }}>
                  No Data Found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FavoritesPage;
