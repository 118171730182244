import React from 'react';

export default function TabBottomLine() {
  return (
    <>
      <div
        style={{
          width: '100%',
          height: 7,
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bottom: 0
        }}>
        <div className="bottom-line"></div>
      </div>
    </>
  );
}
