import { createSlice } from '@reduxjs/toolkit';
import api from '../../../services';
import toast from 'react-hot-toast';
import { RENTEDWHEELSAPI } from '../../../services/apiConstant';

const slice = createSlice({
  name: 'notification',
  initialState: {
    NotificationsList: []
    // unreadCount:[]
  },
  reducers: {
    getnotificationListSuccess: (state, action) => {
      state.NotificationsList = action.payload;
      // state.unreadCount = action.payload.unreadCount;
      console.log('action.payloadnotification', action.payload.unreadCount);
    },
    readNotificationSuccess: (state, action) => {
      console.log('act', action.payload.data);
      let index = state.NotificationsList.data.findIndex(
        (item) => item.id === action.payload.data._id
      );
      //  state.unreadCount = [];
      console.log('index', index);
    }
  }
});
export default slice.reducer;

/**********************ACTIONS************************************************ */

const { getnotificationListSuccess, readNotificationSuccess } = slice.actions;

export const getnotificationListing =
  (request, setIsLoading, setIsListloading) => async (dispatch) => {
    api
      .post(`${RENTEDWHEELSAPI.notification.getnotificationListing}`, request)
      .then((response) => {
        let result = response.data;
        if (result.status == 1) {
          dispatch(getnotificationListSuccess(result));
        } else {
          toast.error(result.message);
        }
        if (setIsLoading) {
          setIsLoading(false);
        }
        setIsListloading(false);
      })
      .catch(() => {});
  };

// read notification
export const readNotification = (request, setIsLoading) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.notification.readNotification}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(readNotificationSuccess(result));
      } else {
        toast.error(result.message);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
    })
    .catch(() => {});
};
export const ratingReview = (request, setIsLoading, handleClose) => async () => {
  api
    .post(`${RENTEDWHEELSAPI.notification.ratingReview}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
      setIsLoading(false);
      handleClose();
    })
    .catch(() => {});
};
