import React from 'react';
import Header from '../../../components/header';
import { Link } from 'react-router-dom';
import './style.css';
import NotificationList from '../../commonComponent/notificationList';

const NotificationLanding = () => {
  return (
    <>
      <div className="w-100 " style={{ background: '#e5e5e5', minHeight: '100vh' }}>
        <Header background color />

        <div className="container bg-white p-4 pb-5" style={{ marginTop: '4.5rem' }}>
          <div
            style={{
              fontFamily: 'Circular Std Medium',
              cursor: 'pointer'
            }}>
            <Link to={'/'} style={{ color: '#63575778', textDecoration: 'none' }}>
              Home
            </Link>
            &nbsp;/&nbsp;<span>Notifications</span>
          </div>

          <NotificationList />
        </div>
      </div>
    </>
  );
};

export default NotificationLanding;

{
  /* <div style={{ marginTop: '4rem' }}>
            <div className="card task-box" style={{ border: 'none' }}>
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-xl-10 col-sm-8">
                    <h3 style={{ color: '#132649', fontFamily: 'Circular Std Bold' }}>
                      N$90 Deposit Refunded
                    </h3>
                    <p
                      style={{
                        color: '#5c6064',
                        fontFamily: 'Circular Std Book',
                        fontSize: '15px'
                      }}>
                      Your white Dodge Challenger Booking Date 20-02-196 deposit amount has been
                      Refunded
                    </p>
                  </div>
                  <div className="col-xl-2 col-sm-4 text-sm-right">
                    <div style={{ color: '#5c6064', fontFamily: 'Circular Std Book' }}>
                      Today at 06:24 pm
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '1rem' }}>
            <div className="card task-box rounded-3" style={{ border: 'none' }}>
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-xl-10 col-sm-8">
                    <h3 style={{ color: '#132649', fontFamily: 'Circular Std Bold' }}>
                      Your Booking was cancelled by Owner
                    </h3>
                    <p
                      style={{
                        color: '#5c6064',
                        fontFamily: 'Circular Std Book',
                        fontSize: '15px'
                      }}>
                      Booking ID:123456789
                    </p>
                  </div>
                  <div className="col-xl-2 col-sm-4 text-sm-right">
                    <div style={{ color: '#5c6064', fontFamily: 'Circular Std Book' }}>
                      Yesterday
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '1rem' }}>
            <div className="card task-box round" style={{ border: 'none' }}>
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-xl-10 col-sm-8">
                    <h3 style={{ color: '#132649', fontFamily: 'Circular Std Bold' }}>
                      Your Booking has been Successful
                    </h3>
                    <p
                      style={{
                        color: '#5c6064',
                        fontFamily: 'Circular Std Book',
                        fontSize: '15px'
                      }}>
                      Booking Id:125634798
                    </p>
                    <button className="button">Track your Booking</button>
                  </div>
                  <div className="col-xl-2 col-sm-4 text-sm-right">
                    <div style={{ color: '#5c6064', fontFamily: 'Circular Std Book' }}>2d ago</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '1rem' }}>
            <div className="card task-box rounded-3" style={{ border: 'none' }}>
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-xl-10 col-sm-8">
                    <h3 style={{ color: '#132649', fontFamily: 'Circular Std Bold' }}>
                      Profile picture Updated
                    </h3>
                  </div>
                  <div className="col-xl-2 col-sm-4 text-sm-right">
                    <div style={{ color: '#5c6064', fontFamily: 'Circular Std Book' }}>3d ago</div>
                  </div>
                </div>
              </div>
            </div>
          </div> */
}
