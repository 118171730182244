import React, { useEffect } from 'react';
import { useState } from 'react';
import OTPInput from 'react-otp-input';
// import { useResponsive } from '../../../themes/useResponsive';
import Button from '../../../components/button';
import { useDispatch } from 'react-redux';
import { verfiyOtpForResetPass } from '../../../store/slice/auth';

export default function ForgotOtp(props) {
  const { setForgotData, handleGetOtp, forgotData } = props;
  const [otp, setOtp] = useState('');
  // const { screenType } = useResponsive();
  const dispatch = useDispatch();
  useEffect(() => {
    if (otp) {
      setForgotData((forgotData) => ({
        ...forgotData,
        otpErr: ''
      }));
    }
  }, [otp]);
  const handleVerifyOtp = () => {
    if (!otp) {
      setForgotData((forgotData) => ({
        ...forgotData,
        otpErr: 'Please enter OTP'
      }));
      return;
    }
    if (otp.length < 4) {
      setForgotData((forgotData) => ({
        ...forgotData,
        otpErr: 'Please enter valid OTP'
      }));
      return;
    }
    let request = {
      email: forgotData?.email,
      enteredOtp: otp * 1
    };
    dispatch(verfiyOtpForResetPass(request, setForgotData));
  };
  return (
    <>
      <div className="pb-3">
        <h3 className="authHeading">Oops! </h3>
        <h3 className="authHeading">Forgot Password.</h3>
      </div>
      <p className="mb-4 text-muted pr-5" style={{ fontFamily: 'Circular Std Book', fontSize: 19 }}>
        Your registered email address is {forgotData?.email}. Please enter the received OTP to reset
        the password.
      </p>
      <div className="mt-5">
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          className="gap-5"
          renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
          renderInput={(props) => (
            <input {...props} className="rounded-3 w-100 text-center px-1 gap-2" type="number" />
          )}
        />
      </div>
      <div
        style={{
          color: 'red',
          fontSize: 13,
          marginTop: '0.5rem',
          paddingLeft: 5
        }}
        className="text-error">
        {forgotData?.otpErr && forgotData?.otpErr}
      </div>
      <div className="mt-5">
        <Button onClick={handleVerifyOtp} title={'Verify'} />
      </div>
      <div className="d-flex mt-5 mb-5 align-items-center">
        <div className="text-left text-muted login-footer-text">{'Didn’t receive OTP ? '} </div>{' '}
        &nbsp;
        <div className="d-inline highlight" onClick={handleGetOtp}>
          Resend
        </div>
      </div>
    </>
  );
}
