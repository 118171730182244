import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/header';
import './style.css';
import { useResponsive } from '../../themes/useResponsive';
import { Icon } from '@iconify/react';
import moment from 'moment';
import { getAllBlogsList } from '../../store/slice/landingPage';
import { useDispatch, useSelector } from 'react-redux';
import PopOverSpinner from 'components/popOverSpinner';

export const CarBlog = () => {
  const { screenType } = useResponsive();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { blogsData } = useSelector((state) => state.landingPage);
  const [isLoading, setIsloading] = useState(false);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    fetchBlogDataList();
    setIsloading(true);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchBlogDataList = (perPage) => {
    let request = {
      page: 1,
      perPage: perPage ? perPage : 10,
      language: 'en',
      limit: 10,
      blogId: id
    };
    dispatch(getAllBlogsList(request, setIsloading));
  };
  // const handleScroll = () => {
  //   setIsloading(true);
  //   limit = limit + 10;
  //   fetchBlogDataList(limit);
  // };

  return (
    <>
      <div className="w-100" style={{ background: '#e5e5e5', minHeight: '100vh' }}>
        <Header background color />

        <div className="container bg-white p-4" style={{ marginTop: '4.5rem' }}>
          <div
            style={{
              fontFamily: 'Circular Std Medium',
              cursor: 'pointer'
            }}>
            <Link to={'/'} style={{ color: '#63575778', textDecoration: 'none' }}>
              Home
            </Link>
            &nbsp;/&nbsp;<span>My Blogs</span>
          </div>
          {isLoading ? <PopOverSpinner /> : ''}
          <div>
            {blogsData?.length > 0 ? (
              <>
                {blogsData?.map((data, index) => (
                  <div key={index} className={` ${screenType === 'MOBILE' ? 'pt-4' : 'p-4'}`}>
                    <div
                      className="shadow p-3 mb-2 bg-white rounded row"
                      style={{ height: 'auto' }}
                      onClick={() => navigate(`/car-blog/blog-detail/${data?.id}`)}>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="position-relative">
                          <img
                            src={data?.blogThumbnail}
                            width={'100%'}
                            height={'200px'}
                            style={{ objectFit: 'cover', borderRadius: '1rem' }}
                          />
                        </div>
                      </div>
                      <div
                        className="col-lg-8 col-md-8 col-sm-12"
                        style={{ marginTop: screenType === 'MOBILE' ? '1REM' : '' }}>
                        <div className="d-grid align-items-center h-100 w-100">
                          <div className="d-flex justify-content-start blog-date">
                            {moment(data?.createdAt).format('Do MMM, YYYY')}
                          </div>
                          <div
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}>
                            <h3 className="blog-main-heading">{data?.addTags}</h3>
                            <h6
                              className="blog-date"
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '700px'
                              }}>
                              {data?.writeBlogs}
                            </h6>
                          </div>
                          {screenType === 'MOBILE' ? (
                            <div>
                              <small className="d-inline d-md-inline smallText">
                                {data?.mainTitle}
                              </small>
                              &nbsp;&nbsp;
                              <small className="d-inline d-md-inline">
                                <Icon icon="mdi:dot" />
                              </small>
                              &nbsp;&nbsp;
                              <small className="d-inline d-md-inline smallText">
                                {data?.mainTitle}
                              </small>
                            </div>
                          ) : (
                            <div>
                              <small className="d-inline d-md-inline smallText">
                                {data?.mainTitle}
                              </small>
                              &nbsp;&nbsp;
                              <small className="d-inline d-md-inline">
                                <Icon icon="mdi:dot" />
                              </small>
                              &nbsp;&nbsp;
                              <small className="d-inline d-md-inline smallText">
                                {data?.mainTitle}
                              </small>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="noData" style={{ height: '82vh' }}>
                No Data Found
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
