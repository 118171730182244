import './index.css';

import image from '../../assets/img/auth.png';
import { useResponsive } from '../../themes/useResponsive';
import { Link, Outlet } from 'react-router-dom';
import logo from '../../assets/img/authLogo.png';
export default function AuthLayout() {
  const { screenType } = useResponsive();
  return (
    <>
      <div className="d-lg-flex half">
        <div
          className="bg order-1 order-md-2"
          style={{
            backgroundImage: `url("${image}")`,
            display: screenType != 'desktop' ? 'none' : ''
          }}></div>
        <div className="contents order-2 order-md-1" style={{ background: 'white' }}>
          <div className="container">
            <div className="row align-items-center justify-content-center py-3">
              <div className="col-md-8">
                <div>
                  <Link to="/">
                    <img src={logo} height={'20%'} width={'40%'} />
                  </Link>
                </div>

                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
