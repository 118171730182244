import React from 'react';
import './index.css';
export default function NotFoundPage() {
  return (
    <>
      <section className="error-container">
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
        <span className="zero">
          <span className="screen-reader-text">0</span>
        </span>
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
      </section>
      <div className="link-container">
        <a href="/" className="more-link">
          GO TO HOME
        </a>
      </div>
    </>
  );
}
