import LatestCardImageBox from 'pages/landingPage/subComponent/latestCars/latestCardImageBox';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ColoredButton from 'components/button/colorFullButtons';
import Spinner from 'react-bootstrap/Spinner';

const BookingHistory = ({ ...props }) => {
  const { allBookingsList, limit, setLimit, isLoading, getAllList, bookingTotalCount, updateFav } =
    props;
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log('allBookingsList', allBookingsList);
  return (
    <>
      <div className="container" style={{ minHeight: '80vh' }}>
        <div className="row mx-2 mt-5">
          {allBookingsList?.length >= 1 ? (
            <>
              {allBookingsList?.map((item, index) => {
                console.log('item -- ', item.isFavorite);
                return (
                  <div className="col-lg-4 col-md-6 col-sm-12 gy-3" key={index}>
                    <LatestCardImageBox
                      bookingHistory
                      excludeLinks
                      updateFav={updateFav}
                      data={item?.productData}
                      isFavorite={item.isFavorite}
                      onClick={() => navigate(`/car-details/${item?.productData?.id}`)}
                    />
                    {/* <CardBox bookingHistory data={item?.productData} /> */}
                  </div>
                );
              })}

              <div className="d-flex justify-content-center mt-5">
                {isLoading ? (
                  <Spinner animation="border" style={{ color: '#6CD2C2' }} />
                ) : (
                  bookingTotalCount > limit && (
                    <ColoredButton
                      onClick={() => {
                        let perPage = limit + 10;
                        setLimit(perPage);
                        getAllList(perPage);
                      }}
                      title="View More"
                      style={{ background: '#6CD2C2', width: '130px' }}
                    />
                  )
                )}
              </div>
            </>
          ) : (
            <div className="noData" style={{ height: '70vh' }}>
              No Data Found
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BookingHistory;
